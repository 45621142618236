import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getAllDocumentsWithSort } from '../common/Firebase';
import { useCore } from '../core/CoreContextProvider';
import LoadingSpinner from '../common/Loading';
import ViewGuardianPopup from './ViewGuardianPopUp';
import BackButton from '../common/BackButton';
import MaterialTable from '../common/MaterialTable';

export function ManageGuardian() {
  const { firestore } = useCore();
  const [guardian, setGuardian] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openViewGuardianPopUp, setOpenViewGuardianPopUp] = useState('');
  const [viewData, setViewData] = useState({});
  useEffect(() => {
    const guardian = async () => {
      try {
        const data = await getAllDocumentsWithSort(
          firestore,
          'guardian',
          'guardian_id'
        );
        if (data) {
          setGuardian(data);
        }
      } catch (err) {
        console.error(`Error while fetching guardian: `, err.message);
      }
      setLoading(false);
    };
    guardian();
  }, []);

  useEffect(() => {
    if (openViewGuardianPopUp !== '') {
      const filterViewData = guardian.filter(
        (guardian) => guardian.guardian_id === openViewGuardianPopUp
      )[0];
      setViewData(filterViewData);
    } else {
      setViewData({});
    }
  }, [openViewGuardianPopUp]);

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton />
            <h2 className="text-2xl font-medium mr-auto">
              Manage Guardian Enrollment
            </h2>
          </div>
          {loading && <LoadingSpinner />}
          <div className="grid grid-cols-12 gap-6 mt-5">
            <div className="col-span-12 lg:col-span-12">
              <div className="bg-white shadow-md rounded-md">
                <div className="w-full mx-auto text-left flex mt-5">
                  <div className="table-section w-full overflow-x-auto">
                    <MaterialTable
                      columns={[
                        {
                          title: 'GUARDIAN ID',
                          field: 'guardian_id',
                        },
                        {
                          title: 'GUARDIAN NAME',
                          sorting: false,
                          render: (guardian) => {
                            return `${guardian.first_name} ${guardian.middle_name} ${guardian.last_name}`;
                          },
                        },
                        {
                          title: 'Email Address',
                          field: 'email_address',
                          sorting: false,
                        },
                        {
                          title: 'STATUS',
                          field: 'guardian_status',
                          sorting: false,
                          render: (guardian) => {
                            const colorClass =
                              guardian.guardian_status === 'active'
                                ? 'text-green-500'
                                : guardian.guardian_status == 'inactive'
                                ? 'text-gray-500'
                                : guardian.guardian_status == 'registered' ||
                                  guardian.guardian_status == 'created'
                                ? 'text-black'
                                : 'text-blue-900';
                            return (
                              <p className={colorClass}>
                                {guardian.guardian_status}
                              </p>
                            );
                          },
                        },

                        {
                          title: 'ACTION',
                          sorting: false,
                          render: (element) => (
                            <div className="flex items-center">
                              <div
                                onClick={() =>
                                  setOpenViewGuardianPopUp(element.guardian_id)
                                }
                                className="border p-3 mr-2 tooltip relative"
                              >
                                <span className="bg-view block cursor-pointer"></span>
                                <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[39px] after:left-[19px] after:z-[9999]">
                                  View
                                </span>
                              </div>
                              <NavLink
                                to="/edit/guardian"
                                state={{ id: element.id ?? element.objectID }}
                                className="border p-3 mr-2 tooltip relative"
                              >
                                <span className="bg-edit block cursor-pointer"></span>
                                <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[39px] after:left-[16px] after:z-[9999]">
                                  Edit
                                </span>
                              </NavLink>
                            </div>
                          ),
                        },
                      ]}
                      algoliaIndex={
                        process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + '_guardian'
                      }
                    />
                    <ViewGuardianPopup
                      open={openViewGuardianPopUp !== ''}
                      data={viewData}
                      handleClose={() => setOpenViewGuardianPopUp('')}
                    />
                  </div>
                </div>
              </div>
              {!loading && !guardian.length && (
                <div className="text-center bg-indigo-100 text-black font-bold rounded-t px-4 py-2 w-80 mb-3">
                  <p>No Guardian Found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
