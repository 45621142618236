import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import GetAnswer from '../../InputTag/GetAnswer';
import Operand from '../../InputTag/Operand';

const SingleType = ({
  nextStep,
  step,
  storeAnswer,
  storedResponse,
  actualCondition,
  getStepNumber,
}) => {
  const [response, setResponse] = useState([]);

  const storeResponse = (value, name, index) => {
    const tempArr = [...response];
    tempArr[index][name] = value;
    setResponse(tempArr);
    storeAnswer(tempArr);
  };

  useEffect(() => {
    if (storedResponse.length) {
      setResponse(storedResponse);
    } else {
      const init = { operand: '', target: '' };
      init.operand = step.operand ? step.operand : '==';
      setResponse([init]);
    }
  }, [storedResponse, step]);

  return (
    <>
      {response.map((res, index) => {
        return (
          <>
            <div className="w-full">
              <div className="flex w-full justify-center">
                {storedResponse.length !== 0 && (
                  <>
                    <div className="mr-4 w-1/2">
                      <Operand
                        step={step}
                        storeAnswer={storeResponse}
                        response={res.operand}
                        index={index}
                        operand={res.operand}
                      />
                    </div>
                    <div className="ml-4 w-1/2">
                      <GetAnswer
                        step={step}
                        storeAnswer={storeResponse}
                        response={actualCondition.answer}
                        disable={true}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="w-full flex justify-between mt-4">
                <div className="w-1/3 flex-auto">
                  <div className="w-full text-center px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 focus:outline-none">
                    Result
                  </div>
                </div>

                <div className="w-2/3 flex-auto ml-4">
                  <select
                    onChange={(e) => {
                      storeResponse(e.target.value, e.target.name, index);
                    }}
                    name={'target'}
                    value={res.target}
                    className="px-6 w-full py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2 hover:shadow-lg focus:outline-none "
                  >
                    <option value="">Select your answer</option>
                    {nextStep.map((e) => {
                      return (
                        <option key={e.id} value={e.id}>
                          {getStepNumber(e)}) {e.heading}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
SingleType.propTypes = {
  nextStep: PropTypes.array,
  step: PropTypes.object,
  storeAnswer: PropTypes.func,
  storedResponse: PropTypes.array,
  actualCondition: PropTypes.any,
  getStepNumber: PropTypes.func,
};
export default SingleType;
