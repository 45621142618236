import { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Option, Select } from '@material-tailwind/react';
import { addDocument } from '../common/Firebase';
import log from '../core/log';
import { useCore } from '../core/CoreContextProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { formStepsValidationSchema } from '../common/Validation';
import Autocomplete from './AutoComplete';
import CreateValidation from './Validation/CreateValidation';
import { toast } from 'react-toastify';
import { updateDoc, doc } from 'firebase/firestore';
import EditValidation from './Validation/EditValidation';

const SubSteps = ({
  createEmptyOptions,
  popup,
  resetTopic,
  setAddedSteps,
  addedSteps,
  formData,
  variable,
  setLoading,
  checkQueryExist,
  eachStepData,
  setEachStepData,
  selectedSubStep,
  setAddedVariable,
  addedVariable,
  setSelectedSubStep,
  resetStep,
}) => {
  const { topicData } = popup;
  const [options, setOptions] = useState({});
  const [formType, setFormType] = useState('free_text');
  const [addedSubSteps, setAddedSubSteps] = useState([]);
  const [totalSteps, setTotalSteps] = useState('2');
  const { firestore } = useCore();
  const [stepAdded, setStepAdded] = useState();
  const [popupEditValidation, setPopupEditValidation] = useState({
    show: false,
    data: null,
  });
  const heading = useRef();
  const formOptions = {
    resolver: yupResolver(formStepsValidationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm(formOptions);

  useEffect(() => {
    if (formType !== 'free_text') {
      const data = createEmptyOptions();
      setOptions(data);
    }
    if (eachStepData?.heading) {
      setAddedSubSteps(eachStepData.sub_steps);
    }
    if (selectedSubStep?.heading) {
      reset(selectedSubStep);
      heading.current.value = selectedSubStep.heading;
    }
  }, [formType]);

  const setFields = (value, key) => {
    let tempObj = { ...options };
    tempObj[key] = value;
    setOptions(tempObj);
  };

  const addOption = () => {
    const optionsArr = [...Object.keys(options)];
    let tempObj = {};
    tempObj[+optionsArr[optionsArr.length - 1] + 1] = '';
    if (Object.keys(options).length < 20)
      setOptions({ ...options, ...tempObj });
  };

  const onSubmit = async (data) => {
    if (selectedSubStep?.heading) {
      setLoading(true);
      let finalData = {
        ...data,
        heading: heading.current.value,
      };
      if (
        data.type == 'multi_choice_question' ||
        data.type == 'multi_select_question'
      ) {
        finalData = { ...finalData, options: { ...options } };
      }

      finalData.topic_id = topicData.id;
      finalData.step_no = selectedSubStep.step_no;
      let id = await updateDoc(
        doc(
          firestore,
          `tapouts_form/${formData.form_name}/steps/${topicData.id}/sub_steps`,
          selectedSubStep.id
        ),
        finalData
      );
      setSelectedSubStep(finalData);
      addedSteps.forEach((e) => {
        if (e.id == topicData.id) {
          e.sub_steps.forEach((e1, index) => {
            if (e1.id == selectedSubStep.id) {
              e.sub_steps[index] = { ...finalData, selectedSubStep: id };
              setEachStepData(e);
              setAddedSubSteps(e.sub_steps);
            }
          });
        }
      });
      setAddedSteps(addedSteps);
      setPopupEditValidation({
        show: true,
        data: finalData,
      });
      toast.success(`Step-${finalData.step_no} has been updated`);
      setLoading(false);
    } else {
      if (checkQueryExist(data.url_query)) {
        setLoading(true);
        let finalData = {
          ...data,
          heading: heading.current.value,
          validations: '',
        };

        if (
          data.type == 'multi_choice_question' ||
          data.type == 'multi_select_question'
        ) {
          finalData = { ...finalData, options: { ...options } };
        }

        finalData.topic_id = topicData.id;

        finalData.step_no = addedSubSteps.length + 1;
        if (finalData.variable) {
          setAddedVariable((oldArr) => [...oldArr, finalData.variable]);
        } else {
          finalData.variable = '';
        }

        try {
          let id = await addDocument(
            `tapouts_form/${formData.form_name}/steps/${topicData.id}/sub_steps`,
            firestore,
            finalData
          );

          addedSteps.forEach((e) => {
            if (e.id == topicData.id) {
              e.sub_steps = [...addedSubSteps, { ...finalData, id: id }];
              if (eachStepData) setEachStepData(e);
            }
          });
          setStepAdded({ ...finalData, id: id });
          setAddedSteps(addedSteps);
          setAddedSubSteps((oldSteps) => [
            ...oldSteps,
            { ...finalData, id: id },
          ]);
          toast.success(`Step-${finalData.step_no} has been created`);

          setLoading(false);
        } catch (error) {
          log('Firebase error: while adding history data', error);
          setLoading(false);
        }
      } else {
        toast.info('Url query already exist');
      }
    }
  };

  const resetField = () => {
    reset({ type: '', sub_heading: '', url_query: '' });
    heading.current.value = '';
    setOptions({});
    setFormType('free_text');
    setStepAdded();

    if (totalSteps == addedSubSteps.length) {
      setTotalSteps('2');
      resetTopic();
    }
  };
  const removeOption = (key) => {
    let tempObj = { ...options };
    delete tempObj[key];
    if (Object.keys(options).length > 2) setOptions(tempObj);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mr-4 flex-auto w-1/3 relative mt-5">
          <input
            id="form_status"
            type="number"
            min="1"
            defaultValue={totalSteps}
            placeholder=" "
            className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
            onChange={(e) => {
              setTotalSteps(e.target.value);
            }}
          />
          <label
            htmlFor="no_of_steps"
            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
          >
            No Of Steps
            <span className="text-red-600">*</span>
          </label>
        </div>
        <div className="flex w-3/3">
          <div className="flex-auto w-1/3 relative mt-3">
            <Autocomplete
              suggestions={variable}
              inputValue={heading}
              addedVariables={addedVariable}
            />
            <label
              htmlFor="heading"
              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
            >
              Heading
              <span className="text-red-600">*</span>
            </label>
          </div>
          {selectedSubStep != undefined &&
          Object.keys(selectedSubStep).length != 0 &&
          selectedSubStep?.variable != '' ? (
            <>
              <div className="flex-auto ml-4 mt-3 w-1/3 relative">
                <input
                  type="text"
                  placeholder=" "
                  defaultValue={variable[selectedSubStep.variable]}
                  disabled={true}
                  className={`pt-6 bg-gray-300 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                />
                <label
                  htmlFor="heading"
                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                >
                  variable
                  <span className="text-red-600"></span>
                </label>
              </div>

              <div
                className="flex-auto ml-4 mt-3 w-1/3 relative item-center"
                onClick={() => {
                  let key = selectedSubStep.variable;
                  selectedSubStep.variable = '';
                  setSelectedSubStep(selectedSubStep);
                  let arr = [...addedVariable];
                  const index = arr.indexOf(key);
                  if (index > -1) {
                    arr.splice(index, 1);
                  }
                  setAddedVariable(arr);
                }}
              >
                <a className=" p-1 mr-1 cursor-pointer text-red-600">
                  <span className="block">Change variable</span>
                </a>
              </div>
            </>
          ) : (
            <div className="flex-auto ml-2 mt-3 w-1/3 relative">
              {Object.keys(variable).length !== 0 &&
                Object.keys(variable).length !== addedVariable.length && (
                  <Controller
                    name={'variable'}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          onChange={(e) => {
                            onChange(e);
                          }}
                          id="variable"
                          value={value}
                          label="Select variable"
                          className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                            value ? ' focus-label' : ''
                          }`}
                        >
                          {Object.keys(variable).map((e) => {
                            return (
                              <>
                                {!addedVariable.includes(e) && (
                                  <Option key={btoa(e)} value={e}>
                                    {variable[e]}
                                  </Option>
                                )}
                              </>
                            );
                          })}
                        </Select>
                      );
                    }}
                    rules={{ required: false }}
                  />
                )}
            </div>
          )}
        </div>
        <div className="mr-4 flex-auto w-1/3 relative mt-3">
          <span className="text-red-600 absolute -top-6">
            {errors?.sub_heading?.message}
          </span>
          <input
            type="text"
            placeholder=" "
            defaultValue=""
            className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
            {...register('sub_heading')}
          />
          <label
            htmlFor="heading"
            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
          >
            Sub-Heading
          </label>
        </div>
        <div className="mr-4 flex-auto w-1/3 relative mt-3">
          <span className="text-red-600 absolute -top-6">
            {errors?.url_query?.message}
          </span>
          <input
            type="text"
            placeholder=" "
            defaultValue=""
            className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
            {...register('url_query')}
          />
          <label
            htmlFor="heading"
            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
          >
            Url-Query
            <span className="text-red-600">*</span>
          </label>
        </div>

        <div className="flex-auto mr-4 w-1/3 relative mt-5">
          <span className="text-red-600 absolute -top-6">
            {errors?.type?.message}
          </span>
          <Controller
            name={'type'}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  onChange={(e) => {
                    onChange(e);
                    setFormType(e);
                  }}
                  id="type"
                  value={value}
                  label="Select type"
                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                    value ? ' focus-label' : ''
                  }`}
                >
                  <Option value={'multi_choice_question'}>
                    Multi Choice Question
                  </Option>
                  <Option value={'free_text'}>Free Text</Option>
                  <Option value={'multi_select_question'}>
                    Multi Select Question
                  </Option>
                  <Option value={'number'}>Number</Option>
                </Select>
              );
            }}
            rules={{ required: false }}
          />
        </div>

        {(formType === 'multi_choice_question' ||
          formType === 'multi_select_question') && (
          <ul>
            {Object.keys(options).map((key, index) => (
              <li key={key}>
                <div className="mr-4 flex-auto w-1/3 relative mt-3 ">
                  <input
                    type="text"
                    placeholder=" "
                    defaultValue={options[key]}
                    className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                    onChange={(e) => {
                      setFields(e.target.value, key);
                    }}
                  />

                  <label
                    htmlFor="heading"
                    className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                  >
                    option - {index + 1} <span className="text-red-600">*</span>
                  </label>
                  {index + 1 === Object.keys(options).length ? (
                    <button type="button" onClick={() => addOption()}>
                      +
                    </button>
                  ) : (
                    <button type="button" onClick={() => removeOption(key)}>
                      -
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}

        {!stepAdded && (
          <div className="p-5 text-center">
            <button className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none">
              {selectedSubStep?.heading ? 'Update sub step' : 'Add sub step'}
            </button>
          </div>
        )}
      </form>
      {stepAdded && (
        <CreateValidation
          step={stepAdded}
          form_name={formData.form_name}
          resetStep={resetField}
          setLoading={setLoading}
        />
      )}
      {popupEditValidation.show && (
        <EditValidation
          step={stepAdded}
          form_name={formData.form_name}
          resetStep={resetStep}
          setLoading={setLoading}
          popupEditValidation={popupEditValidation}
        />
      )}
    </>
  );
};

SubSteps.propTypes = {
  createEmptyOptions: PropTypes.func.isRequired,
  popup: PropTypes.object,
  resetTopic: PropTypes.func,
  setAddedSteps: PropTypes.func,
  addedSteps: PropTypes.array,
  formData: PropTypes.object,
  variable: PropTypes.object,
  setLoading: PropTypes.func,
  checkQueryExist: PropTypes.func,
  setEachStepData: PropTypes.func,
  eachStepData: PropTypes.object,
  selectedSubStep: PropTypes.object,
  setAddedVariable: PropTypes.func,
  addedVariable: PropTypes.array,
  setSelectedSubStep: PropTypes.func,
  resetStep: PropTypes.func,
};

export default SubSteps;
