import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getAllDocumentsWithSort } from '../common/Firebase';
import { useCore } from '../core/CoreContextProvider';
import ViewModulePopup from './ViewModulePopup';
import LoadingSpinner from '../common/Loading';
import BackButton from '../common/BackButton';
import MaterialTable from '../common/MaterialTable';

export function ManageModule() {
  const { firestore } = useCore();
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openViewModulePopUp, setOpenViewModulePopUp] = useState('');
  const [viewData, setViewData] = useState({});

  useEffect(() => {
    const modules = async () => {
      try {
        const data = await getAllDocumentsWithSort(
          firestore,
          'modules',
          'module_id'
        );
        if (data) {
          setModules(data);
        }
      } catch (err) {
        console.error(`Error while fetching modules: `, err.message);
      }
      setLoading(false);
    };
    modules();
  }, []);

  useEffect(() => {
    const filterViewData = modules.filter(
      (module) => module.module_id === openViewModulePopUp
    )[0];
    setViewData(filterViewData);
  }, [openViewModulePopUp]);

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton />
            <h2 className="text-2xl font-medium mr-auto">Manage Module</h2>
          </div>
          {loading && <LoadingSpinner />}
          <div className="grid grid-cols-12 gap-6 mt-5">
            {modules.length !== 0 && (
              <div className="col-span-12 lg:col-span-12">
                <div className="bg-white shadow-md rounded-md">
                  <div className="w-full mx-auto text-left flex mt-5">
                    <div className="table-section w-full">
                      {!loading && (
                        <MaterialTable
                          columns={[
                            {
                              title: 'MODULE ID',
                              field: 'module_id',
                            },
                            {
                              title: 'MODULE NAME',
                              field: 'module_name',
                              sorting: false,
                            },
                            {
                              title: 'STATUS',
                              field: 'module_status',
                              sorting: false,
                              render: (module) => {
                                return (
                                  <p
                                    className={
                                      'font-bold px-6 py-4 whitespace-nowrap ' +
                                      (module.module_status === 'created'
                                        ? 'text-black'
                                        : module.module_status == 'active'
                                        ? 'text-green-500'
                                        : 'text-gray-400')
                                    }
                                  >
                                    {module.module_status}
                                  </p>
                                );
                              },
                            },
                            {
                              title: 'LINKED_SESSIONS',
                              sorting: false,
                              render: (module) => {
                                return module.sessions
                                  ? module.sessions.length
                                  : 0;
                              },
                            },
                            {
                              title: 'ACTION',
                              sorting: false,
                              render: (element) => (
                                <div className="flex items-center">
                                  <a
                                    className="border p-3 mr-2 modal-open cursor-pointer tooltip relative"
                                    onClick={() =>
                                      setOpenViewModulePopUp(element.module_id)
                                    }
                                  >
                                    <span className="bg-view block"></span>
                                    <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[40px] after:left-[18px] after:z-[9999]">
                                      View
                                    </span>
                                  </a>
                                  <NavLink
                                    to="/edit/module"
                                    state={{
                                      id:
                                        element.id && element.id !== ''
                                          ? element.id
                                          : element.objectID,
                                    }}
                                    className="border p-3 mr-2 tooltip relative"
                                  >
                                    <span className="bg-edit block"></span>
                                    <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[39px] after:left-[16px] after:z-[9999]">
                                      Edit
                                    </span>
                                  </NavLink>
                                </div>
                              ),
                            },
                          ]}
                          algoliaIndex={
                            process.env.REACT_APP_ALGOLIA_INDEX_PREFIX +
                            '_modules'
                          }
                        />
                      )}
                      {openViewModulePopUp !== '' && (
                        <ViewModulePopup
                          open={openViewModulePopUp !== ''}
                          data={viewData}
                          handleClose={() => setOpenViewModulePopUp('')}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!loading && !modules.length && (
              <div className="text-center bg-indigo-100 text-black font-bold rounded-t px-4 py-2 w-80 mb-3 ">
                <p>No Module Found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
