import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ValueMultiple from './ValueMultiple';

const MultiType = ({
  previousStep,
  step,
  storeAnswer,
  storedResponse,
  getStepNumber,
}) => {
  console.log(step);
  const initial = [
    { step_id: '', type: '', answer: '', operand: '', connector: '' },
  ];
  const [addedStep, setAddedStep] = useState([]);
  const [response, setResponse] = useState([]);

  const UpdateResponse = (value, index) => {
    const tempArr = [...response];
    tempArr[index] = value;
    tempArr[index].sequence = index;

    storeAnswer(tempArr);
    setResponse(tempArr);
  };

  useEffect(() => {
    if (storedResponse.length) {
      let tempArr = [...addedStep];
      storedResponse.forEach((res, index) => {
        tempArr[index] = res.step_id;
      });
      setResponse(storedResponse);
      setAddedStep(tempArr);
    } else {
      setResponse(initial);
    }
  }, [storedResponse]);

  const checkResponse = () => {
    const last = response.slice(-1);
    const notFilled =
      last[0].answer == '' || last[0].operand == '' || last[0].connector == '';
    return notFilled;
  };

  const addAnotherCondition = () => {
    const notFilled = checkResponse();
    if (notFilled) {
      toast.info('please fill all the field');
    } else if (addedStep.length == previousStep.length) {
      toast.info('You have reached the limit');
    } else {
      setResponse((oldArr) => [...oldArr, ...initial]);
    }
  };

  return (
    <>
      {response.length &&
        response.map((res, index) => {
          return (
            <div key={btoa(index)}>
              <ValueMultiple
                previousStep={previousStep}
                response={res}
                UpdateResponse={UpdateResponse}
                setAddedStep={setAddedStep}
                index={index}
                addedStep={addedStep}
                getStepNumber={getStepNumber}
              />
            </div>
          );
        })}

      <div className="py-2 text-center">
        <button
          onClick={addAnotherCondition}
          className="mt-4 w-40 text-center px-3 py-1  text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-green-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
        >
          Add Another Condition
        </button>
      </div>
    </>
  );
};

MultiType.propTypes = {
  previousStep: PropTypes.array,
  step: PropTypes.object,
  storeAnswer: PropTypes.func,
  storedResponse: PropTypes.array,
  getStepNumber: PropTypes.func,
};

export default MultiType;
