import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LoadingSpinner from '../common/Loading';
import { useCore } from '../core/CoreContextProvider';
import { ViewCourse } from './ViewCourse';
import HierarchryPopup from './HierarchyPopup';
import BackButton from '../common/BackButton';
import { findCourse } from '../common/utils';
import MaterialTable from '../common/MaterialTable';

export const ManageCourse = () => {
  const { firestore } = useCore();
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState({ show: false, data: null });
  const [hierarchryPopup, setHierarchryPopup] = useState({
    show: false,
    data: null,
  });

  const showHirarcy = async (courseId, data) => {
    setLoading(true);
    const { courseData, status } = await findCourse(firestore, courseId);
    setHierarchryPopup({
      show: true,
      displayData: courseData,
      actualData: data,
      status: status,
      podCheck: true,
    });
    setLoading(false);
  };
  useEffect(() => {
    hierarchryPopup.show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [hierarchryPopup]);

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton />
            <h2 className="text-2xl font-medium mr-auto">Manage Course</h2>
          </div>
          {loading && <LoadingSpinner />}
          <div className="grid grid-cols-12 gap-6 mt-5">
            <div className="col-span-12 lg:col-span-12">
              <div className="bg-white shadow-md rounded-md">
                <div className="p-5">
                  <div className="w-full mx-auto text-left flex mt-5">
                    <div className="table-section w-full overflow-x-auto">
                      <MaterialTable
                        columns={[
                          {
                            title: 'COURSE ID',
                            field: 'course_id',
                          },
                          {
                            title: 'COURSE NAME',
                            sorting: false,
                            render: (courses) => {
                              return courses.course_name;
                            },
                          },
                          {
                            title: 'STATUS',
                            field: 'course_status',
                            sorting: false,
                            render: (courses) => {
                              const colorClass =
                                courses.course_status === 'active'
                                  ? 'text-green-500'
                                  : courses.course_status == 'inactive'
                                  ? 'text-gray-500'
                                  : courses.course_status == 'registered' ||
                                    courses.course_status == 'created'
                                  ? 'text-black'
                                  : 'text-blue-900';
                              return (
                                <p className={colorClass}>
                                  {courses.course_status}
                                </p>
                              );
                            },
                          },
                          {
                            title: 'LINKED LEVELS',
                            sorting: false,
                            render: (courses) => {
                              return courses.levels ? courses.levels.length : 0;
                            },
                          },
                          {
                            title: 'ACTION',
                            sorting: false,
                            render: (element) => (
                              <div className="flex items-center">
                                <div
                                  onClick={() =>
                                    setPopup({
                                      show: true,
                                      data: element,
                                    })
                                  }
                                  className="border p-3 mr-2 tooltip relative"
                                >
                                  <span className="bg-view block cursor-pointer"></span>
                                  <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[39px] after:left-[19px] after:z-[9999]">
                                    View
                                  </span>
                                </div>
                                <NavLink
                                  to="/edit/course"
                                  state={{ id: element.objectID }}
                                  className="border p-3 mr-2 tooltip relative"
                                >
                                  <span className="bg-edit block cursor-pointer"></span>
                                  <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[35px] after:left-[14px] after:z-[9999]">
                                    Edit
                                  </span>
                                </NavLink>
                                <a
                                  className="border p-3 cursor-pointer tooltip relative"
                                  onClick={() =>
                                    showHirarcy(element.objectID, element)
                                  }
                                >
                                  <span>
                                    <svg
                                      viewBox="0 0 202.205 202.205"
                                      width="26px"
                                      height="26px"
                                    >
                                      <g>
                                        <path
                                          d="M172.912,38.304V0h-38.315v12.551H96.114V96.79H80.993V73.692h-51.7v51.707h51.7v-23.098h15.121
		v84.246h38.483v15.657h38.315V163.89h-38.315v17.146h-32.976v-78.735h32.976V118.7h38.315V80.392h-38.315V96.79h-32.976V18.062
		h32.976v20.242H172.912z M167.401,5.515v27.278h-27.289V5.515H167.401z M167.401,85.907v27.278h-27.289V85.907H167.401z
		 M167.401,169.405v27.289h-27.289v-27.289H167.401z M34.808,119.884v-40.67h40.67v40.67H34.808z"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 left-[-23px] hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[39px] after:left-[37px] after:z-[9999]">
                                    Hierarchy
                                  </span>
                                </a>
                              </div>
                            ),
                          },
                        ]}
                        algoliaIndex={
                          process.env.REACT_APP_ALGOLIA_INDEX_PREFIX +
                          '_courses'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewCourse popup={popup} setPopup={setPopup} />
      <HierarchryPopup popup={hierarchryPopup} setPopup={setHierarchryPopup} />
    </div>
  );
};
