import { useEffect, useState } from 'react';
import { useCore } from '../core/CoreContextProvider';
import BackButton from '../common/BackButton';
import { addDocument, getData, updateDocument } from '../common/Firebase';
import log from '../core/log';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { blurbValidationSchema } from '../common/Validation';
import { useLocation } from 'react-router';
import LoadingSpinner from '../common/Loading';
import { useForm } from 'react-hook-form';

const CreateBlurb = () => {
  const { firestore } = useCore();
  const formOptions = { resolver: yupResolver(blurbValidationSchema) };
  const [toasting, setToasting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({});
  const location = useLocation();
  const { id } = location.state || {};

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm(formOptions);

  useEffect(() => {
    (async () => {
      if (!id) {
        reset({ blurb_name: '', description: '', image_url: '' });
        createEmptyOptions();
        setLoading(false);
      } else {
        const data = await getData(firestore, id, 'tapouts_blurbs');
        reset(data);
        setOptions(data.buttons);
        setLoading(false);
      }
    })();
  }, [id]);

  const submithandle = async (data) => {
    setLoading(true);
    if (!id) {
      try {
        const finalData = { ...data, buttons: { ...options } };
        await addDocument(`tapouts_blurbs`, firestore, finalData);
        reset({ blurb_name: '', description: '', image_url: '' });
        createEmptyOptions();
        toast.success('Blurb has been created');
        setLoading(false);
      } catch (err) {
        log('Error while storing blurbs' + err);
        setLoading(false);
      }
    } else {
      try {
        const finalData = { ...data, buttons: { ...options } };
        const result = await updateDocument(
          id,
          'tapouts_blurbs',
          firestore,
          finalData
        );
        if (result) {
          toast.success(`Blurb updated Successfully`, {
            toastId: 'edit_coach',
            onClose: () => {
              setToasting(false);
            },
            onOpen: () => {
              setToasting(true);
            },
          });
        }
        reset({ blurb_name: '', description: '', image_url: '' });
        createEmptyOptions();
        setLoading(false);
      } catch (err) {
        log('Error while updating blurbs' + err);
        setLoading(false);
      }
    }
  };

  const setFields = (value, key, heading) => {
    let tempObj = { ...options };

    tempObj[key][heading] = value;

    setOptions(tempObj);
  };

  function createEmptyOptions() {
    let option = {};
    for (var i = 0; i < 3; i++) {
      option[i + 1] = {
        value: '',
        heading: '',
        text_colour: '',
        bg_colour: '',
      };
    }
    setOptions(option);
  }

  const addOption = () => {
    const optionsArr = [...Object.keys(options)];
    let tempObj = {};

    const index = +optionsArr[optionsArr.length - 1] + 1;
    tempObj[index] = { value: '', heading: '', text_colour: '', bg_colour: '' };
    if (Object.keys(options).length < 20)
      setOptions({ ...options, ...tempObj });
  };

  const removeOption = (key) => {
    let tempObj = { ...options };
    delete tempObj[key];
    if (Object.keys(options).length > 2) setOptions(tempObj);
  };

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-5 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton isDirty={isDirty} />
            <h2 className="text-2xl font-medium mr-auto">Create Blurb</h2>
          </div>
          {loading && <LoadingSpinner />}
          <div className="grid grid-cols-12 gap-6 mt-5">
            <div className="col-span-12 lg:col-span-12">
              <form onSubmit={handleSubmit(submithandle)}>
                <div className="bg-white shadow-md rounded-md">
                  <div className="p-5">
                    <div className="preview">
                      <div className="mt-5 flex flex-col sm:flex-row items-center gap-4">
                        <div className="w-8/12 flex-auto  relative mb-10">
                          <span className="text-red-600 absolute -top-8">
                            {errors.blurb_name?.message}
                          </span>
                          <input
                            id="blurb_name"
                            type="text"
                            className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                            placeholder=" "
                            {...register('blurb_name', {
                              required: true,
                            })}
                          />
                          <label
                            htmlFor="blurb_name"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Blurb Name
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                        <div className=" w-60  ml-20">
                          <div className="relative h-40 rounded-lg bg-white flex justify-center items-center hover:cursor-pointer">
                            <div className="absolute">
                              <div className="w-8/12 flex-auto  relative">
                                <span className="text-red-600 absolute -top-8">
                                  {errors.image_url?.message}
                                </span>
                                <input
                                  id="image_url"
                                  type="text"
                                  className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  placeholder=" "
                                  {...register('image_url')}
                                />
                                <label
                                  htmlFor="image_url"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Blurb Image
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="w-8/12 flex flex-col sm:flex-row items-center"
                        style={{ marginTop: '-4rem' }}
                      >
                        <div className="flex-auto w-full relative">
                          <span className="text-red-600 absolute -top-8 mt-2">
                            {errors.description?.message}
                          </span>
                          <textarea
                            id="description"
                            type="text"
                            className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md h-40"
                            placeholder=" "
                            {...register('description', {
                              required: true,
                            })}
                          ></textarea>
                          <label
                            htmlFor="description"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Description
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                      </div>

                      <div>
                        <ul>
                          {Object.keys(options).map((key, index) => (
                            <li key={key}>
                              <div className="mr-4 flex-auto w-1/3 relative mt-3 ">
                                <span className="text-red-600 absolute -top-6"></span>
                                <input
                                  type="text"
                                  placeholder=" "
                                  value={options[key].value}
                                  id="option"
                                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  onChange={(e) => {
                                    setFields(e.target.value, key, 'value');
                                  }}
                                />

                                <label
                                  htmlFor="option"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Button - {index + 1}{' '}
                                </label>
                              </div>

                              <div className="mr-4 flex-auto w-1/3 relative mt-3 ">
                                <input
                                  type="text"
                                  placeholder=" "
                                  id="option_heading"
                                  value={options[key].heading}
                                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  onChange={(e) => {
                                    setFields(e.target.value, key, 'heading');
                                  }}
                                />
                                <label
                                  htmlFor="option_heading"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Label - {index + 1}
                                </label>
                              </div>
                              <div className="mr-4 flex-auto w-1/3 relative mt-3 ">
                                <span className="text-red-600 absolute -top-6"></span>
                                <input
                                  type="text"
                                  placeholder=" "
                                  value={options[key].text_colour}
                                  id="option"
                                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  onChange={(e) => {
                                    setFields(
                                      e.target.value,
                                      key,
                                      'text_colour'
                                    );
                                  }}
                                />

                                <label
                                  htmlFor="option"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Text Colour
                                </label>
                              </div>
                              <div className="mr-4 flex-auto w-1/3 relative mt-3 ">
                                <span className="text-red-600 absolute -top-6"></span>
                                <input
                                  type="text"
                                  placeholder=" "
                                  value={options[key].bg_colour}
                                  id="option"
                                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  onChange={(e) => {
                                    setFields(e.target.value, key, 'bg_colour');
                                  }}
                                />

                                <label
                                  htmlFor="option"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Background Colour
                                </label>
                              </div>

                              {index + 1 === Object.keys(options).length ? (
                                <button
                                  type="button"
                                  onClick={() => addOption()}
                                >
                                  +
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => removeOption(key)}
                                >
                                  -
                                </button>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="p-5 text-center">
                        <button
                          disabled={toasting}
                          type="submit"
                          className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                        >
                          {id ? 'Update' : 'Create'} Blurb
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBlurb;
