import { collection, query, where } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getDataByQuery, updateFieldInDocument } from '../common/Firebase';
import { updatedPayload, compare } from '../common/utils';
import { useAuth } from '../core/AuthContextProvider';
import { useCore } from '../core/CoreContextProvider';
import log from '../core/log';
import { algoliaFunction } from '../core/searchUtils';

export const ManageCourseLevels = (props) => {
  const { showAddLevels, addedLevels, setAddedLevels, setLoading, courseId } =
    props;
  const [searchLevel, setSearchLevel] = useState('');
  const [listLevels, setListLevels] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const { firestore } = useCore();
  const { userData } = useAuth();

  const handleSearchLevel = async () => {
    setLoading(true);
    if (searchLevel != '') {
      let filterArray = [
        {
          connector: '',
          conditions: [
            {
              connector: '',
              fieldName: 'level_status',
              fieldValue: 'active',
              operator: ':',
            },
          ],
        },
      ];
      if (addedLevels?.displayContent?.length) {
        const obj = {
          connector: 'AND',
          conditions: addedLevels?.displayContent?.map((level, index) => {
            return {
              connector: index == 0 ? '' : 'AND',
              fieldName: 'level_id',
              fieldValue: level.level_id,
              operator: 'NOT',
            };
          }),
        };
        filterArray.push(obj);
      }
      const result = await algoliaFunction(
        filterArray,
        process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + '_levels',
        searchLevel,
        40,
        0
      );
      let hits = result.hits ? result.hits : [];
      let i;
      for (i = 0; i < hits.length; i++) {
        hits[i].id = hits[i]['objectID'];
        delete hits[i].objectID;
      }
      if (hits.length) {
        hits.sort(function (it1, it2) {
          return compare(it1, it2, 'level_id');
        });
        setListLevels(hits);
      } else {
        toast.error('No levels are found');
      }
    } else {
      toast.error('Please enter the level name');
    }
    setLoading(false);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const selectedIds = listLevels.map(({ id }) => id);
      setSelectedLevels(selectedIds);
    } else {
      setSelectedLevels([]);
    }
  };

  const handleSelectOne = (e) => {
    let tempArr = [...selectedLevels];
    if (e.target.checked) {
      tempArr.push(e.target.value);
      setSelectedLevels(tempArr);
    } else {
      let index = tempArr.indexOf(e.target.value);
      index != -1 ? tempArr.splice(index, 1) : '';
      setSelectedLevels(tempArr);
    }
  };

  const filterLevel = async (levelIds) => {
    let levels = listLevels.filter(({ id }) => {
      return selectedLevels.includes(id);
    });
    levels = addedLevels?.displayContent?.length
      ? levels.concat(addedLevels.displayContent)
      : levels;
    setAddedLevels({
      actualContent: levelIds,
      displayContent: levels,
    });
  };

  const handleAddLevels = async () => {
    setLoading(true);
    const levelIds = addedLevels?.actualContent?.length
      ? selectedLevels.concat(addedLevels.actualContent)
      : selectedLevels;
    if (levelIds.length != 0) {
      try {
        updatedPayload(
          {
            levels: levelIds,
          },
          'course',
          'update',
          userData.uid
        ).then(async (payLoadData) => {
          await updateFieldInDocument(
            firestore,
            'courses',
            courseId,
            payLoadData
          );
          filterLevel(levelIds);
          setSelectedLevels([]);
          getActiveLevels(levelIds);
          setLoading(false);
          toast.success('the selected level is added');
        });
      } catch (err) {
        log('Firebase error: ', err);
      }
    } else {
      toast.error('Please select the level');
    }
  };

  const getActiveLevels = async (levels) => {
    setLoading(true);
    const levelRef = collection(firestore, 'levels');
    const q = query(levelRef, where('level_status', '==', 'active'));
    const levelData = await getDataByQuery(q);
    const filteredLevels = levelData.filter((level) =>
      levels
        ? !levels.includes(module.id)
        : !addedLevels.actualContent.includes(level.id)
    );
    filteredLevels.sort(function (it1, it2) {
      return compare(it1, it2, 'level_id');
    });
    setListLevels(filteredLevels);
    setLoading(false);
  };

  useEffect(() => {
    getActiveLevels();
  }, [addedLevels]);

  return (
    <>
      {showAddLevels ? (
        <>
          <div className="flex w-3/4 mx-auto flex-col sm:flex-row items-center justify-end">
            <div className="flex w-full relative z-0 border border-b-2 rounded-md">
              <input
                onChange={(e) => {
                  setSearchLevel(e.target.value);
                  if (e.target.value === '') {
                    getActiveLevels();
                  } else {
                    setListLevels([]);
                  }
                  setSelectedLevels([]);
                }}
                onKeyPress={(e) => e.key === 'Enter' && handleSearchLevel()}
                type="text"
                className="pt-6 shadow-md pb-1 block w-full px-4 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200"
              />
              <label
                htmlFor="get-level"
                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
              >
                Get Level
              </label>
              <button
                onClick={handleSearchLevel}
                className="flex items-center justify-center px-4 border-l"
              >
                <div className="bg-search"></div>
              </button>
            </div>
            <div className="text-center">
              <button
                onClick={handleAddLevels}
                disabled={selectedLevels.length === 0}
                className={
                  'w-40 px-6 py-3 ml-4 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none focus:outline-none ' +
                  (selectedLevels != 0
                    ? 'bg-blue-900 hover:bg-yellow-500 hover:shadow-lg'
                    : 'bg-gray-400')
                }
              >
                Add Selected
              </button>
            </div>
          </div>
          {listLevels.length != 0 && (
            <div className="w-3/4 mx-auto my-0 text-left flex mt-5">
              <div className="table-section w-full">
                <table className="min-w-full border">
                  <thead className="uppercase bg-indigo-50 border-b">
                    <tr>
                      <th
                        scope="col"
                        className="font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        <div className="col-span-12 flex justify-center">
                          <input
                            type="checkbox"
                            checked={
                              selectedLevels.length === listLevels.length
                            }
                            onChange={(e) => handleSelectAll(e)}
                          />
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Level ID
                      </th>
                      <th
                        scope="col"
                        className="font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Level Name
                      </th>
                      <th
                        scope="col"
                        className="font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listLevels.map((res) => {
                      return (
                        <tr
                          key={res.id}
                          className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                            <div className="col-span-12 flex justify-center">
                              <input
                                type="checkbox"
                                value={res.id}
                                checked={selectedLevels.includes(res.id)}
                                onChange={(e) => handleSelectOne(e)}
                              />
                            </div>
                          </td>
                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {res.level_id}
                          </td>
                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {res.level_name}
                          </td>
                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {res.level_status}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="pt-6">
          Please create course first to add level to it
        </div>
      )}
    </>
  );
};

ManageCourseLevels.propTypes = {
  showAddLevels: PropTypes.bool.isRequired,
  addedLevels: PropTypes.object,
  setAddedLevels: PropTypes.func,
  setLoading: PropTypes.func,
  courseId: PropTypes.string.isRequired,
};
