import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import BackButton from '../common/BackButton';
import { getAllDocumentsWithSort } from '../common/Firebase';
import LoadingSpinner from '../common/Loading';
import MaterialTable from '../common/MaterialTable';
import { useCore } from '../core/CoreContextProvider';
import ViewCoachPopup from './ViewCoachPopup';

export function ManageCoaches() {
  const { firestore } = useCore();
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openViewCoachPopUp, setOpenViewCoachPopUp] = useState('');
  const [viewData, setViewData] = useState({});
  useEffect(() => {
    const coaches = async () => {
      try {
        const data = await getAllDocumentsWithSort(
          firestore,
          'coaches',
          'coach_id'
        );
        if (data) {
          setCoaches(data);
        }
      } catch (err) {
        console.error(`Error while fetching coaches: `, err.message);
      }
      setLoading(false);
    };
    coaches();
  }, []);

  useEffect(() => {
    const filterViewData = coaches.filter(
      (coach) => coach.coach_id === openViewCoachPopUp
    )[0];
    setViewData(filterViewData);
  }, [openViewCoachPopUp]);

  const getStatusColor = (status) => {
    let colorClass = 'text-black';
    switch (status) {
      case 'registered':
        colorClass = 'text-black';
        break;
      case 'approved':
        colorClass = 'text-blue-900';
        break;
      case 'active':
        colorClass = 'text-green-500';
        break;
      case 'inactive':
        colorClass = 'text-gray-400';
        break;
      default:
        break;
    }
    return colorClass;
  };

  return (
    <div className="text-center">
      <div className="grid grid-rows-1 min-h-screen">
        <div>
          <div className="flex overflow-hidden">
            <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
              <div className="intro-y flex items-center mt-6">
                <BackButton />
                <h2 className="text-2xl font-medium mr-auto">
                  Manage Coach Enrollment
                </h2>
              </div>
              {loading && <LoadingSpinner />}
              <div className="grid grid-cols-12 gap-6 mt-5">
                <div className="col-span-12 lg:col-span-12">
                  {coaches.length !== 0 && (
                    <div className="bg-white shadow-md rounded-md">
                      <div className="w-full mx-auto text-left flex mt-5">
                        <div className="table-section w-full">
                          <MaterialTable
                            columns={[
                              {
                                title: 'COACH ID',
                                field: 'coach_id',
                              },
                              {
                                title: 'COACH NAME',
                                sorting: false,
                                render: (coach) => {
                                  return `${coach.first_name} ${coach.middle_name} ${coach.last_name}`;
                                },
                              },
                              {
                                title: 'POD ID',
                                sorting: false,
                                render: (coach) => {
                                  return (
                                    <ul>
                                      {coach.pod_id
                                        ? coach.pod_id.map((pod) => {
                                            return <li key={pod}>{pod}</li>;
                                          })
                                        : ''}
                                    </ul>
                                  );
                                },
                              },
                              {
                                title: 'EMAIL ADDRESS',
                                field: 'email_address',
                                sorting: false,
                              },
                              {
                                title: 'PHONE NUMBER',
                                field: 'phone_number',
                                sorting: false,
                              },
                              {
                                title: 'STATUS',
                                field: 'coach_status',
                                sorting: false,
                                render: (coach) => {
                                  return (
                                    <p
                                      className={getStatusColor(
                                        coach.coach_status
                                      )}
                                    >
                                      {coach.coach_status}
                                    </p>
                                  );
                                },
                              },
                              {
                                title: 'ROLE',
                                field: 'coach_type',
                                sorting: false,
                                render: (coach) => {
                                  return `${
                                    coach.coach_type &&
                                    coach.coach_type == 'onlooker'
                                      ? 'Assistant'
                                      : 'Coach'
                                  }`;
                                },
                              },

                              {
                                title: 'ACTION',
                                sorting: false,
                                render: (element) => (
                                  <div className="flex items-center">
                                    <div
                                      onClick={() =>
                                        setOpenViewCoachPopUp(element.coach_id)
                                      }
                                      className="border p-3 mr-2 tooltip relative"
                                    >
                                      <span className="bg-view block cursor-pointer"></span>
                                      <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[39px] after:left-[19px] after:z-[9999]">
                                        View
                                      </span>
                                    </div>
                                    <NavLink
                                      to="/edit/coach"
                                      state={{
                                        id: element.id ?? element.objectID,
                                      }}
                                      className="border p-3 mr-2 tooltip relative"
                                    >
                                      <span className="bg-edit block cursor-pointer"></span>
                                      <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[39px] after:left-[17px] after:z-[9999]">
                                        Edit
                                      </span>
                                    </NavLink>
                                  </div>
                                ),
                              },
                            ]}
                            algoliaIndex={
                              process.env.REACT_APP_ALGOLIA_INDEX_PREFIX +
                              '_coaches'
                            }
                          />
                          <ViewCoachPopup
                            open={openViewCoachPopUp !== ''}
                            data={viewData}
                            handleClose={() => setOpenViewCoachPopUp('')}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {!loading && !coaches.length && (
                    <div className="text-center bg-indigo-100 text-black font-bold rounded-t px-4 py-2 w-80 mb-3">
                      <p>No Coach Found</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
