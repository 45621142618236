import React, { useState } from 'react';
import PropTypes from 'prop-types';
const Autocomplete = ({ suggestions, inputValue, addedVariables }) => {
  const [filtered, setFiltered] = useState({});
  const [isShow, setIsShow] = useState(false);
  const [input, setInput] = useState('');
  const [keyIndex, setKeyIndex] = useState(0);

  const KeyPress = (e) => {
    if (e.keyCode == 188) {
      setFiltered(suggestions);
      setIsShow(true);
    } else if (e.keyCode === 13) {
      setIsShow(false);
    } else {
      setIsShow(false);
    }
    setKeyIndex(e.target.selectionStart + 1);
  };

  const getSelectedSugg = (e) => {
    setIsShow(false);
    inputValue.current.value =
      inputValue.current.value.slice(0, keyIndex) +
      e.currentTarget.innerText +
      '>' +
      inputValue.current.value.slice(keyIndex);
    setInput(inputValue.current.value);
  };
  const renderAutocomplete = () => {
    if (isShow) {
      if (Object.keys(filtered).length) {
        return (
          <ul className="autocomplete border border-t-0 list-none mt-0 overflow-y-auto pl-0 max-h-[143px] w-full absolute z-10  bg-white">
            {Object.keys(filtered).map((e) => {
              return (
                <>
                  {addedVariables.includes(e) && (
                    <li className="p-2" key={e} onClick={getSelectedSugg}>
                      {filtered[e]}
                    </li>
                  )}
                </>
              );
            })}
          </ul>
        );
      } else {
        return (
          <div className="no-autocomplete text-[#999] p-2">
            <em>Not found</em>
          </div>
        );
      }
    }
    return <></>;
  };
  return (
    <>
      <input
        type="text"
        placeholder=" "
        className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md relative"
        ref={inputValue}
        onKeyDown={KeyPress}
        defaultValue={input}
      />
      {renderAutocomplete()}
    </>
  );
};

Autocomplete.propTypes = {
  suggestions: PropTypes.array,
  inputValue: PropTypes.any,
  addedVariables: PropTypes.array,
};
export default Autocomplete;
