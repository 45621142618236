import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../core/AuthContextProvider';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import LoadingSpinner from '../common/Loading';

const LoginPage = () => {
  const navigation = useNavigate();
  const [toasting, setToasting] = useState(false);
  const { signIn, currentUser, userData, authLoading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const roles = ['admin', 'coach'];

  useEffect(() => {
    const userHasRequiredRole =
      currentUser && roles.includes(userData.role) ? true : false;
    if (userHasRequiredRole && userData) {
      navigation('/dashboard');
    }
  }, [currentUser, userData, loading, authLoading]);

  async function onSubmit(data) {
    setLoading(true);
    try {
      const login = await signIn(data.email, data.password);
      if (!login) {
        setLoading(false);
        toast.error('Invalid email or password', {
          toastId: 'login_page',
          onClose: () => {
            setToasting(false);
          },
          onOpen: () => {
            setToasting(true);
          },
        });
      } else {
        setLoading(false);
        navigation('/dashboard');
      }
    } catch (err) {
      toast.error('Invalid email or password', {
        toastId: 'login_page',
        onClose: () => {
          setToasting(false);
        },
        onOpen: () => {
          setToasting(true);
        },
      });
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {(currentUser || authLoading) && <LoadingSpinner />}
      {!currentUser && !authLoading && (
        <div className="w-full">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col min-h-screen bg-black px-10">
              <Link to="/" className="p-4 pt-12 hidden md:flex">
                <img alt="" className="w-56 h-20" src="./images/tapouts.png" />
              </Link>
              <div className="my-auto">
                <div className="text-white font-medium text-4xl leading-tight">
                  A few more clicks to
                  <br />
                  sign in to your account.
                </div>
              </div>
            </div>
            <div className="my-auto mx-auto xl:ml-20  px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
              <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                Sign In
              </h2>
              {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* register your input into the hook by invoking the "register" function */}
                <div className="intro-x mt-8">
                  <input
                    className="form-control w-full border rounded-md shadow-md py-4 px-5 border border-black-300 mb-5 outline-none"
                    placeholder="Email"
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'Please enter a email',
                      },
                      pattern: {
                        value: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Please enter a valid email',
                      },
                    })}
                  />
                  {errors.email && (
                    <div className="w-[434px] mb-2 -mt-3 text-red-500 text-sm">
                      {errors.email?.message}
                    </div>
                  )}
                  {/* include validation with required or other standard HTML validation rules */}
                  <input
                    type="password"
                    className="form-control w-full border rounded-md shadow-md py-4 px-5 border border-black-300 outline-none"
                    placeholder="Password"
                    {...register('password', {
                      required: {
                        value: true,
                        message: 'Please enter a password',
                      },
                    })}
                  />
                  {errors.password && (
                    <div className="w-[434px] mb-2 mt-2 text-red-500 text-sm">
                      {' '}
                      {errors.password?.message}
                    </div>
                  )}
                  {/* errors will return when field validation fails  */}
                </div>

                <div className="flex text-gray-700 justify-between dark:text-gray-600 text-xs sm:text-sm mt-6">
                  <div className="button">
                    <input
                      disabled={toasting}
                      type="submit"
                      className="w-40 px-6 py-3 text-lg cursor-pointer text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                    />
                  </div>
                  <div>
                    <Link
                      to="/forgotpassword"
                      className="text-black hover:text-red-500"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPage;
