import React, { useEffect, useState } from 'react';
import { getAllDocumentsWithSort } from '../common/Firebase';
import { useCore } from '../core/CoreContextProvider';
import { ViewPod } from './ViewPod';
import LoadingSpinner from '../common/Loading';
import { NavLink } from 'react-router-dom';
import BackButton from '../common/BackButton';
import MaterialTable from '../common/MaterialTable';

export const ManagePod = () => {
  const { firestore } = useCore();
  const [podList, setPodList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState({ show: false, data: null });

  useEffect(() => {
    const getPods = async () => {
      const pods = await getAllDocumentsWithSort(firestore, 'pods', 'pod_id');
      if (pods) {
        setPodList(pods);
        setLoading(false);
      }
    };
    getPods();
  }, []);

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton />
            <h2 className="text-2xl font-medium mr-auto">Manage Pod</h2>
          </div>
          {loading && <LoadingSpinner />}
          <div className="grid grid-cols-12 gap-6 mt-5">
            {podList.length !== 0 && (
              <div className="col-span-12 lg:col-span-12">
                <div className="bg-white shadow-md rounded-md">
                  <div className="w-full mx-auto text-left flex mt-5">
                    <div className="table-section w-full">
                      <MaterialTable
                        columns={[
                          {
                            title: 'POD ID',
                            field: 'pod_id',
                          },
                          {
                            title: 'COURSE NAME',
                            field: 'course_name',
                            sorting: false,
                          },
                          {
                            title: 'COACH NAME',
                            field: 'coach_name',
                            sorting: false,
                          },

                          {
                            title: 'AGE GROUP',
                            field: 'age_group',
                            sorting: false,
                          },
                          {
                            title: 'LANGUAGE',
                            field: 'language',
                            sorting: false,
                          },
                          {
                            title: 'NO. OF KIDS',
                            sorting: false,
                            render: (pod) => {
                              return pod.children.length ?? 0;
                            },
                          },
                          {
                            title: 'SCHEDULE',
                            sorting: false,
                            render: (pod) => {
                              return pod.day.length
                                ? `${pod.day.join(', ')} at ${pod.start_time} ${
                                    pod.time_zone_abbr
                                  }`
                                : '';
                            },
                          },
                          {
                            title: 'ACTION',
                            sorting: false,
                            render: (res) => (
                              <div className="flex items-center">
                                <a
                                  className="border p-3 mr-2 modal-open cursor-pointer tooltip relative"
                                  onClick={() =>
                                    setPopup({
                                      ...popup,
                                      show: true,
                                      data: res,
                                    })
                                  }
                                >
                                  <span className="bg-view block"></span>
                                  <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[35px] after:left-[16px] after:z-[9999]">
                                    View
                                  </span>
                                </a>
                                <NavLink
                                  to="/edit/pod"
                                  state={{
                                    id:
                                      res.id && res.id !== ''
                                        ? res.id
                                        : res.objectID,
                                  }}
                                  className="border p-3 mr-2 cursor-pointer tooltip relative"
                                >
                                  <span className="bg-edit block"></span>
                                  <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[35px] after:left-[16px] after:z-[9999]">
                                    Edit
                                  </span>
                                </NavLink>
                              </div>
                            ),
                          },
                        ]}
                        algoliaIndex={
                          process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + '_pods'
                        }
                      />
                      {popup.show && (
                        <ViewPod popup={popup} setPopup={setPopup} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!loading && !podList.length && (
              <div className="text-center bg-indigo-100 text-black font-bold rounded-t px-4 py-2 w-80 mb-3 ">
                <p>No Pod Found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
