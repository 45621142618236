import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllDocuments } from '../common/Firebase';
import { addSubsMinutes, checkDayAvailable, getDates } from '../common/utils';
import { useCore } from '../core/CoreContextProvider';

export const SearchSelectCoach = ({
  setValue,
  coaches,
  setCoaches,
  trigger,
  estimateDatePopupData,
  pod_duration,
  setDirty,
}) => {
  const { firestore } = useCore();
  const searchCoachRef = useRef('');
  const [searchedCoaches, setSearchedCoaches] = useState([]);
  const [coachAvailabilityData, setCoachAvailabilityData] = useState(null);
  const addCoach = (name, id, uid, coachFlag) => {
    if (!coachFlag) return;
    if (coaches.type === 'coach') {
      setValue('coach_id', id);
      setValue('coach_uid', uid);
      setValue('coach_name', name);
      trigger(['coach_name']);
    } else {
      setValue('onlooker_id', id);
      setValue('onlooker_uid', uid);
      setValue('onlooker_name', name);
      trigger(['onlooker_name']);
    }
    setDirty(true);
    setCoaches({ ...coaches, show: false });
  };

  const searchCoach = () => {
    if (searchCoachRef.current.value != '') {
      let searchedCoach = searchCoachRef.current.value;
      const coachesData = coaches.data.filter((res) => {
        return res.first_name
          .toLowerCase()
          .includes(searchedCoach.toLowerCase().trim());
      });
      coachesData.length
        ? setSearchedCoaches(coachesData)
        : toast.error('no coaches found');
    }
  };

  useEffect(() => {
    if (coaches?.data?.length) {
      setSearchedCoaches(coaches.data);
      findAvailableCoaches(
        coaches.day,
        coaches.start_time,
        coaches.data,
        coaches.start_date,
        coaches.podTimeZone,
        coaches.podTimeZoneAbbr
      );
    }
  }, [coaches]);

  useEffect(() => {
    if (coachAvailabilityData) {
      const tempSearchedCoaches = [...searchedCoaches];
      tempSearchedCoaches.forEach((coach, index) => {
        coachAvailabilityData.forEach((availableCoach) => {
          if (availableCoach.coach_id === coach.uid) {
            tempSearchedCoaches[index].availability =
              availableCoach.availability;
          }
        });
      });

      tempSearchedCoaches.forEach((coach, index) => {
        let availableFlag = false;
        coach.availability?.forEach((coachAvailability) => {
          const valuesArr = Object.values(coachAvailability.futureAvailability);
          valuesArr?.forEach((value) => {
            if (value === 'A') availableFlag = true;
          });
        });
        tempSearchedCoaches[index].coach_available = availableFlag;
      });
      setSearchedCoaches(tempSearchedCoaches);
    }
  }, [coachAvailabilityData]);

  const findAvailableCoaches = async (
    day,
    startTime,
    coachesAvailable,
    start_date,
    podTimeZone,
    podTimeZoneAbbr
  ) => {
    const endTime = addSubsMinutes(
      startTime,
      estimateDatePopupData?.pod_duration ?? pod_duration,
      'add'
    );

    const semiAvailableCoaches =
      coachesAvailable.length && coachesAvailable.map((coach) => coach.uid);

    try {
      const data = await getAllDocuments(firestore, 'coach_schedule');
      const filteredCoaches = data.filter((coachData) =>
        semiAvailableCoaches.includes(coachData.coach_id)
      );

      const result = [
        {
          coach_id: '',
          overall: '',
          coachPodInfo: {},
          specific: [
            {
              date: '',
              day: '',
              status: '',
              days: [],
            },
          ],
        },
      ];

      filteredCoaches.forEach((coaches, index) => {
        coachesAvailable.forEach((coach) => {
          if (coach.uid === coaches.id) {
            coaches.time_zone = coach.time_zone;
          }
        });
        let timeSlotStartTime = addSubsMinutes(startTime, '15', 'substract');
        let timeSlotEndTime = addSubsMinutes(endTime, '15', 'add');
        let exceptionTimeZone = {
          HST: 10,
          AKDT: 8,
        };
        // Add the util function for time format
        if (Object.keys(coaches.overall_availability).length) {
          if (podTimeZone !== coaches.time_zone) {
            if (Object.keys(exceptionTimeZone).includes(podTimeZoneAbbr)) {
              var s = new Date(
                `${start_date} ${addSubsMinutes(
                  startTime,
                  '15',
                  'substract'
                )} UTC`
              );
              var e = new Date(
                `${start_date} ${addSubsMinutes(
                  endTime,
                  '15',
                  'substract'
                )} UTC`
              );
              e.setHours(e.getHours() + exceptionTimeZone[podTimeZoneAbbr]);
              s.setHours(s.getHours() + exceptionTimeZone[podTimeZoneAbbr]);
            } else {
              s = new Date(
                `${start_date} ${addSubsMinutes(
                  startTime,
                  '15',
                  'substract'
                )} ${podTimeZoneAbbr}`
              );
              e = new Date(
                `${start_date} ${addSubsMinutes(
                  endTime,
                  '15',
                  'substract'
                )} ${podTimeZoneAbbr}`
              );
            }
            timeSlotStartTime = moment(s).tz(coaches.time_zone).format('HH:mm');
            timeSlotEndTime = moment(e).tz(coaches.time_zone).format('HH:mm');
          }
        }

        result[index] = {
          coach_id: coaches.coach_id,
          assigned_pod: coaches.assigned_pod,
        };
        let overallFlag = true;

        let availableDays = Object.keys(coaches.overall_availability);
        const found = day.every((selectedday) =>
          availableDays.includes(selectedday)
        );
        if (found) {
          let selectedDaysArr = [];
          if (day.length) {
            day.forEach((key) => {
              selectedDaysArr.push(key);
              result[index]['days'] = selectedDaysArr;
              if (
                !checkDayAvailable(
                  coaches,
                  timeSlotStartTime,
                  timeSlotEndTime,
                  key,
                  result,
                  index
                )
              ) {
                overallFlag = false;
              }
            });
          }
        }
        if (!overallFlag) {
          result[index]['overall'] = 'NA';
        } else {
          result[index]['overall'] = 'A';
        }

        if (coaches.assigned_pod) {
          coaches.assigned_pod.forEach((pod) => {
            for (let curDay of day) {
              if (!result[index]['coachPodInfo']) {
                result[index]['coachPodInfo'] = {};
              }
              if (pod.day.includes(curDay)) {
                if (
                  (pod.start_time >= timeSlotStartTime &&
                    pod.end_time <= timeSlotEndTime) ||
                  (timeSlotEndTime > pod.start_time &&
                    timeSlotStartTime < pod.end_time)
                ) {
                  result[index]['coachPodInfo'][curDay] = 'NA';
                } else {
                  result[index]['coachPodInfo'][curDay] = 'A';
                }
              } else {
                result[index]['coachPodInfo'][curDay] = 'A';
              }
            }
          });
        }

        coaches.specific_availability?.length
          ? coaches.specific_availability.forEach((data, index1) => {
              if (day.includes(data.day)) {
                let curDay = data.day;
                let timingsFlag = false;
                data.timings.forEach((time) => {
                  if (
                    time.start_time <= timeSlotStartTime &&
                    time.end_time >= timeSlotEndTime
                  ) {
                    timingsFlag = true;
                    curDay;
                    if (!result[index]['specific']) {
                      result[index]['specific'] = [
                        {
                          day: data.day,
                          date: data.date,
                          status: 'A',
                        },
                      ];
                    } else {
                      result[index]['specific'][index1] = {
                        day: data.day,
                        date: data.date,
                        status: 'A',
                      };
                    }
                  }
                });
                if (!timingsFlag) {
                  if (!result[index]['specific']) {
                    result[index]['specific'] = [
                      {
                        day: data.day,
                        date: data.date,
                        status: 'NA',
                      },
                    ];
                  } else {
                    result[index]['specific'][index1] = {
                      day: data.day,
                      date: data.date,
                      status: 'NA',
                    };
                  }
                }
              }
            })
          : '';
      });

      const showData = [
        {
          coach_id: '',
          availability: [],
        },
      ];
      result.forEach((el, index) => {
        if (el.coachPodInfo) {
          showData[index] = {
            coach_id: el.coach_id,
            day: el.days,
            availability: el.days.map((day) => {
              return {
                day: `${day}`,
                futureAvailability: {
                  1: el.coachPodInfo[day],
                  2: el.coachPodInfo[day],
                  3: el.coachPodInfo[day],
                  4: el.coachPodInfo[day],
                  5: el.coachPodInfo[day],
                },
              };
            }),
          };
        }
        if (el.overall === 'NA') {
          showData[index] = {
            coach_id: el.coach_id,
            day: el.days,
            availability: el.days.map((day) => {
              return {
                day: `${day}`,
                futureAvailability: {
                  1: 'NA',
                  2: 'NA',
                  3: 'NA',
                  4: 'NA',
                  5: 'NA',
                },
              };
            }),
          };
        } else if (el.overall === 'A' && !el.specific && !el.coachPodInfo) {
          showData[index] = {
            coach_id: el.coach_id,
            day: el.days,
            availability: el.days.map((day) => {
              return {
                day: `${day}`,
                futureAvailability: {
                  1: 'A',
                  2: 'A',
                  3: 'A',
                  4: 'A',
                  5: 'A',
                },
              };
            }),
          };
        } else if (el.specific) {
          if (el.days.length) {
            for (let day of el.days) {
              const dates = getDates(5, day, start_date); //[wed all 5 dates]
              const formattedDate = [];
              dates?.forEach((date) => {
                const splitDate = date.split('/');
                const finalDate = `${splitDate[2]}-${
                  splitDate[0].length === 1 ? `0${splitDate[0]}` : splitDate[0]
                }-${
                  splitDate[1].length === 1 ? `0${splitDate[1]}` : splitDate[1]
                }`;
                formattedDate.push(finalDate);
              });

              const specificAvailability = {};
              formattedDate.forEach((date, index) => {
                let dateFound = false;
                el.specific.forEach((el1) => {
                  if (el1.date === date) {
                    dateFound = true;
                    specificAvailability[index + 1] = el1.status;
                  }
                });
                if (!dateFound) {
                  specificAvailability[index + 1] = 'A';
                }
              });

              if (!showData[index]) {
                showData[index] = {
                  coach_id: el.coach_id,
                  day: el.days,
                };
              } else {
                showData[index].coach_id = el.coach_id;
                showData[index].day = el.days;
              }
              if (!showData[index]['availability']) {
                showData[index]['availability'] = [
                  {
                    day: `${day}`,
                    futureAvailability: specificAvailability,
                  },
                ];
              } else {
                // NOTE : Special case if has both specificAvailability and podInfo
                let found = false;
                let tempShowData = showData[index]['availability'];
                for (let index in tempShowData) {
                  if (tempShowData[index].day === day) {
                    found = true;
                    // intersection of both futureAvailability

                    let res = {};
                    let obj1 = tempShowData[index].futureAvailability;
                    let obj2 = specificAvailability;
                    for (let i = 1; i <= 5; i++) {
                      res[i] =
                        obj1[i] === obj2[i]
                          ? obj1[i]
                          : obj1[i] === 'NA'
                          ? 'NA'
                          : obj2[i];
                    }
                    tempShowData[index].futureAvailability = res;
                  }
                }

                if (!found) {
                  showData[index]['availability'].push({
                    day: `${day}`,
                    futureAvailability: specificAvailability,
                  });
                }
              }
            }
          }
        }
      });

      setCoachAvailabilityData(showData);
    } catch (err) {
      console.error(`Error while fetching coaches: `, err.message);
    }
  };

  return (
    <>
      {coaches.show ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-5/6 h-5/6 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div
                  className="modal-close cursor-pointer"
                  onClick={() => setCoaches({ ...coaches, show: false })}
                >
                  <span className="bg-close block cursor-pointer"></span>
                </div>
              </div>
              <div className="p-5">
                <div className="preview">
                  <div className="mt-5 flex flex-col sm:flex-row items-center">
                    <div className="flex w-1/2 mx-auto relative z-0 border border-b-2 rounded-md">
                      <input
                        type="text"
                        id="get-coach"
                        ref={searchCoachRef}
                        onChange={() => setSearchedCoaches(coaches.data)}
                        onKeyPress={(e) => e.key === 'Enter' && searchCoach()}
                        className="pt-6 pb-1 block w-full px-4 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200"
                      />
                      <label
                        htmlFor="get-coach"
                        className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                      >
                        {coaches.type === 'coach'
                          ? 'Get Coach'
                          : 'Get Onlooker'}
                      </label>
                      <div
                        onClick={searchCoach}
                        className="flex items-center justify-center px-4 border-l"
                      >
                        <div className="bg-search"></div>
                      </div>
                    </div>
                  </div>
                  <div className="w-3/4 mx-auto my-0 text-left flex mt-5">
                    <div className="table-section w-full">
                      <table
                        className="min-w-full border border-b-0"
                        id="table"
                      >
                        <thead className="">
                          <tr>
                            <th
                              scope="col"
                              className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                            >
                              {coaches.type === 'coach'
                                ? 'Coach ID'
                                : 'Onlooker ID'}
                            </th>
                            <th
                              scope="col"
                              className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                            >
                              {coaches.type === 'coach'
                                ? 'Coach Name'
                                : 'Onlooker Name'}
                            </th>
                            <th
                              scope="col"
                              className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                            >
                              Gender
                            </th>
                            <th
                              scope="col"
                              className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        {searchedCoaches.length != 0 &&
                          searchedCoaches.map((res) => {
                            return (
                              <tbody key={res.id}>
                                {res.availability && (
                                  <tr className="bg-white transition duration-300 ease-in-out">
                                    <td className="text-gray-900 font-light p-6 whitespace-nowrap">
                                      <NavLink
                                        to="/edit/coach"
                                        state={{ id: res.id }}
                                      >
                                        {res.coach_id}
                                      </NavLink>
                                    </td>
                                    <td className="text-gray-900 font-light p-6 whitespace-nowrap">
                                      {res.first_name}
                                    </td>
                                    <td className="text-gray-900 font-light p-6 whitespace-nowrap">
                                      {res.gender}
                                    </td>
                                    <td className="text-gray-900 font-light p-6 whitespace-nowrap">
                                      <a
                                        className={`text-blue-600 ${
                                          res.coach_available
                                            ? 'cursor-pointer'
                                            : 'cursor-not-allowed'
                                        }`}
                                        onClick={() =>
                                          addCoach(
                                            res.first_name +
                                              ' ' +
                                              res.middle_name +
                                              ' ' +
                                              res.last_name,
                                            res.id,
                                            res.uid,
                                            res.coach_available
                                          )
                                        }
                                      >
                                        {res.coach_available
                                          ? 'Add'
                                          : 'Not Available'}
                                      </a>
                                    </td>
                                  </tr>
                                )}

                                {res.availability &&
                                  res.availability.map(
                                    (selectedDate, selDateIndex) => {
                                      return (
                                        <tr key={selDateIndex.toString()}>
                                          <td colSpan="7">
                                            <div className="flex items-center border-b-2 justify-center py-3 pb-6">
                                              <span>{`${selectedDate.day} - 5 Weeks Availability -`}</span>
                                              {Object.keys(
                                                selectedDate.futureAvailability
                                              ).map((avail, index) => {
                                                return (
                                                  <div
                                                    key={index}
                                                    className={`border px-3 py-1 mx-1 mx-3 ${
                                                      selectedDate
                                                        .futureAvailability[
                                                        avail
                                                      ] === 'A'
                                                        ? 'bg-green-600'
                                                        : 'bg-red-600'
                                                    }`}
                                                  >
                                                    {`${avail}`}
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            );
                          })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

SearchSelectCoach.propTypes = {
  setValue: PropTypes.func,
  coaches: PropTypes.object.isRequired,
  setCoaches: PropTypes.func,
  trigger: PropTypes.func,
  estimateDatePopupData: PropTypes.object,
  pod_duration: PropTypes.string,
  setDirty: PropTypes.func,
};
