import { useEffect, useState } from 'react';
import { getAllDocuments } from '../common/Firebase';
import LoadingSpinner from '../common/Loading';
import { useCore } from '../core/CoreContextProvider';
import { NavLink } from 'react-router-dom';
import log from '../core/log';

const ManageTypeForm = () => {
  const { firestore } = useCore();
  const [form, setForms] = useState([]);
  const [loading, setLoading] = useState(true);

  const getFormData = async () => {
    try {
      const data = await getAllDocuments(firestore, 'tapouts_form');
      setForms(data);
      setLoading(false);
    } catch (err) {
      log('Error while fetching form data' + err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFormData();
  }, []);
  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <h2 className="text-2xl font-medium mr-auto">Manage Forms</h2>
          </div>
          {loading && <LoadingSpinner />}
          {!loading && (
            <div className="grid grid-cols-12 gap-6 mt-5">
              {form.length ? (
                <div className="col-span-12 lg:col-span-12">
                  <div className="bg-white shadow-md rounded-md">
                    <div className="p-5">
                      <div className="w-full mx-auto text-left flex mt-5">
                        <div className="table-section w-full">
                          <table
                            className="min-w-full text-sm border"
                            id="table"
                          >
                            <thead className="uppercase bg-indigo-50 border-b">
                              <tr>
                                <th
                                  data-type="number"
                                  scope="col"
                                  className="font-medium text-gray-900 p-3 text-left"
                                >
                                  Form Name
                                </th>
                                <th
                                  data-type="number"
                                  scope="col"
                                  className="font-medium text-gray-900 p-3 text-left"
                                >
                                  No Of Steps
                                </th>
                                <th
                                  data-type="number"
                                  scope="col"
                                  className="font-medium text-gray-900 p-3 text-left"
                                >
                                  Form Status
                                </th>
                                <th
                                  data-type="number"
                                  scope="col"
                                  className="font-medium text-gray-900 p-3 text-left"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {form.map((form) => {
                                return (
                                  <tr
                                    key={form.id}
                                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                  >
                                    <td className="text-gray-900 font-light p-3 whitespace-nowrap   ">
                                      {form.form_name}
                                    </td>

                                    <td className="text-gray-900 font-light p-3 whitespace-nowrap">
                                      {form.no_of_steps}
                                    </td>

                                    <td className="text-gray-900 font-light p-3 whitespace-nowrap">
                                      {form.form_status}
                                    </td>
                                    <td>
                                      <NavLink
                                        to="/edit/form"
                                        state={{
                                          id: form.id,
                                          data: form,
                                        }}
                                        className=" p-3 mr-2 cursor-pointer tooltip relative"
                                      >
                                        <span className="bg-edit block"></span>
                                        <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[35px] after:left-[16px] after:z-[9999]">
                                          Edit
                                        </span>
                                      </NavLink>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center bg-indigo-100 text-black font-bold rounded-t px-4 py-2 w-80 mb-3">
                  <p>No Forms Found</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageTypeForm;
