import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCore } from '../core/CoreContextProvider';
import { getData } from '../common/Firebase';
import LoadingSpinner from '../common/Loading';

function ViewGuardianPopup({ open, data, handleClose }) {
  const { firestore } = useCore();
  let navigate = useNavigate();

  const [allChildren, setAllChildren] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleEdit = () => {
    navigate('/edit/guardian', { state: { id: data.id } });
  };

  useEffect(() => {
    if (open && Object.keys(data).length) {
      const children = async () => {
        if (data?.children) {
          setLoading(true);
          const childrenData = [];
          try {
            await Promise.all(
              data?.children?.map(async (childId) => {
                const child = await getData(firestore, childId, 'children');
                childrenData.push({ ...child, id: childId });
              })
            );
            setAllChildren(childrenData);
          } catch (err) {
            console.error(
              `Error while fetching linked child documents`,
              err.message
            );
          }
          setLoading(false);
        } else {
          setAllChildren([]);
        }
        setLoading(false);
      };
      children();
    }
  }, [open, data]);

  return (
    <>
      {open ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          {loading && <LoadingSpinner />}
          <div className="modal-container bg-white w-5/6 h-5/6 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div
                  className="modal-close cursor-pointer"
                  onClick={() => handleClose()}
                >
                  <span className="bg-close block cursor-pointer"></span>
                </div>
              </div>

              <div className="col-span-12 lg:col-span-12">
                <div className="bg-white rounded-md">
                  <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                    <h2 className="font-bold text-base mr-auto">
                      Guardian Details
                    </h2>
                  </div>
                  <div id="input" className="p-5">
                    <div className="preview">
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="script-name"
                          className="text-black w-40 px-4 font-bold"
                        >
                          Guardian ID:
                        </label>
                        <div
                          id="script-name"
                          type="text"
                          className="mt-0 bg-transparent"
                        >
                          {data?.guardian_id}
                        </div>
                      </div>
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="script-id"
                          className="text-black px-4 font-bold w-40"
                        >
                          First Name:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data?.first_name}
                        </div>
                      </div>
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="topics"
                          className="text-black px-4 font-bold w-40"
                        >
                          Middle Name:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data?.middle_name}
                        </div>
                      </div>
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="tags"
                          className="text-black px-4 font-bold w-40"
                        >
                          Last Name:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data?.last_name}
                        </div>
                      </div>
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="status"
                          className="text-black px-4 font-bold w-40"
                        >
                          Relationship:{' '}
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data?.relationship}
                        </div>
                      </div>
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="version"
                          className="text-black px-4 font-bold w-40"
                        >
                          Email Address:
                        </label>

                        <div className="mt-0 bg-transparent">
                          {data?.email_address}
                        </div>
                      </div>
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="language"
                          className="text-black px-4 font-bold w-40"
                        >
                          Phone Number:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data?.phone_number}
                        </div>
                      </div>
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="language"
                          className="text-black px-4 font-bold w-40"
                        >
                          Zip Code:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data?.zip_code}
                        </div>
                      </div>
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="language"
                          className="text-black px-4 font-bold w-40"
                        >
                          Country:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data?.country}
                        </div>
                      </div>
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="language"
                          className="text-black px-4 font-bold w-40"
                        >
                          Time Zone:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data?.time_zone}
                        </div>
                      </div>{' '}
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="language"
                          className="text-black px-4 font-bold w-40"
                        >
                          Status:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data?.guardian_status}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-10 font-bold text-center float-left w-full">
                  Added Child
                </div>
                <div className="w-full mx-auto my-0 text-left flex mt-5">
                  <div className="table-section w-full">
                    <table className="min-w-full border" id="table">
                      <thead className="">
                        <tr>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Child ID
                          </th>

                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Children Name
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Language
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Age
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allChildren?.length
                          ? allChildren.map((child) => {
                              return (
                                <tr
                                  key={child.id}
                                  className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 cursor-move"
                                >
                                  <td className="text-blue-900 font-light px-6 whitespace-nowrap cursor-pointer underline">
                                    <NavLink
                                      to="/edit/children"
                                      state={{ id: child.id }}
                                    >
                                      {child.children_id}
                                    </NavLink>
                                  </td>
                                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {`${child.first_name} ${child.middle_name} ${child.last_name}`}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {child.language}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {child.age}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {child.child_status}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="p-5 text-center">
                  <button
                    className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                    onClick={handleEdit}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

ViewGuardianPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ViewGuardianPopup;
