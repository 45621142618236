import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmationPopup from '../common/ConfirmationPopup';
import {
  getAllDocuments,
  getData,
  updateFieldInDocument,
} from '../common/Firebase';
import { calculateAge, convertLocale, updatedPayload } from '../common/utils';
import { useAuth } from '../core/AuthContextProvider';
import { useCore } from '../core/CoreContextProvider';
function Children({
  podId,
  handleSetLoading,
  handleUpdatePodStatus,
  selectedPodStatus,
  podData,
  ageGroup,
  autoGenPodId,
  setChildrenPayload,
}) {
  const { firestore } = useCore();
  const { userData, countryCode } = useAuth();
  const [children, setChildren] = useState([]);
  const [activeChildren, setActiveChildren] = useState([]);
  const [waitingChildren, setWaitingChildren] = useState([]);
  const [childTab, setChildTab] = useState(podData ? 'children' : 'default');
  const [addedChildren, setAddedChildren] = useState([]);
  const [childSearchText, setChildSearchText] = useState('');
  const [selectedChildrenIds, setSelectedChildrenIds] = useState([]);
  const [confirmationPopup, setConfirmationPopup] = useState({
    show: false,
    message: 'Do you really want to unlink this children?',
    id: null,
  });

  const getActiveChildren = async () => {
    try {
      handleSetLoading(true);
      const data = await getAllDocuments(firestore, 'children');
      if (data) {
        setChildren(data);
        const filteredChildren = data.filter((child) => {
          return (
            child.child_status === 'active' &&
            ((calculateAge(child.date_of_birth) >=
              Number(ageGroup?.split('-')[0]) &&
              calculateAge(child.date_of_birth) <=
                Number(ageGroup?.split('-')[1])) ||
              (child.child_age_override && child.uid))
          );
        });
        setActiveChildren(filteredChildren);
        handleSetLoading(false);
      }
    } catch (err) {
      handleSetLoading(false);
      console.error(`Error while fetching children: `, err.message);
    }
  };

  useEffect(() => {
    if (ageGroup) {
      const children = async () => {
        getActiveChildren();
      };
      children();
    }
  }, [ageGroup]);

  useEffect(() => {
    if (podData) {
      const getLinkedChildren = async () => {
        try {
          if (Object.keys(podData).length) {
            const linkedChildren = [];
            if (podData?.children?.length) {
              await Promise.all(
                podData.children.map(async (child) => {
                  const data = await getData(firestore, child.id, 'children');
                  linkedChildren.push(data);
                })
              );
            }
            setAddedChildren(linkedChildren);
            const waitingChildren = [];
            if (podData?.waiting_children?.length) {
              await Promise.all(
                podData.waiting_children.map(async (child) => {
                  const data = await getData(firestore, child.id, 'children');
                  waitingChildren.push(data);
                })
              );
            }
            setWaitingChildren(waitingChildren);
            setChildTab('children');
          }
        } catch (err) {
          console.error(`Error while fetching linked children: `, err.message);
        }
      };

      getLinkedChildren();
    }
  }, [podData]);

  const handleAddSelectedChildren = async () => {
    handleSetLoading(true);
    const selectedChildren = children.filter((child) =>
      selectedChildrenIds.includes(child.id)
    );
    setAddedChildren((prevAddedChildren) => [
      ...prevAddedChildren,
      ...selectedChildren,
    ]);
    try {
      await Promise.all(
        selectedChildren?.map(async (child) => {
          updatedPayload(
            { status: 'added' },
            'child',
            'update',
            userData.uid
          ).then(async (payLoadData) => {
            await updateFieldInDocument(
              firestore,
              `children_pod_data/${child.uid ? child.uid : child.id}/${podId}`,
              'init',
              payLoadData
            );
          });
        })
      );

      const pod = await getData(firestore, podId, 'pods');

      let childDetailsPayload = [];
      if (pod.children) {
        childDetailsPayload = [...pod.children];
      }
      selectedChildren.forEach((child) => {
        childDetailsPayload.push({
          id: child.id ?? '',
          child_uid: child.uid ?? '',
          child_email: child.email_address ?? '',
          guardian_uid: child.guardian_uid ?? '',
          guardian_email: child.communication_email ?? '',
          guardian_phone: child.communication_phone ?? '',
        });
      });
      setChildrenPayload(childDetailsPayload);
      updatedPayload(
        {
          children: childDetailsPayload,
        },
        'pods',
        'update',
        userData.uid
      ).then(async (payLoadData) => {
        await updateFieldInDocument(firestore, 'pods', podId, payLoadData);
      });

      await Promise.all(
        selectedChildren?.map(async (child) => {
          updatedPayload(
            {
              child_status: 'enrolled',
              pod_id: autoGenPodId,
            },
            'children',
            'update',
            userData.uid
          ).then(async (payLoadData) => {
            await updateFieldInDocument(
              firestore,
              'children',
              child.id,
              payLoadData
            );
          });
        })
      );

      const data = await getAllDocuments(firestore, 'children');
      if (data) {
        const filteredChildren = data.filter((child) => {
          return (
            child.child_status === 'active' &&
            ((calculateAge(child.date_of_birth) >=
              Number(ageGroup?.split('-')[0]) &&
              calculateAge(child.date_of_birth) <=
                Number(ageGroup?.split('-')[1])) ||
              child.child_age_override)
          );
        });

        const updatedActiveChildren = filteredChildren.filter(
          (child) => !selectedChildrenIds.includes(child.id)
        );
        setActiveChildren(updatedActiveChildren);
      }

      if (pod.pod_status !== 'active') {
        handleUpdatePodStatus({
          created: 'disabled',
          published: 'disabled',
          pending: 'enabled',
          active: 'disabled',
          inactive: 'disabled',
        });
      }
      setChildSearchText('');
      toast.success('Selected children added successfully to pod!');
    } catch (err) {
      console.error(`Error while linking child document`, err.message);
    }
    setSelectedChildrenIds([]);
    handleSetLoading(false);
  };

  const handleSelectOneCheckbox = (e) => {
    const { value, checked } = e.target;

    let tempArr = [...selectedChildrenIds];
    if (checked) {
      tempArr.push(value);
      setSelectedChildrenIds(tempArr);
    } else {
      let index = tempArr.indexOf(value);
      index != -1 ? tempArr.splice(index, 1) : '';
      setSelectedChildrenIds(tempArr);
    }
  };

  const handleClearSearchText = () => {
    getActiveChildren();
    setChildSearchText('');
  };

  const deleteLinkedChild = async (child, type) => {
    handleSetLoading(true);
    const pod = await getData(firestore, podId, 'pods');
    if (type && type === 'waiting') {
      const updatedWaitingChildren = pod.waiting_children
        ? pod.waiting_children.filter((data) => data.id !== child.id)
        : [];
      const updatedChildren = waitingChildren.filter(
        (data) => data.id != child.id
      );
      setConfirmationPopup({
        ...confirmationPopup,
        show: false,
        id: null,
      });
      updatedPayload(
        {
          waiting_children: updatedWaitingChildren,
        },
        'pods',
        'update',
        userData.uid
      ).then(async (payLoadData) => {
        try {
          await updateFieldInDocument(firestore, 'pods', podId, payLoadData);
        } catch (err) {
          console.log(
            'Firebase error while updating waiting children!! ' + err.message
          );
          toast.error('Some error happen');
        }
      });
      updatedPayload(
        {
          waiting_pod_id: '',
        },
        'children',
        'update',
        userData.uid
      ).then(async (payLoadData) => {
        try {
          await updateFieldInDocument(
            firestore,
            'children',
            child.id,
            payLoadData
          );
        } catch (err) {
          console.log(
            'Firebase error while updating children !! ' + err.message
          );
          toast.error('Some error happen');
        }
      });
      handleSetLoading(false);
      toast.success('the child has been unlinked from waiting list');
      setWaitingChildren(updatedChildren);
    } else {
      try {
        updatedPayload(
          { status: 'removed' },
          'child',
          'update',
          userData.uid
        ).then(async (payLoadData) => {
          await updateFieldInDocument(
            firestore,
            `children_pod_data/${child.uid ? child.uid : child.id}/${podId}`,
            'init',
            payLoadData
          );
        });

        setConfirmationPopup({
          ...confirmationPopup,
          show: false,
          id: null,
        });

        const updatedChildrenPayload = pod.children.filter(
          (data) => data.id !== child.id
        );
        setChildrenPayload(updatedChildrenPayload);
        updatedPayload(
          {
            children: updatedChildrenPayload,
          },
          'pods',
          'update',
          userData.uid
        ).then(async (payLoadData) => {
          await updateFieldInDocument(firestore, 'pods', podId, payLoadData);
          handleSetLoading(false);
          toast.success('Selected child has been unlinked to the pod');
        });

        updatedPayload(
          {
            child_status: 'active',
            pod_id: '',
          },
          'children',
          'update',
          userData.uid
        ).then(async (payLoadData) => {
          await updateFieldInDocument(
            firestore,
            'children',
            child.id,
            payLoadData
          );
        });

        const selectedChildren = addedChildren.filter(
          (addedChild) => addedChild.id !== child.id
        );
        if (!selectedChildren.length) {
          handleUpdatePodStatus({
            created: 'enabled',
            published: 'enabled',
            pending: 'disabled',
            active: 'disabled',
            inactive: 'enabled',
          });
        }
        setAddedChildren(selectedChildren);
        setActiveChildren([...activeChildren, child]);
      } catch (err) {
        handleSetLoading(false);
        console.error(`Error while unlinking child from pod`, err.message);
      }
    }
  };

  const handleChildSearch = () => {
    const tempActiveChildren = [...activeChildren];
    const searchedChild =
      tempActiveChildren?.length &&
      tempActiveChildren.filter((child) => {
        const name =
          child.first_name + ' ' + child.middle_name + ' ' + child.last_name;
        return name
          .toLowerCase()
          .includes(childSearchText.toLowerCase().trim());
      });

    if (searchedChild.length !== 0) {
      setActiveChildren(searchedChild);
    } else {
      handleClearSearchText();
      toast.info('Children Not Found');
    }
  };

  useEffect(() => {
    if (childSearchText === '') {
      handleClearSearchText();
    }
  }, [childSearchText]);

  return (
    <div className="tabs-section">
      <ul id="tabs" className="inline-flex pt-2 w-full border-b">
        {podData ? (
          <>
            <li
              onClick={() => setChildTab('children')}
              className={
                'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                (childTab === 'children'
                  ? 'bg-white border-t border-r border-l -mb-px'
                  : '')
              }
            >
              <a>Children</a>
            </li>{' '}
            <li
              onClick={() => setChildTab('default')}
              className={
                'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                (childTab === 'default'
                  ? 'bg-white border-t border-r border-l -mb-px'
                  : '')
              }
            >
              <a id="default-tab">Add Children</a>
            </li>
            <li
              onClick={() => setChildTab('waiting')}
              className={
                'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                (childTab === 'waiting'
                  ? 'bg-white border-t border-r border-l -mb-px'
                  : '')
              }
            >
              <a id="default-tab">Waiting Children</a>
            </li>
          </>
        ) : (
          <>
            {' '}
            <li
              onClick={() => setChildTab('default')}
              className={
                'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                (childTab === 'default'
                  ? 'bg-white border-t border-r border-l -mb-px'
                  : '')
              }
            >
              <a id="default-tab">Add Children</a>
            </li>
            <li
              onClick={() => setChildTab('children')}
              className={
                'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                (childTab === 'children'
                  ? 'bg-white border-t border-r border-l -mb-px'
                  : '')
              }
            >
              <a>Children</a>
            </li>
            <li
              onClick={() => setChildTab('waiting')}
              className={
                'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                (childTab === 'waiting'
                  ? 'bg-white border-t border-r border-l -mb-px'
                  : '')
              }
            >
              <a>Waiting Children</a>
            </li>
          </>
        )}
      </ul>

      <div id="tab-contents" className="border border-t-0">
        <div
          id="first"
          className={'p-5 ' + (childTab != 'default' ? 'hidden' : '')}
        >
          {activeChildren.length ? (
            <>
              <div className="flex w-3/4 mx-auto flex-col sm:flex-row items-center justify-end">
                <div className="flex w-full relative z-0 border border-b-2 rounded-md">
                  <input
                    id="get-child"
                    type="text"
                    value={childSearchText}
                    placeholder=" "
                    className="pt-6 shadow-md pb-1 block w-full px-4 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200"
                    onChange={(e) => {
                      setChildSearchText(e.target.value);
                      e.target.value == '' && handleClearSearchText();
                    }}
                    onKeyPress={(e) => e.key === 'Enter' && handleChildSearch()}
                  />
                  <label
                    htmlFor="get-child"
                    className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                  >
                    Search Child
                  </label>
                  {childSearchText !== '' && (
                    <button
                      className="flex items-center cursor-pointer justify-center px-4 border-l"
                      onClick={handleClearSearchText}
                    >
                      <div className="bg-close"></div>
                    </button>
                  )}
                  <button
                    className="flex items-center justify-center px-4 border-l"
                    onClick={handleChildSearch}
                    disabled={childSearchText === ''}
                  >
                    <div className="bg-search"></div>
                  </button>
                </div>

                <div className="text-center">
                  <button
                    disabled={
                      selectedChildrenIds.length === 0 ||
                      selectedPodStatus === 'created'
                    }
                    onClick={handleAddSelectedChildren}
                    className={
                      'w-40 px-6 py-3 ml-4 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none focus:outline-none ' +
                      (selectedChildrenIds.length !== 0 &&
                      selectedPodStatus !== 'created'
                        ? 'bg-blue-900 hover:bg-yellow-500 hover:shadow-lg'
                        : 'bg-gray-400')
                    }
                  >
                    Add Selected
                  </button>
                </div>
              </div>
              {selectedPodStatus === 'created' && (
                <div className="flex justify-end mt-4 text-red-600 ">
                  {`**Please select the pod status as 'published' in order to add the children`}
                </div>
              )}
              <div className="w-full mx-auto my-0 text-left flex mt-5">
                <div className="table-section w-full overflow-x-auto">
                  <table className="min-w-full border">
                    <thead className="uppercase bg-indigo-50 border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        ></th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Child ID
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Child Name
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Guardian Name
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Guardian ID
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Personality
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Date of Birth
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Language
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeChildren.length
                        ? activeChildren.map((child) => {
                            return (
                              <tr
                                className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                key={child.id}
                              >
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  <div className="col-span-12 flex justify-center">
                                    <input
                                      type="checkbox"
                                      className="cursor-pointer"
                                      value={child.id}
                                      checked={selectedChildrenIds.includes(
                                        child.id
                                      )}
                                      onChange={(e) =>
                                        handleSelectOneCheckbox(e)
                                      }
                                    />
                                  </div>
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {child.children_id}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {`${child.first_name} ${child.middle_name} ${child.last_name}`}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {child.guardian_name ?? ''}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {child.guardian_id ?? ''}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {child.personality}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {convertLocale(
                                    child.date_of_birth,
                                    countryCode
                                  )}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {child.language}
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            'No active child found!'
          )}
        </div>
        <div
          id="second"
          className={'p-5 ' + (childTab != 'children' ? 'hidden' : '')}
        >
          {addedChildren.length ? (
            <div className="w-full mx-auto my-0 text-left mt-5">
              <div className="text-center float-right mb-4">
                <button className="w-40 px-4 py-4 ml-4 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none">
                  Notify All
                </button>
              </div>
              <div className="table-section w-full overflow-x-auto">
                <table className="min-w-full border" id="table">
                  <thead className="uppercase bg-indigo-50 border-b">
                    <tr>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Action
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Child ID
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Guardian Name
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Guardian ID
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Child Name
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Personality
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Age
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Language
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {addedChildren.length
                      ? addedChildren.map((child) => {
                          return (
                            <tr
                              className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 cursor-move"
                              key={child.id}
                            >
                              <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <button
                                    onClick={() =>
                                      setConfirmationPopup({
                                        ...confirmationPopup,
                                        show: true,
                                        child_type: 'active',
                                        id: child,
                                      })
                                    }
                                    className="w-30 px-2 py-2 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                                  >
                                    Unlink
                                  </button>
                                </div>
                              </td>
                              <td className="text-blue-900 font-light p-3 whitespace-nowrap cursor-pointer underline">
                                <NavLink
                                  to="/edit/children"
                                  state={{ id: child.id }}
                                >
                                  {child.children_id}
                                </NavLink>
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {child.guardian_name ?? ''}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {child.guardian_id ?? ''}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {`${child.first_name} ${child.middle_name} ${child.last_name}`}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {child.personality}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {calculateAge(child.date_of_birth)}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {child.language}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            'No Child found!'
          )}
        </div>
        <div
          id="third"
          className={'p-5 ' + (childTab != 'waiting' ? 'hidden' : '')}
        >
          {waitingChildren.length ? (
            <>
              <div className="flex w-3/4 mx-auto flex-col sm:flex-row items-center justify-end">
                <div className="flex w-full relative z-0 border border-b-2 rounded-md">
                  <input
                    id="get-child"
                    type="text"
                    value={childSearchText}
                    placeholder=" "
                    className="pt-6 shadow-md pb-1 block w-full px-4 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200"
                    onChange={(e) => {
                      setChildSearchText(e.target.value);
                      e.target.value == '' && handleClearSearchText();
                    }}
                    onKeyPress={(e) => e.key === 'Enter' && handleChildSearch()}
                  />
                  <label
                    htmlFor="get-child"
                    className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                  >
                    Search Child
                  </label>
                  {childSearchText !== '' && (
                    <button
                      className="flex items-center cursor-pointer justify-center px-4 border-l"
                      onClick={handleClearSearchText}
                    >
                      <div className="bg-close"></div>
                    </button>
                  )}
                  <button
                    className="flex items-center justify-center px-4 border-l"
                    onClick={handleChildSearch}
                    disabled={childSearchText === ''}
                  >
                    <div className="bg-search"></div>
                  </button>
                </div>
              </div>
              {selectedPodStatus === 'created' && (
                <div className="flex justify-end mt-4 text-red-600 ">
                  {`**Please select the pod status as 'published' in order to add the children`}
                </div>
              )}
              <div className="w-full mx-auto my-0 text-left flex mt-5">
                <div className="table-section w-full overflow-x-auto">
                  <table className="min-w-full border">
                    <thead className="uppercase bg-indigo-50 border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Action
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Child ID
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Child Name
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Guardian Name
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Guardian ID
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Personality
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Date of Birth
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Language
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {waitingChildren.length
                        ? waitingChildren.map((child) => {
                            return (
                              <tr
                                className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                key={child.id}
                              >
                                <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  <div className="flex items-center">
                                    <button
                                      onClick={() =>
                                        setConfirmationPopup({
                                          ...confirmationPopup,
                                          show: true,
                                          child_type: 'waiting',
                                          id: child,
                                        })
                                      }
                                      className="w-30 px-2 py-2 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                                    >
                                      Unlink
                                    </button>
                                  </div>
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {child.children_id}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {`${child.first_name} ${child.middle_name} ${child.last_name}`}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {child.guardian_name ?? ''}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {child.guardian_id ?? ''}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {child.personality}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {convertLocale(
                                    child.date_of_birth,
                                    countryCode
                                  )}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {child.language}
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            'No waiting child found!'
          )}
        </div>
      </div>
      {confirmationPopup.show && (
        <ConfirmationPopup
          setPopup={setConfirmationPopup}
          popup={confirmationPopup}
          deleteData={(child) =>
            deleteLinkedChild(child, confirmationPopup.child_type)
          }
        />
      )}
    </div>
  );
}

Children.propTypes = {
  podId: PropTypes.string,
  handleSetLoading: PropTypes.func.isRequired,
  handleUpdatePodStatus: PropTypes.func.isRequired,
  selectedPodStatus: PropTypes.string,
  podData: PropTypes.object,
  ageGroup: PropTypes.string,
  autoGenPodId: PropTypes.string,
  setChildrenPayload: PropTypes.func,
};

export default React.memo(Children);
