import React from 'react';
import Table from '@material-table/core';
import PropTypes from 'prop-types';
import { algoliaFunction } from '../core/searchUtils';

export function MaterialTable({ algoliaIndex = '', columns }) {
  return (
    <Table
      title=""
      options={{
        pageSize: 5,
        pageSizeOptions: [5, 10, 20],
        rowStyle: (rowData) => {
          return {
            fontFamily: `"-apple-system, BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen",
            "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
            sans-serif`,
            border: rowData.pod_status === 'active' ? '3px solid green' : '',
          };
        },
        headerStyle: {
          backgroundColor: '#eef2ff',
          color: 'black',
        },
        debounceInterval: 700,
        // tableLayout: 'auto',

        filtering: true,
        sorting: true,
        draggable: false,
      }}
      data={(query) => {
        const result = new Promise((resolve, reject) => {
          const filterArray = [];
          query.filters.map((filter, index) => {
            filterArray.push({
              connector: index == 0 ? '' : 'AND',
              conditions: [
                {
                  connector: '',
                  fieldName: filter.column.field,
                  fieldValue: filter.value,
                  operator: typeof filter.value == 'string' ? ':' : '=',
                },
              ],
            });
          });
          algoliaFunction(
            filterArray,
            algoliaIndex,
            query.search,
            query.pageSize,
            query.page,
            query
          )
            .then((result) => {
              const arr = result.hits;
              resolve({
                data: arr, // your data array
                page: query.page, // current page number
                totalCount: result.nbHits, // total row number
              });
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        });
        return result;
      }}
      columns={columns}
    />
  );
}

MaterialTable.propTypes = {
  algoliaIndex: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
};

export default MaterialTable;
