import { yupResolver } from '@hookform/resolvers/yup';
import { Option, Select } from '@material-tailwind/react';
import axios from 'axios';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import BackButton from '../common/BackButton';
import {
  addUserData,
  EmailExist,
  getConfigurationData,
  getData,
  updateDocument,
} from '../common/Firebase';
import LoadingSpinner from '../common/Loading';
import {
  capitalizeFirstWords,
  updatedPayload,
  zipCodeApi,
} from '../common/utils';
import { coachValidationSchema } from '../common/Validation';
import { useAuth } from '../core/AuthContextProvider';
import { useCore } from '../core/CoreContextProvider';

export function CreateEditCoach() {
  const { firestore, storage } = useCore();
  const { userData } = useAuth();
  const location = useLocation();
  const { id } = location.state || {};
  const [configData, setConfigData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageLoader, setImageLoader] = useState(false);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [coachData, setCoachData] = useState({});
  const [toasting, setToasting] = useState(false);
  const formOptions = { resolver: yupResolver(coachValidationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    reset,
    getValues,
    control,
    watch,
  } = useForm(formOptions);

  const selectedCountry = watch('country');
  const unique_id = uuid();
  const small_id = unique_id.slice(0, 8);

  const handleImageAsFile = (e) => {
    setImageLoader(true);
    const image = e.target.files[0];
    if (image === '') {
      console.error(`not an image, the image file is a ${typeof image}`);
    }
    const storageRef = ref(
      storage,
      `/images/${getValues('email_address')}-${small_id}`
    );
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setImageUploadProgress(prog);
      },
      (err) => console.log('err', err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setValue('image_url', url);
          setImageLoader(false);
        });
      }
    );
  };

  const onSubmit = async (payload) => {
    setLoading(true);
    payload.coach_type = payload.coach_type ?? 'coach';
    if (id) {
      try {
        updatedPayload(payload, 'coach', 'update', userData.uid).then(
          async (payLoadData) => {
            if (payLoadData.coach_status == 'active') {
              const url = process.env.REACT_APP_COACH_EMAIL_VERIFICATION;
              let zoomResponse = await axios.get(url, {
                params: { email: payLoadData.email_address },
              });
              console.log(zoomResponse);
              if (zoomResponse?.data?.status == 'active') {
                const result = await updateDocument(
                  id,
                  'coaches',
                  firestore,
                  payLoadData
                );
                if (result) {
                  toast.success(
                    `Coach:${payload.coach_id} Updated Successfully`,
                    {
                      toastId: 'edit_coach',
                      onClose: () => {
                        setToasting(false);
                      },
                      onOpen: () => {
                        setToasting(true);
                      },
                    }
                  );
                }
                setLoading(false);
              } else {
                toast.error('User not verified in Zoom Account');
                setLoading(false);
              }
            } else {
              const result = await updateDocument(
                id,
                'coaches',
                firestore,
                payLoadData
              );
              if (result) {
                toast.success(
                  `Coach:${payload.coach_id} Updated Successfully`,
                  {
                    toastId: 'edit_coach',
                    onClose: () => {
                      setToasting(false);
                    },
                    onOpen: () => {
                      setToasting(true);
                    },
                  }
                );
              }
              setLoading(false);
            }
          }
        );
      } catch (err) {
        console.error(
          `Error while updating coach document : ${id}`,
          err.message
        );
        setLoading(false);
      }
    } else {
      try {
        updatedPayload(payload, 'coach', 'create', userData.uid).then(
          async (payLoadData) => {
            await addUserData('coaches', firestore, payLoadData)
              .then((result) => {
                if (result.status) {
                  toast.success('Coach Added Successfully');
                  reset();
                } else {
                  toast.error(result.message);
                }
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                console.log(error);
                toast.error(error);
              });
          }
        );
      } catch (err) {
        console.error(`Error while creating coach document: `, err.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const config = async () => {
      try {
        const config = await getConfigurationData(firestore);
        if (config) {
          setConfigData(config);
        }
      } catch (err) {
        console.error(`Error while fetching config data: `, err.message);
      }
      setLoading(false);
    };
    config();
  }, []);

  useEffect(() => {
    (async () => {
      if (configData && id) {
        const result = await getData(firestore, id, 'coaches');

        reset(result);
        setCoachData(result);
      }
    })();
  }, [configData]);

  useEffect(() => {
    if (!id) {
      const resetPayload = {};
      for (let key in coachData) {
        resetPayload[key] = '';
      }
      setCoachData({});
      reset(resetPayload);
    }
  }, [id]);

  const handleOnBlurZipCode = (e) => {
    setLoading(true);
    zipCodeApi(e, getValues('country'))
      .then((res) => {
        if (typeof res === 'string') {
          toast.error(res);
        }
        setValue('state', res.state);
        setValue('city', res.city);
        setValue('time_zone', res.timezone.timezone_identifier);
        setValue('time_zone_abbr', res.timezone.timezone_abbr);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setValue('state', '');
        setValue('city', '');
        setValue('time_zone_abbr', '');
        setLoading(false);
      });
  };

  const EmailValid = (emailId) => {
    emailId &&
      EmailExist(firestore, emailId)
        .then((res) => {
          if (!res.status) {
            toast.error('user email is already exist');
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };
  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overnpmflow-hidden">
        <div className="content relative flex-1 px-6 pb-10 bg-indigo-50">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="intro-y flex items-center mt-6">
              <BackButton isDirty={isDirty} />
              <h2 className="text-2xl font-medium mr-auto">
                Coach Enrollment Form
              </h2>
            </div>
            {loading && <LoadingSpinner />}
            {Object.keys(configData).length !== 0 && (
              <div className="grid grid-cols-12 mt-5">
                <div className="col-span-12 lg:col-span-12">
                  <div className="bg-white shadow-md rounded-md">
                    <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                      <h2 className="font-bold text-base mr-auto">
                        {id ? 'Edit ' : ''}Coach Details
                      </h2>
                    </div>
                    <div className="p-5">
                      <div className="preview">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="col-span-9 lg:col-span-9">
                            <div className="flex flex-col sm:flex-row items-center">
                              <div className="flex-auto w-1/3 relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.first_name?.message}
                                </span>
                                <input
                                  id="first-name"
                                  type="text"
                                  {...register('first_name', {
                                    required: true,
                                  })}
                                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  placeholder=" "
                                />
                                <label
                                  htmlFor="first-name"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  First Name
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mx-4 flex-auto w-1/3 relative">
                                <input
                                  id="middle-name"
                                  type="text"
                                  {...register('middle_name')}
                                  className="pt-6 pb-1 block shadow-md w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  placeholder=" "
                                />
                                <label
                                  htmlFor="middle-name"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Middle Name
                                </label>
                              </div>
                              <div className="flex-auto w-1/3 relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.last_name?.message}
                                </span>
                                <input
                                  id="last-name"
                                  type="text"
                                  {...register('last_name', {
                                    required: true,
                                  })}
                                  className="pt-6 pb-1 block shadow-md w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  placeholder=" "
                                />
                                <label
                                  htmlFor="last-name"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Last Name
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                            </div>
                            <div className="mt-5 flex flex-col sm:flex-row items-center">
                              <div className="flex-auto w-1/3 relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.email_address?.message}
                                </span>
                                <input
                                  id="email-address"
                                  type="text"
                                  {...register('email_address', {
                                    onBlur: (e) =>
                                      EmailValid(e.target.value.trim()),
                                  })}
                                  className={`pt-6 pb-1 block shadow-md w-full px-4 mt-0  border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                    id ? 'bg-gray-300' : 'bg-transparent'
                                  }`}
                                  placeholder=" "
                                  disabled={id}
                                />
                                <label
                                  htmlFor="email-address"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Email Address
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="flex-auto mx-4 w-1/3 relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.phone_number?.message}
                                </span>
                                <Controller
                                  name={'phone_number'}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <ReactPhoneInput
                                        id="phone_number"
                                        onChange={(e) => {
                                          onChange(`+${e}`);
                                        }}
                                        country={'us'}
                                        value={value}
                                        disableDropdown={true}
                                        countryCodeEditable={false}
                                      />
                                    );
                                  }}
                                  rules={{ required: true }}
                                />
                              </div>
                              <div className="flex-auto w-1/3 c-select relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.country?.message}
                                </span>
                                {configData?.country && (
                                  <Controller
                                    name={'country'}
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return (
                                        <Select
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                          id="country"
                                          value={value}
                                          label="Select Country"
                                          variant="outlined"
                                          className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                            value ? ' focus-label' : ''
                                          }`}
                                        >
                                          {configData?.country?.map((item) => {
                                            return (
                                              <Option value={item} key={item}>
                                                {capitalizeFirstWords(item)}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      );
                                    }}
                                    rules={{ required: true }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="mt-5 flex flex-col sm:flex-row items-center">
                              <div className="flex-auto w-1/3 relative">
                                <span className="text-red-600 absolute -top-6">
                                  {!selectedCountry
                                    ? ''
                                    : errors?.zip_code?.message}
                                </span>
                                <input
                                  id="zipcode"
                                  type="text"
                                  {...register('zip_code', {
                                    required: true,
                                    onBlur: (e) => {
                                      handleOnBlurZipCode(e);
                                    },
                                  })}
                                  onKeyDown={(e) => {
                                    e.keyCode === 13 && e.target.blur();
                                  }}
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 ${
                                    !selectedCountry
                                      ? 'bg-gray-300'
                                      : 'bg-transparent'
                                  } border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                                  placeholder=" "
                                  disabled={!selectedCountry}
                                />
                                <label
                                  htmlFor="zipcode"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Zip Code
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="flex-auto mx-4 w-1/3 relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.state?.message}
                                </span>
                                <input
                                  id="state"
                                  type="text"
                                  {...register('state')}
                                  className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 mt-0 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  placeholder=" "
                                  disabled
                                />
                                <label
                                  htmlFor="state"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  State<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="flex-auto w-1/3 relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.city?.message}
                                </span>
                                <input
                                  id="city"
                                  type="text"
                                  {...register('city')}
                                  className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 mt-0 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  placeholder=" "
                                  disabled
                                />
                                <label
                                  htmlFor="city"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  City<span className="text-red-600">*</span>
                                </label>
                              </div>
                            </div>
                            <div className="mt-5 flex flex-col sm:flex-row items-center">
                              <div className="flex-auto w-1/3 relative">
                                <input
                                  id="timezone"
                                  type="text"
                                  {...register('time_zone')}
                                  className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 mt-0 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  placeholder=" "
                                  disabled
                                />
                                <label
                                  htmlFor="timezone"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Time Zone
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="flex-auto c-select mx-4 w-1/3 relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.coach_status?.message}
                                </span>
                                {configData?.coach_status && (
                                  <Controller
                                    name={'coach_status'}
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return (
                                        <Select
                                          value={value}
                                          label="Select Status"
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                          className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                            value ? ' focus-label' : ''
                                          }`}
                                        >
                                          {configData?.coach_status?.map(
                                            (item) => {
                                              return (
                                                <Option
                                                  value={item}
                                                  key={item}
                                                  disabled={
                                                    !coachData.zoom_account_id &&
                                                    item == 'active'
                                                  }
                                                >
                                                  {capitalizeFirstWords(item)}
                                                </Option>
                                              );
                                            }
                                          )}
                                        </Select>
                                      );
                                    }}
                                    rules={{ required: true }}
                                  />
                                )}
                              </div>
                              <div className="flex-auto w-1/3 c-select relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.gender?.message}
                                </span>
                                {configData?.gender && (
                                  <Controller
                                    name={'gender'}
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return (
                                        <Select
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                          value={value}
                                          label="Select Gender"
                                          className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                            value ? ' focus-label' : ''
                                          }`}
                                        >
                                          {configData?.gender?.map((item) => {
                                            return (
                                              <Option value={item} key={item}>
                                                {capitalizeFirstWords(item)}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      );
                                    }}
                                    rules={{ required: true }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="mt-5 flex flex-col sm:flex-row items-center">
                              <div className="flex-auto w-1/3 relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.address_line_one?.message}
                                </span>
                                <input
                                  id="address-line-one"
                                  type="text"
                                  placeholder=" "
                                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  {...register('address_line_one')}
                                />
                                <label
                                  htmlFor="address-line-one"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Building/Street
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="flex-auto mx-4 w-1/3 relative">
                                <input
                                  id="address-line-two"
                                  type="text"
                                  placeholder=" "
                                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  {...register('address_line_two')}
                                />
                                <label
                                  htmlFor="address-line-two"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Address Line 2
                                </label>
                              </div>
                              <div className="flex-auto w-1/3 relative">
                                <input
                                  id="address-line-three"
                                  type="text"
                                  placeholder=" "
                                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  {...register('address_line_three')}
                                />
                                <label
                                  htmlFor="address-line-three"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Address Line 3
                                </label>
                              </div>

                              <div className="flex-auto c-select ml-4 w-1/3 relative">
                                <Controller
                                  name={'coach_type'}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <Select
                                        value={value ?? 'coach'}
                                        label="Select Coach Type"
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md focus-label`}
                                      >
                                        <Option value={'coach'} key={'coach'}>
                                          Coach
                                        </Option>
                                        <Option
                                          value={'onlooker'}
                                          key={'onlooker'}
                                        >
                                          Assistant
                                        </Option>
                                      </Select>
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="mt-5 flex flex-col sm:flex-row items-center">
                              <div className="flex-auto w-3/12 relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.language?.message}
                                </span>
                                <select
                                  multiple
                                  {...register('language', {
                                    required: true,
                                  })}
                                  id="language"
                                  placeholder=" "
                                  className="pt-10 shadow-md pb-1 block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 h-40 rounded-md"
                                >
                                  {configData?.language?.map((item) => {
                                    return (
                                      <option
                                        className="py-1 checked:bg-blue-900 checked:text-white"
                                        value={item}
                                        key={item}
                                      >
                                        {capitalizeFirstWords(item)}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label
                                  htmlFor="language"
                                  className="absolute duration-300 top-4 bg-white left-0 right-0 z-1 origin-0 text-gray-500 px-4"
                                >
                                  Language
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="flex-auto ml-4 w-3/12 relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.age_group?.message}
                                </span>
                                <select
                                  {...register('age_group', {
                                    required: true,
                                  })}
                                  id="age-group"
                                  multiple
                                  className="pt-10 shadow-md pb-1 block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 h-40 rounded-md"
                                >
                                  {configData?.age_group?.map((item) => {
                                    return (
                                      <option
                                        className="py-1 checked:bg-blue-900 checked:text-white"
                                        value={item}
                                        key={item}
                                      >
                                        {capitalizeFirstWords(item)}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label
                                  htmlFor="age-group"
                                  className="absolute duration-300 top-4 bg-white left-0 right-0 z-1 origin-0 text-gray-500 px-4"
                                >
                                  Age Group
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                            </div>
                            <div className="mt-5 flex flex-col sm:flex-row items-center">
                              <div className="flex-auto w-full relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.short_bio?.message}
                                </span>
                                <textarea
                                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md h-40 w-full"
                                  cols="30"
                                  rows=""
                                  id="bio"
                                  placeholder=" "
                                  {...register('short_bio', { required: true })}
                                ></textarea>
                                <label
                                  htmlFor="short_bio"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4 bio"
                                >
                                  Short Bio
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                            </div>
                            <div className="mt-5 flex flex-col sm:flex-row items-center">
                              <div className="flex-auto w-full relative">
                                <span className="text-red-600 absolute -top-6">
                                  {errors?.detailed_bio?.message}
                                </span>
                                <textarea
                                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md h-40 w-full"
                                  cols="30"
                                  rows=""
                                  id="detailed_bio"
                                  placeholder=" "
                                  {...register('detailed_bio', {
                                    required: true,
                                  })}
                                ></textarea>
                                <label
                                  htmlFor="detailed_bio"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4 bio"
                                >
                                  Detailed Bio
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                            </div>
                            {Object.keys(coachData).length > 0 && (
                              <div className="mt-5 flex flex-col sm:flex-row items-center">
                                <div className="flex-auto w-full flex relative text-xs">
                                  <span className="text-black-600 mr-1 font-black">
                                    Note:
                                  </span>
                                  <p className="text-red-600 font-bold">
                                    Please check whether adequate Zoom licenses
                                    (Type: Licensed) are available, if the Coach
                                    status does not change to Active
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-span-3 lg:col-span-3">
                            <div className="flex-auto w-full relative">
                              <label htmlFor="">Profile Picture</label>
                              <div className="mt-5">
                                <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                  <div className="absolute">
                                    <div className="flex flex-col items-center ">
                                      {!getValues('image_url') &&
                                      !imageLoader ? (
                                        <>
                                          <span className="block text-gray-400 font-normal">
                                            Upload you file here
                                          </span>
                                          <span className="block text-gray-400 font-normal">
                                            or
                                          </span>
                                          <span className="block text-blue-400 font-normal">
                                            Browse files
                                          </span>{' '}
                                        </>
                                      ) : imageLoader ? (
                                        `Uploading... ${imageUploadProgress}%`
                                      ) : (
                                        <img
                                          src={getValues('image_url')}
                                          alt="new"
                                          width={120}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <input
                                    type="file"
                                    className="h-full shadow-md w-full opacity-0 cursor-pointer"
                                    name=""
                                    onChange={handleImageAsFile}
                                  />
                                </div>
                                <div className="justify-between items-center text-gray-400">
                                  {' '}
                                  <span>Accepted file type: .jpg, .png</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white shadow-md rounded-md mt-5">
                    <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                      <h2 className="font-bold text-base mr-auto">
                        Payment Details
                      </h2>
                    </div>
                    <div className="p-5">
                      <div className="h-32"></div>
                    </div>
                  </div>
                  <div className="pt-5 text-center">
                    <button
                      disabled={toasting}
                      className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                    >
                      {id ? 'Update' : 'Add'}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
