import { createNullCache } from '@algolia/cache-common';
const algoliasearch = require('algoliasearch');

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
  {
    // Caches responses from Algolia
    responsesCache: createNullCache(), // or

    // Caches Promises with the same request payload
    requestsCache: createNullCache(), // or
  }
);

export async function algoliaFunction(
  filterArr = [],
  indexName = '',
  searchText = '',
  hitsperpage = 40,
  page = 0,
  query
) {
  let filter_string = '';
  let searchIndex = searchClient.initIndex(indexName);
  if (query && query.orderBy) {
    searchIndex = searchClient.initIndex(
      `${indexName}_${query.orderBy.field}_${query.orderDirection}`
    );
  }
  if (filterArr.length > 0) {
    filterArr.map((filter) => {
      if (filter.connector) {
        filter_string = filter_string + ` ${filter.connector}`;
      }
      if (filter.conditions) {
        if (filter.conditions.length > 1) {
          if (filter_string.length > 0) {
            filter_string = filter_string + ' (';
          } else {
            filter_string = filter_string + '(';
          }
        }
        filter.conditions.map((conditions) => {
          if (conditions.connector) {
            filter_string = filter_string + ` ${conditions.connector}`;
          }

          let fieldName = `${conditions.fieldName}`,
            fieldValue = `${conditions.fieldValue}`,
            operator = `${conditions.operator}`;
          let whitespace_in_fieldName = /\s/g.test(conditions.fieldName);
          let whitespace_in_fieldValue = /\s/g.test(conditions.fieldValue);
          if (whitespace_in_fieldName) {
            fieldName = `"${conditions.fieldName}"`;
          }
          if (whitespace_in_fieldValue) {
            fieldValue = `"${conditions.fieldValue}"`;
          }
          if (typeof conditions.fieldValue === 'string') {
            if (conditions.operator == 'NOT') {
              operator = ':';
              if (filter_string.length > 0) {
                filter_string = filter_string + ' NOT';
              } else {
                filter_string = filter_string + 'NOT';
              }
            }
          }
          if (filter_string.length > 0) {
            filter_string =
              filter_string + ` ${fieldName}${operator}${fieldValue}`;
          } else {
            filter_string =
              filter_string + `${fieldName}${operator}${fieldValue}`;
          }
        });
        if (filter.conditions.length > 1) {
          filter_string = filter_string + ' )';
        }
      }
    });
  }
  let result;
  if (filter_string.length > 0) {
    result = await searchIndex.search(searchText, {
      filters: `${filter_string}`,
      hitsPerPage: hitsperpage,
      page,
    });
  } else {
    result = await searchIndex.search(searchText, {
      hitsPerPage: hitsperpage,
      page,
    });
  }
  return result;
}
