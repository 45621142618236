import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import GetAnswer from '../../InputTag/GetAnswer';
import Operand from '../../InputTag/Operand';

const ValueMultiple = ({
  previousStep,
  response,
  UpdateResponse,
  index,
  addedStep,
  setAddedStep,
  getStepNumber,
}) => {
  const [selectedStep, setSelectedStep] = useState();

  const storeResponse = (value, name) => {
    const obj = { ...response };
    obj[name] = value;
    if (name == 'step_id') {
      const step = previousStep.find((e1) => e1.id == value);
      obj.type = step ? step.type : '';
      let tempArr = [...addedStep];
      tempArr[index] = value;
      setAddedStep(tempArr);
      setSelectedStep(step);
    }

    UpdateResponse(obj, index);
  };

  useEffect(() => {
    if (response?.step_id) {
      const step = previousStep.find((e1) => e1.id == response.step_id);
      setSelectedStep(step);
    }
  }, []);
  return (
    <>
      <div className="mt-4">
        <select
          onChange={(e) => {
            storeResponse(e.target.value, e.target.name);
          }}
          value={response.connector}
          name={'connector'}
          className="w-full px-6 py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2 mr-5   hover:shadow-lg focus:outline-none "
        >
          <option value="">Select a condition</option>
          <option value={'&&'}>AND</option>
          <option value={'||'}>OR</option>
        </select>
      </div>
      <div className="flex w-full justify-between">
        {response.connector !== '' && (
          <div className="w-1/3 flex-auto">
            <select
              onChange={(e) => {
                storeResponse(e.target.value, e.target.name);
              }}
              name={'step_id'}
              value={response.step_id}
              className="px-6 w-full py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2 hover:shadow-lg focus:outline-none "
            >
              <option value="" disabled={response.step_id}>
                Select your step
              </option>
              {previousStep.map((e) => {
                return (
                  <>
                    <option
                      key={e.id}
                      value={e.id}
                      disabled={e.id == response.step_id}
                    >
                      {getStepNumber(e)}) {e.heading}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
        )}
        {selectedStep && (
          <>
            <div className="w-1/3 flex-auto mx-2">
              <Operand
                step={selectedStep}
                storeAnswer={storeResponse}
                response={response.operand}
              />
            </div>
            <div className="w-1/3 flex-auto">
              <GetAnswer
                step={selectedStep}
                storeAnswer={storeResponse}
                response={response.answer}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

ValueMultiple.propTypes = {
  previousStep: PropTypes.array,
  UpdateResponse: PropTypes.func,
  response: PropTypes.object,
  index: PropTypes.number,
  addedStep: PropTypes.array,
  setAddedStep: PropTypes.func,
  getStepNumber: PropTypes.func,
};

export default ValueMultiple;
