import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Select, Option } from '@material-tailwind/react';
import GenerateSteps from './GenerateSteps';
import { yupResolver } from '@hookform/resolvers/yup';
import { formDetailsValidationSchema } from '../common/Validation';
import { useCore } from '../core/CoreContextProvider';
import { NavLink, useLocation } from 'react-router-dom';
import log from '../core/log';
import {
  getData,
  updateFieldInDocument,
  getAllDocumentsWithSort,
  getAllDocuments,
} from '../common/Firebase';
import LoadingSpinner from '../common/Loading';
import { toast } from 'react-toastify';
import { setDoc, doc, deleteDoc } from '@firebase/firestore';
import AddCondition from './Condition.js/AddCondition';
import Style from './Style/Style';

const CreateTypeForm = () => {
  const { firestore } = useCore();
  const location = useLocation();
  const [popup, setPopup] = useState({ show: false });
  const [variable, setVariable] = useState({});
  const [addedVariable, setAddedVariable] = useState([]);
  const [editVariable, setEditVariable] = useState({ index: 0, value: '' });
  const [addedSteps, setAddedSteps] = useState([]);
  const [addedQuery, setAddedQuery] = useState([]);
  const [formData, setFormData] = useState({});
  const [tab, setTab] = useState('default');
  const [loading, setLoading] = useState(true);
  const variableName = useRef();
  const formOptions = {
    resolver: yupResolver(formDetailsValidationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm(formOptions);
  useEffect(() => {
    if (location?.state) {
      let data = location.state.data;

      const getAllSteps = async () => {
        const formDocData = await getData(
          firestore,
          data.form_name,
          'tapouts_form'
        );
        setFormData({ ...formDocData });
        reset(formDocData);
        let usedVariable = [];
        const stepsData = await getAllDocumentsWithSort(
          firestore,
          `tapouts_form/${data.form_name}/steps/`,
          'step_no'
        );
        for (let step of stepsData) {
          const subStepData = await getAllDocumentsWithSort(
            firestore,
            `tapouts_form/${data.form_name}/steps/${step.id}/sub_steps/`,
            'step_no'
          );
          const conditions = await getAllDocuments(
            firestore,
            `tapouts_form/${data.form_name}/steps/${step.id}/conditions`
          );
          if (subStepData.length) {
            step.sub_steps = [...subStepData];
          } else if (conditions.length) {
            step.conditions = [...conditions];
          }
          if (step.variable != '') {
            usedVariable.push(step.variable);
          }
          subStepData.map((stepDetails) => {
            if (stepDetails.variable != '') {
              usedVariable.push(stepDetails.variable);
            }
          });
        }
        setAddedVariable(usedVariable);
        const obj = { ...formDocData.variable };
        setVariable(obj);
        setAddedSteps(stepsData);
        setPopup({
          show: true,
        });
      };
      getAllSteps();
    }
    setLoading(false);
  }, []);
  const addVariable = async (name) => {
    if (name === '') return;

    if (Object.values(variable).includes(name)) {
      toast.info('Variable already exist');
    } else if (Object.keys(formData).length) {
      if (editVariable.value == '') {
        const obj = { ...variable };
        const editableNextIndex = Object.keys(obj).length + 1;
        obj[editableNextIndex] = name;
        try {
          await updateFieldInDocument(
            firestore,
            'tapouts_form',
            formData.form_name,
            { variable: obj }
          );
        } catch (err) {
          log('Error while adding variable' + err);
        }
        setVariable(obj);
        variableName.current.value = '';
      } else {
        const obj = { ...variable };
        let key = editVariable.index;
        obj[key] = name;
        try {
          await updateFieldInDocument(
            firestore,
            'tapouts_form',
            formData.form_name,
            { variable: obj }
          );
        } catch (err) {
          log('Error while adding variable' + err);
        }
        setVariable(obj);
        variableName.current.value = '';
        let editObj = {
          index: 0,
          value: '',
        };
        setEditVariable(editObj);
        setTab('variable');
      }
    } else {
      toast.info('Please create the form');
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    if (Object.keys(formData).length === 0) {
      try {
        const formData = await getData(
          firestore,
          data.form_name,
          `tapouts_form`
        );
        if (!formData) {
          const id = await setDoc(
            doc(firestore, `tapouts_form`, data.form_name),
            { ...data, variable: {} }
          );
          setFormData({ ...data, id });
          setPopup({
            show: true,
          });

          toast.success('Form has been created');
        } else {
          toast.info('Form already exist');
        }
        setLoading(false);
      } catch (err) {
        log('Error while creating form' + err);
        setLoading(false);
      }
    } else {
      try {
        if (
          data.no_of_steps !== formData.no_of_steps &&
          data.form_status === 'Published'
        ) {
          setLoading(false);
          toast.error('Please add the remaining steps');
        } else {
          await updateFieldInDocument(
            firestore,
            'tapouts_form',
            formData.form_name,
            data
          );
          setPopup({
            show: true,
          });
          toast.success('Form has been updated');
          setFormData(data);
          setLoading(false);
        }
      } catch (err) {
        log('Error while updating form' + err);
        setLoading(false);
      }
    }
  };
  const variableEdit = async (value, key) => {
    variableName.current.value = value;
    editVariable.index = key;
    editVariable.value = value;
    setEditVariable(editVariable);
    setTab('default');
  };
  const deleteStep = async (stepId, subStepId) => {
    setLoading(true);
    if (subStepId) {
      try {
        await deleteDoc(
          doc(
            firestore,
            `tapouts_form/${formData.form_name}/steps/${stepId}/sub_steps`,
            subStepId
          )
        );
        removeSteps(stepId, subStepId);
        setLoading(false);
      } catch (err) {
        log('Error while deleting doc' + err);
        setLoading(false);
      }
    } else {
      try {
        await deleteDoc(
          doc(firestore, `tapouts_form/${formData.form_name}/steps`, stepId)
        );
        removeSteps(stepId);
        setLoading(false);
      } catch (err) {
        log('Error while deleting sub step' + err);
        setLoading(false);
      }
    }
  };

  const removeSteps = (stepId, subStepId) => {
    const finalArr = [...addedSteps];
    const stepsId = {};

    if (!subStepId) {
      finalArr.forEach((step, index) => {
        if (step.id === stepId) {
          if (step.variable) {
            setAddedVariable([
              ...addedVariable.filter((e) => e !== step.variable),
            ]);
          }
          finalArr.splice(index, 1);
        }
      });
    } else {
      finalArr.forEach((e) => (stepsId[e.id] = e));
      stepsId[stepId].sub_steps.forEach((e, index) => {
        if (e.id == subStepId) {
          stepsId[stepId].sub_steps.splice(index, 1);
          if (e.variable) {
            setAddedVariable([
              ...addedVariable.filter((e1) => e1 !== e.variable),
            ]);
          }
        }
      });
    }
    setAddedSteps(finalArr);
  };

  return (
    <div className="bg-white shadow-md rounded-md mt-5">
      <div className="p-5">
        <div className="preview">
          {loading && <LoadingSpinner />}
          <div className="tabs-section">
            <ul id="tabs" className="inline-flex pt-2 w-full border-b">
              <li
                onClick={() => setTab('default')}
                className={
                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                  (tab === 'default'
                    ? 'bg-white border-t border-r border-l -mb-px'
                    : '')
                }
              >
                <a id="default-tab">Add Steps</a>
              </li>
              <li
                onClick={() => setTab('child')}
                className={
                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                  (tab === 'child'
                    ? 'bg-white border-t border-r border-l -mb-px'
                    : '')
                }
              >
                <a>Manage Steps</a>
              </li>
              <li
                onClick={() => setTab('variable')}
                className={
                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                  (tab === 'variable'
                    ? 'bg-white border-t border-r border-l -mb-px'
                    : '')
                }
              >
                <a>Manage Variables</a>
              </li>
              <li
                onClick={() => setTab('condition')}
                className={
                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                  (tab === 'condition'
                    ? 'bg-white border-t border-r border-l -mb-px'
                    : '') +
                  (addedSteps.length == formData.no_of_steps ? '' : 'hidden')
                }
              >
                <a>Form Conditions</a>
              </li>
              <li
                onClick={() => setTab('style')}
                className={
                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                  (tab === 'style'
                    ? 'bg-white border-t border-r border-l -mb-px'
                    : '') +
                  (addedSteps.length == formData.no_of_steps ? '' : 'hidden')
                }
              >
                <a>Form Style</a>
              </li>
            </ul>

            <div id="tab-contents" className="border border-t-0">
              <div
                id="first"
                className={'p-5 ' + (tab != 'default' ? 'hidden' : '')}
              >
                <div className="p-5">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="preview">
                      <div className="flex flex-col sm:flex-row items-center">
                        <div className="mr-4 flex-auto w-1/3 relative">
                          <span className="text-red-600 absolute -top-6">
                            {errors?.form_name?.message}
                          </span>
                          <input
                            id="form_name"
                            type="text"
                            placeholder=" "
                            disabled={Object.keys(formData).length != 0}
                            className={`pt-6 ${
                              Object.keys(formData).length != 0
                                ? 'bg-gray-300'
                                : 'bg-transparent'
                            } pb-1 shadow-md block w-full px-4 mt-0  border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                            {...register('form_name')}
                          />
                          <label
                            htmlFor="form_name"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Form Name
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row items-center mt-5 ">
                        <div className="mr-4 flex-auto w-1/3 relative">
                          <span className="text-red-600 absolute -top-6">
                            {errors?.form_description?.message}
                          </span>
                          <input
                            id="form_status"
                            type="text"
                            placeholder=" "
                            className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                            {...register('form_description')}
                          />
                          <label
                            htmlFor="form_description"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Form Description
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row items-center mt-5 ">
                        <div className="mr-4 flex-auto w-1/3 relative">
                          <span className="text-red-600 absolute -top-6">
                            {errors?.no_of_steps?.message}
                          </span>
                          <input
                            id="form_status"
                            placeholder=" "
                            type="number"
                            min="1"
                            className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                            {...register('no_of_steps')}
                          />
                          <label
                            htmlFor="form_description"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            No Of Steps
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                      </div>

                      <div className="mt-5 flex flex-col sm:flex-row items-center">
                        <div className="flex-auto mr-4 w-1/3 relative">
                          <span className="text-red-600 absolute -top-6">
                            {errors?.form_status?.message}
                          </span>

                          <Controller
                            name={'form_status'}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  id="form_status"
                                  defaultValue=""
                                  value={value}
                                  label="Select status"
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                    value ? ' focus-label' : ''
                                  }`}
                                >
                                  <Option value={'created'}>Created</Option>
                                  <Option
                                    disabled={
                                      Object.keys(formData).length === 0 ||
                                      addedSteps.length == 0 ||
                                      addedSteps.length != formData.no_of_steps
                                    }
                                    value={'published'}
                                  >
                                    Published
                                  </Option>
                                </Select>
                              );
                            }}
                            rules={{ required: false }}
                          />
                        </div>
                      </div>

                      <div className="p-5 text-center">
                        <button className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none">
                          {Object.keys(formData).length !== 0
                            ? 'Update form'
                            : 'Create form'}
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="flex justify-between items-center">
                    <div className="mr-4 flex-auto w-2/3 relative">
                      <input
                        id="form_status"
                        type="text"
                        placeholder=" "
                        ref={variableName}
                        className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                      />
                      <label
                        htmlFor="form_description"
                        className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                      >
                        Variable Name
                        <span className="text-red-600">*</span>
                      </label>
                    </div>
                    <div className="p-5 text-center w-1/3 flex-auto">
                      <button
                        onClick={() => addVariable(variableName.current.value)}
                        className="w-40 p-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                      >
                        {editVariable.value == ''
                          ? 'Create Variable'
                          : 'Update Variable'}
                      </button>
                    </div>
                  </div>

                  <div className="flex-auto w-2/3 relative text-red-600">
                    <p>
                      *You should mention the variables in a html anchor tag
                    </p>
                    <p> For ex: name should be given as {'<name>'}*</p>
                  </div>

                  {popup.show && (
                    <GenerateSteps
                      setAddedSteps={setAddedSteps}
                      formData={formData}
                      addedSteps={addedSteps}
                      setLoading={setLoading}
                      setVariable={setVariable}
                      variable={variable}
                      loading={loading}
                      setAddedQuery={setAddedQuery}
                      addedQuery={addedQuery}
                      addedVariable={addedVariable}
                      setAddedVariable={setAddedVariable}
                      setEditVariable={setEditVariable}
                      editVariable={editVariable}
                    />
                  )}
                </div>
              </div>
              <div
                id="second"
                className={'p-5 ' + (tab != 'child' ? 'hidden' : '')}
              >
                <div className="w-full mx-auto my-0 text-left flex mt-5">
                  <div className="table-section w-full">
                    {addedSteps.length !== 0 ? (
                      <>
                        <div className="w-full mx-auto my-0 text-left flex mt-5">
                          <div className="table-section w-full">
                            <table className="min-w-full border">
                              <thead className="uppercase bg-indigo-50 border-b">
                                <tr>
                                  <th
                                    scope="col"
                                    className="font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Heading
                                  </th>
                                  <th
                                    scope="col"
                                    className="font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Type
                                  </th>
                                  <th
                                    scope="col"
                                    className="font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Variable
                                  </th>
                                  <th
                                    scope="col"
                                    className="font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Delete
                                  </th>
                                  <th
                                    scope="col"
                                    className="font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Edit
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {addedSteps?.map((step, i) => {
                                  return (
                                    <React.Fragment key={btoa(i)}>
                                      <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          {step.heading}
                                        </td>
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          {step.type}
                                        </td>
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          {step.variable
                                            ? variable[step.variable]
                                            : '-'}
                                        </td>
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          <div
                                            onClick={() => {
                                              deleteStep(step.id);
                                            }}
                                            className="flex items-center"
                                          >
                                            <a className="border p-3 mr-2 cursor-pointer">
                                              <span className="bg-delete block"></span>
                                            </a>
                                          </div>
                                        </td>
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          <NavLink
                                            to="/edit/steps"
                                            state={{
                                              data: formData,
                                              index: i,
                                            }}
                                            className=" p-3 mr-2 cursor-pointer tooltip relative"
                                          >
                                            <div className="flex items-center">
                                              <a className="border p-3 mr-2 cursor-pointer">
                                                <span className="bg-edit block"></span>
                                              </a>
                                            </div>
                                          </NavLink>
                                        </td>
                                      </tr>
                                      {step?.type == 'topic' &&
                                        Object.prototype.hasOwnProperty.call(
                                          step,
                                          'sub_steps'
                                        ) && (
                                          <tr>
                                            <td colSpan="7">
                                              <table className="mt-5 w-3/4 mx-auto">
                                                <thead className="uppercase bg-indigo-50 border-b">
                                                  <tr>
                                                    <th
                                                      scope="col"
                                                      className="font-medium text-gray-900 px-6 py-4 text-left"
                                                    >
                                                      Heading
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="font-medium text-gray-900 px-6 py-4 text-left"
                                                    >
                                                      Type
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="font-medium text-gray-900 px-6 py-4 text-left"
                                                    >
                                                      Variable
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="font-medium text-gray-900 px-6 py-4 text-left"
                                                    >
                                                      Delete
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="font-medium text-gray-900 px-6 py-4 text-left"
                                                    >
                                                      Edit
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {step.sub_steps.map(
                                                    (subStep, i1) => {
                                                      return (
                                                        <tr
                                                          className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                                          key={btoa(i1)}
                                                        >
                                                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {subStep.heading}
                                                          </td>
                                                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {subStep.type}
                                                          </td>
                                                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {subStep.variable
                                                              ? subStep.variable
                                                              : '-'}
                                                          </td>
                                                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            <div
                                                              onClick={() => {
                                                                deleteStep(
                                                                  subStep.topic_id,
                                                                  subStep.id
                                                                );
                                                              }}
                                                              className="flex items-center"
                                                            >
                                                              <a className="border p-3 mr-2 cursor-pointer">
                                                                <span className="bg-delete block"></span>
                                                              </a>
                                                            </div>
                                                          </td>
                                                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            <NavLink
                                                              to="/edit/steps"
                                                              state={{
                                                                data: formData,
                                                                index: i,
                                                                subStepIndex:
                                                                  i1,
                                                              }}
                                                              className=" p-3 mr-2 cursor-pointer tooltip relative"
                                                            >
                                                              <div className="flex items-center">
                                                                <a className="border p-3 mr-2 cursor-pointer">
                                                                  <span className="bg-edit block"></span>
                                                                </a>
                                                              </div>
                                                            </NavLink>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        )}
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="pt-6"> No added steps found!</div>
                    )}
                  </div>
                </div>
              </div>
              <div
                id="third"
                className={'p-5 ' + (tab != 'variable' ? 'hidden' : '')}
              >
                <div className="w-full mx-auto my-0 text-left flex mt-5">
                  <div className="table-section w-full">
                    {Object.keys(variable).length !== 0 ? (
                      <>
                        <div className="w-full mx-auto my-0 text-left flex mt-5">
                          <div className="table-section w-full">
                            <table className="min-w-full border">
                              <thead className="uppercase bg-indigo-50 border-b">
                                <tr>
                                  <th
                                    scope="col"
                                    className="font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Variable Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Edit
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(variable)?.map((key, i) => {
                                  return (
                                    <React.Fragment key={btoa(i)}>
                                      <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          {variable[key]}
                                        </td>
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          <div
                                            className="flex items-center"
                                            onClick={() => {
                                              variableEdit(variable[key], key);
                                            }}
                                          >
                                            <a className="border p-3 mr-2 cursor-pointer">
                                              <span className="bg-edit block"></span>
                                            </a>
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="pt-6"> No variables found!</div>
                    )}
                  </div>
                </div>
              </div>
              <div
                id="fourth"
                className={'p-5 ' + (tab != 'condition' ? 'hidden' : '')}
              >
                {addedSteps.length == formData.no_of_steps && (
                  <AddCondition steps={addedSteps} formData={formData} />
                )}
              </div>
              <div
                id="fifth"
                className={'p-5 ' + (tab != 'style' ? 'hidden' : '')}
              >
                {addedSteps.length == formData.no_of_steps && (
                  <Style steps={addedSteps} formData={formData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTypeForm;
