import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../common/Loading';
import {
  getConfigurationData,
  addDocument,
  updateFieldInDocument,
  getData,
} from '../common/Firebase';
import { ManageLevelModules } from './ManageLevelModules';
import { capitalizeFirstWords, updatedPayload } from '../common/utils';
import { useCore } from '../core/CoreContextProvider';
import { useAuth } from '../core/AuthContextProvider';
import { levelValidationSchema } from '../common/Validation';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import log from '../core/log';
import BackButton from '../common/BackButton';
import { Select, Option } from '@material-tailwind/react';

export const CreateLevel = () => {
  const [configs, setConfigs] = useState({});
  const location = useLocation();
  const id = location?.state?.id ? location?.state?.id : '';
  const [loading, setLoading] = useState(true);
  const { firestore } = useCore();
  const { userData } = useAuth();
  const [levelData, setLevelData] = useState({});
  const [showAddModules, setShowAddModules] = useState(false);
  const [levelId, setLevelId] = useState('');
  const [toasting, setToasting] = useState(false);

  const formOptions = { resolver: yupResolver(levelValidationSchema) };
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    setLoading(true);
    if (showAddModules && levelId != '') {
      updatedPayload(data, 'level', 'update', userData.uid).then(
        async (payLoadData) => {
          await updateFieldInDocument(
            firestore,
            'levels',
            levelId,
            payLoadData
          );
          toast.success('Level Updated Successfully', {
            toastId: 'update_level',
            onClose: () => {
              setToasting(false);
            },
            onOpen: () => {
              setToasting(true);
            },
          });
          reset(data);
          setLoading(false);
        }
      );
    } else {
      updatedPayload(data, 'level', 'create', userData.uid).then(
        async (payLoadData) => {
          const levelDocumentId = await addDocument(
            'levels',
            firestore,
            payLoadData
          );

          setLevelId(levelDocumentId);
          setLevelData(payLoadData);
          setLoading(false);
          setShowAddModules(true);
          toast.success('Level Added Successfully', {
            toastId: 'create_level',
            onClose: () => {
              setToasting(false);
            },
            onOpen: () => {
              setToasting(true);
            },
          });
          reset(data);
        }
      );
    }
  };

  const getLevelData = async (id) => {
    const levelData = await getData(firestore, id, 'levels');
    const obj = { ...levelData };
    delete obj.modules;
    setLevelData(levelData);
    reset(obj);
    setLevelId(levelData.id);
    setShowAddModules(true);
    setLoading(false);
  };

  useEffect(() => {
    const getConfigData = async () => {
      try {
        const data = await getConfigurationData(firestore);
        if (data) setConfigs(data);
      } catch (error) {
        log('firebase error: while getting configuration data', error);
      }
    };
    getConfigData();
    if (id) {
      getLevelData(id);
    } else {
      reset({ level_name: '', level_status: '', description: '' });
      setLevelData({});
      setLevelId('');
      setShowAddModules(false);
      setLoading(false);
    }
  }, [location]);

  return (
    <div className="grid grid-rows-1 min-h-screen">
      {Object.keys(configs).length != 0 && (
        <div className="flex overflow-hidden">
          <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
            <div className="intro-y flex items-center mt-6">
              <BackButton isDirty={isDirty} />
              <h2 className="text-2xl font-medium mr-auto">
                {levelId != '' ? 'Edit' : 'Create'} Level
              </h2>
            </div>
            {loading && <LoadingSpinner />}
            {Object.keys(configs).length !== 0 && (
              <div className="grid grid-cols-12 gap-6 mt-5">
                <div className="col-span-12 lg:col-span-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="bg-white shadow-md rounded-md">
                      <div className="p-5">
                        <div className="preview">
                          <div className="mt-5 flex flex-col sm:flex-row items-center">
                            <div className="flex-auto w-1/3 relative">
                              <span className="text-red-600 absolute -top-8">
                                {errors.level_name?.message}
                              </span>
                              <input
                                {...register('level_name')}
                                id="level-name"
                                type="text"
                                className="pt-6 pb-1 block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                placeholder=" "
                              />
                              <label
                                htmlFor="level-name"
                                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                              >
                                Level Name
                                <span className="text-red-600">*</span>
                              </label>
                            </div>
                            {levelData?.level_id && (
                              <div className="w-1/3 relative mx-2">
                                <input
                                  id="level_id"
                                  type="text"
                                  className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-gray-200 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                  placeholder=" "
                                  value={levelData.level_id}
                                  disabled={true}
                                />
                                <label
                                  htmlFor="level_id"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Level Id
                                </label>
                              </div>
                            )}
                            <div
                              className={`${
                                !levelData?.level_id && 'mx-4'
                              } flex-auto w-1/3 relative`}
                            >
                              <span className="text-red-600 absolute -top-8">
                                {errors.level_status?.message}
                              </span>
                              {configs?.level_status && (
                                <Controller
                                  name={'level_status'}
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <Select
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        id="level_status"
                                        value={value}
                                        label="Select Level Status*"
                                        variant="outlined"
                                        className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                          value ? ' focus-label' : ''
                                        }`}
                                      >
                                        {configs?.level_status?.map(
                                          (status) => {
                                            return (
                                              <Option
                                                key={status}
                                                value={status}
                                              >
                                                {capitalizeFirstWords(status)}
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    );
                                  }}
                                  rules={{ required: true }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="mt-10 flex flex-col sm:flex-row items-center">
                            <div className="flex-auto w-full relative">
                              <span className="text-red-600 absolute -top-8">
                                {errors.description?.message}
                              </span>
                              <textarea
                                {...register('description')}
                                id="level-description"
                                type="text"
                                className="pt-6 pb-1 block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md h-40"
                                placeholder=" "
                              ></textarea>
                              <label
                                htmlFor="level-description"
                                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                              >
                                Description
                                <span className="text-red-600">*</span>
                              </label>
                            </div>
                          </div>
                          <div className="p-5 text-center">
                            <button
                              disabled={toasting}
                              className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                            >
                              {levelId != '' ? 'Update' : 'Create'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <ManageLevelModules
                    showAddModules={showAddModules}
                    levelId={levelId}
                    data={levelData}
                    setLoading={setLoading}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
CreateLevel.propTypes = {
  control: PropTypes.any,
};
