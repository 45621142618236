import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useCore } from '../core/CoreContextProvider';
import { toast } from 'react-toastify';
import log from '../core/log';
import { addDocument, getData } from '../common/Firebase';
import ViewScript from './ViewScript';
import LoadingSpinner from '../common/Loading';
import BackButton from '../common/BackButton';
import MaterialTable from '../common/MaterialTable';
import { updatedPayload } from '../common/utils';
import { useAuth } from '../core/AuthContextProvider';

export const ManageScripts = () => {
  const [popup, setPopup] = useState({ show: false, data: null });
  const [loading, setLoading] = useState(false);
  const { firestore } = useCore();
  const { userData } = useAuth();

  const handleDuplicate = async (id) => {
    setLoading(true);
    const data = await getData(firestore, id, 'scripts');
    if (data) {
      data.version = parseInt(data.version) + 1;
      data.script_status = 'created';
      delete data.contents;
      delete data.id;
      try {
        updatedPayload(data, 'scripts', 'update', userData.uid).then(
          async (payLoadData) => {
            const docID = await addDocument('scripts', firestore, payLoadData);
            if (docID) {
              toast.success('Your script has been duplicated');
            }
            setLoading(false);
          }
        );
      } catch (err) {
        log('Firebase error while adding script', err);
        setLoading(false);
      }
    } else {
      console.log('No data found');
      setLoading(false);
    }
  };

  return (
    <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
      <div className="intro-y flex items-center mt-6">
        <BackButton />
        <h2 className="text-2xl font-medium mr-auto">Manage Script</h2>
      </div>
      {loading && <LoadingSpinner />}
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="col-span-12 lg:col-span-12">
          <div className="bg-white shadow-md rounded-md">
            <div className="p-5">
              <div className="w-full mx-auto text-left flex mt-5">
                <div className="table-section w-full overflow-x-auto">
                  <MaterialTable
                    columns={[
                      {
                        title: 'SCRIPT ID',
                        field: 'script_id',
                      },
                      {
                        title: 'SCRIPT NAME',
                        sorting: false,
                        render: (scripts) => {
                          return scripts.script_name;
                        },
                      },
                      {
                        title: 'TOPIC',
                        sorting: false,
                        render: (scripts) => {
                          return scripts.topic;
                        },
                      },
                      {
                        title: 'AGE GROUP',
                        sorting: false,
                        render: (scripts) => {
                          return scripts.age_group;
                        },
                      },
                      {
                        title: 'LANGUAGE',
                        field: 'language',
                        sorting: false,
                      },
                      {
                        title: 'STATUS',
                        field: 'script_status',
                        sorting: false,
                        render: (scripts) => {
                          const colorClass =
                            scripts.script_status === 'active'
                              ? 'text-green-500'
                              : scripts.script_status == 'inactive'
                              ? 'text-gray-500'
                              : scripts.script_status == 'registered' ||
                                scripts.script_status == 'created'
                              ? 'text-black'
                              : 'text-blue-900';
                          return (
                            <p className={colorClass}>
                              {scripts.script_status}
                            </p>
                          );
                        },
                      },
                      {
                        title: 'VERSION',
                        field: 'version',
                      },
                      {
                        title: 'ACTION',
                        sorting: false,
                        render: (element) => (
                          <div className="flex items-center">
                            <div
                              onClick={() =>
                                setPopup({
                                  show: true,
                                  data: element,
                                })
                              }
                              className="border p-2 mr-2 tooltip relative"
                            >
                              <span className="bg-view block cursor-pointer"></span>
                              <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[39px] after:left-[19px] after:z-[9999]">
                                View
                              </span>
                            </div>
                            <div className="border p-2 mr-2 tooltip relative">
                              <span
                                className="bg-copy block cursor-pointer"
                                onClick={() =>
                                  handleDuplicate(element.objectID)
                                }
                              ></span>
                              <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[35px] after:left-[16px] after:z-[9999]">
                                Copy
                              </span>
                            </div>
                            <NavLink
                              to="/edit/script"
                              state={{ id: element.objectID }}
                              className="border p-2 mr-2 tooltip relative"
                            >
                              <span className="bg-edit block cursor-pointer"></span>
                              <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[35px] after:left-[14px] after:z-[9999]">
                                Edit
                              </span>
                            </NavLink>
                          </div>
                        ),
                      },
                    ]}
                    algoliaIndex={
                      process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + '_scripts'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup.show && <ViewScript setPopup={setPopup} popup={popup} />}
    </div>
  );
};
