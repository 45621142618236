import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Evaluation from './Evaluation';
import GetAnswer from './InputTag/GetAnswer';
import { useCore } from '../../../core/CoreContextProvider';
import {
  deleteDocument,
  updateFieldInDocument,
  addDocument,
} from '../../../common/Firebase';
import { toast } from 'react-toastify';
import { doc, setDoc } from '@firebase/firestore';
import log from '../../../core/log';
import Operand from './InputTag/Operand';

const SubCondition = ({
  parentStep,
  remainingSteps,
  formData,
  setAddedSteps,
  setShowCondition,
  setParentStep,
  getStepNumber,
}) => {
  const { firestore } = useCore();
  const operandName = {
    '<': 'Lessar than ',
    '>': 'Greater than',
    '==': 'Equal to',
    '!==': 'Not Equal to',
    '<=': 'Lessar than or Equal to ',
    '=>': 'Greater than or Equal to',
  };
  const [previousStep, setPreviousStep] = useState([]);
  const [nextStep, setNextStep] = useState([]);
  const [currentAnswer, setCurrentAnswer] = useState();
  const [operandCondition, setOperandCondition] = useState();
  const [response, setResponse] = useState([]);
  const [showEvaluation, setShowEvaluation] = useState();
  const [showNextAddBtn, setShowNextAddBtn] = useState(true);
  const targetInitial = {
    type: 'default_target',
    target: '',
  };
  const initial = {
    answer: '',
    condition: [],
    type: '',
    target: '',
  };
  const [defaultTarget, setDefaultTarget] = useState(targetInitial);

  const operandType = [
    'number',
    'scale',
    'free_text',
    'multi_choice_question',
    'blurb',
  ];

  useState(() => {
    const tempArr = [...remainingSteps];
    const index = tempArr.findIndex((e) => e.id == parentStep.id);
    setNextStep(tempArr.slice(index + 1));
    setPreviousStep(tempArr.splice(0, index));
  }, [remainingSteps]);

  const saveResponse = async (data, index) => {
    try {
      let path = `tapouts_form/${formData.form_name}/steps/${parentStep.id}/conditions`;
      if (Object.prototype.hasOwnProperty.call(parentStep, 'topic_id')) {
        path = `tapouts_form/${formData.form_name}/steps/${parentStep.topic_id}/sub_steps/${parentStep.id}/conditions`;
      }
      if (data.id) {
        await updateFieldInDocument(firestore, path, data.id, data);
        toast.success('Condition has been updated');
      } else {
        const id = await addDocument(path, firestore, data);
        data.id = id;
        toast.success('Condition has been added');
        setShowCondition(parentStep.id);
        setParentStep('');
        setShowNextAddBtn(true);
        setParentStep('');
      }

      const tempArr = [...response];
      tempArr[index] = data;

      setShowEvaluation();
      const stepArr = [...remainingSteps];
      const parentStepIndex = stepArr.findIndex((e) => e.id == parentStep.id);
      stepArr[parentStepIndex].conditions = tempArr;
      if (stepArr[parentStepIndex].type == 'blurb') {
        stepArr[parentStepIndex].options = { ...parentStep.options };
      }

      setAddedSteps(stepArr);
      setResponse(tempArr);
      setOperandCondition();
      setCurrentAnswer();
    } catch (err) {
      console.log(err);
    }
  };

  const getConditionAnswer = (data, index) => {
    saveResponse(data, index);
  };

  useEffect(() => {
    if (
      Object.prototype.hasOwnProperty.call(parentStep, 'conditions') &&
      parentStep.conditions.length
    ) {
      const tempArr = [...parentStep.conditions];
      const defaultCondIndex = tempArr.findIndex(
        (e) => e.type == 'default_target'
      );
      if (defaultCondIndex !== -1) {
        setDefaultTarget(tempArr[defaultCondIndex]);
      }

      setResponse(tempArr);
    }
  }, [parentStep]);

  useEffect(() => {
    if (response.length && parentStep.type == 'multi_choice_question') {
      const tempArr =
        parentStep?.conditions && parentStep.conditions.length
          ? [...parentStep.conditions]
          : [];
      if (tempArr.length) {
        const ids = [];
        tempArr.forEach((step) => {
          ids.push(step.answer);
        });
      }
    }
  }, [response]);

  const storeDefault = async () => {
    try {
      if (defaultTarget.id) {
        await updateFieldInDocument(
          firestore,
          `tapouts_form/${formData.form_name}/steps/${parentStep.id}/conditions`,
          defaultTarget.id,
          defaultTarget
        );
        toast.success('Default has been updated');
      } else {
        await setDoc(
          doc(
            firestore,
            `tapouts_form/${formData.form_name}/steps/${parentStep.id}/conditions/`,
            'default'
          ),
          defaultTarget
        );
        setParentStep('');
        toast.success('Default has been added');
      }
      const stepArr = [...remainingSteps];
      const parentStepIndex = stepArr.findIndex((e) => e.id == parentStep.id);

      let condition = stepArr[parentStepIndex].conditions;
      condition ? condition.push(defaultTarget) : (condition = [defaultTarget]);
      stepArr[parentStepIndex].conditions = condition;
      setCurrentAnswer('');
      setAddedSteps(stepArr);
    } catch (err) {
      log('Error while adding default value' + err);
    }
  };

  const deleteCondition = async (id, index) => {
    try {
      await deleteDocument(
        firestore,
        `tapouts_form/${formData.form_name}/steps/${parentStep.id}/conditions/`,
        id
      );
      const tempArr = [...response];
      tempArr.splice(index, 1);
      setResponse(tempArr);
      const stepArr = [...remainingSteps];
      const parentStepIndex = stepArr.findIndex((e) => e.id == parentStep.id);
      stepArr[parentStepIndex].conditions = tempArr;
      setAddedSteps(stepArr);
    } catch (err) {
      log('Error while deleting condition' + err);
    }
  };
  useEffect(() => {
    if (currentAnswer) setShowEvaluation();
  }, [currentAnswer]);

  return (
    <div className="border pb-4">
      {response.length !== 0 &&
        response.map((cond, index) => {
          return (
            <>
              {cond.type !== 'default_target' && (
                <>
                  {cond.answer !== '' ? (
                    <>
                      <div
                        className="flex items-center mt-4 w-[77.5%] mx-auto bg-blue-900"
                        key={btoa(index)}
                      >
                        {operandType.includes(parentStep.type) && (
                          <div
                            onClick={() => {
                              setShowEvaluation(
                                showEvaluation == cond.id ? null : cond.id
                              );
                            }}
                            className="border p-3 text-center text-white cursor-pointer w-full"
                          >
                            {cond.operand ? operandName[cond.operand] : '-'}
                          </div>
                        )}
                        <div
                          onClick={() => {
                            setShowEvaluation(
                              showEvaluation == cond.id ? null : cond.id
                            );
                          }}
                          className="border p-3 text-center text-white cursor-pointer w-full"
                        >
                          {parentStep.type == 'scale'
                            ? parentStep.options[cond.answer].value
                            : parentStep.type == 'blurb'
                            ? parentStep.options[cond.answer].heading
                            : parentStep.type === 'multi_choice_question'
                            ? parentStep.options[cond.answer]
                            : cond.answer}
                        </div>
                        <div className="bg-gray-100 p-3">
                          <span
                            onClick={() => {
                              deleteCondition(cond.id, index);
                            }}
                            className="bg-delete block cursor-pointer"
                          ></span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="px-10 ">
                      <div
                        className={`flex justify-center mt-5 ${
                          operandType.includes(parentStep.type)
                            ? 'w-10/12 mx-auto'
                            : ''
                        }`}
                      >
                        {operandType.includes(parentStep.type) && (
                          <Operand
                            step={parentStep}
                            storeAnswer={setOperandCondition}
                            response={operandCondition}
                          />
                        )}
                        <GetAnswer
                          step={parentStep}
                          storeAnswer={setCurrentAnswer}
                          response={currentAnswer}
                        />
                      </div>
                    </div>
                  )}

                  {(!cond.id || showEvaluation == cond.id) && (
                    <div
                      className="border w-10/12 mx-auto py-6 flex justify-center"
                      style={{ width: '53rem' }}
                    >
                      <div className="w-10/12">
                        <Evaluation
                          previousStep={previousStep}
                          nextStep={nextStep}
                          storeAnswer={getConditionAnswer}
                          step={{
                            ...parentStep,
                            currentAnswer: currentAnswer
                              ? currentAnswer
                              : cond.answer,
                            operand: operandCondition
                              ? operandCondition
                              : cond.operand
                              ? cond.operand
                              : '',
                          }}
                          storedResponse={cond}
                          index={index}
                          getStepNumber={getStepNumber}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          );
        })}
      {showNextAddBtn && (
        <button
          onClick={() => {
            const initObj = { ...initial };
            if (operandType.includes(parentStep.type)) initObj.operand = '';
            setShowEvaluation();
            setResponse((oldArr) => [...oldArr, initObj]);
            setShowNextAddBtn(false);
          }}
          className="mt-4 w-10 px-3 ml-4 py-1  text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-green-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
        >
          +
        </button>
      )}
      <div className="w-1/2 mt-4 mx-auto flex justify-center gap-3">
        <div className=" w-2/3 ml-2">
          <select
            value={defaultTarget.target}
            onChange={(e) => {
              const obj = { ...defaultTarget };
              obj[e.target.name] = e.target.value;
              setDefaultTarget(obj);
            }}
            name={'target'}
            className="w-full px-6 py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2 hover:shadow-lg focus:outline-none "
          >
            <option value="">Select your default answer</option>
            {nextStep.map((step) => {
              return (
                <option key={step.id} value={step.id}>
                  {getStepNumber(step)}) {step.heading}
                </option>
              );
            })}
          </select>
        </div>
        <div className="w-1/3">
          <button
            onClick={() => {
              !defaultTarget.target
                ? toast.info('Please select a default option')
                : storeDefault();
            }}
            className="px-6 text-center py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-green-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
          >
            Save Default
          </button>
        </div>
      </div>
    </div>
  );
};

SubCondition.propTypes = {
  parentStep: PropTypes.object,
  remainingSteps: PropTypes.array,
  formData: PropTypes.object,
  setAddedSteps: PropTypes.func,
  setShowCondition: PropTypes.func,
  setParentStep: PropTypes.func,
  getStepNumber: PropTypes.func,
};

export default SubCondition;
