import PropTypes from 'prop-types';
import React from 'react';

export default function ConfirmationPopup({ setPopup, popup, deleteData }) {
  return (
    <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-40">
      <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
      <div className="modal-container bg-white w-1/3 mx-auto rounded shadow-lg overflow-y-auto">
        <div className="modal-content py-4 text-left px-6">
          <div className="flex justify-end pb-3">
            <div className="modal-close cursor-pointer">
              <span
                className="bg-close block cursor-pointer"
                onClick={() => setPopup({ ...popup, show: false, id: null })}
              ></span>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-12">
            <div className="bg-white rounded-md">
              <div className="p-5 text-center">
                <div className="text-3xl mt-5">Are you sure?</div>
                <div className="text-gray-600 mt-2">{popup.message}</div>
              </div>
              <div className="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="w-40 px-6 py-3 mt-3 mr-2 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                  onClick={() => setPopup({ ...popup, show: false, id: null })}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="w-40 px-6 py-3 mt-3 ml-2 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-red-600 hover:bg-black hover:shadow-lg hover:text-white focus:outline-none"
                  onClick={() => deleteData(popup.id)}
                >
                  {popup.message ===
                  'You have unsaved changes! Are you sure you want to leave this page?'
                    ? 'Confirm'
                    : popup.btnText ?? 'Remove'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmationPopup.propTypes = {
  setPopup: PropTypes.func.isRequired,
  popup: PropTypes.object.isRequired,
  deleteData: PropTypes.func.isRequired,
};
