import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getData } from '../../common/Firebase';
import LoadingSpinner from '../../common/Loading';
import { useCore } from '../../core/CoreContextProvider';
import AddStyle from './AddStyle';
const Style = ({ steps, formData }) => {
  const { firestore } = useCore();
  const [showCondition, setShowCondition] = useState('');
  const [addedSteps, setAddedSteps] = useState([]);
  const [addedId, setAddedId] = useState([]);
  const [loading, setLoading] = useState(true);
  const [parentStep, setParentStep] = useState();
  const noStyle = ['static'];

  useEffect(() => {
    const ids = [];
    addedSteps.forEach((step) => {
      if (step?.styles && Object.keys(step.styles).length !== 0) {
        ids.push(step.id);
      }
    });
    setAddedId(ids);
    setLoading(false);
  }, [addedSteps]);
  useEffect(() => {
    const allignSteps = async () => {
      if (steps.length) {
        let final = [];

        for (let e of steps) {
          if (e.type == 'blurb') {
            const blurbData = await getData(
              firestore,
              e.blurb_id,
              'tapouts_blurbs'
            );
            final.push({ ...e, options: blurbData.buttons });
          } else if (
            e.type === 'topic' &&
            Object.prototype.hasOwnProperty.call(e, 'sub_steps')
          ) {
            final.push(e, ...e.sub_steps);
          } else {
            final.push(e);
          }
        }

        setAddedSteps(final);
      }
    };
    allignSteps();
  }, [steps]);

  return (
    <>
      {loading && <LoadingSpinner />}
      {addedSteps.map((step, i) => {
        if (addedId.includes(step.id)) {
          return (
            <>
              <div
                className="border p-3 text-center bg-blue-900 text-white cursor-pointer mt-2"
                onClick={() => {
                  setShowCondition(showCondition == step.id ? '' : step.id);
                  setParentStep();
                }}
              >
                {i + 1}) {step.heading}
              </div>
              {showCondition == step.id && (
                <AddStyle
                  parentStep={step}
                  setParentStep={setParentStep}
                  formData={formData}
                  setAddedSteps={setAddedSteps}
                  setShowCondition={setShowCondition}
                  addedSteps={addedSteps}
                />
              )}
            </>
          );
        }
      })}
      {showCondition == '1234' && (
        <div>
          <select
            onChange={(e) => {
              setParentStep(addedSteps.find((e1) => e1.id == e.target.value));
            }}
            className="w-full px-6 py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2   hover:shadow-lg focus:outline-none"
          >
            <option>Select the step</option>
            {addedSteps.map((e, i) => {
              return (
                <option
                  disabled={addedId.includes(e.id) || noStyle.includes(e.type)}
                  key={e.id}
                  value={e.id}
                >
                  {i + 1}) {e.heading}
                </option>
              );
            })}
          </select>
          {parentStep && (
            <AddStyle
              parentStep={parentStep}
              setParentStep={setParentStep}
              formData={formData}
              setAddedSteps={setAddedSteps}
              setShowCondition={setShowCondition}
              addedSteps={addedSteps}
            />
          )}
        </div>
      )}
      {showCondition !== '1234' && (
        <button
          onClick={() => {
            setShowCondition('1234');
            setParentStep();
          }}
          className="w-20 px-3 py-1 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-green-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
        >
          +
        </button>
      )}
    </>
  );
};

Style.propTypes = {
  steps: PropTypes.array,
  formData: PropTypes.object,
};

export default Style;
