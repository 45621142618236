import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  runTransaction,
  query,
  orderBy,
} from 'firebase/firestore';
import log from '../core/log';

export const getConfigurationData = async (firestore) => {
  console.log('Calling getConfigurationData ');
  const docRef = doc(firestore, 'app_config_data', 'core_configs');
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log('Documents are', docSnap.data());
    return docSnap.data();
  } else {
    console.log('No such document!');
    return false;
  }
};

export function getDocData(fist, docName) {
  return new Promise((resolve, reject) => {
    if (docName) {
      let docData = new Map();
      getDoc(docName)
        .then((doc) => {
          docData.set(doc.id, doc.data());
          resolve(docData);
        })
        .catch((err) => {
          log(1001, '<ERROR> on getDocs' + err);
          Promise.reject(new Error(`${docName} Failed to get data on getDocs`));
        });
    } else {
      log(
        1002,
        reject(
          '<ERROR> Failed to get Collection Data. Collection Does not exist' +
            docName
        )
      );
      Promise.reject(
        new Error(`${docName} Failed to get Document Data on getDocs`)
      );
    }
  });
}

export const addDocument = async (path, firestore, data) => {
  console.log('The Data is ', data);
  try {
    const docRef = await addDoc(collection(firestore, path), { ...data });
    return docRef.id;
  } catch (err) {
    console.log('Firebase error: No document added');
    return false;
  }
};

export const updateDocument = async (docID, path, firestore, data) => {
  try {
    await setDoc(doc(firestore, path, docID), { ...data });
    return true;
  } catch (err) {
    console.log('Firebase :' + err);
    return false;
  }
};

export const getAllDocuments = async (firestore, path, subPath) => {
  const querySnapshot = await getDocs(collection(firestore, path));
  let arr = [];
  if (querySnapshot) {
    querySnapshot.forEach(async (doc) => {
      let data = doc.data();
      data.id = doc.id;
      arr.push(data);
    });
    if (subPath) {
      arr.forEach(async (element, index) => {
        const subCollection = await getDocs(
          collection(firestore, path + '/' + element.id + '/' + subPath)
        );
        if (subCollection) {
          let contents = [];
          subCollection.forEach((doc) => {
            contents.push(doc.data());
          });
          arr[index] = { ...arr[index], contents };
        } else {
          console.log('firebase error: doc is not found');
        }
      });
    }
    return arr;
  } else {
    console.log('firebase error: doc is not found');
    return false;
  }
};

export const getData = async (firestore, docID, path) => {
  try {
    const docRef = doc(firestore, path, docID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let data = { ...docSnap.data() };
      data.id = docRef.id;
      return data;
    } else {
      console.log('No such document!');
      return false;
    }
  } catch (err) {
    console.log('Firebase error:', err);
  }
};

export const getDataByQuery = async (query) => {
  const querySnapshot = await getDocs(query);
  let arr = [];
  if (querySnapshot) {
    querySnapshot.forEach(async (doc) => {
      arr.push({ id: doc.id, ...doc.data() });
    });
    return arr;
  } else {
    console.log('firebase error: doc is not found');
    return false;
  }
};

export const updateFieldInDocument = async (
  firestore,
  path,
  docID,
  payload
) => {
  await setDoc(doc(firestore, path, docID), payload, { merge: true });
};

export const deleteDocument = async (firestore, collectionName, docId) => {
  await deleteDoc(doc(firestore, collectionName, docId));
};

export const getAllDocumentsWithSort = async (firestore, path, sortField) => {
  const querySnapshot = await getDocs(
    query(collection(firestore, path), orderBy(sortField, 'asc'))
  );

  let arr = [];
  if (querySnapshot) {
    querySnapshot.forEach(async (doc) => {
      let data = doc.data();
      data.id = doc.id;
      arr.push(data);
    });
    return arr;
  } else {
    console.log('firebase error: doc is not found');
    return false;
  }
};

export const EmailExist = async (firestore, email) => {
  try {
    const userDocRef = doc(firestore, 'user_emails', email);
    const userData = await getDoc(userDocRef);
    let result = {};
    if (userData.exists()) {
      result = { status: false, message: 'User email already exists' };
      return result;
    } else {
      result = { status: true };
      return result;
    }
  } catch (err) {
    console.log('firebase error:' + err);
    throw new Error('firebase error:' + err);
  }
};

export const addUserData = async (collectionName, firestore, payLoadData) => {
  const emailAddress = payLoadData.email_address;
  console.log(emailAddress);
  const userEmailDocRef = doc(firestore, 'user_emails', emailAddress);
  try {
    const resultData = await runTransaction(firestore, async (transaction) => {
      const sfDoc = await transaction.get(userEmailDocRef);
      if (sfDoc.exists()) {
        const result = {
          status: false,
          message: 'User email already exists',
        };
        return result;
      } else {
        transaction.set(userEmailDocRef, { email: emailAddress });
        const result = {
          status: true,
        };
        return result;
      }
    });
    if (resultData.status) {
      const userDataDoc = await addDoc(collection(firestore, collectionName), {
        ...payLoadData,
      });
      resultData.id = userDataDoc.id;
      return userDataDoc.id
        ? resultData
        : { status: false, message: 'user collection has not updated' };
    } else {
      return resultData;
    }
  } catch (err) {
    await deleteDoc(doc(firestore, 'user_emails', emailAddress));
    console.log(err);
  }
};
