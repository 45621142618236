import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../core/AuthContextProvider';

export const UserIcon = () => {
  const [hover, setHover] = useState(false);
  const { logOut, setCurrentUser } = useAuth();

  const logoutHandler = () => {
    setCurrentUser(null);
    logOut();
  };
  return (
    <div
      className="intro-x dropdown w-8 h-8 relative"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className="w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit"
        role="button"
        aria-expanded="false"
      >
        <img alt="Profile" className="w-32" src="/images/profile-9.jpg" />
      </div>
      {hover && (
        <div className="absolute bg-indigo-100 z-[999] min-w-[160px] right-0 top-10 after:content-[''] after:h-0 after:w-0 after:border-x-[12px] after:border-x-transparent after:border-b-[10px] after:border-b-indigo-100 after:absolute after:-top-2 after:left:auto after:right-2 after:z-[9999]">
          <NavLink to="/myaccount">
            <p className="text-black block p-4 hover:bg-blue-900 hover:text-white cursor-pointer">
              My Account
            </p>
          </NavLink>
          <p
            onClick={logoutHandler}
            className="text-black block p-4 hover:bg-blue-900 hover:text-white cursor-pointer"
          >
            Log out
          </p>
        </div>
      )}
    </div>
  );
};
