import Header from './Header';
import { Footer } from './Footer';
//import NavBar from './NavBar';
import PropTypes from 'prop-types';
import { Logo } from './Logo';
import { Notification } from './Notification';
import { UserIcon } from './UserIcon';
import { Menu } from './Menu';

const Layout = ({ children }) => {
  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div>
        <Header>
          <Logo />
          <Notification />
          <UserIcon />
        </Header>
        <div className="flex overflow-hidden relative">
          <div className="side-nav w-52 z-10 pb-32 pt-28 bg-indigo-100">
            <Menu />
          </div>
          <div className="content relative flex-1 px-6 pt-24 pb-10 bg-indigo-50">
            {' '}
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
