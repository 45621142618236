import { useCore } from '../core/CoreContextProvider';
import { useAuth } from '../core/AuthContextProvider';
import {
  addDocument,
  getDataByQuery,
  getData,
  updateFieldInDocument,
} from '../common/Firebase';
import { updatedPayload, capitalizeFirstWords } from '../common/utils';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { AddedScripts } from './AddedScripts';
import { toast } from 'react-toastify';
import LoadingSpinner from '../common/Loading';
import ConfirmationPopup from '../common/ConfirmationPopup';
import { sessionValidationSchema } from '../common/Validation';
import { getConfigurationData } from '../common/Firebase';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { where, collection, query } from 'firebase/firestore';
import log from '../core/log';
import BackButton from '../common/BackButton';
import { Select, Option } from '@material-tailwind/react';

export const CreateSession = () => {
  const location = useLocation();
  const [session, setSession] = useState({
    displayContent: null,
    actualContent: null,
  });
  const [configs, setConfigs] = useState({});
  const [state, setState] = useState({
    action: 'Create',
    text: 'Create Session',
    id: null,
  });
  const [popup, setPopup] = useState({
    show: false,
    message: 'Do you really want to remove this script',
    id: null,
  });
  const [loading, setLoading] = useState(false);
  const [toasting, setToasting] = useState(false);
  const { firestore } = useCore();
  const { userData } = useAuth();
  const formOptions = { resolver: yupResolver(sessionValidationSchema) };
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors, isDirty } = formState;

  const submithandle = async (data) => {
    setLoading(true);
    const result = { ...data };
    const scriptRef = collection(firestore, 'scripts');
    const q = query(
      scriptRef,
      where('script_id', '==', data.script_id),
      where('script_status', '==', 'active')
    );
    const scriptData = await getDataByQuery(q);
    if (scriptData.length) {
      let scripts = [];
      scriptData.map((script) => {
        scripts.push(script.id);
      });
      data.scripts = scripts;
      result.scripts = scriptData;
    } else {
      data.scripts = [];
      result.scripts = [];
    }
    if (state.id) {
      data.session_id = session.actualContent.session_id;
      updatedPayload(data, 'session', 'update', userData.uid).then(
        async (uploadedData) => {
          result.session_id = uploadedData.session_id;
          await updateFieldInDocument(
            firestore,
            'sessions',
            session.actualContent.id,
            uploadedData
          );
          setLoading(false);
          setSession({
            displayContent: { ...result, id: session.displayContent.id },
            actualContent: { ...data, id: session.actualContent.id },
          });
          result.scripts != 0
            ? toast.success('The session has been updated', {
                toastId: 'session_updated',
                onClose: () => {
                  setToasting(false);
                },
                onOpen: () => {
                  setToasting(true);
                },
              })
            : toast.success(
                'the session has been created, But no active scripts are there to add',
                {
                  toastId: 'session_update',
                  onClose: () => {
                    setToasting(false);
                  },
                  onOpen: () => {
                    setToasting(true);
                  },
                }
              );
          reset(data);
        }
      );
    } else {
      updatedPayload(data, 'session', 'create', userData.uid).then(
        async (uploadedData) => {
          data.session_id = uploadedData.session_id;
          const sessionID = await addDocument(
            'sessions',
            firestore,
            uploadedData
          );
          if (sessionID) {
            setSession({
              ...session,
              displayContent: { ...result, id: sessionID },
              actualContent: { ...data, id: sessionID },
            });
            setState({
              ...state,
              action: 'Update',
              text: 'Update Session',
              id: sessionID,
            });
            setLoading(false);
            result.scripts != 0
              ? toast.success('The session has been created')
              : toast.success(
                  'the session has been created, But no active scripts are there to add'
                );
            reset(data);
          } else {
            console.log('session is not stored');
          }
        }
      );
    }
  };

  const removeScript = async (id) => {
    let prevObj = { ...session };
    prevObj.actualContent.scripts.splice(
      prevObj.actualContent.scripts.indexOf(id),
      1
    );
    prevObj.displayContent.scripts = prevObj.displayContent.scripts.filter(
      (item) => prevObj.actualContent.scripts.includes(item.id)
    );

    try {
      updatedPayload(
        {
          scripts: prevObj.actualContent.scripts,
        },
        'sessions',
        'update',
        userData.uid
      ).then(async (payLoadData) => {
        await updateFieldInDocument(
          firestore,
          'sessions',
          state.id,
          payLoadData
        );
      });
      setPopup({ ...popup, show: false });
      setSession({ ...session, ...prevObj });
      toast.success(
        `${prevObj.actualContent.script_id} is removed from this session`
      );
      return true;
    } catch (err) {
      log('Firebase error : while removing script from session' + err);
      return false;
    }
  };

  const getSession = async (id) => {
    setLoading(true);
    const data = await getData(firestore, id, 'sessions');
    if (data) {
      let arr = [];
      try {
        await Promise.all(
          data.scripts.map(async (scriptID) => {
            const script = await getData(firestore, scriptID, 'scripts');
            arr.push({ ...script, id: scriptID });
          })
        );
      } catch (error) {
        log('Firebase error: while getting scripts', error);
      }
      setSession({
        ...session,
        displayContent: { ...data, scripts: arr, id: id },
        actualContent: { ...data, id: id },
      });
      reset(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getConfigData = async () => {
    setLoading(true);
    try {
      const data = await getConfigurationData(firestore);
      if (data) setConfigs(data);
      setLoading(false);
    } catch (error) {
      log('Firebase error: while getting configuration data', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getConfigData();
    if (location?.state?.id) {
      const id = location.state.id;
      getSession(id);
      setState({ action: 'Update', text: 'Edit Session', id: id });
    } else {
      reset({
        script_id: '',
        session_name: '',
        session_status: '',
        description: '',
      });
      setState({ action: 'Create', text: 'Create Session', id: null });
      setSession({ actualContent: {}, displayContent: {} });
    }
  }, [location]);

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-5 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton isDirty={isDirty} />
            <h2 className="text-2xl font-medium mr-auto">{state.text}</h2>
          </div>
          {loading && <LoadingSpinner />}
          {Object.keys(configs).length !== 0 && (
            <div className="grid grid-cols-12 gap-6 mt-5">
              <div className="col-span-12 lg:col-span-12">
                <form onSubmit={handleSubmit(submithandle)}>
                  <div className="bg-white shadow-md rounded-md">
                    <div className="p-5">
                      <div className="preview">
                        <div className="mt-5 flex flex-col sm:flex-row items-center">
                          <div
                            className={`flex-auto ${
                              state?.id ? 'w-1/6' : 'w-1/3'
                            } relative`}
                          >
                            <span className="text-red-600 absolute -top-8">
                              {errors.script_id?.message}
                            </span>
                            {configs?.script_id && (
                              <Controller
                                name={'script_id'}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      id="script_id"
                                      value={value}
                                      label="Select Script ID*"
                                      variant="outlined"
                                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                        value ? ' focus-label' : ''
                                      }`}
                                    >
                                      {configs.script_id.map((id) => {
                                        return (
                                          <Option key={id} value={id}>
                                            {id}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  );
                                }}
                                rules={{ required: true }}
                              />
                            )}
                          </div>
                          {session?.actualContent?.session_id && (
                            <div className="w-1/6 ml-2 relative">
                              <input
                                id="session_id"
                                type="text"
                                className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-gray-200 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                placeholder=" "
                                disabled={true}
                                value={session.actualContent.session_id}
                              />
                              <label
                                htmlFor="session_id"
                                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                              >
                                Session Id
                              </label>
                            </div>
                          )}

                          <div className="mx-2 flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-8">
                              {errors.session_name?.message}
                            </span>
                            <input
                              id="session_name"
                              type="text"
                              className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              placeholder=" "
                              {...register('session_name', {
                                required: true,
                              })}
                            />
                            <label
                              htmlFor="session_name"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Session Name
                              <span className="text-red-600">*</span>
                            </label>
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-8">
                              {errors.session_status?.message}
                            </span>
                            {configs?.session_status && (
                              <Controller
                                name={'session_status'}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      id="session_status"
                                      value={value}
                                      label="Select Status*"
                                      variant="outlined"
                                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                        value ? ' focus-label' : ''
                                      }`}
                                    >
                                      {configs.session_status.map((status) => {
                                        return (
                                          <Option
                                            key={btoa(status)}
                                            value={status}
                                          >
                                            {capitalizeFirstWords(status)}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  );
                                }}
                                rules={{ required: true }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="mt-8 flex flex-col sm:flex-row items-center">
                          <div className="flex-auto w-full relative">
                            <span className="text-red-600 absolute -top-8 mt-2">
                              {errors.description?.message}
                            </span>
                            <textarea
                              id="description"
                              type="text"
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md h-40"
                              placeholder=" "
                              {...register('description', {
                                required: true,
                              })}
                            ></textarea>
                            <label
                              htmlFor="description"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Description
                              <span className="text-red-600">*</span>
                            </label>
                          </div>
                        </div>
                        <div className="p-5 text-center">
                          <button
                            disabled={toasting}
                            type="submit"
                            className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                          >
                            {state.action}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                {session?.displayContent?.scripts &&
                Object.keys(session.displayContent?.scripts).length !== 0 ? (
                  <div className="bg-white shadow-md rounded-md mt-5">
                    <div className="p-5">
                      <div className="preview">
                        <div className="tabs-section">
                          <ul
                            id="tabs"
                            className="inline-flex pt-2 w-full border-b"
                          >
                            <li
                              className={
                                'bg-white px-5 text-gray-800 font-semibold py-3 rounded-t border-t border-r border-l -mb-px'
                              }
                            >
                              <p>Scripts</p>
                            </li>
                          </ul>

                          <div id="tab-contents" className="border border-t-0">
                            <AddedScripts
                              data={session.displayContent.scripts}
                              setPopup={setPopup}
                              popup={popup}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {popup.show && (
                <ConfirmationPopup
                  setPopup={setPopup}
                  popup={popup}
                  deleteData={removeScript}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
