import React, { useEffect, useState } from 'react';
import { useCore } from '../core/CoreContextProvider';
import {
  getConfigurationData,
  getData,
  updateDocument,
  getDataByQuery,
} from '../common/Firebase';
import { updatedPayload } from '../common/utils';
import { addDocument } from '../common/Firebase';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { scriptValidationSchema } from '../common/Validation';
import LoadingSpinner from '../common/Loading';
import ScriptDetails from './ScriptDetails';
import log from '../core/log';
import { useAuth } from '../core/AuthContextProvider';
import { where, collection, query } from 'firebase/firestore';
import BackButton from '../common/BackButton';

export const CreateScript = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [configs, setConfigs] = useState({});
  const [scriptData, setScriptData] = useState({});
  const { userData } = useAuth();
  const [state, setState] = useState({
    action: 'Create',
    text: 'Create Script',
    id: null,
  });
  const [loading, setLoading] = useState(true);
  const formOptions = {
    resolver: yupResolver(scriptValidationSchema),
    defaultValues: { version: 1 },
  };
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors, isDirty } = formState;
  const { firestore } = useCore();

  const getConfigData = async () => {
    const data = await getConfigurationData(firestore);
    if (data) setConfigs(data);
    setLoading(false);
  };

  const getScriptDoc = async (id) => {
    try {
      const data = await getData(firestore, id, 'scripts');
      if (data) {
        const configs = await getConfigurationData(firestore);
        if (data.script_status !== 'created' && configs.script_status) {
          let tempObj = { ...configs };
          let index = configs.script_status.indexOf('created');
          index !== -1 ? tempObj.script_status.splice(index, 1) : '';
          setConfigs(tempObj);
        }
        setConfigs(configs);
        reset(data);
        setScriptData(data);
        setLoading(false);
      }
    } catch (err) {
      log('Firebase Error while getting script : ', err);
    }
  };

  const createScript = async (data) => {
    setLoading(true);
    try {
      updatedPayload(data, 'script', 'create', userData.uid).then(
        async (uploadedData) => {
          const docID = await addDocument('scripts', firestore, uploadedData);
          if (docID && data.script_status === 'active') {
            const SessionRef = collection(firestore, 'sessions');
            const q = query(
              SessionRef,
              where('script_id', '==', data.script_id)
            );
            const sessionData = await getDataByQuery(q);
            if (sessionData.length) {
              try {
                await Promise.all(
                  sessionData.map(async (session) => {
                    session.scripts.push(docID);
                    updatedPayload(
                      session,
                      'session',
                      'update',
                      userData.uid
                    ).then(async (uploadedData) => {
                      await updateDocument(
                        session.id,
                        'sessions',
                        firestore,
                        uploadedData
                      );
                    });
                  })
                );
              } catch (err) {
                log('Firebase Error while adding script to session : ', err);
              }
            }
          }
          reset();
          setLoading(false);
          toast.success('Your script has been saved');
        }
      );
    } catch (err) {
      log('Firebase Error while creating script : ', err);
      setLoading(false);
    }
  };

  const removeScript = async (sessionData, id) => {
    try {
      await Promise.all(
        sessionData.map(async (session) => {
          const index = session.scripts.indexOf(id);
          if (index > -1) {
            session.scripts.splice(index, 1);
          }
          updatedPayload(session, 'session', 'update', userData.uid).then(
            async (uploadedData) => {
              await updateDocument(
                session.id,
                'sessions',
                firestore,
                uploadedData
              );
            }
          );
        })
      );
    } catch (err) {
      log('Firebase Error while removing script from session : ', err);
    }
  };

  const addScript = async (sessionData, id) => {
    await Promise.all(
      sessionData.map(async (session) => {
        session.scripts.push(id);
        updatedPayload(session, 'session', 'update', userData.uid).then(
          async (uploadedData) => {
            await updateDocument(
              session.id,
              'sessions',
              firestore,
              uploadedData
            );
          }
        );
      })
    );
  };

  const checkScript = async (sessionData, id) => {
    try {
      await Promise.all(
        sessionData.map(async (session) => {
          if (!session.scripts.includes(id)) {
            session.scripts.push(id);
            updatedPayload(session, 'session', 'update', userData.uid).then(
              async (uploadedData) => {
                await updateDocument(
                  session.id,
                  'sessions',
                  firestore,
                  uploadedData
                );
              }
            );
          }
        })
      );
    } catch (err) {
      console.error(
        `Error while checking script to add in sessions : ${id}`,
        err.message
      );
    }
  };

  const updateScript = async (updatedData, id) => {
    setLoading(true);
    try {
      const SessionRef = collection(firestore, 'sessions');
      const q = query(
        SessionRef,
        where('script_id', '==', updatedData.script_id)
      );
      const sessionData = await getDataByQuery(q);
      if (scriptData.script_id !== updatedData.script_id) {
        const query1 = query(
          SessionRef,
          where('script_id', '==', scriptData.script_id)
        );
        const Data = await getDataByQuery(query1);
        Data.length ? removeScript(Data, id) : '';
        if (updatedData.script_status === 'active') {
          sessionData.length ? addScript(sessionData, id) : '';
        }
      } else if (updatedData.script_status !== 'active') {
        sessionData.length ? removeScript(sessionData, id) : '';
      } else {
        sessionData.length ? checkScript(sessionData, id) : '';
      }
      updatedPayload(updatedData, 'scripts', 'update', userData.uid).then(
        async (payLoadData) => {
          await updateDocument(id, 'scripts', firestore, payLoadData);
        }
      );
      setScriptData(updatedData);
      toast.success('Your script has been Updated');
      setLoading(false);
      navigate('/manage/script');
    } catch (err) {
      console.log('firebase error: ' + err);
      setLoading(false);
    }
  };

  const validateCopy = async (data) => {
    const scriptRef = collection(firestore, 'scripts');
    const q = query(
      scriptRef,
      where('script_id', '==', data.script_id),
      where('age_group', '==', data.age_group),
      where('language', '==', data.language),
      where('script_status', '==', data.script_status)
    );
    const result = await getDataByQuery(q);
    return result;
  };

  const onSubmit = async (data) => {
    data.version = scriptData.version ? scriptData.version : 1;
    const isValid = await validateCopy(data);
    if (state?.id) {
      if (
        isValid.length === 0 ||
        (isValid.length === 1 && isValid[0].id === state.id)
      ) {
        updateScript(data, state.id);
      } else {
        toast.error('The script is already available with this combination');
      }
    } else {
      if (isValid.length) {
        toast.error('The script is already available with this combination');
      } else {
        createScript(data);
      }
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      setLoading(true);
      const id = location.state.id;
      getScriptDoc(id);
      setState({ action: 'Update', text: 'Edit Script', id: id });
    } else {
      getConfigData();
      reset({
        script_name: '',
        script_id: '',
        age_group: '',
        topic: '',
        script_status: '',
        language: '',
      });
      setScriptData({});
      setState({ action: 'Create', text: 'Create Script', id: null });
    }
  }, [location?.state?.id]);

  return (
    <div className="overflow-hidden">
      <div className="content relative min-h-screen flex-1 px-6 pt-4 pb-10 bg-indigo-50">
        <div className="intro-y flex items-center mt-6">
          <BackButton isDirty={isDirty} />
          <h2 className="text-2xl font-medium mr-auto">{state?.text}</h2>
        </div>
        {loading && <LoadingSpinner />}
        {Object.keys(configs).length !== 0 && (
          <div className="grid grid-cols-12 gap-6 mt-5">
            <form
              className="col-span-12 lg:col-span-12"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="bg-white shadow-md rounded-md">
                <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                  <h2 className="font-bold text-base mr-auto">
                    Script Details
                  </h2>
                </div>

                <ScriptDetails
                  register={register}
                  configs={configs}
                  errors={errors}
                  scriptData={scriptData}
                  setConfigs={setConfigs}
                  control={control}
                />

                <div className="p-5 text-center">
                  <input
                    type="submit"
                    value={state?.action}
                    className="cursor-pointer w-40 px-6 py-3 mt-8 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
