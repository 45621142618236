import React, { useState, useEffect } from 'react';
import {
  getConfigurationData,
  getData,
  updateFieldInDocument,
} from '../common/Firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useCore } from '../core/CoreContextProvider';
import { useAuth } from '../core/AuthContextProvider';
import ConfirmationPopup from '../common/ConfirmationPopup';
import LoadingSpinner from '../common/Loading';
import {
  updatedPayload,
  getLimit,
  capitalizeFirstWords,
} from '../common/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { childrenDetailsValidationSchema } from '../common/Validation';
import { toast } from 'react-toastify';
import BackButton from '../common/BackButton';
import ReactPhoneInput from 'react-phone-input-2';
import { Select, Option } from '@material-tailwind/react';

export function EditChild() {
  const { firestore } = useCore();
  const { userData } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = location.state || {};
  const [configData, setConfigData] = useState([]);
  const [childData, setChildData] = useState({});
  const [loading, setLoading] = useState(true);
  const [toasting, setToasting] = useState(false);
  const [popup, setPopup] = useState({});
  const [subscription, setSubscription] = useState(false);
  const [resume, setResume] = useState(false);
  const formOptions = {
    resolver: yupResolver(childrenDetailsValidationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm(formOptions);

  useEffect(() => {
    const config = async () => {
      try {
        const config = await getConfigurationData(firestore);
        if (config) {
          setConfigData(config);
        }
      } catch (err) {
        console.error(`Error while fetching config data: `, err.message);
      }
      setLoading(false);
    };
    config();
  }, []);

  const getChildDetails = async (id) => {
    setLoading(true);
    const data = await getData(firestore, id, 'children');
    setChildData(data);
    if (data.subscription_id) {
      if (data.subscription_status === 'paused') {
        setResume(true);
      } else {
        const subscriptionData = await fetch(
          `${process.env.REACT_APP_STRIPE_PAYMENT_URL}/get-subscription`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              subscriptionId: data.subscription_id,
            }),
          }
        ).then((res) => res.json());
        if (!subscriptionData.pause_collection) setSubscription(true);
      }
    }
    reset(data);
    setLoading(false);
  };
  useEffect(() => {
    if (configData && id) {
      getChildDetails(id);
    }
  }, [configData]);

  const onSubmit = async (payload) => {
    setLoading(true);
    try {
      if (!payload.guardian_id) {
        delete payload.guardian_id;
        delete payload.guardian_name;
        delete payload.communication_email;
        delete payload.communication_phone;
      }
      const id = payload.id;
      delete payload.id;
      payload.subscription_status = payload.scholarship
        ? 'scholarship'
        : payload.subscription_status
        ? payload.subscription_status
        : '';
      if (
        !payload.scholarship &&
        payload.subscription_status == 'scholarship'
      ) {
        payload.subscription_status = 'active';
      }
      Object.keys(payload).map((key) => {
        payload[key] = payload[key] ?? '';
      });
      updatedPayload(payload, 'children', 'update', userData.uid).then(
        async (payLoadData) => {
          await updateFieldInDocument(firestore, 'children', id, payLoadData);
          setLoading(false);
          toast.success('Child has been updated successfully', {
            toastId: 'edit_child',
            onClose: () => {
              setToasting(false);
            },
            onOpen: () => {
              setToasting(true);
            },
          });
          navigate('/manage/children');
        }
      );
    } catch (err) {
      console.error(
        `Error while updating children: ${payload.id}`,
        err.message
      );
    } finally {
      reset(payload);
    }
  };

  const changeSubscription = async (id, subscription_status) => {
    setLoading(true);
    try {
      let payLoadData = {
        subscription_status: subscription_status,
      };
      await updateFieldInDocument(firestore, 'children', id, payLoadData);
      if (subscription_status == 'paused') {
        setSubscription(false);
        setResume(true);
      } else {
        setSubscription(true);
        setResume(false);
      }
      setPopup({});
      setLoading(false);
    } catch (err) {
      console.log(err.message, 'Firebase error!!!');
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="overflow-hidden">
        <div className="content relative min-h-screen flex-1 px-6 pt-4 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton isDirty={isDirty} />
            <h2 className="text-2xl font-medium mr-auto">Edit Child</h2>
          </div>
          {loading && <LoadingSpinner />}
          <div className="grid grid-cols-12 gap-6 mt-5">
            <div className="col-span-12 lg:col-span-12">
              <div className="bg-white shadow-md rounded-md">
                <div className="p-5">
                  <div className="preview">
                    <div className="flex flex-col mt-5 sm:flex-row items-center">
                      <div className="flex-auto w-1/3 relative">
                        <input
                          id="child-id"
                          type="text"
                          className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 shadow-md mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                          placeholder=" "
                          {...register('children_id')}
                          disabled
                        />
                        <label
                          htmlFor="child-id"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Child ID
                        </label>
                      </div>

                      <div className="mx-4 flex-auto w-1/3 relative">
                        <input
                          id="guardian-id"
                          type="text"
                          className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 shadow-md mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                          placeholder=" "
                          {...register('guardian_id')}
                          disabled
                        />
                        <label
                          htmlFor="guardian-id"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Guardian ID
                        </label>
                      </div>
                      <div className="flex-auto w-1/3 relative">
                        <input
                          id="guardian-name"
                          type="text"
                          className="pt-6 pb-1 bg-gray-300 shadow-md block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                          placeholder=" "
                          {...register('guardian_name')}
                          disabled
                        />
                        <label
                          htmlFor="guardian-name"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Guardian name
                        </label>
                      </div>
                    </div>
                    <div className="flex mt-5 flex-col sm:flex-row items-center">
                      <div className="flex-auto w-1/3 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.first_name?.message}
                        </span>
                        <input
                          id="first-name"
                          type="text"
                          className="pt-6 pb-1 shadow-md block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                          placeholder=" "
                          {...register('first_name')}
                        />
                        <label
                          htmlFor="first-name"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Children First Name
                        </label>
                      </div>
                      <div className="mx-4 flex-auto w-1/3 relative">
                        <input
                          id="middle-name"
                          type="text"
                          className="pt-6 pb-1 shadow-md block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                          placeholder=" "
                          {...register('middle_name')}
                        />
                        <label
                          htmlFor="middle-name"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Children Middle Name
                        </label>
                      </div>
                      <div className="flex-auto w-1/3 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.last_name?.message}
                        </span>
                        <input
                          id="last-name"
                          type="text"
                          className="pt-6 pb-1 shadow-md block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                          placeholder=" "
                          {...register('last_name')}
                        />
                        <label
                          htmlFor="last-name"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Children Last Name
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col mt-5 sm:flex-row items-center">
                      <div className="flex-auto w-1/3 relative">
                        <input
                          id="email-address"
                          type="text"
                          className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 shadow-md mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                          placeholder=" "
                          disabled
                          {...register('email_address')}
                        />
                        <label
                          htmlFor="email-address"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Email Address
                        </label>
                      </div>
                      <div className="mx-4 flex-auto w-1/3 relative">
                        <Controller
                          name={'phone_number'}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <ReactPhoneInput
                                id="phone_number"
                                onChange={(e) => {
                                  onChange(`+${e}`);
                                }}
                                country={'us'}
                                value={value}
                                disableDropdown={true}
                                countryCodeEditable={false}
                              />
                            );
                          }}
                          rules={{ required: false }}
                        />
                      </div>
                      <div className="flex-auto w-1/3 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.date_of_birth?.message}
                        </span>
                        <input
                          type="date"
                          {...register('date_of_birth')}
                          max={getLimit('max')}
                          min={getLimit('min')}
                          className="pt-6 pb-1 shadow-md block w-full px-4 shadow-md mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                          placeholder=" "
                        />
                        <label
                          htmlFor="date_of_birth"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Date of Birth
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col mt-5 sm:flex-row items-center">
                      <div className="flex-auto w-1/3 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.language?.message}
                        </span>
                        {configData?.language && (
                          <Controller
                            name={'language'}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  id="language"
                                  value={value}
                                  label="Select Language"
                                  variant="outlined"
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                    value ? ' focus-label' : ''
                                  }`}
                                >
                                  {configData?.language?.map((item) => {
                                    return (
                                      <Option value={item} key={item}>
                                        {capitalizeFirstWords(item)}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              );
                            }}
                            rules={{ required: false }}
                          />
                        )}
                      </div>
                      <div className="mx-4 flex-auto w-1/3 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.personality?.message}
                        </span>
                        {configData?.personality && (
                          <Controller
                            name={'personality'}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  id="personality"
                                  value={value}
                                  label="Select Personality"
                                  variant="outlined"
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                    value ? ' focus-label' : ''
                                  }`}
                                >
                                  {configData?.personality?.map((item) => {
                                    return (
                                      <Option value={item} key={item}>
                                        {capitalizeFirstWords(item)}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              );
                            }}
                            rules={{ required: false }}
                          />
                        )}
                      </div>
                      <div className="flex-auto w-1/3 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.country?.message}
                        </span>
                        {configData?.country && (
                          <Controller
                            name={'country'}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  id="country"
                                  value={value}
                                  label="Select Country"
                                  variant="outlined"
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                    value ? ' focus-label' : ''
                                  }`}
                                >
                                  {configData?.country?.map((item) => {
                                    return (
                                      <Option value={item} key={item}>
                                        {capitalizeFirstWords(item)}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              );
                            }}
                            rules={{ required: false }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col mt-5 sm:flex-row items-center">
                      <div className="flex-auto w-1/4 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.time_zone?.message}
                        </span>
                        <input
                          id="time-zone"
                          type="text"
                          className="pt-6 pb-1 shadow-md block w-full px-4 shadow-md mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                          placeholder=" "
                          {...register('time_zone')}
                        />
                        <label
                          htmlFor="time-zone"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Time Zone
                        </label>
                      </div>

                      <div className="flex-auto mx-4 w-1/4 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.child_status?.message}
                        </span>
                        {childData.child_status &&
                          childData.child_status === 'enrolled' && (
                            <div>
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <div className="bg-caret_down"></div>
                              </div>
                              <select
                                disabled
                                className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 mt-0 border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              >
                                <option value={childData.child_status}>
                                  {capitalizeFirstWords(childData.child_status)}
                                </option>
                              </select>
                              <label
                                htmlFor="status"
                                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                              >
                                Status
                              </label>
                            </div>
                          )}
                        {configData?.child_status &&
                          childData.child_status != 'enrolled' && (
                            <Controller
                              name={'child_status'}
                              control={control}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <Select
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    id="child_status"
                                    value={value}
                                    label="Select Status"
                                    variant="outlined"
                                    className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                      value ? ' focus-label' : ''
                                    }`}
                                  >
                                    {childData.child_status === 'enrolled' ? (
                                      <Option value={childData.child_status}>
                                        {capitalizeFirstWords(
                                          childData.child_status
                                        )}
                                      </Option>
                                    ) : (
                                      configData?.child_status?.map((item) => {
                                        return (
                                          <Option
                                            value={item}
                                            key={item}
                                            disabled={
                                              item === 'enrolled' ||
                                              item === 'incourse'
                                            }
                                          >
                                            {capitalizeFirstWords(item)}
                                          </Option>
                                        );
                                      })
                                    )}
                                  </Select>
                                );
                              }}
                              rules={{ required: false }}
                            />
                          )}
                      </div>
                      <div className="flex-auto w-1/4 relative">
                        <input
                          type="checkbox"
                          {...register('child_age_override')}
                        />
                        <label
                          htmlFor="age-override"
                          className="top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Age Override
                        </label>
                      </div>
                      <div className="flex-auto w-1/4 relative">
                        <input type="checkbox" {...register('scholarship')} />
                        <label
                          htmlFor="scholarship"
                          className="top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Scholarship
                        </label>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <div className="p-5 text-center">
                        <button
                          disabled={toasting}
                          className="w-64 px-6 py-3 mt-8 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                        >
                          Update
                        </button>
                      </div>
                      {subscription && (
                        <div className="p-5 text-center">
                          <div
                            disabled={toasting}
                            onClick={() =>
                              setPopup({
                                show: true,
                                id: id ?? '',
                                message:
                                  'Do you want to pause the subscription!',
                                btnText: 'Pause',
                                status: 'paused',
                              })
                            }
                            className="cursor-pointer w-64 px-6 py-3 mt-8 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-red-600 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                          >
                            Pause Subscription
                          </div>
                        </div>
                      )}
                      {resume && (
                        <div className="p-5 text-center">
                          <div
                            disabled={toasting}
                            onClick={() =>
                              setPopup({
                                show: true,
                                id: id ?? '',
                                message:
                                  'Do you want to resume the subscription!',
                                btnText: 'Resume',
                                status: 'active',
                              })
                            }
                            className="cursor-pointer w-64 px-6 py-3 mt-8 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-green-600 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                          >
                            Resume Subscription
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup.show && (
        <ConfirmationPopup
          popup={popup}
          setPopup={setPopup}
          deleteData={(id) => changeSubscription(id, popup.status)}
        />
      )}
    </form>
  );
}
