import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  getData,
  getDataByQuery,
  updateFieldInDocument,
} from '../common/Firebase';
import { useCore } from '../core/CoreContextProvider';
import { useAuth } from '../core/AuthContextProvider';
import { updatedPayload, compare } from '../common/utils';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ConfirmationPopup from '../common/ConfirmationPopup';
import { collection, query, where } from 'firebase/firestore';
import { algoliaFunction } from '../core/searchUtils';

function ManageModuleSessions({
  showAddSessions,
  moduleDocId,
  handleSetLoading,
  editData,
}) {
  const { firestore } = useCore();
  const { userData } = useAuth();
  const [activeSessions, setActiveSessions] = useState([]);
  const [allActiveSessions, setAllActiveSessions] = useState([]);
  const [sessionSearchText, setSessionSearchText] = useState('');
  const [checkedState, setCheckedState] = useState({});
  const [checkedStateArray, setCheckedStateArray] = useState([]);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [tab, setTab] = useState('default');
  const [popup, setPopup] = useState({
    show: false,
    message: 'Do you really want to remove this session?',
    id: null,
  });

  const handleOnSessionCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const checkedStateCopy = { ...checkedState };
    checked
      ? (checkedStateCopy[name] = checked)
      : delete checkedStateCopy[name];
    setCheckedState(checkedStateCopy);
    setSelectAllCheckbox(false);
    convertCheckStateObjToArray(checkedStateCopy);
  };

  const convertCheckStateObjToArray = (checkedStateObj) => {
    const checkedStateArr = [];
    for (let key in checkedStateObj) {
      if (checkedStateObj[key]) {
        checkedStateArr.push(key);
      }
    }
    setCheckedStateArray(checkedStateArr);
  };

  const handleSessionSearch = async () => {
    let filterArray = [
      {
        connector: '',
        conditions: [
          {
            connector: '',
            fieldName: 'session_status',
            fieldValue: 'active',
            operator: ':',
          },
        ],
      },
    ];
    if (selectedSessions.length) {
      const obj = {
        connector: 'AND',
        conditions: selectedSessions.map((ses, index) => {
          return {
            connector: index == 0 ? '' : 'AND',
            fieldName: 'session_id',
            fieldValue: ses.session_id,
            operator: 'NOT',
          };
        }),
      };
      filterArray.push(obj);
    }
    const result = await algoliaFunction(
      filterArray,
      process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + '_sessions',
      sessionSearchText,
      40,
      0
    );
    let hits = result.hits ? result.hits : [];
    let i;
    for (i = 0; i < hits.length; i++) {
      hits[i].id = hits[i]['objectID'];
      delete hits[i].objectID;
    }
    hits.sort(function (it1, it2) {
      return compare(it1, it2, 'session_id');
    });
    setActiveSessions(hits);
  };

  const handleClearSearchText = () => {
    const selectedSessionIds = selectedSessions.map((session) => session.id);
    const updatedActiveSessions = allActiveSessions.filter(
      (active) => !selectedSessionIds?.includes(active.id)
    );
    updatedActiveSessions.sort(function (it1, it2) {
      return compare(it1, it2, 'session_id');
    });
    setActiveSessions(updatedActiveSessions);

    setSessionSearchText('');
  };

  const findAddedSessions = async (sessions) => {
    let sessionsData = [];
    try {
      await Promise.all(
        sessions.map(async (id) => {
          const session = await getData(firestore, id, 'sessions');
          sessionsData.push(session);
        })
      );
    } catch (err) {
      console.log('Error while getting Added Session' + err.message);
    }
    setSelectedSessions(sessionsData);
  };

  useEffect(() => {
    handleSetLoading(true);
    const sessions = async () => {
      try {
        const sessionRef = collection(firestore, 'sessions');
        const q = query(sessionRef, where('session_status', '==', 'active'));
        const sessionData = await getDataByQuery(q);
        if (sessionData.length) {
          setAllActiveSessions(sessionData);
          sessionData.sort(function (it1, it2) {
            return compare(it1, it2, 'session_id');
          });
          setActiveSessions(sessionData);
        }
        handleSetLoading(false);
      } catch (err) {
        handleSetLoading(false);
        console.error(`Error while fetching sessions: `, err.message);
      }
    };
    sessions();
    if (!showAddSessions) {
      setSelectedSessions([]);
    }
  }, [showAddSessions]);

  useEffect(() => {
    if (editData?.sessions) {
      findAddedSessions(editData.sessions);
    }
    const updatedActiveSessions = activeSessions.filter(
      (active) => !editData?.sessions?.includes(active.id)
    );
    updatedActiveSessions.sort(function (it1, it2) {
      return compare(it1, it2, 'session_id');
    });
    setActiveSessions(updatedActiveSessions);
  }, [editData, allActiveSessions]);

  const handleAddSelected = async () => {
    handleSetLoading(true);
    const alreadyAddedSessions = [
      ...selectedSessions.map((selected) => selected.id),
      ...checkedStateArray,
    ];
    try {
      updatedPayload(
        {
          sessions: alreadyAddedSessions,
        },
        'module',
        'update',
        userData.uid
      ).then(async (payLoadData) => {
        await updateFieldInDocument(
          firestore,
          'modules',
          moduleDocId !== '' ? moduleDocId : editData.id,
          payLoadData
        );
        setSelectedSessions(findAddedSessions(alreadyAddedSessions));
        toast.success('Sessions Added Successfully');
        setCheckedState({});
        const sessionSearch = activeSessions.filter((sess) => {
          return !checkedStateArray.includes(sess.id);
        });
        sessionSearch.sort(function (it1, it2) {
          return compare(it1, it2, 'session_id');
        });
        setActiveSessions(sessionSearch);
        setSelectAllCheckbox(false);
        handleSetLoading(false);
      });
    } catch (err) {
      handleSetLoading(false);
      console.error(
        `Error while updating module document : ${
          moduleDocId !== '' ? moduleDocId : editData?.id
        }`,
        err.message
      );
    }
  };

  const handleRemoveSession = async (session) => {
    handleSetLoading(true);
    const updatedSelectedSessions = selectedSessions.filter(
      (ses) => ses.id !== session.id
    );
    const updatedActiveSessions =
      session.session_status === 'active'
        ? [...activeSessions, session]
        : activeSessions;
    const updatedSessionsId = updatedSelectedSessions.map((ses) => ses.id);
    try {
      updatedPayload(
        { sessions: updatedSessionsId },
        'module',
        'update',
        userData.uid
      ).then(async (payLoadData) => {
        await updateFieldInDocument(
          firestore,
          'modules',
          moduleDocId !== '' ? moduleDocId : editData.id,
          payLoadData
        );
        setSelectedSessions(updatedSelectedSessions);
        updatedSelectedSessions.sort(function (it1, it2) {
          return compare(it1, it2, 'session_id');
        });
        setActiveSessions(updatedActiveSessions);
        setPopup({
          ...popup,
          show: false,
          id: null,
        });
        handleSetLoading(false);
        toast.success(
          `Session ${session.session_id}: ${session.session_name} has been removed!`
        );
      });
    } catch (err) {
      handleSetLoading(false);
      console.error(
        `Error while updating module document : ${
          moduleDocId !== '' ? moduleDocId : editData?.id
        }`,
        err.message
      );
    }
  };

  const handleSelectAllCheckbox = () => {
    if (selectAllCheckbox) {
      setCheckedState({});
      setSelectAllCheckbox(false);
    } else {
      const checkedObj = {};
      activeSessions.forEach((sess) => {
        checkedObj[sess.id] = true;
      });
      setCheckedState(checkedObj);
      const checkedStateArr = [];
      for (let key in checkedObj) {
        if (checkedObj[key]) {
          checkedStateArr.push(key);
        }
      }
      setCheckedStateArray(checkedStateArr);
      setSelectAllCheckbox(true);
    }
  };

  const handleDragEnd = async (res) => {
    if (!res?.destination) return;
    handleSetLoading(true);
    let tempSession = [...selectedSessions];
    let [selectedRow] = tempSession.splice(res?.source?.index, 1);
    tempSession.splice(res?.destination.index, 0, selectedRow);
    const sessionIds = tempSession.map((temp) => temp.id);
    try {
      updatedPayload(
        {
          sessions: sessionIds,
        },
        'module',
        'update',
        userData.uid
      ).then(async (payLoadData) => {
        await updateFieldInDocument(
          firestore,
          'modules',
          moduleDocId !== '' ? moduleDocId : editData.id,
          payLoadData
        );
        setSelectedSessions(tempSession);
        handleSetLoading(false);
        toast.success('Sessions rearranged successfully');
      });
    } catch (err) {
      handleSetLoading(false);
      console.error(
        `Error while updating module document : ${
          moduleDocId !== '' ? moduleDocId : editData?.id
        }`,
        err.message
      );
    }
  };

  return (
    <div className="bg-white shadow-md rounded-md mt-5">
      <div className="p-5">
        <div className="preview">
          <div className="tabs-section">
            <ul id="tabs" className="inline-flex pt-2 w-full border-b">
              <li
                onClick={() => setTab('default')}
                className={
                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                  (tab === 'default'
                    ? 'bg-white border-t border-r border-l -mb-px'
                    : '')
                }
              >
                <a id="default-tab">Add Sessions</a>
              </li>
              <li
                onClick={() => setTab('session')}
                className={
                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                  (tab === 'session'
                    ? 'bg-white border-t border-r border-l -mb-px'
                    : '')
                }
              >
                <a>Sessions</a>
              </li>
            </ul>

            <div id="tab-contents" className="border border-t-0">
              <div
                id="first"
                className={'p-5 ' + (tab != 'default' ? 'hidden' : '')}
              >
                {showAddSessions || Object.keys(editData).length != 0 ? (
                  <>
                    <div className="flex w-3/4 mx-auto flex-col sm:flex-row items-center justify-end">
                      <div className="flex w-full relative z-0 border border-b-2 rounded-md">
                        <input
                          id="get-session"
                          type="text"
                          value={sessionSearchText ?? ''}
                          placeholder=" "
                          className="pt-6 shadow-md pb-1 block w-full px-4 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200"
                          onChange={(e) => setSessionSearchText(e.target.value)}
                          onKeyPress={(e) =>
                            e.key === 'Enter' && handleSessionSearch()
                          }
                        />
                        <label
                          htmlFor="get-session"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Search Session by Session Name
                        </label>
                        {sessionSearchText !== '' && (
                          <button
                            className="flex items-center cursor-pointer justify-center px-4 border-l"
                            onClick={handleClearSearchText}
                          >
                            <div className="bg-close"></div>
                          </button>
                        )}
                        <button
                          className="flex items-center justify-center px-4 border-l"
                          onClick={handleSessionSearch}
                          disabled={sessionSearchText === ''}
                        >
                          <div className="bg-search"></div>
                        </button>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          onClick={handleAddSelected}
                          disabled={!Object.keys(checkedState).length}
                          className={`w-40 px-6 py-3 ml-4 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none focus:outline-none ${
                            Object.keys(checkedState).length != 0
                              ? 'bg-blue-900 hover:bg-yellow-500 hover:shadow-lg'
                              : 'bg-gray-400'
                          }`}
                        >
                          Add Selected
                        </button>
                      </div>
                    </div>
                    <div className="w-3/4 mx-auto my-0 text-left flex mt-5">
                      {activeSessions.length !== 0 ? (
                        <div className="table-section w-full">
                          <table className="min-w-full border">
                            <thead className="uppercase bg-indigo-50 border-b">
                              <tr>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left"
                                >
                                  <div className="col-span-12 flex justify-center">
                                    <input
                                      type="checkbox"
                                      // name={session.id}
                                      // value={session.session_id}
                                      checked={selectAllCheckbox}
                                      onChange={handleSelectAllCheckbox}
                                    />
                                  </div>
                                </th>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left"
                                >
                                  Session ID
                                </th>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left"
                                >
                                  Session Name
                                </th>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left"
                                >
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {activeSessions?.map((session) => {
                                return (
                                  <tr
                                    key={session.id}
                                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                  >
                                    <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                      <div className="col-span-12 flex justify-center">
                                        <input
                                          type="checkbox"
                                          name={session.id}
                                          // value={session.session_id}
                                          checked={
                                            checkedState[session.id] ?? false
                                          }
                                          onChange={(e) =>
                                            handleOnSessionCheckboxChange(e)
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                      {session.session_id}
                                    </td>
                                    <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                      {session.session_name}
                                    </td>
                                    <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                      {session.session_status}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <span>No active sessions available</span>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="pt-6">
                    {' '}
                    Please create module first to add sessions to it{' '}
                  </div>
                )}
              </div>
              <div
                id="second"
                className={'p-5 ' + (tab != 'session' ? 'hidden' : '')}
              >
                <div className="w-3/4 mx-auto my-0 text-left flex mt-5">
                  <div className="table-section w-full">
                    <DragDropContext
                      onDragEnd={(results) => handleDragEnd(results)}
                    >
                      {selectedSessions?.length ? (
                        <table className="min-w-full border" id="table">
                          <thead className="">
                            <tr>
                              <th
                                data-type="number"
                                scope="col"
                                className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                              ></th>
                              <th
                                data-type="number"
                                scope="col"
                                className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                              >
                                Session ID
                              </th>
                              <th
                                scope="col"
                                className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                              >
                                Session Name
                              </th>
                              <th
                                scope="col"
                                className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <Droppable droppableId="droppable">
                            {(provided) => (
                              <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {selectedSessions?.length
                                  ? selectedSessions?.map((session, index) => (
                                      <Draggable
                                        key={session.id}
                                        draggableId={session.id}
                                        index={index}
                                      >
                                        {(provided) => (
                                          <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                            key={session.id}
                                          >
                                            <td
                                              {...provided.dragHandleProps}
                                              className="text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-move relative text-2xl after:absolute after:top-2 after:right-6 after:content-[':::'] transform"
                                            ></td>
                                            <td className="text-blue-900 font-light p-3 whitespace-nowrap cursor-pointer underline">
                                              <NavLink
                                                to="/edit/session"
                                                state={{ id: session.id }}
                                              >
                                                {session.session_id}
                                              </NavLink>
                                            </td>
                                            <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                              {session.session_name}
                                            </td>
                                            <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                              {session.session_status}
                                            </td>
                                            <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                              <a
                                                className="text-blue-600 hover:text-red-500 cursor-pointer"
                                                onClick={() =>
                                                  setPopup({
                                                    ...popup,
                                                    show: true,
                                                    id: session,
                                                  })
                                                }
                                              >
                                                Remove
                                              </a>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    ))
                                  : ''}
                                {provided.placeholder}
                              </tbody>
                            )}
                          </Droppable>
                        </table>
                      ) : (
                        'No Sessions are added!'
                      )}
                    </DragDropContext>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup.show && (
        <ConfirmationPopup
          setPopup={setPopup}
          popup={popup}
          deleteData={(session) => handleRemoveSession(session)}
        />
      )}
    </div>
  );
}

ManageModuleSessions.propTypes = {
  showAddSessions: PropTypes.bool.isRequired,
  moduleDocId: PropTypes.string.isRequired,
  handleSetLoading: PropTypes.func.isRequired,
  editData: PropTypes.object,
};

export default React.memo(ManageModuleSessions);
