import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function ViewCoachPopup({ open, data, handleClose }) {
  let navigate = useNavigate();

  const handleEdit = () => {
    navigate('/edit/coach', { state: { id: data.id } });
  };

  return (
    <>
      {open ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-5/6 h-5/6 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div
                  className="modal-close cursor-pointer"
                  onClick={() => handleClose()}
                >
                  <svg
                    className="fill-current text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                  >
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                  </svg>
                </div>
              </div>

              <div className="col-span-12 lg:col-span-12">
                <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                  <h2 className="font-bold text-base mr-auto">Coach Details</h2>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="first-name"
                    className="text-black w-40 px-4 font-bold"
                  >
                    First Name:
                  </label>
                  <div
                    id="first-name"
                    type="text"
                    className="mt-0 bg-transparent"
                  >
                    {data?.first_name}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="middle-name"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Middle Name:
                  </label>
                  <div
                    id="middle-name"
                    type="text"
                    className="mt-0 bg-transparent"
                  >
                    {data?.middle_name === '' ? '-' : data?.middle_name}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="last-name"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Last Name:
                  </label>
                  <div
                    id="last-name"
                    type="text"
                    className="mt-0 bg-transparent"
                  >
                    {data?.last_name}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="email-address"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Email Address:
                  </label>
                  <div
                    id="email-address"
                    type="text"
                    className="mt-0 bg-transparent"
                  >
                    {data?.email_address}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="phone-number"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Phone Number:
                  </label>
                  <div
                    id="phone-number"
                    type="text"
                    className="mt-0 bg-transparent"
                  >
                    {data?.phone_number}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="zip-code"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Zip Code:
                  </label>
                  <div id="zipcode" type="text" className="mt-0 bg-transparent">
                    {data?.zip_code}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="country"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Country:
                  </label>
                  <div id="country" type="text" className="mt-0 bg-transparent">
                    {data?.country}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="status"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Status:
                  </label>
                  <div id="status" type="text" className="mt-0 bg-transparent">
                    {data?.coach_status}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="gender"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Gender:
                  </label>
                  <div id="gender" type="text" className="mt-0 bg-transparent">
                    {data?.gender}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="language"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Language:
                  </label>
                  <div
                    id="language"
                    type="text"
                    className="mt-0 bg-transparent"
                  >
                    {data?.language.map((lang) => {
                      return <div key={lang}>{lang}</div>;
                    })}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="age-group"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Age Group:
                  </label>
                  <div
                    id="age-group"
                    type="text"
                    className="mt-0 bg-transparent"
                  >
                    {data?.age_group.map((age) => {
                      return <div key={age}>{age}</div>;
                    })}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="short_bio"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Short Bio:
                  </label>
                  <div
                    id="short_bio"
                    type="text"
                    className="mt-0 bg-transparent"
                  >
                    {data?.short_bio}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="detailed_bio"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Detailed Bio:
                  </label>
                  <div
                    id="detailed_bio"
                    type="text"
                    className="mt-0 bg-transparent"
                  >
                    {data?.detailed_bio}
                  </div>
                </div>
                <div className="flex w-1/2 float-left mt-5">
                  <label
                    htmlFor="pro-pic"
                    className="text-black w-40 px-4 font-bold"
                  >
                    Profile Picture:
                  </label>
                  <div id="pro-pic" type="text" className="mt-0 bg-transparent">
                    {data?.image_url && data?.image_url !== '' ? (
                      <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                        <div className="">
                          <div className="flex flex-col items-center ">
                            <img src={data?.image_url} alt="new" width={120} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      'No image attached'
                    )}
                  </div>
                </div>
                <div className="p-5 text-center w-full float-left">
                  <button
                    className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                    onClick={handleEdit}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

ViewCoachPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

export default ViewCoachPopup;
