import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useCore } from '../../core/CoreContextProvider';
import { updateFieldInDocument } from '../../common/Firebase';
import { toast } from 'react-toastify';
const AddStyle = ({
  parentStep,
  setParentStep,
  formData,
  setAddedSteps,
  addedSteps,
  setShowCondition,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { firestore } = useCore();

  const noBtn = ['payment', 'blurb', 'pod', 'payment'];

  useEffect(() => {
    reset(parentStep.styles);
  }, [parentStep]);

  const submithandle = async (obj) => {
    const data = { ...obj };
    try {
      let path = `tapouts_form/${formData.form_name}/steps/`;
      if (Object.prototype.hasOwnProperty.call(parentStep, 'topic_id')) {
        path = `tapouts_form/${formData.form_name}/steps/${parentStep.topic_id}/sub_steps/`;
      }

      await updateFieldInDocument(firestore, path, parentStep.id, {
        styles: { ...data },
      });
      toast.success('Style has been added');

      const tempArr = [...addedSteps];
      const parentStepIndex = addedSteps.findIndex(
        (e) => e.id == parentStep.id
      );
      tempArr[parentStepIndex].styles = data;
      setAddedSteps(tempArr);
      setShowCondition();
      setParentStep();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(submithandle)}>
      <div className="flex items-center mt-4 w-[77.5%] mx-auto ">
        <div className="mt-5 flex flex-col sm:flex-row items-center gap-4">
          <div className="w-8/12 flex-auto  relative">
            <span className="text-red-600 absolute -top-8">
              {errors.background_image?.message}
            </span>
            <input
              id="primary_colour"
              type="text"
              className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
              placeholder=" "
              {...register('background_image')}
            />
            <label
              htmlFor="primary_colour"
              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
            >
              Background Image
            </label>
          </div>
        </div>
      </div>
      <div className="mt-5 flex flex-col sm:flex-row items-center gap-4">
        <div className="w-8/12 flex-auto  relative">
          <span className="text-red-600 absolute -top-8">
            {errors.primary_colour?.message}
          </span>
          <input
            id="primary_colour"
            type="text"
            className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
            placeholder=" "
            {...register('primary_colour')}
          />
          <label
            htmlFor="primary_colour"
            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
          >
            Primary Colour
          </label>
        </div>
      </div>
      <div className="mt-5 flex flex-col sm:flex-row items-center gap-4">
        <div className="w-8/12 flex-auto  relative">
          <span className="text-red-600 absolute -top-8">
            {errors.secondary_colour?.message}
          </span>
          <input
            id="secondary_colour"
            type="text"
            className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
            defaultValue=""
            placeholder=" "
            {...register('secondary_colour')}
          />
          <label
            htmlFor="secondary_colour"
            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
          >
            Secondary Colour
          </label>
        </div>
      </div>
      {!noBtn.includes(parentStep.type) && (
        <div>
          <div className="mt-5">Button Style</div>
          <div className="mt-5 flex flex-col sm:flex-row items-center gap-4">
            <div className="w-8/12 flex-auto  relative">
              <span className="text-red-600 absolute -top-8">
                {errors.btn_content?.message}
              </span>
              <input
                id="btn_content"
                type="text"
                className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                placeholder=" "
                {...register('btn_content')}
              />
              <label
                htmlFor="btn_content"
                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
              >
                Button content
              </label>
            </div>
          </div>
          <div className="mt-5 flex flex-col sm:flex-row items-center gap-4">
            <div className="w-8/12 flex-auto  relative">
              <span className="text-red-600 absolute -top-8">
                {errors.btn_bg_colour?.message}
              </span>
              <input
                id="btn_bg_colour"
                type="text"
                className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                placeholder=" "
                {...register('btn_bg_colour')}
              />
              <label
                htmlFor="btn_bg_colour"
                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
              >
                Button Background colour
              </label>
            </div>
          </div>
        </div>
      )}

      <div className="w-1/3">
        <button
          type="submit"
          className="px-6 text-center py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-green-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
        >
          Save Style
        </button>
      </div>
    </form>
  );
};
AddStyle.propTypes = {
  parentStep: PropTypes.object,
  formData: PropTypes.object,
  setAddedSteps: PropTypes.func,
  setShowCondition: PropTypes.func,
  setParentStep: PropTypes.func,
  addedSteps: PropTypes.array,
};

export default AddStyle;
