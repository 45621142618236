import SubMenu from './SubMenu';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const MenuItem = ({ props }) => {
  const { id, label, target, subMenuExists, subMenu, selected } = props;
  const menuStyles = `${
    selected ? 'text-black-800 relative px-6 ' : ''
  }flex items-center justify-between text-black-800 leading-6 h-12`;
  const [subMenuShow, setSubMenuShow] = useState({ show: false, label: null }); // eslint-disable-line

  const onHandleMenu = (label, action) => {
    if (action === 'over') {
      setSubMenuShow({ ...subMenuShow, show: true, label: label });
      document.getElementById(`submenu-${label}`).style.display = 'block';
      document
        .getElementById(`submenu-${label}`)
        .classList.add('menu-transition');
    } else {
      document.getElementById(`submenu-${label}`).style.display = 'none';
      document
        .getElementById(`submenu-${label}`)
        .classList.remove('menu-transition');
      setSubMenuShow({ ...subMenuShow, show: false, label: null });
    }
  };

  const generateMenuItem = (
    id,
    label,
    target,
    subMenuExists,
    subMenu,
    selected // eslint-disable-line
  ) => {
    if (!subMenuExists) {
      return (
        <Link to={target} className={menuStyles}>
          {label}
        </Link>
      );
    } else {
      return (
        <div
          className="h-12 cursor-pointer relative hover:bg-indigo-50 px-5 active:bg-indigo-50 focus:bg-indigo-50"
          onMouseLeave={() => onHandleMenu(label, 'leave')}
          onMouseOver={() => onHandleMenu(label, 'over')}
        >
          <div className={menuStyles}>
            <span className="pr-3">{label}</span>
            <div
              className={
                'down-arrow ' + (subMenuShow.label === label ? 'hidden' : '')
              }
            >
              <svg
                className="w-20 h-5 -rotate-90"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <div
              className={
                'right-arrow ' + (subMenuShow.label === label ? '' : 'hidden')
              }
            >
              <svg
                className="w-20 h-5 -rotate-90"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>

          <div
            onClick={() =>
              (document.getElementById(`submenu-${label}`).style.display =
                'block')
            }
            className="absolute w-full flex cursor-pointer  justify-center max-w-sm px-2 left-[204px] top-0 z-50 sm:px-0"
          >
            <SubMenu id={`submenu-${label}`} subMenu={subMenu} />
          </div>
        </div>
      );
    }
  };

  return (
    <>{generateMenuItem(id, label, target, subMenuExists, subMenu, selected)}</>
  );
};

MenuItem.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  target: PropTypes.string,
  subMenuExists: PropTypes.bool,
  selected: PropTypes.bool,
  props: PropTypes.object,
  subMenu: PropTypes.object,
};

export default MenuItem;
