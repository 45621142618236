import { collection, query, where } from '@firebase/firestore';
import { yupResolver } from '@hookform/resolvers/yup';
import { Option, Select } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import BackButton from '../common/BackButton';
import {
  addDocument,
  getAllDocuments,
  getData,
  getDataByQuery,
  updateDocument,
} from '../common/Firebase';
import LoadingSpinner from '../common/Loading';
import { capitalizeFirstWords } from '../common/utils';
import { contentValidationSchema } from '../common/Validation';
import { useCore } from '../core/CoreContextProvider';
import log from '../core/log';

const CreateContent = () => {
  const { firestore } = useCore();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { id } = location.state || {};
  const formOptions = { resolver: yupResolver(contentValidationSchema) };
  const [formNames, setFormNames] = useState([]);
  const [toasting, setToasting] = useState(false);
  const [contentData, setContentData] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm(formOptions);

  const submithandle = async (data) => {
    setLoading(true);
    data.content_name = data.content_name.toLowerCase();
    const { form_name } = data;
    const collectionName = `tapouts_form_contents`;

    if (!contentData?.id) {
      const contentRef = collection(firestore, collectionName);
      const q = query(
        contentRef,
        where('content_name', '==', data.content_name),
        where('form_name', '==', form_name)
      );
      const checkContentUnique = await getDataByQuery(q);

      if (!checkContentUnique.length) {
        try {
          const addedId = await addDocument(collectionName, firestore, data);
          toast.success('Content created successfully');
          setContentData({ ...data, id: addedId });
          setLoading(false);
        } catch (err) {
          log('Error while adding content' + err);
          setLoading(false);
        }
      } else {
        toast.info('Content already exist with this name try another one');
        setLoading(false);
      }
    } else {
      const result = await updateDocument(
        contentData?.id,
        collectionName,
        firestore,
        data
      );
      if (result) {
        toast.success(`Content updated Successfully`, {
          onClose: () => {
            setToasting(false);
          },
          onOpen: () => {
            setToasting(true);
          },
        });
      }
      navigate('/manage/content');
      setContentData({ ...data });
      setLoading(false);
    }
  };
  const getFormName = async () => {
    const nameArr = [];
    try {
      const data = await getAllDocuments(firestore, 'tapouts_form');
      if (data.length !== 0) {
        data.forEach((e) => nameArr.push(e.id));
        setFormNames(nameArr);
      }
      setLoading(false);
    } catch (err) {
      log('Error while getting Form names');
      setLoading(false);
    }
  };

  const getContentData = async () => {
    try {
      const contentData = await getData(firestore, id, `tapouts_form_contents`);
      if (contentData) setContentData(contentData);
      reset(contentData);
    } catch (err) {
      log('Error while grrtting content data');
    }
  };

  useEffect(() => {
    getFormName();
    if (id) {
      getContentData();
    } else {
      reset({ content_name: '', description: '', form_name: '' });
      setContentData({});
    }
  }, [location]);
  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-5 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton isDirty={isDirty} />
            <h2 className="text-2xl font-medium mr-auto">Create Content</h2>
          </div>
          {loading && <LoadingSpinner />}
          <div className="grid grid-cols-12 gap-6 mt-5">
            <div className="col-span-12 lg:col-span-12">
              <form onSubmit={handleSubmit(submithandle)}>
                <div className="bg-white shadow-md rounded-md">
                  <div className="p-5">
                    <div className="preview">
                      <div className="mt-5 flex flex-col sm:flex-row items-center gap-4">
                        <div className="w-8/12 flex-auto  relative">
                          <span className="text-red-600 absolute -top-8">
                            {errors.content_name?.message}
                          </span>
                          <input
                            id="session_name"
                            type="text"
                            className={`pt-6 pb-1 block w-full shadow-md px-4 mt-0 ${
                              contentData?.id ? 'bg-gray-300' : 'bg-transparent'
                            }  border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                            placeholder=" "
                            disabled={contentData?.content_name}
                            {...register('content_name', {
                              required: true,
                            })}
                          />
                          <label
                            htmlFor="session_name"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Content Name
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                      </div>
                      <div className={` flex-auto w-1/3 relative`}>
                        <span className="text-red-600 absolute -top-6">
                          {errors?.form_name?.message}
                        </span>
                        {formNames.length !== 0 && (
                          <Controller
                            name={'form_name'}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  id="form_name"
                                  value={value}
                                  label="Select Form name*"
                                  variant="outlined"
                                  disabled={contentData?.form_name}
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 ${
                                    contentData?.id
                                      ? 'bg-gray-300'
                                      : 'bg-transparent'
                                  }  border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 mt-4 focus:border-gray-400 border-gray-200 rounded-md ${
                                    value ? ' focus-label' : ''
                                  }`}
                                >
                                  {formNames?.map((name) => {
                                    return (
                                      <Option key={name} value={name}>
                                        {capitalizeFirstWords(name)}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              );
                            }}
                            rules={{ required: true }}
                          />
                        )}
                      </div>
                      <div className="w-8/12 flex flex-col sm:flex-row items-center mt-4">
                        <div className="flex-auto w-full relative">
                          <span className="text-red-600 absolute -top-8 mt-2">
                            {errors.description?.message}
                          </span>
                          <textarea
                            id="description"
                            type="text"
                            className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md h-40"
                            placeholder=" "
                            {...register('description', {
                              required: true,
                            })}
                          ></textarea>
                          <label
                            htmlFor="description"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Description
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                      </div>

                      <div className="p-5 text-center">
                        <button
                          disabled={toasting}
                          type="submit"
                          className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                        >
                          {contentData?.id ? 'Update' : 'Create'} Content
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateContent;
