import { Timestamp } from '@firebase/firestore';
import axios from 'axios';
import moment from 'moment-timezone';
import { getData } from './Firebase';

export const updatedPayload = async (payload, entity, type, userId) => {
  let prefix = '';
  switch (entity) {
    case 'session':
      prefix = 'SES';
      break;
    case 'module':
      prefix = 'MOD';
      break;
    case 'coach':
      prefix = 'COA';
      break;
    case 'guardian':
      prefix = 'GUA';
      break;
    case 'children':
      prefix = 'CHI';
      break;
    case 'level':
      prefix = 'LEV';
      break;
    case 'course':
      prefix = 'COU';
      break;
    case 'pod':
      prefix = 'POD';
      break;
    default:
      prefix = 'COA';
      break;
  }
  //generate 4 digit random number
  //const val = Math.floor(1000 + Math.random() * 9000);
  // call getUID to get a unique ID per collection
  const url = process.env.REACT_APP_GET_UID;
  try {
    if (type === 'create') {
      let result = await axios.get(url, {
        params: { collection: entity },
      });
      let val = result.data.toString();
      return entity === 'script'
        ? {
            ...payload,
            created_at: Timestamp.fromDate(new Date()),
            modified_at: '',
            created_by: userId,
            modified_by: '',
          }
        : {
            ...payload,
            [`${entity}_id`]: prefix + val.padStart(5, '0'),
            created_at: Timestamp.fromDate(new Date()),
            modified_at: '',
            created_by: userId,
            modified_by: '',
          };
    } else if (type === 'update') {
      return {
        ...payload,
        modified_at: Timestamp.fromDate(new Date()),
        modified_by: userId,
      };
    }
  } catch (err) {
    throw new Error('Failed to get the unique id' + err);
  }
};

//function to add and substract minutes to the time for finding out coach availability
export const addSubsMinutes = (time, minsToAddSubs, action) => {
  function D(J) {
    return (J < 10 ? '0' : '') + J;
  }
  var piece = time.split(':');
  let mins;
  if (action === 'add') {
    mins = piece[0] * 60 + +piece[1] + +minsToAddSubs;
  } else {
    mins = piece[0] * 60 + +piece[1] + -minsToAddSubs;
  }
  return D(((mins % (24 * 60)) / 60) | 0) + ':' + D(mins % 60);
};

export const findDayFromIndex = (value) => {
  const selectedDayNumber = new Date(value).getDay();
  let selectedDayString = '';
  switch (selectedDayNumber) {
    case 0:
      selectedDayString = 'SUN';
      break;
    case 1:
      selectedDayString = 'MON';
      break;
    case 2:
      selectedDayString = 'TUE';
      break;
    case 3:
      selectedDayString = 'WED';
      break;
    case 4:
      selectedDayString = 'THU';
      break;
    case 5:
      selectedDayString = 'FRI';
      break;
    case 6:
      selectedDayString = 'SAT';
      break;
    default:
      break;
  }

  return selectedDayString;
};

export const zipCodeApi = async (e, country) => {
  const { value } = e.target;
  if (country.toLowerCase() === 'united states' && value !== '') {
    try {
      const response = await axios.get(
        `https://www.zipcodeapi.com/rest/${process.env.REACT_APP_ZIPCODE_API_NEW_KEY}/info.json/${value}/degrees`
      );
      return response.data;
    } catch (err) {
      console.error(`Zipcode API failed : ${err.message}`);
      return err.response.data ? err.response.data.error_msg : err.message;
    }
  }
};

export const getTodaysDate = () => {
  const date = new Date();
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  const todaysDate =
    year + '-' + (month.length === 1 ? '0' + month : month) + '-' + day;
  return todaysDate;
};

export const firebaseTimestampToDateTime = (ts) => {
  const fireBaseTime = ts;
  const date = fireBaseTime.toLocaleDateString('en-GB').split('/');
  const time = fireBaseTime
    .toLocaleTimeString('en-US', { hour12: false })
    .slice(0, -3);

  const finalDate = `${date[2]}-${date[1]}-${date[0]}`;
  return { finalDate, time };
};

export const dateTimeToTimestamp = (time, date) => {
  var timeParts = time.split(':');
  var startDate = new Date(date);
  startDate.setHours(+timeParts[0]);
  startDate.setMinutes(+timeParts[1]);
  startDate.setSeconds(0);
  return startDate;
};

export const convertLocale = (ts, code) => {
  const date = new Date(ts).toLocaleDateString(code, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return date;
};

export const convertTimestampToDateTime = (ts) => {
  const dateFormat = new Date(ts);
  const year = dateFormat.getFullYear().toString();
  const month = (dateFormat.getMonth() + 1).toString();
  const day = dateFormat.getDate().toString();
  const date =
    year + '/' + (month.length === 1 ? '0' + month : month) + '/' + day;

  const hours = dateFormat.getHours();
  const minutes =
    dateFormat.getMinutes().toString().length === 1
      ? '0' + dateFormat.getMinutes()
      : dateFormat.getMinutes();
  const time = `${hours}:${minutes}`;
  return { date, time };
};

export const calculateAge = (dob) => {
  if (!dob) return '';
  const birth = new Date(dob);
  return ~~((Date.now() - birth) / 31557600000);
};

export const getCustomTimeZeneOffSet = (curStartTime, destTimeZone) => {
  const date = new Date(curStartTime);
  const cutTimeZoneOffSet = date.getTimezoneOffset();

  // get the current time so we know which offset to take (DST is such bullkitten)
  let now = moment.utc();

  // get the zone offsets for this time, in minutes
  let destTZOffset = moment.tz.zone(destTimeZone).utcOffset(now);
  let localTZOffset = moment.tz
    .zone(moment.tz.guess())
    .utcOffset(cutTimeZoneOffSet);

  // Time difference in minutes
  let offSet = destTZOffset - localTZOffset;
  return offSet < 0 ? [Math.abs(offSet), '-'] : [offSet, '+'];
};

export const timeFormatter = (time, offset, operation) => {
  let [offsetHour, offsetMinute] = offset
    .split(':')
    .map((val) => parseInt(val));

  let [hour, minute] = time.split(':').map((val) => parseInt(val));

  if (operation === '+') {
    let totalMin = offsetMinute + minute;
    let [finalMin, carry] = totalMin < 60 ? [totalMin, 0] : [0, 1];

    let totalHr = offsetHour + hour + carry;
    totalHr = totalHr < 24 ? totalHr : totalHr - 24;

    return `${totalHr}:${finalMin}`;
  }
  let totalMin = offsetMinute - minute;
  let [finalMin, carry] =
    totalMin < 0 ? [Math.abs(totalMin), -1] : [totalMin, 0];
  let totalHr = offsetHour + hour + carry;
  totalHr = totalHr < 24 ? totalHr : totalHr - 24;
  return `${totalHr}:${finalMin}`;
};

export const convertMinsToHrsMins = (mins) => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;
  return `${h}:${m}`;
};

export const getNextDay = (day) => {
  let daysMap = {
    MON: 'TUE',
    TUE: 'WED',
    WED: 'THU',
    THU: 'FRI',
    FRI: 'SAT',
    SAT: 'SUN',
    SUN: 'MON',
  };

  return daysMap[day];
};

export const formatAMPM = (format) => {
  const date = new Date(format);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const getLimit = (query) => {
  const date = new Date();

  if (query === 'max') {
    date.setFullYear(date.getFullYear() - 4);
    date.setDate(date.getDate() - 1);
  } else {
    date.setFullYear(date.getFullYear() - 18);
    date.setDate(date.getDate() + 1);
  }

  return date.toISOString().split('T')[0];
};

/*
 * @description : overlapping of time check
 */
const overlapping = (a, b) => {
  const getMinutes = (s) => {
    const p = s.split(':').map(Number);
    return p[0] * 60 + p[1];
  };
  return (
    getMinutes(a.end) >= getMinutes(a.start) &&
    getMinutes(b.start) >= getMinutes(b.end)
  );
};

export const isOverlapping = (arr) => {
  let i, j;
  for (i = 0; i < arr.length - 1; i++) {
    for (j = i + 1; j < arr.length; j++) {
      if (overlapping(arr[i], arr[j])) {
        return true;
      }
    }
  }
  return false;
};

export const checkDayAvailable = (
  coaches,
  timeSlotStartTime,
  timeSlotEndTime,
  key,
  result,
  index
) => {
  let currentDayFlag = false;
  coaches.overall_availability[key].length
    ? coaches.overall_availability[key].forEach((time) => {
        if (
          isOverlapping([
            {
              start: time.start_time,
              end: timeSlotStartTime,
            },
            {
              start: time.end_time,
              end: timeSlotEndTime,
            },
          ])
        ) {
          currentDayFlag = true;
        } else {
          currentDayFlag = false;
          return currentDayFlag;
        }
      })
    : (result[index]['overall'] = 'NA');
  return currentDayFlag;
};

const addDays = (date, day) => {
  let new_date = new Date(date.getTime());
  new_date.setDate(new_date.getDate() + day);
  return new_date;
};

export const getDates = (k, day, start_date) => {
  const day_num = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].indexOf(
    day
  ); // converting day name to number

  let new_day = new Date(start_date);
  while (new_day.getDay() !== day_num) {
    new_day = addDays(new_day, 1);
  }

  return Array(k)
    .fill()
    .map((_, index) => addDays(new_day, index * 7).toLocaleDateString());
};
let status = true;
export const findCourse = async (firestore, course_id) => {
  status = true;
  let courseData = {};

  const getCourse = await getData(firestore, course_id, 'courses');
  courseData = { ...getCourse };
  if (courseData.levels && courseData.levels.length != 0) {
    const level = await findLevel(firestore, courseData.levels);
    courseData.levels = [...level.levels];
  } else {
    status = false;
  }
  return { courseData, status };
};

const findLevel = async (firestore, idArr) => {
  let levels = [];

  await Promise.all(
    idArr.map(async (id) => {
      const contents = await getData(firestore, id, 'levels');
      const levelContents = { ...contents };
      if (
        contents.level_status == 'active' &&
        contents.modules &&
        contents.modules.length != 0
      ) {
        const levelModules = await findModule(firestore, contents.modules);
        levelContents.modules = levelModules.module;
      } else if (contents.modules && contents.modules.length != 0) {
        status = false;
        const levelModules = await findModule(firestore, contents.modules);
        levelContents.modules = levelModules.module;
      } else {
        status = false;
        levelContents.modules = [];
      }
      levels.push(levelContents);
    })
  );
  return {
    levels,
  };
};

const findModule = async (firestore, idArr) => {
  let module = [];
  await Promise.all(
    idArr.map(async (id) => {
      const contents = await getData(firestore, id, 'modules');
      const moduleContents = { ...contents };
      if (
        contents.module_status == 'active' &&
        contents.sessions &&
        contents.sessions.length != 0
      ) {
        const moduleSession = await findSession(firestore, contents.sessions);
        moduleContents.sessions = moduleSession.session;
      } else if (contents.sessions && contents.sessions.length != 0) {
        status = false;
        const moduleSession = await findSession(firestore, contents.sessions);
        moduleContents.sessions = moduleSession.session;
      } else {
        status = false;
        moduleContents.sessions = [];
      }
      module.push(moduleContents);
    })
  );

  return {
    module,
  };
};

const findSession = async (firestore, idArr) => {
  let session = [];
  await Promise.all(
    idArr.map(async (id) => {
      const contents = await getData(firestore, id, 'sessions');
      const sessionContents = { ...contents };
      if (
        contents.session_status == 'active' &&
        contents.scripts &&
        contents.scripts.length != 0
      ) {
        const sessionScripts = await findScript(firestore, contents.scripts);
        sessionContents.scripts = sessionScripts.scripts;
      } else if (contents.scripts && contents.scripts.length != 0) {
        status = false;
        const sessionScripts = await findScript(firestore, contents.scripts);
        sessionContents.scripts = sessionScripts.scripts;
      } else {
        status = false;
        sessionContents.scripts = [];
      }
      session.push(sessionContents);
    })
  );

  return { session };
};

const findScript = async (firestore, idArr) => {
  let scripts = [];

  await Promise.all(
    idArr.map(async (id) => {
      const contents = await getData(firestore, id, 'scripts');
      if (contents.script_status != 'active') {
        status = false;
      }
      scripts.push(contents);
    })
  );
  return { scripts };
};

export const capitalizeFirstWords = (str) => {
  if (typeof str !== 'string') return;
  const arr = str.split(' ');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');
  return str2;
};

export const compare = (it1, it2, currentKey, index) => {
  index = index || 0;
  //var currentKey = keys[index];
  return it1[currentKey] > it2[currentKey]
    ? 1
    : it1[currentKey] < it2[currentKey]
    ? -1
    : compare(it1, it2, currentKey, index + 1);
};
