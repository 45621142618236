import { yupResolver } from '@hookform/resolvers/yup';
import { Option, Select } from '@material-tailwind/react';
import { collection, query, Timestamp, where } from 'firebase/firestore';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackButton from '../common/BackButton';
import ConfirmationPopup from '../common/ConfirmationPopup';
import {
  addDocument,
  getAllDocuments,
  getConfigurationData,
  getData,
  getDataByQuery,
  updateFieldInDocument,
} from '../common/Firebase';
import LoadingSpinner from '../common/Loading';
import {
  addSubsMinutes,
  capitalizeFirstWords,
  checkDayAvailable,
  convertLocale,
  findCourse,
  formatAMPM,
  getDates,
  updatedPayload,
  zipCodeApi,
} from '../common/utils';
import { PodValidationSchema } from '../common/Validation';
import { useAuth } from '../core/AuthContextProvider';
import { useCore } from '../core/CoreContextProvider';
import log from '../core/log';
import HierarchyPopup from '../Course/HierarchyPopup';
import Children from './Children';
import EstimatedDatePopup from './EstimateDatePopup';
import History from './History';
import Schedule from './Schedule';
import { SearchSelectCoach } from './SearchSelectCoach';

export const CreatePod = () => {
  const [configs, setConfigs] = useState({});
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState([]);
  const [podId, setPodId] = useState('');
  const [courseId, setCourseId] = useState('');
  const [tab, setTab] = useState('default');
  const [schedulePopup, setSchedulePopup] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [hierarchyPopup, setHierarchyPop] = useState({
    show: false,
    data: null,
  });
  const [coaches, setCoaches] = useState({ show: false, data: null });
  const [popUpData, setPopUpData] = useState(null);
  const [podData, setPodData] = useState(null);
  const [autoGenPodId, setAutoGenPodId] = useState('');
  const [toasting, setToasting] = useState(false);
  const [podStatusMap, setPodStatusMap] = useState({
    created: 'enabled',
    published: 'enabled',
    pending: 'disabled',
    active: 'disabled',
    inactive: 'enabled',
  });

  const [popup, setPopup] = useState({
    show: false,
    message: '',
    id: null,
  });

  const [childrenPayload, setChildrenPayload] = useState([]);
  const { firestore } = useCore();
  const { userData, countryCode } = useAuth();
  const location = useLocation();

  let navigate = useNavigate();

  const formOptions = {
    resolver: yupResolver(PodValidationSchema),
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState,
    trigger,
    control,
  } = useForm(formOptions);
  const { errors, isDirty } = formState;
  const selectedCountry = watch('country', '');
  const selectedPodStatus = watch('pod_status', '');

  const onSubmit = async (data) => {
    const tempData = {
      ...data,
      start_date:
        popUpData == null
          ? convertLocale(data.scheduled_timestamp)
          : convertLocale(popUpData.scheduled_timestamp),
      scheduled_timestamp:
        popUpData == null
          ? Timestamp.fromDate(data.scheduled_timestamp)
          : Timestamp.fromDate(popUpData.scheduled_timestamp),
      pod_duration:
        popUpData == null ? data.pod_duration : popUpData.pod_duration,
      children: childrenPayload.length
        ? childrenPayload
        : data?.children
        ? data.children
        : [],
    };
    setLoading(true);
    try {
      const payload =
        podId !== ''
          ? { ...tempData }
          : {
              ...tempData,
              start_date: popUpData.start_date,
              pod_duration: popUpData.pod_duration,
            };

      payload.end_time = addSubsMinutes(
        payload.start_time,
        popUpData?.pod_duration ?? payload.pod_duration,
        'add'
      );
      updatedPayload(
        payload,
        'pod',
        podId !== '' ? 'update' : 'create',
        userData.uid
      ).then(async (uploadedData) => {
        setAutoGenPodId(uploadedData.pod_id);
        let docID = '';
        if (podId !== '') {
          await updateFieldInDocument(firestore, 'pods', podId, uploadedData);
          toast.success('Your pod is updated', {
            toastId: 'update_pod',
            onClose: () => {
              setToasting(false);
            },
            onOpen: () => {
              setToasting(true);
            },
          });
          navigate('/manage/pod');
        } else {
          uploadedData.children = [];
          docID = await addDocument('pods', firestore, uploadedData);
          setPodId(docID);
          toast.success('Pod is created', {
            toastId: 'pod_created',
            onClose: () => {
              setToasting(false);
            },
            onOpen: () => {
              setToasting(true);
            },
          });
        }

        updateCoachScheduleCollection(
          uploadedData,
          docID,
          uploadedData.coach_uid
        );
        updateCoachScheduleCollection(
          uploadedData,
          docID,
          uploadedData.onlooker_uid
        );
        setLoading(false);
      });
    } catch (err) {
      log('Firebase error, while creating a pod', err);
    }
  };

  const updateCoachScheduleCollection = async (uploadedData, docID, uid) => {
    if (!uid) return;
    const result = await getData(firestore, uid, 'coach_schedule');

    let podAlreadyAssigned = false;
    result.assigned_pod?.forEach((pod) => {
      if (pod.pod_id === podId) podAlreadyAssigned = true;
    });

    let payload = {};
    if (result.assigned_pod && result.assigned_pod.length) {
      payload = {
        assigned_pod: [
          ...result.assigned_pod,
          {
            day: uploadedData.day,
            start_time: uploadedData.start_time,
            end_time: uploadedData.end_time,
            pod_id: docID === '' ? podId : docID,
          },
        ],
      };
    } else {
      payload = {
        assigned_pod: [
          {
            day: uploadedData.day,
            start_time: uploadedData.start_time,
            end_time: uploadedData.end_time,
            pod_id: docID === '' ? podId : docID,
          },
        ],
      };
    }

    if (!podAlreadyAssigned) {
      updatedPayload(payload, 'coach_schedule', 'update', userData.uid).then(
        async (payLoadData) => {
          await updateFieldInDocument(
            firestore,
            'coach_schedule',
            uid,
            payLoadData
          );
        }
      );

      const coachRef = collection(firestore, 'coaches');
      let q = query(coachRef, where('uid', '==', uid));
      let coachData = await getDataByQuery(q);

      let podIdList = [];
      if (coachData[0].pod_id && coachData[0].pod_id.length) {
        podIdList = [...coachData[0].pod_id, uploadedData.pod_id];
      } else {
        podIdList = [uploadedData.pod_id];
      }

      try {
        updatedPayload(
          { pod_id: podIdList },
          'coaches',
          'update',
          userData.uid
        ).then(async (uploadedData) => {
          await updateFieldInDocument(
            firestore,
            'coaches',
            coachData[0].id,
            uploadedData
          );
        });
      } catch (err) {
        log(
          'Firebase error : While updating coaches collection for updating pod id',
          err
        );
      }
    }
  };

  const getCoaches = async (type) => {
    if (
      getValues('pod_status') === 'active' ||
      !watch('time_zone_abbr') ||
      (!popUpData && !podData)
    )
      return;
    setLoading(true);
    const { country, language, age_group } = getValues();
    if (country != '' && language != '' && age_group != '') {
      try {
        const coachRef = collection(firestore, 'coaches');

        let q = query(
          coachRef,
          where('country', '==', country),
          where('language', 'array-contains', language),
          where('coach_status', '==', 'active'),
          where('coach_type', '==', 'onlooker')
        );
        let onlookerData = await getDataByQuery(q);
        let onlookerDataIds =
          onlookerData.length && onlookerData.map((data) => data.uid);

        let filteredCoachData;
        if (type !== 'onlooker') {
          let r = query(
            coachRef,
            where('country', '==', country),
            where('language', 'array-contains', language),
            where('coach_status', '==', 'active')
          );
          let allCoachData = await getDataByQuery(r);
          if (onlookerDataIds) {
            filteredCoachData = allCoachData.filter(
              (allData) => !onlookerDataIds.includes(allData.uid)
            );
          } else {
            filteredCoachData = allCoachData;
          }
        }
        let coachData;
        if (type === 'onlooker') {
          coachData = onlookerData;
        } else {
          coachData = filteredCoachData;
        }

        if (coachData.length) {
          coachData = coachData.filter((data) => {
            return data?.age_group?.includes(age_group);
          });

          coachData.length
            ? setCoaches({
                ...coaches,
                show: true,
                data: coachData,
                day: getValues('day'),
                start_time: getValues('start_time'),
                start_date: popUpData?.start_date ?? getValues('start_date'),
                podTimeZone: getValues('time_zone'),
                podTimeZoneAbbr: getValues('time_zone_abbr'),
                type,
              })
            : toast.error(`No ${type} found based on your selection`);
          setLoading(false);
        } else {
          toast.error(`No ${type} found based on your selection`);
          setLoading(false);
        }
      } catch (err) {
        log('Firebase error : While getting coaches', err);
        setLoading(false);
      }
    } else {
      toast.error('Select country, language and age group to search coach');
      setLoading(false);
    }
  };

  const handleCourse = (id) => {
    setCourseId(id);
    const result = courseData.find((course) => {
      return course.id === id;
    });
    setValue('course_name', result.course_name);
  };
  useEffect(() => {
    hierarchyPopup.show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [hierarchyPopup]);
  useEffect(() => {
    schedulePopup
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [schedulePopup]);

  const getCourseData = async () => {
    const coursesRef = collection(firestore, 'courses');
    const q = query(coursesRef, where('course_status', '==', 'active'));
    const data = await getDataByQuery(q);

    if (data) {
      console.log(data, 'course DATA');
      setCourseData(data);
    }
  };

  const getConfigData = async () => {
    const data = await getConfigurationData(firestore);
    if (data) setConfigs(data);
    setLoading(false);
  };

  const getHierarchyPop = async () => {
    setLoading(true);
    const data = await getData(firestore, 'courses', courseId);
    const { courseData, status } = await findCourse(firestore, courseId);
    setHierarchyPop({
      show: true,
      displayData: courseData,
      actualData: data,
      status: status,
      podCheck: true,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (podId !== '' && selectedPodStatus !== podData?.pod_status) {
      try {
        updatedPayload(
          { pod_status: selectedPodStatus },
          'pod',
          'update',
          userData.uid
        ).then(async (uploadedData) => {
          await updateFieldInDocument(firestore, 'pods', podId, uploadedData);
        });
      } catch (err) {
        log('Firebase error : While updating pod', err);
      }
    }
  }, [selectedPodStatus]);

  useEffect(() => {
    if (location?.state?.id) {
      const id = location.state.id;
      setPodId(id);
      const getPodData = async () => {
        await getConfigData();

        const data = await getData(firestore, id, 'pods');
        if (data) {
          let resetData = { ...data };
          resetData.scheduled_timestamp = data.scheduled_timestamp.toDate();
          await getCourseData();
          reset(resetData);
          setCourseId(data.course_id);
          setAutoGenPodId(data.pod_id);
          let timeZone = data.time_zone;
          let timestamp;
          timestamp = new Date(
            data.scheduled_timestamp.toDate()
          ).toLocaleString('en-US', {
            timeZone,
          });
          data.scheduled_timestamp = timestamp;
          setPodData(data);

          if (data.pod_status === 'active' || data.pod_status === 'inactive') {
            setValue('pod_status', data.pod_status);
            setPodStatusMap({
              created: 'disabled',
              published: 'disabled',
              pending: 'disabled',
              active: 'enabled',
              inactive: 'enabled',
            });
          }
          if (data.pod_status === 'pending') {
            setPodStatusMap({
              created: 'disabled',
              published: 'disabled',
              pending: 'enabled',
              active: 'disabled',
              inactive: 'disabled',
            });
          }
        }
      };
      getPodData();
    } else {
      getConfigData();
      getCourseData();
      setPodId('');
      setPopUpData(null);
      setPodData(null);
      setCourseId('');
      reset({
        course_id: '',
        age_group: '',
        language: '',
        country: '',
        zip_code: '',
        time_zone_abbr: '',
        pod_status: '',
      });
      setAutoGenPodId('');
      setPodStatusMap({
        created: 'enabled',
        published: 'enabled',
        pending: 'disabled',
        active: 'disabled',
        inactive: 'enabled',
      });
    }
  }, [location]);

  useEffect(() => {
    if (podId !== '') {
      if (podStatusMap['pending'] === 'enabled') {
        setValue('pod_status', 'pending');
      } else if (
        podStatusMap['created'] === 'enabled' ||
        podStatusMap['published'] === 'enabled'
      ) {
        setValue('pod_status', 'published');
      }
    }
  }, [podStatusMap]);

  const handleOnBlurZipCode = (e) => {
    if (getValues('pod_status') === 'active') return;
    setLoading(true);
    zipCodeApi(e, getValues('country'))
      .then((res) => {
        if (typeof res === 'string') {
          toast.error(res);
        }
        setValue('state', res.state);
        setValue('city', res.city);
        setValue('time_zone', res.timezone.timezone_identifier);
        setValue('time_zone_abbr', res.timezone.timezone_abbr);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setValue('state', '');
        setValue('city', '');
        setValue('time_zone_abbr', '');
        setLoading(false);
      });
  };

  const handleStartPod = () => {
    setValue('pod_status', 'active');
    setPodStatusMap({
      created: 'disabled',
      published: 'disabled',
      pending: 'disabled',
      active: 'enabled',
      inactive: 'enabled',
    });
    setPopup({
      ...popup,
      show: false,
      btnText: '',
    });
    toast.success('Pod is started');
  };

  const reCalculateAvailability = async (
    day,
    startTime,
    start_date,
    podTimeZone,
    podTimeZoneAbbr,
    podDuration
  ) => {
    setLoading(true);
    const endTime = addSubsMinutes(startTime, podDuration, 'add');
    const coachRef = collection(firestore, 'coaches');
    let q = query(
      coachRef,
      where('country', '==', getValues('country')),
      where('language', 'array-contains', getValues('language')),
      where('coach_status', '==', 'active')
    );
    let coachesAvailable = await getDataByQuery(q);
    coachesAvailable = coachesAvailable?.filter((data) => {
      return data?.age_group?.includes(getValues('age_group'));
    });

    const semiAvailableCoaches =
      coachesAvailable.length && coachesAvailable.map((coach) => coach.uid);

    try {
      const data = await getAllDocuments(firestore, 'coach_schedule');
      const filteredCoaches = data.filter((coachData) =>
        semiAvailableCoaches.includes(coachData.coach_id)
      );

      const result = [
        {
          coach_id: '',
          overall: '',
          coachPodInfo: {},
          specific: [
            {
              date: '',
              day: '',
              status: '',
              days: [],
            },
          ],
        },
      ];

      filteredCoaches.forEach((coaches, index) => {
        // Convert To Local Time
        coachesAvailable.forEach((coach) => {
          if (coach.uid === coaches.id) {
            coaches.time_zone = coach.time_zone;
          }
        });
        // Add the util function for time format
        let timeSlotStartTime = addSubsMinutes(startTime, '15', 'substract');
        let timeSlotEndTime = addSubsMinutes(endTime, '15', 'add');
        let exceptionTimeZone = {
          HST: 10,
          AKDT: 8,
        };
        if (Object.keys(coaches.overall_availability).length) {
          if (podTimeZone !== coaches.time_zone) {
            if (Object.keys(exceptionTimeZone).includes(podTimeZoneAbbr)) {
              var s = new Date(
                `${start_date} ${addSubsMinutes(
                  startTime,
                  '15',
                  'substract'
                )} UTC`
              );
              var e = new Date(
                `${start_date} ${addSubsMinutes(
                  endTime,
                  '15',
                  'substract'
                )} UTC`
              );
              e.setHours(e.getHours() + exceptionTimeZone[podTimeZoneAbbr]);
              s.setHours(s.getHours() + exceptionTimeZone[podTimeZoneAbbr]);
            } else {
              s = new Date(
                `${start_date} ${addSubsMinutes(
                  startTime,
                  '15',
                  'substract'
                )} ${podTimeZoneAbbr}`
              );
              e = new Date(
                `${start_date} ${addSubsMinutes(
                  endTime,
                  '15',
                  'substract'
                )} ${podTimeZoneAbbr}`
              );
            }
            timeSlotStartTime = moment(s).tz(coaches.time_zone).format('HH:mm');
            timeSlotEndTime = moment(e).tz(coaches.time_zone).format('HH:mm');
          }
        }

        result[index] = {
          coach_id: coaches.coach_id,
          assigned_pod: coaches.assigned_pod,
        };
        let overallFlag = true;

        let availableDays = Object.keys(coaches.overall_availability);
        const found = day.every((selectedday) =>
          availableDays.includes(selectedday)
        );
        if (found) {
          let selectedDaysArr = [];
          if (day.length) {
            day.forEach((key) => {
              selectedDaysArr.push(key);
              result[index]['days'] = selectedDaysArr;
              if (
                !checkDayAvailable(
                  coaches,
                  timeSlotStartTime,
                  timeSlotEndTime,
                  key,
                  result,
                  index
                )
              ) {
                overallFlag = false;
              }
            });
          }
        }
        if (!overallFlag) {
          result[index]['overall'] = 'NA';
        } else {
          result[index]['overall'] = 'A';
        }

        if (coaches.assigned_pod) {
          coaches.assigned_pod.forEach((pod) => {
            for (let curDay of day) {
              if (!result[index]['coachPodInfo']) {
                result[index]['coachPodInfo'] = {};
              }
              if (pod.day.includes(curDay)) {
                if (
                  (pod.start_time >= timeSlotStartTime &&
                    pod.end_time <= timeSlotEndTime) ||
                  (timeSlotEndTime > pod.start_time &&
                    timeSlotStartTime < pod.end_time)
                ) {
                  result[index]['coachPodInfo'][curDay] = 'NA';
                } else {
                  result[index]['coachPodInfo'][curDay] = 'A';
                }
              } else {
                result[index]['coachPodInfo'][curDay] = 'A';
              }
            }
          });
        }

        coaches.specific_availability?.length
          ? coaches.specific_availability.forEach((data, index1) => {
              if (day.includes(data.day)) {
                let curDay = data.day;
                let timingsFlag = false;
                data.timings.forEach((time) => {
                  if (
                    time.start_time <= timeSlotStartTime &&
                    time.end_time >= timeSlotEndTime
                  ) {
                    timingsFlag = true;
                    curDay;
                    if (!result[index]['specific']) {
                      result[index]['specific'] = [
                        {
                          day: data.day,
                          date: data.date,
                          status: 'A',
                        },
                      ];
                    } else {
                      result[index]['specific'][index1] = {
                        day: data.day,
                        date: data.date,
                        status: 'A',
                      };
                    }
                  }
                });
                if (!timingsFlag) {
                  if (!result[index]['specific']) {
                    result[index]['specific'] = [
                      {
                        day: data.day,
                        date: data.date,
                        status: 'NA',
                      },
                    ];
                  } else {
                    result[index]['specific'][index1] = {
                      day: data.day,
                      date: data.date,
                      status: 'NA',
                    };
                  }
                }
              }
            })
          : '';
      });

      const showData = [
        {
          coach_id: '',
          availability: [],
        },
      ];
      result.forEach((el, index) => {
        if (el.coachPodInfo) {
          showData[index] = {
            coach_id: el.coach_id,
            day: el.days,
            availability: el.days.map((day) => {
              return {
                day: `${day}`,
                futureAvailability: {
                  1: el.coachPodInfo[day],
                  2: el.coachPodInfo[day],
                  3: el.coachPodInfo[day],
                  4: el.coachPodInfo[day],
                  5: el.coachPodInfo[day],
                },
              };
            }),
          };
        }
        if (el.overall === 'NA') {
          showData[index] = {
            coach_id: el.coach_id,
            day: el.days,
            availability: el.days.map((day) => {
              return {
                day: `${day}`,
                futureAvailability: {
                  1: 'NA',
                  2: 'NA',
                  3: 'NA',
                  4: 'NA',
                  5: 'NA',
                },
              };
            }),
          };
        } else if (el.overall === 'A' && !el.specific && !el.coachPodInfo) {
          showData[index] = {
            coach_id: el.coach_id,
            day: el.days,
            availability: el.days.map((day) => {
              return {
                day: `${day}`,
                futureAvailability: {
                  1: 'A',
                  2: 'A',
                  3: 'A',
                  4: 'A',
                  5: 'A',
                },
              };
            }),
          };
        } else if (el.specific) {
          if (el.days.length) {
            for (let day of el.days) {
              const dates = getDates(5, day, start_date); //[wed all 5 dates]
              const formattedDate = [];
              dates?.forEach((date) => {
                const splitDate = date.split('/');
                const finalDate = `${splitDate[2]}-${
                  splitDate[0].length === 1 ? `0${splitDate[0]}` : splitDate[0]
                }-${
                  splitDate[1].length === 1 ? `0${splitDate[1]}` : splitDate[1]
                }`;
                formattedDate.push(finalDate);
              });

              const specificAvailability = {};
              formattedDate.forEach((date, index) => {
                let dateFound = false;
                el.specific.forEach((el1) => {
                  if (el1.date === date) {
                    dateFound = true;
                    specificAvailability[index + 1] = el1.status;
                  }
                });
                if (!dateFound) {
                  specificAvailability[index + 1] = 'A';
                }
              });

              if (!showData[index]) {
                showData[index] = {
                  coach_id: el.coach_id,
                  day: el.days,
                };
              } else {
                showData[index].coach_id = el.coach_id;
                showData[index].day = el.days;
              }
              if (!showData[index]['availability']) {
                showData[index]['availability'] = [
                  {
                    day: `${day}`,
                    futureAvailability: specificAvailability,
                  },
                ];
              } else {
                // NOTE : Special case if has both specificAvailability and podInfo
                let found = false;
                let tempShowData = showData[index]['availability'];
                for (let index in tempShowData) {
                  if (tempShowData[index].day === day) {
                    found = true;
                    // intersection of both futureAvailability

                    let res = {};
                    let obj1 = tempShowData[index].futureAvailability;
                    let obj2 = specificAvailability;
                    for (let i = 1; i <= 5; i++) {
                      res[i] =
                        obj1[i] === obj2[i]
                          ? obj1[i]
                          : obj1[i] === 'NA'
                          ? 'NA'
                          : obj2[i];
                    }
                    tempShowData[index].futureAvailability = res;
                  }
                }

                if (!found) {
                  showData[index]['availability'].push({
                    day: `${day}`,
                    futureAvailability: specificAvailability,
                  });
                }
              }
            }
          }
        }
      });
      setLoading(false);
      return showData;
      // setCoachAvailabilityData(showData);
    } catch (err) {
      setLoading(false);
      console.error(`Error while fetching coaches: `, err.message);
    }
  };

  const reCheckAvailabilityOnAgeLanguageCountryChange = async () => {
    if (getValues('coach_uid') === '') return;
    const coachRef = collection(firestore, 'coaches');
    let q = query(
      coachRef,
      where('country', '==', getValues('country')),
      where('language', 'array-contains', getValues('language')),
      where('coach_status', '==', 'active')
    );
    let coachesAvailable = await getDataByQuery(q);
    coachesAvailable = coachesAvailable?.filter((data) => {
      return data?.age_group?.includes(getValues('age_group'));
    });

    let availableFlag = false;
    coachesAvailable.forEach((coach) => {
      if (coach.uid === getValues('coach_uid')) {
        availableFlag = true;
      }
    });

    if (!availableFlag) {
      getData(firestore, getValues('coach_uid'), 'coach_schedule').then(
        async (response) => {
          if (response.assigned_pod) {
            const tempAssignedPod = [...response.assigned_pod];
            response.assigned_pod.forEach((pod, index) => {
              if (pod.pod_id === podId) {
                tempAssignedPod.splice(index, 1);
              }
            });

            updatedPayload(
              { assigned_pod: tempAssignedPod },
              'coach_schedule',
              'update',
              userData.uid
            ).then(async (payLoadData) => {
              updateFieldInDocument(
                firestore,
                'coach_schedule',
                getValues('coach_uid'),
                payLoadData
              );
            });

            const coachRef = collection(firestore, 'coaches');
            let q = query(coachRef, where('uid', '==', getValues('coach_uid')));
            let coachData = await getDataByQuery(q);

            let podIdList = [...coachData[0].pod_id];
            const index = podIdList.indexOf(autoGenPodId);
            if (index !== -1) {
              podIdList.splice(index, 1);
            }

            setValue('coach_id', '');
            setValue('coach_uid', '');
            setValue('coach_name', '');

            setPodStatusMap({
              created: 'enabled',
              published: 'disabled',
              pending: 'disabled',
              active: 'disabled',
              inactive: 'enabled',
            });

            try {
              updatedPayload(
                { pod_id: podIdList },
                'coaches',
                'update',
                userData.uid
              ).then(async (uploadedData) => {
                await updateFieldInDocument(
                  firestore,
                  'coaches',
                  coachData[0].id,
                  uploadedData
                );
              });
            } catch (err) {
              log(
                'Firebase error : While updating coaches collection for updating pod id',
                err
              );
            }
          }
        }
      );

      toast.error(
        'Coach is not available based on the new selection, please search the coach again'
      );
    }
  };

  const reCheckAvailabilityOnAgeLanguageCountryChangeForOnlooker = async () => {
    if (!getValues('onlooker_uid') || getValues('onlooker_uid') === '') return;
    const coachRef = collection(firestore, 'coaches');
    let q = query(
      coachRef,
      where('country', '==', getValues('country')),
      where('language', 'array-contains', getValues('language')),
      where('coach_status', '==', 'active'),
      where('coach_type', '==', 'onlooker')
    );
    let coachesAvailable = await getDataByQuery(q);
    coachesAvailable = coachesAvailable?.filter((data) => {
      return data?.age_group?.includes(getValues('age_group'));
    });

    let availableFlag = false;
    coachesAvailable.forEach((coach) => {
      if (coach.uid === getValues('onlooker_uid')) {
        availableFlag = true;
      }
    });

    if (!availableFlag) {
      getData(firestore, getValues('onlooker_uid'), 'coach_schedule').then(
        async (response) => {
          if (response.assigned_pod) {
            const tempAssignedPod = [...response.assigned_pod];
            response.assigned_pod.forEach((pod, index) => {
              if (pod.pod_id === podId) {
                tempAssignedPod.splice(index, 1);
              }
            });

            updatedPayload(
              { assigned_pod: tempAssignedPod },
              'coach_schedule',
              'update',
              userData.uid
            ).then(async (payLoadData) => {
              updateFieldInDocument(
                firestore,
                'coach_schedule',
                getValues('onlooker_uid'),
                payLoadData
              );
            });

            const coachRef = collection(firestore, 'coaches');
            let q = query(
              coachRef,
              where('uid', '==', getValues('onlooker_uid'))
            );
            let coachData = await getDataByQuery(q);

            let podIdList = [...coachData[0].pod_id];
            const index = podIdList.indexOf(autoGenPodId);
            if (index !== -1) {
              podIdList.splice(index, 1);
            }

            setValue('onlooker_id', '');
            setValue('onlooker_uid', '');
            setValue('onlooker_name', '');

            try {
              updatedPayload(
                { pod_id: podIdList },
                'coaches',
                'update',
                userData.uid
              ).then(async (uploadedData) => {
                await updateFieldInDocument(
                  firestore,
                  'coaches',
                  coachData[0].id,
                  uploadedData
                );
              });
            } catch (err) {
              log(
                'Firebase error : While updating coaches collection for updating pod id',
                err
              );
            }
          }
        }
      );

      toast.error(
        'Onlooker is not available based on the new selection, please search the onlooker again'
      );
    }
  };

  const availabilityCoachOnlooker = (coach) => {
    let final = true;
    coach.availability.forEach((available) => {
      const values = Object.values(available?.futureAvailability);
      values.forEach((value) => {
        if (value === 'NA') {
          final = false;
        }
      });
    });
    return final;
  };

  const reEvaluateCoachAndOnlooker = async (data) => {
    const res = await reCalculateAvailability(
      data.day,
      data.start_time,
      data?.start_date,
      podData.time_zone,
      podData.time_zone_abbr,
      data?.duration
    );
    const findCoach = res.find((coach) => coach.coach_id == data?.coach_uid);
    const findOnlooker = res.find(
      (coach) => coach.coach_id == data?.onlooker_uid
    );
    const resultObj = {};
    if (findCoach) {
      resultObj.coach = availabilityCoachOnlooker(findCoach);
    }
    if (findOnlooker) {
      resultObj.onlooker = availabilityCoachOnlooker(findOnlooker);
    }

    return resultObj;
  };

  const handlePopUpClose = (data) => {
    if (location?.state?.id) {
      reCalculateAvailability(
        getValues('day'),
        getValues('start_time'),
        data?.start_date,
        getValues('time_zone'),
        getValues('time_zone_abbr'),
        data?.pod_duration
      ).then(async (res) => {
        res?.forEach((coach) => {
          if (coach.coach_id === getValues('coach_uid')) {
            let availableFlag = true;
            coach.availability.forEach((available) => {
              const values = Object.values(available?.futureAvailability);

              values.forEach((value) => {
                if (value === 'NA') {
                  availableFlag = false;
                }
              });
            });

            getData(firestore, data.coach_uid, 'coach_schedule').then(
              async (response) => {
                if (response.assigned_pod) {
                  const tempAssignedPod = [...response.assigned_pod];
                  response.assigned_pod.forEach((pod, index) => {
                    if (pod.pod_id === podId) {
                      tempAssignedPod.splice(index, 1);
                    }
                  });

                  updatedPayload(
                    { assigned_pod: tempAssignedPod },
                    'coach_schedule',
                    'update',
                    userData.uid
                  ).then(async (payLoadData) => {
                    updateFieldInDocument(
                      firestore,
                      'coach_schedule',
                      data.coach_uid,
                      payLoadData
                    );
                  });

                  const coachRef = collection(firestore, 'coaches');
                  let q = query(coachRef, where('uid', '==', data.coach_uid));
                  let coachData = await getDataByQuery(q);

                  let podIdList = [...coachData[0].pod_id];
                  const index = podIdList.indexOf(autoGenPodId);
                  if (index !== -1) {
                    podIdList.splice(index, 1);
                  }

                  try {
                    updatedPayload(
                      { pod_id: podIdList },
                      'coaches',
                      'update',
                      userData.uid
                    ).then(async (uploadedData) => {
                      await updateFieldInDocument(
                        firestore,
                        'coaches',
                        coachData[0].id,
                        uploadedData
                      );
                    });
                  } catch (err) {
                    log(
                      'Firebase error : While updating coaches collection for updating pod id',
                      err
                    );
                  }
                }
              }
            );
            if (!availableFlag) {
              setValue('coach_id', '');
              setValue('coach_uid', '');
              setValue('coach_name', '');

              updatedPayload(
                { coach_id: '', coach_uid: '', coach_name: '' },
                'pod',
                podId !== '' ? 'update' : 'create',
                userData.uid
              ).then(async (uploadedData) => {
                await updateFieldInDocument(
                  firestore,
                  'pods',
                  podId,
                  uploadedData
                );
              });
              setPodStatusMap({
                created: 'enabled',
                published: 'disabled',
                pending: 'disabled',
                active: 'disabled',
                inactive: 'enabled',
              });
              setValue('pod_status', 'created');
              toast.error(
                'Coach is not available based on the new selection, please search the coach again'
              );
            }
          }
        });
      });
    }
  };

  const confirmation = (data) => {
    if ((!isDirty && !dirty) || podId == '') onSubmit(data);
    else
      setPopup({
        id: data,
        show: true,
        message: 'Are you sure you want to Update the Pod?',
        btnText: 'Yes',
      });
  };

  return (
    <div className="overflow-hidden">
      {<HierarchyPopup setPopup={setHierarchyPop} popup={hierarchyPopup} />}
      <div className="content relative min-h-screen flex-1 px-6 pt-4 pb-10 bg-indigo-50">
        <div className="intro-y flex items-center mt-6">
          <BackButton
            isDirty={formState.isDirty}
            unsavedChanges={watch('coach_name')}
          />
          <h2 className="text-2xl font-medium mr-auto">
            {podId != '' ? 'Update' : 'Create'} Pod
          </h2>
        </div>
        {loading && <LoadingSpinner />}
        {Object.keys(configs).length !== 0 && (
          <div className="grid grid-cols-12 gap-6 mt-5">
            <div className="col-span-12 lg:col-span-12">
              <div
                className={`bg-white shadow-md rounded-md ${
                  getValues('pod_status') === 'active'
                    ? 'border-4 border-green-600'
                    : ''
                }`}
              >
                <div className="flex flex-col sm:flex-row items-center p-3 border-b border-slate-200/60 dark:border-darkmode-400 justify-between">
                  <h2 className="font-bold text-base">Pod Details</h2>
                  {getValues('pod_status') === 'active' && (
                    <h2 className="font-bold text-base text-green-600">
                      Pod is Live!
                    </h2>
                  )}
                </div>
                <div id="input" className="p-5 mt-4">
                  <form
                    onSubmit={handleSubmit(confirmation)}
                    className="preview"
                  >
                    <div className="flex flex-col sm:flex-row">
                      {autoGenPodId !== '' && (
                        <div className="w-1/3 relative mr-4">
                          <input
                            id="pod_id"
                            type="text"
                            className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-gray-200 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                            placeholder=" "
                            value={autoGenPodId}
                            disabled={true}
                          />
                          <label
                            htmlFor="pod_id"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Pod Id
                          </label>
                        </div>
                      )}
                      <div className="flex-auto mr-2 w-1/3 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.course_id?.message}
                        </span>
                        {courseData.length != 0 && (
                          <Controller
                            name={'course_id'}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  onChange={(e) => {
                                    onChange(handleCourse(e));
                                    onChange(e);
                                  }}
                                  id="course_id"
                                  value={value}
                                  label="Select Course*"
                                  variant="outlined"
                                  disabled={
                                    getValues('pod_status') === 'active'
                                  }
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0  border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                    getValues('pod_status') === 'active'
                                      ? 'bg-gray-300 '
                                      : 'bg-transparent '
                                  } ${value ? ' focus-label' : ''}`}
                                >
                                  {courseData.map((key) => {
                                    return (
                                      <Option key={btoa(key.id)} value={key.id}>
                                        {key.course_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              );
                            }}
                            rules={{ required: true }}
                          />
                        )}

                        {courseId && (
                          <div>
                            <span
                              onClick={() => getHierarchyPop()}
                              className="pl-2 mt-2 underline font-bold text-blue-900 cursor-pointer"
                            >
                              Hierarchy Popup
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="flex-auto mx-2 w-1/3 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.age_group?.message}
                        </span>
                        {configs?.age_group && (
                          <Controller
                            name={'age_group'}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  onChange={(e) => {
                                    onChange(e);
                                    {
                                      if (popUpData || podData) {
                                        reCheckAvailabilityOnAgeLanguageCountryChange();
                                        reCheckAvailabilityOnAgeLanguageCountryChangeForOnlooker();
                                      }
                                    }
                                  }}
                                  id="age_group"
                                  value={value}
                                  label="Select Age Group*"
                                  variant="outlined"
                                  disabled={
                                    getValues('pod_status') === 'active'
                                  }
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0  border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                    getValues('pod_status') === 'active'
                                      ? 'bg-gray-300 '
                                      : 'bg-transparent '
                                  } ${value ? ' focus-label' : ''}`}
                                >
                                  {configs.age_group &&
                                    configs.age_group.map((value) => {
                                      return (
                                        <Option key={btoa(value)} value={value}>
                                          {capitalizeFirstWords(value)}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              );
                            }}
                            rules={{ required: true }}
                          />
                        )}
                      </div>
                      <div className="flex-auto w-1/3 ml-2 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.language?.message}
                        </span>
                        {configs?.language && (
                          <Controller
                            name={'language'}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  onChange={(e) => {
                                    onChange(e);
                                    {
                                      if (popUpData || podData) {
                                        reCheckAvailabilityOnAgeLanguageCountryChange();
                                        reCheckAvailabilityOnAgeLanguageCountryChangeForOnlooker();
                                      }
                                    }
                                  }}
                                  id="language"
                                  value={value}
                                  label="Select Language*"
                                  variant="outlined"
                                  disabled={
                                    getValues('pod_status') === 'active'
                                  }
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                    getValues('pod_status') === 'active'
                                      ? 'bg-gray-300 '
                                      : 'bg-transparent '
                                  } ${value ? ' focus-label' : ''}`}
                                >
                                  {configs.language &&
                                    configs.language.map((value) => {
                                      return (
                                        <Option key={btoa(value)} value={value}>
                                          {capitalizeFirstWords(value)}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              );
                            }}
                            rules={{ required: true }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="mt-5 flex flex-col sm:flex-row items-center">
                      <div className="flex-auto mx-auto w-1/4 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.country?.message}
                        </span>
                        {configs?.country && (
                          <Controller
                            name={'country'}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  onChange={(e) => {
                                    onChange(e);
                                    {
                                      if (popUpData || podData) {
                                        reCheckAvailabilityOnAgeLanguageCountryChange();
                                        reCheckAvailabilityOnAgeLanguageCountryChangeForOnlooker();
                                      }
                                    }
                                  }}
                                  id="country"
                                  value={value}
                                  label="Select Country*"
                                  variant="outlined"
                                  disabled={
                                    getValues('pod_status') === 'active'
                                  }
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                    getValues('pod_status') === 'active'
                                      ? 'bg-gray-300 '
                                      : 'bg-transparent '
                                  } ${value ? ' focus-label' : ''}`}
                                >
                                  {configs.country &&
                                    configs.country.map((value) => {
                                      return (
                                        <Option key={btoa(value)} value={value}>
                                          {capitalizeFirstWords(value)}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              );
                            }}
                            rules={{ required: true }}
                          />
                        )}
                      </div>

                      <div className="flex-auto ml-4 w-1/4 relative">
                        <span className="text-red-600 absolute -top-6">
                          {selectedCountry === ''
                            ? ''
                            : errors?.zip_code?.message}
                        </span>
                        <input
                          {...register('zip_code', {
                            required: true,
                            onBlur: (e) => {
                              handleOnBlurZipCode(e);
                            },
                          })}
                          onKeyDown={(e) => {
                            e.keyCode === 13 && e.target.blur();
                          }}
                          id="zipcode"
                          className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 ${
                            selectedCountry === '' ||
                            getValues('pod_status') === 'active'
                              ? 'bg-gray-300'
                              : 'bg-transparent'
                          } border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                          placeholder=" "
                          disabled={
                            selectedCountry === '' ||
                            getValues('pod_status') === 'active'
                          }
                        />
                        <label
                          htmlFor="zipcode"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Zip Code
                        </label>
                      </div>
                      {watch('time_zone_abbr') !== '' && (
                        <div className="flex-auto ml-4 w-1/4 relative">
                          <input
                            id="time-zone"
                            className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-gray-300 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                            placeholder=" "
                            disabled={true}
                            {...register('time_zone_abbr')}
                          />
                          <label
                            htmlFor="zipcode"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Time Zone
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="mt-5 py-5 bg-blue-50 flex items-center">
                      <div className="flex-auto w-1/4 relative text-center">
                        <input
                          type="button"
                          value="Schedule"
                          onClick={() => setSchedulePopup(true)}
                          disabled={getValues('pod_status') === 'active'}
                          className={`cursor-pointer w-40 px-6 py-3 ml-4 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none ${
                            getValues('pod_status') !== 'active'
                              ? 'bg-blue-900 hover:bg-yellow-500 hover:shadow-lg'
                              : 'bg-gray-400'
                          } focus:outline-none`}
                        />
                      </div>
                    </div>
                    <div className="p-5 bg-blue-50 flex flex-col sm:flex-row">
                      {popUpData || podData ? (
                        <>
                          <div className="flex mr-4 w-1/5 relative border border-gray-300 items-center p-3">
                            <label
                              htmlFor="day"
                              className="text-black w-24 px-4 font-bold"
                            >
                              Day/s:
                            </label>
                            <div
                              id="day"
                              type="text"
                              className="mt-0 bg-transparent"
                            >
                              {getValues('day')
                                ? getValues('day').join(', ')
                                : ''}
                            </div>
                          </div>
                          <div className="flex mx-4 w-1/4 relative border border-gray-300 items-center p-3">
                            <label
                              htmlFor="starttime"
                              className="text-black w-32 px-4 font-bold"
                            >
                              Start Time:
                            </label>
                            <div
                              id="starttime"
                              type="text"
                              className="mt-0 bg-transparent"
                            >
                              {popUpData?.start_time
                                ? formatAMPM(popUpData.datePickerTimestamp)
                                : formatAMPM(podData.scheduled_timestamp)}
                            </div>
                          </div>
                          <div className="flex mx-4 w-2/5 relative border border-gray-300 items-center p-3">
                            <label
                              htmlFor="startdate"
                              className="text-black w-56 px-4 font-bold"
                            >
                              Start Date:
                            </label>
                            <div
                              id="startdate"
                              type="text"
                              className="mt-0 bg-transparent"
                            >
                              {popUpData?.start_date
                                ? popUpData.start_date
                                : convertLocale(
                                    podData.scheduled_timestamp,
                                    countryCode
                                  )}
                            </div>
                          </div>
                          <div className="flex ml-4 w-1/4 relative border border-gray-300 items-center p-3">
                            <label
                              htmlFor="duration"
                              className="text-black w-40 px-4 font-bold"
                            >
                              Duration:
                            </label>
                            <div
                              id="duration"
                              type="text"
                              className="mt-0 bg-transparent"
                            >
                              {popUpData?.pod_duration
                                ? popUpData.pod_duration
                                : podData.pod_duration}{' '}
                              min
                            </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="mt-5 flex flex-col sm:flex-row items-center">
                      <div className="flex-auto w-1/3 mr-4 relative rounded-md">
                        <div className="flex relative border border-b-2 rounded-md">
                          <span className="text-red-600 absolute -top-6">
                            {errors?.coach_name?.message}
                          </span>
                          <input
                            value={
                              watch('coach_name') ? watch('coach_name') : ''
                            }
                            type="text"
                            readOnly
                            className={`pt-6 shadow-md pb-1 block w-full px-4 mt-0 ${
                              getValues('pod_status') === 'active'
                                ? 'bg-gray-300'
                                : 'bg-transparent'
                            } appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200`}
                          />
                          <label
                            htmlFor="coach_name"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Coach
                          </label>
                          <div
                            onClick={() => getCoaches('coach')}
                            className={` ${
                              getValues('pod_status') === 'active' ||
                              !watch('time_zone_abbr') ||
                              (!popUpData && !podData)
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer'
                            } flex items-center justify-center px-4 modal-open border-l`}
                          >
                            <div className="bg-search"></div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-auto w-1/3 mr-4 relative">
                        <span className="text-red-600 absolute -top-6">
                          {errors?.pod_status?.message}
                        </span>
                        {configs?.pod_status && (
                          <Controller
                            name={'pod_status'}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  onChange={(e) => {
                                    onChange(e);
                                    {
                                      if (popUpData || podData) {
                                        reCheckAvailabilityOnAgeLanguageCountryChange();
                                      }
                                    }
                                  }}
                                  id="pod_status"
                                  value={value}
                                  label="Select Status*"
                                  variant="outlined"
                                  disabled={
                                    getValues('pod_status') === 'active'
                                  }
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                    getValues('pod_status') === 'active'
                                      ? 'bg-gray-300 '
                                      : 'bg-transparent '
                                  } ${value ? ' focus-label' : ''}`}
                                >
                                  {podStatusMap &&
                                    Object.keys(podStatusMap).map((value) => {
                                      return (
                                        <Option
                                          key={btoa(value)}
                                          value={value}
                                          disabled={
                                            podStatusMap[value] === 'disabled'
                                          }
                                        >
                                          {capitalizeFirstWords(value)}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              );
                            }}
                            rules={{ required: true }}
                          />
                        )}
                      </div>
                      <div className="flex w-1/3 relative border border-b-2 rounded-md">
                        <input
                          value={
                            watch('onlooker_name') ? watch('onlooker_name') : ''
                          }
                          type="text"
                          readOnly
                          className={`pt-6 shadow-md pb-1 block w-full px-4 mt-0 ${
                            getValues('pod_status') === 'active'
                              ? 'bg-gray-300'
                              : 'bg-transparent'
                          } appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200`}
                        />
                        <label
                          htmlFor="onlooker_name"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Onlooker
                        </label>
                        <div
                          onClick={() => getCoaches('onlooker')}
                          className={` ${
                            getValues('pod_status') === 'active' ||
                            !watch('time_zone_abbr') ||
                            (!popUpData && !podData)
                              ? 'cursor-not-allowed'
                              : 'cursor-pointer'
                          } flex items-center justify-center px-4 modal-open border-l`}
                        >
                          <div className="bg-search"></div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 flex flex-col sm:flex-row items-center">
                      {podData && podData.start_url && (
                        <div className="w-1/2 shadow-md block w-full p-4 bg-gray-300 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md">
                          <div className="">Start URL:</div>
                          <div className="truncate ...">
                            <a
                              href={podData.start_url}
                              target="_blank"
                              rel="noreferrer"
                              className="text-blue-900 underline"
                            >
                              <span>{podData.start_url}</span>
                            </a>
                          </div>
                        </div>
                      )}
                      {podData && podData.join_url && (
                        <div className="ml-4 w/1/2 shadow-md block w-full p-4 bg-gray-300 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md">
                          <div className="">Join URL:</div>
                          <div className="">
                            <a
                              href={podData.join_url}
                              target="_blank"
                              rel="noreferrer"
                              className="text-blue-900 underline"
                            >
                              {podData.join_url}
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="p-5 text-center">
                      <input
                        type="submit"
                        disabled={toasting}
                        value={podId != '' ? 'Update' : 'Create'}
                        className="cursor-pointer w-40 px-6 py-3 mt-8 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                      />
                      {getValues('pod_status') === 'pending' && (
                        <input
                          type="button"
                          value="Start Pod"
                          onClick={() =>
                            setPopup({
                              ...popup,
                              show: true,
                              message:
                                'Are you sure you want to start the Pod?',
                              btnText: 'Yes',
                            })
                          }
                          className="cursor-pointer w-40 px-6 py-3 ml-4 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                        />
                      )}
                      {getValues('pod_status') === 'active' && (
                        <span className="px-4 font-semibold">
                          Pod is started
                        </span>
                      )}
                    </div>
                  </form>
                  <SearchSelectCoach
                    coaches={coaches}
                    setCoaches={setCoaches}
                    setValue={setValue}
                    trigger={trigger}
                    estimateDatePopupData={popUpData}
                    setDirty={setDirty}
                    pod_duration={getValues('pod_duration')}
                  />
                </div>
              </div>
              {podId !== '' && (
                <div className="bg-white shadow-md rounded-md mt-5">
                  <div id="input" className="p-5">
                    <div className="preview">
                      <div className="tabs-section">
                        <ul
                          id="tab"
                          className="inline-flex pt-2 w-full border-b"
                        >
                          <li
                            onClick={() => setTab('default')}
                            className={
                              'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                              (tab === 'default'
                                ? 'bg-white border-t border-r border-l -mb-px'
                                : '')
                            }
                          >
                            <a id="default-tab">Children</a>
                          </li>
                          {location.pathname.toLowerCase() === '/edit/pod' && (
                            <>
                              <li
                                onClick={() => setTab('schedule')}
                                className={
                                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                                  (tab === 'schedule'
                                    ? 'bg-white border-t border-r border-l -mb-px'
                                    : '')
                                }
                              >
                                <a>Schedule</a>
                              </li>
                              <li
                                onClick={() => setTab('history')}
                                className={
                                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                                  (tab === 'history'
                                    ? 'bg-white border-t border-r border-l -mb-px'
                                    : '')
                                }
                              >
                                <a>History</a>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                      <div id="tab-content" className="border border-t-0">
                        <div
                          id="first"
                          className={
                            'p-5 ' + (tab != 'default' ? 'hidden' : '')
                          }
                        >
                          <Children
                            podId={podId}
                            handleSetLoading={(flag) => setLoading(flag)}
                            handleUpdatePodStatus={(data) =>
                              setPodStatusMap(data)
                            }
                            selectedPodStatus={selectedPodStatus}
                            podData={podData}
                            ageGroup={
                              getValues('age_group')
                                ? getValues('age_group')
                                : podData?.age_group
                            }
                            autoGenPodId={autoGenPodId}
                            setChildrenPayload={setChildrenPayload}
                          />
                        </div>
                        <div
                          id="second"
                          className={
                            'p-5 ' + (tab != 'schedule' ? 'hidden' : '')
                          }
                        >
                          <Schedule
                            podId={podId}
                            podData={podData}
                            handleSetLoading={(flag) => setLoading(flag)}
                            setDirty={setDirty}
                            reEvaluateCoachAndOnlooker={
                              reEvaluateCoachAndOnlooker
                            }
                          />
                        </div>
                        <div
                          id="third"
                          className={
                            'p-5 ' + (tab != 'history' ? 'hidden' : '')
                          }
                        >
                          <History podId={podId} podData={podData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {schedulePopup !== '' && (
              <EstimatedDatePopup
                open={schedulePopup}
                day={(day) => setValue('day', day)}
                startTime={(time) => setValue('start_time', time)}
                handleClose={() => setSchedulePopup(false)}
                popupData={(data) => {
                  setPopUpData(data);
                  handlePopUpClose(data);
                }}
                editPopupData={podData}
                timezoneAbbr={getValues('time_zone_abbr')}
                setDirty={setDirty}
              />
            )}
          </div>
        )}
      </div>
      {popup.show && (
        <ConfirmationPopup
          setPopup={setPopup}
          popup={popup}
          deleteData={(data) => (data ? onSubmit(data) : handleStartPod())}
        />
      )}
    </div>
  );
};
