import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Select, Option } from '@material-tailwind/react';
import { capitalizeFirstWords } from '../common/utils';

export default function ScriptDetails(props) {
  const { register, errors, configs, scriptData, control } = props;
  return (
    <div id="input" className="p-5">
      <div className="preview">
        <div className="mt-5 flex flex-col sm:flex-row items-center">
          <div className="flex-auto w-2/3 relative">
            <span className="text-red-600 absolute -top-8">
              {errors.script_name?.message}
            </span>
            <input
              id="script_name"
              type="text"
              {...register(`script_name`)}
              className="pt-6 pb-1 block w-full px-4 shadow-md mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
              placeholder=" "
            />
            <label
              htmlFor="script_name"
              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
            >
              Script Name
            </label>
          </div>
          <div className="ml-4 flex-auto w-1/3 relative">
            <span className="text-red-600 absolute -top-8">
              {errors.script_id?.message}
            </span>
            {configs.script_id && (
              <Controller
                name={'script_id'}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={(e) => {
                        onChange(e);
                      }}
                      id="script_id"
                      value={value}
                      label="Select Script ID*"
                      variant="outlined"
                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                        value ? ' focus-label' : ''
                      }`}
                    >
                      {configs.script_id.map((value) => {
                        return (
                          <Option key={btoa(value)} value={value}>
                            {capitalizeFirstWords(value)}
                          </Option>
                        );
                      })}
                    </Select>
                  );
                }}
                rules={{ required: true }}
              />
            )}
          </div>
        </div>
        <div className="mt-10 flex flex-col sm:flex-row items-center">
          <div className="flex-auto w-1/3 relative">
            <span className="text-red-600 absolute -top-8">
              {errors.age_group?.message}
            </span>
            {configs.age_group && (
              <Controller
                name={'age_group'}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={(e) => {
                        onChange(e);
                      }}
                      id="age_group"
                      value={value}
                      label="Select Age Group*"
                      variant="outlined"
                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                        value ? ' focus-label' : ''
                      }`}
                    >
                      {configs.age_group.map((value) => {
                        return (
                          <Option key={btoa(value)} value={value}>
                            {capitalizeFirstWords(value)}
                          </Option>
                        );
                      })}
                    </Select>
                  );
                }}
                rules={{ required: true }}
              />
            )}
          </div>
          <div className="flex-auto ml-4 w-1/3 relative">
            <span className="text-red-600 absolute -top-8">
              {errors.topic?.message}
            </span>
            {configs.topic && (
              <Controller
                name={'topic'}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={(e) => {
                        onChange(e);
                      }}
                      id="topic"
                      value={value}
                      label="Select Topic*"
                      variant="outlined"
                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                        value ? ' focus-label' : ''
                      }`}
                    >
                      {configs.topic.map((value) => {
                        return (
                          <Option key={btoa(value)} value={value}>
                            {capitalizeFirstWords(value)}
                          </Option>
                        );
                      })}
                    </Select>
                  );
                }}
                rules={{ required: true }}
              />
            )}
          </div>
        </div>
        <div className="mt-10 flex flex-col sm:flex-row items-center">
          <div className="flex-auto w-1/3 relative">
            <span className="text-red-600 absolute -top-8">
              {errors.script_status?.message}
            </span>
            {configs.script_status && (
              <Controller
                name={'script_status'}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={(e) => {
                        onChange(e);
                      }}
                      id="script_status"
                      value={value}
                      label="Select Script Status*"
                      variant="outlined"
                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                        value ? ' focus-label' : ''
                      }`}
                    >
                      {configs.script_status.map((value) => {
                        return (
                          <Option key={btoa(value)} value={value}>
                            {capitalizeFirstWords(value)}
                          </Option>
                        );
                      })}
                    </Select>
                  );
                }}
                rules={{ required: true }}
              />
            )}
          </div>
          <div className="flex-auto mx-4 w-1/3 relative">
            <input
              type="text"
              value={scriptData?.version ? scriptData.version : 1}
              className="pt-6 bg-gray-200 pb-1 block shadow-md w-full px-4 mt-0 border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md "
              disabled={true}
              readOnly={true}
            />
            <label
              htmlFor="version"
              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
            >
              Version
            </label>
          </div>
          <div className="flex-auto w-1/3 relative">
            <span className="text-red-600 absolute -top-8">
              {errors.language?.message}
            </span>
            {configs.language && (
              <Controller
                name={'language'}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={(e) => {
                        onChange(e);
                      }}
                      id="language"
                      value={value}
                      label="Select Language*"
                      variant="outlined"
                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                        value ? ' focus-label' : ''
                      }`}
                    >
                      {configs.language.map((value) => {
                        return (
                          <Option key={btoa(value)} value={value}>
                            {capitalizeFirstWords(value)}
                          </Option>
                        );
                      })}
                    </Select>
                  );
                }}
                rules={{ required: true }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ScriptDetails.propTypes = {
  register: PropTypes.func.isRequired,
  setConfigs: PropTypes.func,
  errors: PropTypes.object.isRequired,
  configs: PropTypes.object.isRequired,
  scriptData: PropTypes.object,
  control: PropTypes.any,
};
