import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Select, Option } from '@material-tailwind/react';
import GenerateSteps from './GenerateSteps';
import { yupResolver } from '@hookform/resolvers/yup';
import { formDetailsValidationSchema } from '../common/Validation';
import { useCore } from '../core/CoreContextProvider';
import { useLocation } from 'react-router-dom';
import {
  getAllDocumentsWithSort,
  getAllDocuments,
  getData,
} from '../common/Firebase';
import LoadingSpinner from '../common/Loading';

const EditTypeForm = () => {
  const { firestore } = useCore();
  const location = useLocation();
  const [popup, setPopup] = useState({ show: false });
  const [variable, setVariable] = useState({});
  const [addedVariable, setAddedVariable] = useState([]);
  const [addedSteps, setAddedSteps] = useState([]);
  const [addedQuery, setAddedQuery] = useState([]);
  const [formData, setFormData] = useState({});
  const [eachStepData, setEachStepData] = useState({});
  const [selectedSubStep, setSelectedSubStep] = useState({});
  const [tab, setTab] = useState('default');
  const [loading, setLoading] = useState(true);

  const formOptions = {
    resolver: yupResolver(formDetailsValidationSchema),
  };
  const {
    register,
    formState: { errors },
    reset,
    control,
  } = useForm(formOptions);

  useEffect(() => {
    if (location?.state) {
      let data = location.state.data;
      let stepIndex = location.state.index;

      const getAllSteps = async () => {
        const formDocData = await getData(
          firestore,
          data.form_name,
          'tapouts_form'
        );
        setFormData({ ...formDocData });
        reset(formDocData);
        let usedVariable = [];
        const stepsData = await getAllDocumentsWithSort(
          firestore,
          `tapouts_form/${data.form_name}/steps/`,
          'step_no'
        );

        for (let step of stepsData) {
          const subStepData = await getAllDocumentsWithSort(
            firestore,
            `tapouts_form/${data.form_name}/steps/${step.id}/sub_steps/`,
            'step_no'
          );
          const conditions = await getAllDocuments(
            firestore,
            `tapouts_form/${data.form_name}/steps/${step.id}/conditions`
          );
          if (subStepData.length) {
            step.sub_steps = [...subStepData];
          } else if (conditions.length) {
            step.conditions = [...conditions];
          }
          if (step.variable != '') {
            usedVariable.push(step.variable);
          }
          subStepData.map((stepDetails) => {
            if (stepDetails.variable != '') {
              usedVariable.push(stepDetails.variable);
            }
          });
        }
        setAddedVariable(usedVariable);
        const obj = { ...formDocData.variable };
        setVariable(obj);
        setAddedSteps(stepsData);
        if (stepsData[stepIndex]) {
          setEachStepData(stepsData[stepIndex]);
          let tempVar = stepsData[stepIndex];
          let subStepIndex = location.state?.subStepIndex;
          if (subStepIndex != undefined) {
            let subStep = tempVar.sub_steps[subStepIndex];
            setSelectedSubStep(subStep);
          }
          setPopup({ show: true });
        }
      };
      getAllSteps();
    }
    setLoading(false);
  }, []);

  return (
    <div className="bg-white shadow-md rounded-md mt-5">
      <div className="p-5">
        <div className="preview">
          {loading && <LoadingSpinner />}
          <div className="tabs-section">
            <ul id="tabs" className="inline-flex pt-2 w-full border-b">
              <li
                onClick={() => setTab('default')}
                className={
                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                  (tab === 'default'
                    ? 'bg-white border-t border-r border-l -mb-px'
                    : '')
                }
              >
                <a id="default-tab">Edit Steps</a>
              </li>
            </ul>

            <div id="tab-contents" className="border border-t-0">
              <div
                id="first"
                className={'p-5 ' + (tab != 'default' ? 'hidden' : '')}
              >
                <div className="p-5">
                  <form>
                    <div className="preview">
                      <div className="flex flex-col sm:flex-row items-center">
                        <div className="mr-4 flex-auto w-1/3 relative">
                          <span className="text-red-600 absolute -top-6">
                            {errors?.form_name?.message}
                          </span>
                          <input
                            id="form_name"
                            type="text"
                            placeholder=" "
                            disabled={true}
                            className={`pt-6 bg-gray-300 pb-1 shadow-md block w-full px-4 mt-0  border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                            {...register('form_name')}
                          />
                          <label
                            htmlFor="form_name"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Form Name
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row items-center mt-5 ">
                        <div className="mr-4 flex-auto w-1/3 relative">
                          <span className="text-red-600 absolute -top-6">
                            {errors?.form_description?.message}
                          </span>
                          <input
                            id="form_status"
                            type="text"
                            placeholder=" "
                            disabled={true}
                            className="pt-6 bg-gray-300 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                            {...register('form_description')}
                          />
                          <label
                            htmlFor="form_description"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Form Description
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row items-center mt-5 ">
                        <div className="mr-4 flex-auto w-1/3 relative">
                          <span className="text-red-600 absolute -top-6">
                            {errors?.no_of_steps?.message}
                          </span>
                          <input
                            id="form_status"
                            placeholder=" "
                            type="number"
                            min="1"
                            disabled={true}
                            className="pt-6 bg-gray-300 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                            {...register('no_of_steps')}
                          />
                          <label
                            htmlFor="form_description"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            No Of Steps
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                      </div>

                      <div className="mt-5 flex flex-col sm:flex-row items-center">
                        <div className="flex-auto mr-4 w-1/3 relative">
                          <span className="text-red-600 absolute -top-6">
                            {errors?.form_status?.message}
                          </span>

                          <Controller
                            name={'form_status'}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  id="form_status"
                                  defaultValue=""
                                  value={value}
                                  disabled={true}
                                  label="Select status"
                                  className={`pt-6 bg-gray-300 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                    value ? ' focus-label' : ''
                                  }`}
                                >
                                  <Option value={'created'}>Created</Option>
                                  <Option
                                    disabled={
                                      Object.keys(formData).length === 0 ||
                                      addedSteps.length == 0 ||
                                      addedSteps.length != formData.no_of_steps
                                    }
                                    value={'published'}
                                  >
                                    Published
                                  </Option>
                                </Select>
                              );
                            }}
                            rules={{ required: false }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>

                  <br></br>
                  {popup.show && (
                    <GenerateSteps
                      setAddedSteps={setAddedSteps}
                      formData={formData}
                      addedSteps={addedSteps}
                      setLoading={setLoading}
                      setVariable={setVariable}
                      variable={variable}
                      loading={loading}
                      setAddedQuery={setAddedQuery}
                      addedQuery={addedQuery}
                      eachStepData={eachStepData}
                      setTab={setTab}
                      setEachStepData={setEachStepData}
                      selectedSubStep={selectedSubStep}
                      addedVariable={addedVariable}
                      setAddedVariable={setAddedVariable}
                      setSelectedSubStep={setSelectedSubStep}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTypeForm;
