import { collection, query, where } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getData,
  getDataByQuery,
  updateFieldInDocument,
} from '../common/Firebase';
import { updatedPayload, compare } from '../common/utils';
import { useAuth } from '../core/AuthContextProvider';
import { useCore } from '../core/CoreContextProvider';
import log from '../core/log';
import { algoliaFunction } from '../core/searchUtils';
import { AddedModules } from './AddedModules';

export const ManageLevelModules = ({
  showAddModules,
  data,
  levelId,
  setLoading,
}) => {
  const [tab, setTab] = useState('default');
  const [searchModule, setSearchModule] = useState('');
  const [listModules, setListModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [addedModules, setAddedModules] = useState({
    actualContent: [],
    displayContent: [],
  });
  const { firestore } = useCore();
  const { userData } = useAuth();

  const handleSearchModule = async () => {
    setLoading(true);
    if (searchModule != '') {
      let filterArray = [
        {
          connector: '',
          conditions: [
            {
              connector: '',
              fieldName: 'module_status',
              fieldValue: 'active',
              operator: ':',
            },
          ],
        },
      ];
      if (addedModules?.displayContent?.length) {
        console.log('Add', addedModules);
        const obj = {
          connector: 'AND',
          conditions: addedModules?.displayContent?.map((mod, index) => {
            return {
              connector: index == 0 ? '' : 'AND',
              fieldName: 'module_id',
              fieldValue: mod.module_id,
              operator: 'NOT',
            };
          }),
        };
        filterArray.push(obj);
      }
      const result = await algoliaFunction(
        filterArray,
        process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + '_modules',
        searchModule,
        40,
        0
      );
      let hits = result.hits ? result.hits : [];
      let i;
      for (i = 0; i < hits.length; i++) {
        hits[i].id = hits[i]['objectID'];
        delete hits[i].objectID;
      }
      if (hits.length) {
        hits.sort(function (it1, it2) {
          return compare(it1, it2, 'module_id');
        });
        setListModules(hits);
      } else {
        toast.error('No modules are found');
      }
    } else {
      toast.error('Please enter the module name');
    }
    setLoading(false);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const selectedIds = listModules.map(({ id }) => id);
      setSelectedModules(selectedIds);
    } else {
      setSelectedModules([]);
    }
  };

  const handleSelectOne = (e) => {
    let tempArr = [...selectedModules];
    if (e.target.checked) {
      tempArr.push(e.target.value);
      setSelectedModules(tempArr);
    } else {
      let index = tempArr.indexOf(e.target.value);
      index != -1 ? tempArr.splice(index, 1) : '';
      setSelectedModules(tempArr);
    }
  };

  const filterModule = async (modulesIds) => {
    let modules = listModules.filter(({ id }) => {
      return selectedModules.includes(id);
    });
    modules = addedModules?.displayContent?.length
      ? modules.concat(addedModules.displayContent)
      : modules;
    setAddedModules({
      actualContent: modulesIds,
      displayContent: modules,
    });
  };

  const handleAddModules = async () => {
    setLoading(true);
    const modulesIds = addedModules?.actualContent?.length
      ? selectedModules.concat(addedModules.actualContent)
      : selectedModules;
    if (modulesIds.length != 0) {
      try {
        updatedPayload(
          {
            modules: modulesIds,
          },
          'level',
          'update',
          userData.uid
        ).then(async (payLoadData) => {
          await updateFieldInDocument(
            firestore,
            'levels',
            levelId,
            payLoadData
          );
          filterModule(modulesIds);
          getActiveModules(modulesIds);
          setSelectedModules([]);
          setLoading(false);
          toast.success('the selected module is added');
        });
      } catch (err) {
        log('Firebase error: ', err);
      }
    } else {
      toast.error('Please select the module');
    }
  };

  const getModules = async (data) => {
    setLoading(true);
    let arr = [];
    let modules = [];
    if (data.modules) {
      await Promise.all(
        data.modules.map(async (obj) => {
          if (obj.id) {
            arr.push(obj.id);
            modules.push(obj);
          } else {
            const moduleData = await getData(firestore, obj, 'modules');
            moduleData && modules.push(moduleData);
            arr.push(obj);
          }
        })
      );

      getActiveModules(data.modules);
    }
    setAddedModules({
      ...addedModules,
      displayContent: modules,
      actualContent: arr,
    });
    setLoading(false);
  };

  const getActiveModules = async (modules) => {
    setLoading(true);
    const moduleRef = collection(firestore, 'modules');
    const q = query(moduleRef, where('module_status', '==', 'active'));
    const moduleData = await getDataByQuery(q);
    const filteredModules = moduleData.filter((module) =>
      modules
        ? !modules.includes(module.id)
        : !addedModules.actualContent.includes(module.id)
    );
    filteredModules.sort(function (it1, it2) {
      return compare(it1, it2, 'module_id');
    });
    setListModules(filteredModules);
    setLoading(false);
  };

  useEffect(() => {
    if (showAddModules) {
      getActiveModules();
    }
  }, [showAddModules]);

  useEffect(() => {
    if (data) {
      getModules(data);
    } else {
      setAddedModules({
        displayContent: [],
        actualContent: [],
      });
    }
  }, [data]);

  return (
    <div className="bg-white shadow-md rounded-md mt-5">
      <div className="p-5">
        <div className="preview">
          <div className="tabs-section">
            <ul id="tabs" className="inline-flex pt-2 w-full border-b">
              <li
                onClick={() => setTab('default')}
                className={
                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                  (tab === 'default'
                    ? 'bg-white border-t border-r border-l -mb-px'
                    : '')
                }
              >
                <a id="default-tab">Add Module</a>
              </li>
              <li
                onClick={() => setTab('module')}
                className={
                  'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                  (tab === 'module'
                    ? 'bg-white border-t border-r border-l -mb-px'
                    : '')
                }
              >
                <a>Module</a>
              </li>
            </ul>
            <div id="tab-contents" className="border border-t-0">
              <div
                id="first"
                className={'p-5 ' + (tab != 'default' ? 'hidden' : '')}
              >
                {showAddModules ? (
                  <>
                    <div className="flex w-3/4 mx-auto flex-col sm:flex-row items-center justify-end">
                      <div className="flex w-full relative z-0 border border-b-2 rounded-md">
                        <input
                          onChange={(e) => {
                            setSearchModule(e.target.value);
                            if (e.target.value === '') {
                              getActiveModules();
                            } else {
                              setListModules([]);
                            }
                            setSelectedModules([]);
                          }}
                          onKeyPress={(e) =>
                            e.key === 'Enter' && handleSearchModule()
                          }
                          type="text"
                          className="pt-6 pb-1 block w-full px-4 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200"
                        />
                        <label
                          htmlFor="get-module"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Get Module
                        </label>
                        <button
                          className="flex items-center justify-center px-4 border-l"
                          onClick={handleSearchModule}
                        >
                          <div className="bg-search"></div>
                        </button>
                      </div>
                      <div className="text-center">
                        <button
                          onClick={handleAddModules}
                          disabled={selectedModules.length === 0}
                          className={
                            'w-40 px-6 py-3 ml-4 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none focus:outline-none ' +
                            (selectedModules != 0
                              ? 'bg-blue-900 hover:bg-yellow-500 hover:shadow-lg'
                              : 'bg-gray-400')
                          }
                        >
                          Add Selected
                        </button>
                      </div>
                    </div>
                    {listModules.length != 0 && (
                      <div className="w-3/4 mx-auto my-0 text-left flex mt-5">
                        <div className="table-section w-full">
                          <table className="min-w-full border">
                            <thead className="">
                              <tr>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                >
                                  <div className="col-span-12 flex justify-center">
                                    <input
                                      type="checkbox"
                                      checked={
                                        selectedModules.length ===
                                        listModules.length
                                      }
                                      onChange={(e) => handleSelectAll(e)}
                                    />
                                  </div>
                                </th>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                >
                                  Module ID
                                </th>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                >
                                  Module Name
                                </th>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                >
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {listModules.map((res) => {
                                return (
                                  <tr
                                    key={btoa(res.id)}
                                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                  >
                                    <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                      <div className="col-span-12 flex justify-center">
                                        <input
                                          type="checkbox"
                                          value={res.id}
                                          checked={selectedModules.includes(
                                            res.id
                                          )}
                                          onChange={(e) => handleSelectOne(e)}
                                        />
                                      </div>
                                    </td>
                                    <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                      {res.module_id}
                                    </td>
                                    <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                      {res.module_name}
                                    </td>
                                    <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                      {res.module_status}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="pt-6">
                    Please create level first to add modules to it
                  </div>
                )}
              </div>
              <div
                id="second"
                className={'p-5 ' + (tab != 'module' ? 'hidden' : '')}
              >
                <AddedModules
                  setAddedModules={setAddedModules}
                  addedModules={addedModules}
                  levelId={levelId}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ManageLevelModules.propTypes = {
  data: PropTypes.any,
  showAddModules: PropTypes.bool.isRequired,
  levelId: PropTypes.string,
  setLoading: PropTypes.func.isRequired,
};
