import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import firebaseConfig from './firebase.config';

console.log('Auth emulator loaded', connectAuthEmulator);
console.log('Firestore emulator loaded', connectFirestoreEmulator);
const CoreContext = React.createContext({});

const CoreContextProvider = ({ children }) => {
  let firebase,
    auth,
    firestore,
    storage = null;
  try {
    firebase = initializeApp(firebaseConfig);
    auth = getAuth(firebase);
    firestore = getFirestore(firebase);
    storage = getStorage(firebase);
    if (process.env.REACT_APP_ENV == 'EMULATOR') {
      connectAuthEmulator(auth, 'http://localhost:9099');
      connectFirestoreEmulator(firestore, 'localhost', 5002);
    }
  } catch (err) {
    console.log(
      20001,
      'Failed to Initialize Application. Error : ' + err.message + err.stack
    );
  }

  const coreValues = {
    firebase,
    firestore,
    auth,
    storage,
  };
  return (
    <CoreContext.Provider value={coreValues}>{children}</CoreContext.Provider>
  );
};

export const useCore = () => {
  return useContext(CoreContext);
};

CoreContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CoreContextProvider };
