import { collection, query, where } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getData, getDataByQuery } from '../common/Firebase';
import { convertTimestampToDateTime } from '../common/utils';
import { useCore } from '../core/CoreContextProvider';

function History({ podId, podData }) {
  const { firestore } = useCore();
  const [, setLoading] = useState(false);

  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    const getHistoryData = async () => {
      setLoading(true);
      try {
        const historyRef = collection(firestore, 'live_session_history');
        const q = query(historyRef, where('pod_doc_id', '==', podId));
        const filteredHistory = await getDataByQuery(q);
        const tempData = filteredHistory;
        console.log(tempData);
        if (tempData && tempData.length) {
          await Promise.all(
            tempData.map(async (session, index) => {
              const coachRef = collection(firestore, 'coaches');
              let q = query(coachRef, where('uid', '==', session.coach_uid));

              let coachData = await getDataByQuery(q);
              tempData[index].coach_name =
                coachData[0].first_name +
                ' ' +
                coachData[0].middle_name +
                ' ' +
                coachData[0].last_name;
            })
          );
        }

        await Promise.all(
          tempData.map(async (history, index) => {
            tempData[index].children = [];
            podData &&
              podData.children &&
              (await Promise.all(
                podData.children?.map(async (child, index1) => {
                  tempData[index].children[index1] = {};
                  console.log(history.id);
                  const feedbackData = await getData(
                    firestore,
                    history.id,
                    `children_pod_data/${child.child_uid}/${podId}/`
                  );
                  console.log(feedbackData);
                  const childData = await getData(
                    firestore,
                    child.id,
                    'children'
                  );

                  tempData[index].children[index1] = {
                    feedback: feedbackData ? feedbackData.feedback : '',
                    child_name: `${childData.first_name} ${childData.middle_name} ${childData.last_name}`,
                    child_id: childData.children_id,
                    child_uid: childData.uid,
                  };
                })
              ));
          })
        );

        tempData.forEach((data, index) => {
          const dateTime = convertTimestampToDateTime(
            data.scheduled_timestamp.toDate()
          );
          let timestamp;
          const timeZone = podData?.time_zone;
          timestamp = new Date(
            `${dateTime.date} ${dateTime.time}`
          ).toLocaleString('en-US', {
            timeZone,
          });

          (tempData[index].start_time =
            convertTimestampToDateTime(timestamp).time),
            (tempData[index].scheduled_date =
              convertTimestampToDateTime(timestamp).date);
        });
        setHistoryData(tempData);
        setLoading(false);
      } catch (err) {
        console.error(
          'Firebase error : While fetching live_session_history',
          err
        );
        setLoading(false);
      }
    };

    getHistoryData();
  }, [podData]);

  const toggleHistoryFeedback = (index) => {
    const tempHistoryData = [...historyData];
    tempHistoryData[index].showFeedback = tempHistoryData[index].showFeedback
      ? !tempHistoryData[index].showFeedback
      : true;
    setHistoryData(tempHistoryData);
  };

  return (
    <div className="w-full mx-auto my-0 text-left flex mt-5">
      <div className="table-section w-full">
        {historyData.length ? (
          <table className="min-w-full border" id="table">
            <thead className="">
              <tr>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                >
                  Session Name
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                >
                  Time
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                >
                  Coach
                </th>
              </tr>
            </thead>

            <tbody>
              {historyData.map((data, index) => {
                return (
                  <>
                    <tr
                      className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                      key={data.session_doc_id}
                    >
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                        <span
                          onClick={() => toggleHistoryFeedback(index)}
                          className="pr-2 text-2xl"
                        >
                          {data.showFeedback ? '-' : '+'}
                        </span>
                        {data.session_name}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {data.scheduled_date}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {data.start_time}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {data.coach_name}
                      </td>
                    </tr>
                    {data.showFeedback ? (
                      <tr>
                        {data.children && data.children.length != 0 ? (
                          <td colSpan="7">
                            <table className="mt-5 w-3/4 mb-11 mx-auto">
                              <thead className="">
                                <tr>
                                  <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                  >
                                    Child ID
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                  >
                                    Child Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                  >
                                    Feedback
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.children?.map((child) => {
                                  return (
                                    <tr
                                      key={child.child_uid}
                                      className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                    >
                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                                        {child.child_id}
                                      </td>
                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                                        {child.child_name}
                                      </td>
                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                                        {child.feedback
                                          ? child.feedback
                                          : 'Nil'}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </td>
                        ) : (
                          <div>No children found in pod</div>
                        )}
                      </tr>
                    ) : null}
                  </>
                );
              })}
            </tbody>
          </table>
        ) : (
          'No History found!'
        )}
      </div>
    </div>
  );
}

History.propTypes = {
  podId: PropTypes.string,
  podData: PropTypes.object,
};

export default React.memo(History);
