import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import BackButton from '../../common/BackButton';
import { getConfigurationData, getData } from '../../common/Firebase';
import LoadingSpinner from '../../common/Loading';
import { capitalizeFirstWords } from '../../common/utils';
import { useAuth } from '../../core/AuthContextProvider';
import { useCore } from '../../core/CoreContextProvider';

export const MyAccount = () => {
  const { addLocale, userData } = useAuth();
  const { firestore } = useCore();
  const [configData, setConfigData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toasting, setToasting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await addLocale(data?.country);
      toast.success('Country and Locale has been added', {
        toastId: 'add_locale',
        onClose: () => {
          setToasting(false);
        },
        onOpen: () => {
          setToasting(true);
        },
      });
      toast.success();
      setLoading(false);
    } catch (err) {
      console.log('Error occur while adding locale' + err.message);
    }
  };
  useEffect(() => {
    const config = async () => {
      try {
        const config = await getConfigurationData(firestore);
        if (config) {
          setConfigData(config);
        }
        setLoading(false);
      } catch (err) {
        console.error(`Error while fetching config data: `, err.message);
        setLoading(false);
      }
    };
    const getLocale = async () => {
      const profile = await getData(firestore, userData.uid, 'profile');
      profile && reset({ country: profile.country });
    };
    config();
    getLocale();
  }, []);

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overnpmflow-hidden">
        <div className="content relative flex-1 px-6 pb-10 bg-indigo-50">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="intro-y flex items-center mt-6">
              <BackButton />
              <h2 className="text-2xl font-medium mr-auto">My Account Page</h2>
            </div>
            <div className="grid grid-cols-12 mt-5">
              <div className="col-span-12 lg:col-span-12">
                <div className="bg-white shadow-md rounded-md">
                  <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                    <h2 className="font-bold text-base mr-auto">
                      User Details
                    </h2>
                  </div>
                  {loading && <LoadingSpinner />}
                  <div className="p-5">
                    <div className="preview">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-9 lg:col-span-9">
                          <div className="flex flex-col sm:flex-row items-center">
                            <div className="mx-4 flex-auto w-1/3 relative z-0">
                              <input
                                id="name"
                                type="text"
                                value={userData?.email}
                                disabled={true}
                                className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 mt-0 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                placeholder=" "
                              />
                              <label
                                htmlFor="first-name"
                                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                              >
                                First Name
                              </label>
                            </div>

                            <div className="mx-4 flex-auto w-1/3 relative z-0">
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg
                                  className="fill-current h-4 w-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                </svg>
                              </div>
                              <span className="text-red-600 absolute -top-6">
                                {errors?.country?.message}
                              </span>
                              <select
                                id="country"
                                {...register('country', { required: true })}
                                placeholder=" "
                                defaultValue=""
                                className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              >
                                <option value="">Select Country</option>
                                {configData?.country_locale?.map((item) => {
                                  return (
                                    <option value={item} key={item}>
                                      {capitalizeFirstWords(item)}
                                    </option>
                                  );
                                })}
                              </select>
                              <label
                                htmlFor="country"
                                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                              >
                                Country<span className="text-red-600">*</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-5 text-center">
                  <button
                    disabled={toasting}
                    type="submit"
                    className={`w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none  hover:bg-yellow-500 hover:shadow-lg focus:outline-none ${
                      toasting ? 'bg-gray-400' : 'bg-blue-900'
                    }  `}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
