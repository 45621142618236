import { useEffect, useState } from 'react';
import { getAllDocuments } from '../common/Firebase';
import LoadingSpinner from '../common/Loading';
import { useCore } from '../core/CoreContextProvider';
import log from '../core/log';
import { NavLink } from 'react-router-dom';

const ManageContent = () => {
  const { firestore } = useCore();
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);

  const getContentData = async () => {
    try {
      const data = await getAllDocuments(firestore, 'tapouts_form_contents');
      setContents(data);
      setLoading(false);
    } catch (err) {
      log('Error while fetching form data' + err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getContentData();
  }, []);
  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <h2 className="text-2xl font-medium mr-auto">Manage Contents</h2>
          </div>
          {loading && <LoadingSpinner />}
          {!loading && (
            <div className="grid grid-cols-12 gap-6 mt-5">
              {contents.length ? (
                <div className="col-span-12 lg:col-span-12">
                  <div className="bg-white shadow-md rounded-md">
                    <div className="p-5">
                      <div className="w-full mx-auto text-left flex mt-5">
                        <div className="table-section w-full">
                          <table
                            className="min-w-full text-sm border"
                            id="table"
                          >
                            <thead className="uppercase bg-indigo-50 border-b">
                              <tr>
                                <th
                                  data-type="number"
                                  scope="col"
                                  className="font-medium text-gray-900 p-3 text-left"
                                >
                                  Content Name
                                </th>
                                <th
                                  data-type="number"
                                  scope="col"
                                  className="font-medium text-gray-900 p-3 text-left"
                                >
                                  Form Name
                                </th>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left w-48"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {contents.map((content) => {
                                return (
                                  <tr
                                    key={content.id}
                                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                  >
                                    <td className="text-gray-900 font-light p-3 whitespace-nowrap   ">
                                      {content.content_name}
                                    </td>
                                    <td className="text-gray-900 font-light p-3 whitespace-nowrap   ">
                                      {content.form_name}
                                    </td>
                                    <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <NavLink
                                          to="/edit/content"
                                          state={{ id: content.id }}
                                          className="border p-3 mr-2 cursor-pointer"
                                        >
                                          <span className="bg-edit block"></span>
                                        </NavLink>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center bg-indigo-100 text-black font-bold rounded-t px-4 py-2 w-80 mb-3">
                  <p>No contents Found</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageContent;
