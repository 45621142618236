import React, { useEffect, useState } from 'react';
import { getAllDocumentsWithSort } from '../common/Firebase';
import { useCore } from '../core/CoreContextProvider';
import LoadingSpinner from '../common/Loading';
import ViewChildPopup from './ViewChildPopup';
import { NavLink } from 'react-router-dom';
import { calculateAge } from '../common/utils';
import BackButton from '../common/BackButton';
import MaterialTable from '../common/MaterialTable';

export function ManageChildren() {
  const { firestore } = useCore();
  const [childrens, setChildrens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openViewChildPopUp, setOpenViewChildPopUp] = useState('');
  const [viewData, setViewData] = useState({});

  useEffect(() => {
    const children = async () => {
      try {
        const data = await getAllDocumentsWithSort(
          firestore,
          'children',
          'children_id'
        );
        if (data) {
          setChildrens(data);
        }
      } catch (err) {
        console.error(`Error while fetching children, `, err.message);
      }
      setLoading(false);
    };
    children();
  }, []);

  useEffect(() => {
    if (openViewChildPopUp !== '') {
      const filterViewData = childrens.filter(
        (children) => children.children_id === openViewChildPopUp
      )[0];
      setViewData(filterViewData);
    } else {
      setViewData({});
    }
  }, [openViewChildPopUp]);

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton />
            <h2 className="text-2xl font-medium mr-auto">Manage Child</h2>
          </div>

          {loading && <LoadingSpinner />}
          {!loading && (
            <div className="grid grid-cols-12 gap-6 mt-5">
              {childrens.length ? (
                <div className="col-span-12 lg:col-span-12">
                  <div className="bg-white shadow-md rounded-md">
                    <div className="w-full mx-auto text-left flex mt-5">
                      <div className="table-section w-full overflow-x-auto">
                        <MaterialTable
                          columns={[
                            {
                              title: 'CHILD ID',
                              field: 'children_id',
                            },
                            {
                              title: 'CHILD NAME',
                              sorting: false,
                              render: (child) => {
                                return `${child.first_name} ${child.middle_name} ${child.last_name}`;
                              },
                            },
                            {
                              title: 'AGE',
                              field: 'age',
                              filtering: false,
                              render: (child) => {
                                return calculateAge(child.date_of_birth);
                              },
                            },
                            {
                              title: 'POD ID',
                              field: 'pod_id',
                              render: (child) => {
                                return child.pod_id ?? '-';
                              },
                            },
                            {
                              title: 'GUARDIAN ID',
                              field: 'guardian_id',
                            },
                            {
                              title: 'GUARDIAN NAME',
                              sorting: false,
                              render: (child) => {
                                return child.guardian_name ?? '-';
                              },
                            },
                            {
                              title: 'SUBSCTIPTION STATUS',
                              field: 'subscription_status',
                              sorting: false,
                              render: (child) => {
                                return child.subscription_status ?? '-';
                              },
                            },
                            {
                              title: 'STATUS',
                              field: 'child_status',
                              sorting: false,
                              render: (child) => {
                                const colorClass =
                                  child.child_status === 'active'
                                    ? 'text-green-500'
                                    : child.child_status == 'inactive'
                                    ? 'text-gray-400'
                                    : child.child_status == 'registered' ||
                                      child.child_status == 'created'
                                    ? 'text-black'
                                    : 'text-blue-900';
                                return (
                                  <p className={'font-bold ' + colorClass}>
                                    {child.child_status}
                                  </p>
                                );
                              },
                            },

                            {
                              title: 'ACTION',
                              sorting: false,
                              render: (child) => (
                                <div className="flex items-center">
                                  <div
                                    onClick={() =>
                                      setOpenViewChildPopUp(child.children_id)
                                    }
                                    className="border p-3 mr-2 tooltip relative"
                                  >
                                    <span className="bg-view block cursor-pointer"></span>
                                    <span className="tool absolute -top-10 bg-yellow-400 z-10 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[34px] after:left-[18px] after:z-[9999]">
                                      View
                                    </span>
                                  </div>
                                  <NavLink
                                    to="/edit/children"
                                    state={{ id: child.objectID }}
                                    className="border p-3 mr-2 tooltip relative"
                                  >
                                    <span className="bg-edit block cursor-pointer"></span>
                                    <span className="tool absolute -top-10 bg-yellow-400 z-10 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[34px] after:left-[18px] after:z-[9999]">
                                      Edit
                                    </span>
                                  </NavLink>
                                </div>
                              ),
                            },
                          ]}
                          algoliaIndex={
                            process.env.REACT_APP_ALGOLIA_INDEX_PREFIX +
                            '_children'
                          }
                        />
                        <ViewChildPopup
                          open={openViewChildPopUp !== ''}
                          data={viewData}
                          handleClose={() => setOpenViewChildPopUp('')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center bg-indigo-100 text-black font-bold rounded-t px-4 py-2 w-80 mb-3">
                  <p>No Childrens Found</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
