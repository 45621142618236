import PropTypes from 'prop-types';

const Operand = ({ step, storeAnswer, response, index, operand }) => {
  return (
    <>
      <select
        onChange={(e) => {
          storeAnswer(e.target.value, e.target.name, index);
        }}
        disabled={operand}
        value={response}
        name={'operand'}
        className="w-full px-6 py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2 mr-5   hover:shadow-lg focus:outline-none "
      >
        <option value="">Select a operand</option>
        {(step.type == 'free_text' ||
          step.type == 'multi_choice_question' ||
          step.type == 'blurb') && (
          <>
            {operand ? (
              <>
                <option value={operand}>{operand}</option>
              </>
            ) : (
              <>
                <option value={'=='}>==</option>
                <option value={'!=='}>!==</option>
              </>
            )}
          </>
        )}
        {step.type == 'number' && (
          <>
            {operand ? (
              <>
                <option value={operand}>{operand}</option>
              </>
            ) : (
              <>
                <option value={'>'}>{'>'}</option>
                <option value={'<'}>{'<'}</option>
                <option value={'=>'}>{'=>'}</option>
                <option value={'<='}>{'<='}</option>
              </>
            )}
          </>
        )}
        {step.type == 'scale' && (
          <>
            {operand ? (
              <>
                <option value={operand}>{operand}</option>
              </>
            ) : (
              <>
                <option value={'=='}>==</option>
                <option value={'!=='}>!==</option>
                <option value={'>'}>{'>'}</option>
                <option value={'<'}>{'<'}</option>
                <option value={'=>'}>{'=>'}</option>
                <option value={'<='}>{'<='}</option>
              </>
            )}
          </>
        )}
      </select>
    </>
  );
};

Operand.propTypes = {
  step: PropTypes.object,
  storeAnswer: PropTypes.func,
  response: PropTypes.string,
  type: PropTypes.string,
  answer: PropTypes.string,
  index: PropTypes.number,
  operand: PropTypes.string,
};

export default Operand;
