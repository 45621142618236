import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { podSchuduleSchema } from '../common/Validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Select, Option } from '@material-tailwind/react';
import {
  convertLocale,
  findDayFromIndex,
  convertTimestampToDateTime,
} from '../common/utils';
import ReactDatePicker from 'react-datepicker';
import { useAuth } from '../core/AuthContextProvider';

function EstimatedDatePopup({
  open,
  handleClose,
  startTime,
  day,
  popupData,
  editPopupData,
  timezoneAbbr,
  setDirty,
}) {
  const [datePickerTimestamp, setDatePickerTimestamp] = useState(null);
  const { countryCode } = useAuth();

  const [mandatoryDay, setMandatoryDay] = useState(null);
  const initialSelectedDays = {
    SUN: false,
    MON: false,
    TUE: false,
    WED: false,
    THU: false,
    FRI: false,
    SAT: false,
  };
  const [selectedDays, setSelectedDays] = useState(initialSelectedDays);

  const formOptions = {
    resolver: yupResolver(podSchuduleSchema),
  };
  const {
    handleSubmit,
    // watch,
    reset,
    register,
    control,
    // setValue,
    // getValues,
    formState: { errors },
    // trigger,
  } = useForm(formOptions);

  const onSubmit = (payload) => {
    const tempPayload = { ...payload };
    tempPayload.datePickerTimestamp = datePickerTimestamp;
    let exceptionTimeZone = {
      HST: 10,
      AKDT: 8,
    };
    if (Object.keys(exceptionTimeZone).includes(timezoneAbbr)) {
      let tempDate = new Date(
        datePickerTimestamp.toDateString() +
          ' ' +
          datePickerTimestamp.toLocaleTimeString() +
          ` UTC`
      );
      tempDate.setHours(tempDate.getHours() + exceptionTimeZone[timezoneAbbr]);
      tempPayload.scheduled_timestamp = tempDate;
    } else {
      tempPayload.scheduled_timestamp = new Date(
        datePickerTimestamp.toDateString() +
          ' ' +
          datePickerTimestamp.toLocaleTimeString() +
          ` ${timezoneAbbr}`
      );
    }
    tempPayload.start_time =
      convertTimestampToDateTime(datePickerTimestamp).time;
    tempPayload.start_date = convertLocale(datePickerTimestamp, countryCode);
    startTime(tempPayload.start_time);
    let selectedDayArr = [];
    for (let key in selectedDays) {
      if (selectedDays[key]) {
        selectedDayArr.push(key);
      }
    }
    day(selectedDayArr);
    handleClose(false);
    popupData(tempPayload);
    setDirty(true);
  };

  // const handleWeekly = (day) => {
  //   if (day !== mandatoryDay) {
  //     setSelectedDays({
  //       ...selectedDays,
  //       [day]: !selectedDays[day],
  //     });
  //   }
  // };

  useEffect(() => {
    const tempSelectedDays = {};
    for (let key in selectedDays) {
      if (key === mandatoryDay) {
        tempSelectedDays[key] = true;
      } else {
        tempSelectedDays[key] = false;
      }
    }
    setSelectedDays(tempSelectedDays);
  }, [mandatoryDay]);

  useEffect(() => {
    if (editPopupData) {
      const date = new Date(editPopupData?.scheduled_timestamp);
      setDatePickerTimestamp(date);
      reset(editPopupData);
      editPopupData.day.forEach((e) => {
        selectedDays[e] = true;
      });
      setSelectedDays(selectedDays);
    } else {
      reset({ pod_duration: '' });
      setSelectedDays(initialSelectedDays);
      setDatePickerTimestamp();
    }
  }, [editPopupData]);

  return (
    <>
      {open ? (
        <div className="modal date-override fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-1/2 h-3/4 overflow-y-auto mx-auto rounded shadow-lg">
            <div className="modal-content text-left">
              <div className="flex justify-end px-3 py-5">
                <div
                  className="modal-close cursor-pointer"
                  onClick={() => handleClose(false)}
                >
                  <svg
                    className="fill-current text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                  >
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                  </svg>
                </div>
              </div>
              <form className="preview">
                <div className="items-center m-2 p-3 shadow-md border-gray-200 border py-6 relative">
                  <h4 className="absolute -z-1 text-gray-500 px-4 origin-0 text-xs top-12">
                    Start Date/Time
                  </h4>
                  <ReactDatePicker
                    selected={datePickerTimestamp}
                    onChange={(v, e) => {
                      setMandatoryDay(findDayFromIndex(v));
                      setDatePickerTimestamp(v, e);
                    }}
                    showTimeSelect
                    timeIntervals={15}
                    minDate={new Date()}
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />{' '}
                  <div className="flex-auto w-1/2 mt-4 relative z-0">
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <div className="bg-caret_down"></div>
                    </div>
                    <select
                      {...register('frequency')}
                      className="pt-6 pb-1 block shadow-md w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                    >
                      {/* <option value="Daily">Daily</option> */}
                      <option value="Weekly">Weekly</option>
                    </select>
                    <label
                      htmlFor="country"
                      className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                    >
                      Frequency
                    </label>
                  </div>
                  <div className="flex mt-4">
                    <div
                      className={`border ${
                        selectedDays['SUN'] ? 'bg-gray-300' : ''
                      } w-15 h-15 rounded-full px-3 py-2 mr-2 flex items-center justify-center ${
                        mandatoryDay === 'SUN'
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer'
                      }`}
                      // onClick={() => handleWeekly('SUN')}
                    >
                      SUN
                    </div>
                    <div
                      className={`border ${
                        selectedDays['MON'] ? 'bg-gray-300' : ''
                      } w-15 h-15 rounded-full px-3 py-2 mr-2 flex items-center justify-center ${
                        mandatoryDay === 'MON'
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer'
                      }`}
                      // onClick={() => handleWeekly('MON')}
                    >
                      MON
                    </div>
                    <div
                      className={`border ${
                        selectedDays['TUE'] ? 'bg-gray-300' : ''
                      } w-15 h-15 rounded-full px-3 py-2 mr-2 flex items-center justify-center ${
                        mandatoryDay === 'TUE'
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer'
                      }`}
                      // onClick={() => handleWeekly('TUE')}
                    >
                      TUE
                    </div>
                    <div
                      className={`border ${
                        selectedDays['WED'] ? 'bg-gray-300' : ''
                      } w-15 h-15 rounded-full px-3 py-2 mr-2 flex items-center justify-center ${
                        mandatoryDay === 'WED'
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer'
                      }`}
                      // onClick={() => handleWeekly('WED')}
                    >
                      WED
                    </div>
                    <div
                      className={`border ${
                        selectedDays['THU'] ? 'bg-gray-300' : ''
                      } w-15 h-15 rounded-full px-3 py-2 mr-2 flex items-center justify-center ${
                        mandatoryDay === 'THU'
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer'
                      }`}
                      // onClick={() => handleWeekly('THU')}
                    >
                      THU
                    </div>
                    <div
                      className={`border ${
                        selectedDays['FRI'] ? 'bg-gray-300' : ''
                      } w-15 h-15 rounded-full px-3 py-2 mr-2 flex items-center justify-center ${
                        mandatoryDay === 'FRI'
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer'
                      }`}
                      // onClick={() => handleWeekly('FRI')}
                    >
                      FRI
                    </div>
                    <div
                      className={`border ${
                        selectedDays['SAT'] ? 'bg-gray-300' : ''
                      } w-15 h-15 rounded-full px-3 py-2 mr-2 flex items-center justify-center ${
                        mandatoryDay === 'SAT'
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer'
                      }`}
                      // onClick={() => handleWeekly('SAT')}
                    >
                      SAT
                    </div>
                  </div>
                  <div className="flex-auto w-1/2 mt-4 relative">
                    <span className="text-red-600 absolute -top-8">
                      {errors.pod_duration?.message}
                    </span>
                    <Controller
                      name={'pod_duration'}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            onChange={(e) => {
                              onChange(e);
                            }}
                            id="pod_duration"
                            value={value}
                            label="Duration*"
                            variant="outlined"
                            className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                              value ? ' focus-label' : ''
                            }`}
                          >
                            <Option value="30">30 min</Option>
                            <Option value="60">1 hour</Option>
                            <Option value="90">1:30 hour</Option>
                            <Option value="120">2 hour</Option>
                          </Select>
                        );
                      }}
                      rules={{ required: true }}
                    />
                  </div>
                  <div className="mt-4">
                    <button
                      onClick={() => handleClose(false)}
                      className="w-40 px-6 py-3 mt-5 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none focus:outline-none bg-red-900 hover:bg-black hover:shadow-lg"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit(onSubmit)}
                      className="w-40 px-6 py-3 bg-blue-900 ml-4 mt-5 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none focus:outline-none hover:bg-yellow-500"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

EstimatedDatePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  startTime: PropTypes.func.isRequired,
  day: PropTypes.func.isRequired,
  popupData: PropTypes.func.isRequired,
  editPopupData: PropTypes.object,
  timezoneAbbr: PropTypes.string,
  control: PropTypes.any,
  setDirty: PropTypes.func,
};

export default EstimatedDatePopup;
