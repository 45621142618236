import { Routes, Route } from 'react-router-dom';
import AdminDashBoard from '../dashboard/admin/AdminDashBoard';
import { CreateScript } from '../Script/CreateScript';
import { ManageScripts } from '../Script/ManageScripts';
import { CreateSession } from '../Session/CreateSession';
import { ManageSession } from '../Session/ManageSession';
import { CreateEditCoach } from '../Coach/CreateEditCoach';
import { ManageCoaches } from '../Coach/ManageCoaches';
import { CreateEditModule } from '../Module/CreateEditModule';
import { ManageModule } from '../Module/ManageModule';
import { CreateLevel } from '../Level/CreateLevel';
import { ManageLevel } from '../Level/ManageLevel';
import { CreateCourse } from '../Course/CreateCourse';
import { ManageCourse } from '../Course/ManageCourse';
import LoginPage from '../login/Login.page';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import { CreateEditGuardian } from '../Guardian/CreateEditGuardian';
import { ManageGuardian } from '../Guardian/ManageGuardian';
import { ManageChildren } from '../Child/ManageChildren';
import { EditChild } from '../Child/EditChild';
import { CreatePod } from '../Pod/CreatePod';
import { ManagePod } from '../Pod/ManagePod';
import ForgotPassword from '../login/ForgotPassword';
import ResetPassword from '../login/ResetPassword';
import PageNotFound from '../common/PageNotFound';
import { MyAccount } from '../dashboard/admin/MyAccount';
import ManageTypeForm from '../TypeForm/ManageTypeForm';
import CreateTypeForm from '../TypeForm/CreateTypeForm';
import CreateBlurb from '../Blurb/CreateBlurb';
import ManageBlurb from '../Blurb/ManageBlurb';
import EditTypeForm from '../TypeForm/EditTypeForm';
import CreateContent from '../Content/CreateContent';
import ManageContent from '../Content/ManageContent';
const TapoutsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route
        path="/dashboard"
        element={<AuthenticatedRoute component={AdminDashBoard} />}
      />
      <Route
        path="/create/script"
        element={<AuthenticatedRoute component={CreateScript} />}
      />
      <Route
        path="/edit/script"
        element={<AuthenticatedRoute component={CreateScript} />}
      />
      <Route
        path="/manage/script"
        element={<AuthenticatedRoute component={ManageScripts} />}
      />
      <Route
        path="/create/level"
        element={<AuthenticatedRoute component={CreateLevel} />}
      />
      <Route
        path="/manage/level"
        element={<AuthenticatedRoute component={ManageLevel} />}
      />
      <Route
        path="/edit/level"
        element={<AuthenticatedRoute component={CreateLevel} />}
      />
      <Route
        path="/create/course"
        element={<AuthenticatedRoute component={CreateCourse} />}
      />
      <Route
        path="/edit/course"
        element={<AuthenticatedRoute component={CreateCourse} />}
      />
      <Route
        path="/manage/course"
        element={<AuthenticatedRoute component={ManageCourse} />}
      />
      <Route
        path="/create/pod"
        element={<AuthenticatedRoute component={CreatePod} />}
      />
      <Route
        path="/manage/pod"
        element={<AuthenticatedRoute component={ManagePod} />}
      />
      <Route
        path="/edit/pod"
        element={<AuthenticatedRoute component={CreatePod} />}
      />
      <Route
        path="/create/session"
        element={<AuthenticatedRoute component={CreateSession} />}
      />
      <Route
        path="/create/coach"
        element={<AuthenticatedRoute component={CreateEditCoach} />}
      />
      <Route
        path="/manage/session"
        element={<AuthenticatedRoute component={ManageSession} />}
      />
      <Route
        path="/edit/coach"
        element={<AuthenticatedRoute component={CreateEditCoach} />}
      />
      <Route
        path="/edit/session"
        element={<AuthenticatedRoute component={CreateSession} />}
      />
      <Route
        path="/manage/coach"
        element={<AuthenticatedRoute component={ManageCoaches} />}
      />
      <Route
        path="/create/module"
        element={<AuthenticatedRoute component={CreateEditModule} />}
      />
      <Route
        path="/edit/module"
        element={<AuthenticatedRoute component={CreateEditModule} />}
      />
      <Route
        path="/manage/module"
        element={<AuthenticatedRoute component={ManageModule} />}
      />
      <Route
        path="/create/guardian"
        element={<AuthenticatedRoute component={CreateEditGuardian} />}
      />
      <Route
        path="/edit/guardian"
        element={<AuthenticatedRoute component={CreateEditGuardian} />}
      />
      <Route
        path="/manage/guardian"
        element={<AuthenticatedRoute component={ManageGuardian} />}
      />
      <Route
        path="/manage/children"
        element={<AuthenticatedRoute component={ManageChildren} />}
      />
      <Route
        path="/edit/children"
        element={<AuthenticatedRoute component={EditChild} />}
      />
      <Route path="*" element={<PageNotFound />} />
      <Route
        path="/myaccount"
        element={<AuthenticatedRoute component={MyAccount} />}
      />
      <Route
        path="/create/form"
        element={<AuthenticatedRoute component={CreateTypeForm} />}
      />
      <Route
        path="/manage/form"
        element={<AuthenticatedRoute component={ManageTypeForm} />}
      />
      <Route
        path="/create/blurb"
        element={<AuthenticatedRoute component={CreateBlurb} />}
      />
      <Route
        path="/edit/blurb"
        element={<AuthenticatedRoute component={CreateBlurb} />}
      />
      <Route
        path="/manage/blurb"
        element={<AuthenticatedRoute component={ManageBlurb} />}
      />
      <Route
        path="/edit/form"
        element={<AuthenticatedRoute component={CreateTypeForm} />}
      />
      <Route
        path="/edit/steps"
        element={<AuthenticatedRoute component={EditTypeForm} />}
      />
      <Route
        path="/create/content"
        element={<AuthenticatedRoute component={CreateContent} />}
      />
      <Route
        path="/edit/content"
        element={<AuthenticatedRoute component={CreateContent} />}
      />
      <Route
        path="/manage/content"
        element={<AuthenticatedRoute component={ManageContent} />}
      />
    </Routes>
  );
};

export default TapoutsRoutes;
