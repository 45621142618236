import { doc } from 'firebase/firestore';
//import SubMenu from './SubMenu';
import MenuItem from './MenuItem';
import { getDocData } from '../common/Firebase';
import { useState, useEffect } from 'react';
import { useCore } from '../core/CoreContextProvider';
import log from '../core/log';
//import { useAuth } from '../core/AuthContextProvider';

const parseMenuItem = (menuObject, roleToGet) => {
  let tmpAppMenu = null;
  if (menuObject) {
    tmpAppMenu = menuObject.get(roleToGet);
    delete tmpAppMenu.admin;
    delete tmpAppMenu.coach;
  } else {
    log(10001, '<ERROR> menuObject is empty');
    throw new Error(`Menu data was not received`);
  }
  const appMenu = [];

  Object.values(tmpAppMenu).forEach((value) => {
    if (!value.subMenuExists) {
      let tmpItem = {
        id: btoa(value.label),
        label: value.label,
        target: value.target,
        selected: value.selected,
      };
      appMenu.push(tmpItem);
    } else {
      value.subMenu.forEach((element) => {
        element.id = btoa(element.id);
      });
      let tmpSubItem = {
        id: btoa(value.label),
        label: value.label,
        target: value.target,
        selected: value.selected,
        subMenuExists: value.subMenuExists,
        subMenu: value.subMenu,
      };
      appMenu.push(tmpSubItem);
    }
  });
  if (!appMenu) throw new Error('No Menu Items Found for app');
  return appMenu;
};

export const Menu = () => {
  //const stack = process.env.REACT_APP_STACK;
  //const [subMenuShow, setSubMenuShow] = useState(false);
  const [menuItems, setMenuItems] = useState(null);
  //const menuName = process.env.REACT_APP_MENUNAME;
  const { firestore } = useCore();
  //const { userData } = useAuth();
  let menuDoc = null;
  //const menuPath = `${menuName}/${userData.role}`;
  const menuPath = 'menu/admin';
  useEffect(() => {
    menuDoc = doc(firestore, menuPath);
    getDocData(firestore, menuDoc)
      .then((value) => {
        //let menu = parseMenuItem(value, userData.role);
        let menu = parseMenuItem(value, 'admin');
        setMenuItems(menu);
      })
      .catch((err) => {
        log(10002, 'Initialization Failed. CORE CONTEXT Error : ' + err.stack);
      });
  }, []);

  //const menu = MenuContext;

  if (menuItems) {
    return (
      <nav className="flex flex-col flex-1 hidden w-full h-full space-x-1 md:flex">
        {menuItems.map((item) => {
          return <MenuItem props={item} key={btoa(item.label)} />;
        })}
      </nav>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default Menu;
