import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { updateDocument } from '../common/Firebase';
import { updatedPayload } from '../common/utils';
import { useAuth } from '../core/AuthContextProvider';
import { useCore } from '../core/CoreContextProvider';
import { toast } from 'react-toastify';
import LoadingSpinner from '../common/Loading';

function HierarchyPopup({ setToasting, popup, setPopup }) {
  const courseData = popup.displayData ? popup.displayData : null;
  const data = popup.actualData ? popup.actualData : null;
  const podCheck = popup.podCheck ?? false;
  const [loading, setLoading] = useState(false);
  const status = popup.status;
  const { firestore } = useCore();
  const { userData } = useAuth();
  const onUpdate = async () => {
    setLoading(true);
    data
      ? updatedPayload(data, 'course', 'update', userData.uid).then(
          async (payLoadData) => {
            try {
              await updateDocument(data.id, 'courses', firestore, payLoadData);
              setLoading(false);
              setPopup({ show: false, data: null });
              toast.success('Course Updated Successfully', {
                toastId: 'update_course',
                onClose: () => {
                  setToasting(false);
                },
                onOpen: () => {
                  setToasting(true);
                },
              });
            } catch (err) {
              console.log(err);
              setLoading(false);
              setPopup({ show: false, data: null });
            }
          }
        )
      : console.log('no data found');
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {popup.show ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-5/6 h-5/6 mx-auto rounded shadow-lg overflow-y-scroll">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div className="modal-close cursor-pointer">
                  <span
                    className="bg-close  block cursor-pointer"
                    onClick={() => setPopup({ show: false, data: null })}
                  ></span>
                </div>
              </div>
              <div className="p-5">
                <div className="text-center my-4 pb-4">
                  <span className="font-bold text-lg">Course Hierarchy</span>
                </div>
                {!status && (
                  <div className="text-center my-4">
                    <span className="text-red-600">
                      Please fill the incomplete branch highlighted in red to
                      change the course to active status
                    </span>
                  </div>
                )}
                <div className="flex justify-between max-w-[93.5%] mx-auto border border-gray-300 bg-indigo-50 py-2">
                  <div className="font-bold pl-16">Course</div>
                  <div className="font-bold">Level</div>
                  <div className="font-bold pl-8">Module</div>
                  <div className="font-bold pr-4">Session</div>
                  <div className="font-bold pr-28">Script</div>
                </div>
                <ul className="tree horizontal flex max-w-[93.5%] mx-auto border border-gray-300 border-t-0">
                  <li className="">
                    <div className="bg-blue-900 text-white p-2 inline-block my-1 ml-2 text-sm">
                      {courseData.course_name}
                    </div>
                    <ul className="">
                      {courseData.levels &&
                        courseData.levels.map((level, index) => {
                          return (
                            <li key={index}>
                              <div
                                className={
                                  level.level_status == 'active' &&
                                  level.modules &&
                                  level.modules.length != 0
                                    ? 'bg-green-600 text-white p-3 text-xs inline-block my-1 ml-2'
                                    : 'bg-red-600 text-white p-3 text-xs inline-block my-1 ml-2'
                                }
                                onClick={() =>
                                  (document.body.style.overflow = 'visible')
                                }
                              >
                                <NavLink
                                  to="/edit/level"
                                  state={{
                                    id: level.id,
                                  }}
                                >
                                  {level.level_id}
                                </NavLink>
                              </div>
                              <ul className="">
                                {level.modules &&
                                  level.modules.map((module, index1) => {
                                    return (
                                      <li key={index1} className=" relative">
                                        <div
                                          className={
                                            module.module_status == 'active' &&
                                            module.sessions &&
                                            module.sessions.length != 0
                                              ? 'bg-green-600 text-white p-3 text-xs inline-block my-1 ml-2'
                                              : 'bg-red-600 text-white p-3 text-xs inline-block my-1 ml-2'
                                          }
                                          onClick={() =>
                                            (document.body.style.overflow =
                                              'visible')
                                          }
                                        >
                                          <NavLink
                                            to="/edit/module"
                                            state={{
                                              id: module.id,
                                            }}
                                          >
                                            {module.module_id}
                                          </NavLink>
                                        </div>
                                        <ul className="">
                                          {module.sessions &&
                                            module.sessions.map(
                                              (session, index2) => {
                                                return (
                                                  <li
                                                    key={index2}
                                                    className=" relative"
                                                  >
                                                    <div
                                                      className={
                                                        session.session_status ==
                                                          'active' &&
                                                        session.scripts &&
                                                        session.scripts
                                                          .length != 0
                                                          ? 'bg-green-600 text-white p-3 text-xs inline-block my-1 ml-2'
                                                          : 'bg-red-600 text-white p-3 text-xs inline-block my-1 ml-2'
                                                      }
                                                      onClick={() =>
                                                        (document.body.style.overflow =
                                                          'visible')
                                                      }
                                                    >
                                                      <NavLink
                                                        to="/edit/session"
                                                        state={{
                                                          id: session.id,
                                                        }}
                                                      >
                                                        {session.session_id}
                                                      </NavLink>
                                                    </div>
                                                    <ul className="">
                                                      {session.scripts &&
                                                        session.scripts.map(
                                                          (script, index3) => {
                                                            return (
                                                              <li
                                                                key={index3}
                                                                className=" relative"
                                                              >
                                                                <div
                                                                  className={
                                                                    script.script_status ==
                                                                    'active'
                                                                      ? 'bg-green-600 text-white p-3 text-xs inline-block my-1 ml-2'
                                                                      : 'bg-red-600 text-white p-3 text-xs inline-block my-1 ml-2'
                                                                  }
                                                                  onClick={() =>
                                                                    (document.body.style.overflow =
                                                                      'visible')
                                                                  }
                                                                >
                                                                  <NavLink
                                                                    to="/edit/script"
                                                                    state={{
                                                                      id: script.id,
                                                                    }}
                                                                  >
                                                                    {
                                                                      script.script_id
                                                                    }
                                                                  </NavLink>
                                                                </div>
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                    </ul>
                                                  </li>
                                                );
                                              }
                                            )}
                                        </ul>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                </ul>

                <div className="p-5 text-center mt-48">
                  {status && !podCheck && (
                    <button
                      onClick={onUpdate}
                      className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

HierarchyPopup.propTypes = {
  popup: PropTypes.object.isRequired,
  setPopup: PropTypes.func,
  setLoading: PropTypes.func,
  setToasting: PropTypes.func,
};

export default HierarchyPopup;
