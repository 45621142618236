import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useCore } from '../core/CoreContextProvider';
import { getData } from '../common/Firebase';
import LoadingSpinner from '../common/Loading';
import ViewSession from './ViewSession';
import log from '../core/log';
import BackButton from '../common/BackButton';
import MaterialTable from '../common/MaterialTable';

export const ManageSession = () => {
  const [popup, setPopup] = useState({ show: false, data: null });
  const [loading, setLoading] = useState(true);
  const { firestore } = useCore();
  const getSessionData = async (data) => {
    try {
      if (data) {
        let tempArr1 = { ...data };
        if (data.scripts) {
          const tempArr2 = await Promise.all(
            data?.scripts.map(async (id) => {
              const result = await getData(firestore, id, 'scripts');
              return { ...result, id: id };
            })
          );
          tempArr1.scripts = tempArr2;
          console.log(tempArr1);
          setPopup({
            show: true,
            data: tempArr1,
          });
          setLoading(false);
        } else {
          tempArr1.scripts = [];
          setPopup({
            show: true,
            data: tempArr1,
          });
          setLoading(false);
        }
      } else {
        console.log('no data found');
        setLoading(false);
      }
    } catch (error) {
      log('Firebase error: while getting all sessions', error);
    }
  };

  useEffect(() => {
    getSessionData();
  }, []);

  return (
    <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
      <div className="intro-y flex items-center mt-6">
        <BackButton />
        <h2 className="text-2xl font-medium mr-auto">Manage Session</h2>
      </div>
      {loading && <LoadingSpinner />}
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="col-span-12 lg:col-span-12">
          <div className="bg-white shadow-md rounded-md">
            <div className="p-5">
              <div className="w-full mx-auto text-left flex mt-5">
                <div className="table-section w-full overflow-x-auto">
                  <MaterialTable
                    columns={[
                      {
                        title: 'SESSION ID',
                        field: 'session_id',
                      },
                      {
                        title: 'SESSION NAME',
                        sorting: false,
                        render: (sessions) => {
                          return sessions.session_name;
                        },
                      },
                      {
                        title: 'STATUS',
                        field: 'session_status',
                        sorting: false,
                        render: (sessions) => {
                          const colorClass =
                            sessions.session_status === 'active'
                              ? 'text-green-500'
                              : sessions.session_status == 'inactive'
                              ? 'text-gray-500'
                              : sessions.session_status == 'registered' ||
                                sessions.session_status == 'created'
                              ? 'text-black'
                              : 'text-blue-900';
                          return (
                            <p className={colorClass}>
                              {sessions.session_status}
                            </p>
                          );
                        },
                      },
                      {
                        title: 'LINKED SCRIPTS',
                        sorting: false,
                        render: (sessions) => {
                          return sessions.scripts ? sessions.scripts.length : 0;
                        },
                      },
                      {
                        title: 'ACTION',
                        sorting: false,
                        render: (element) => (
                          <div className="flex items-center">
                            <div
                              onClick={() => getSessionData(element)}
                              className="border p-2 mr-2 tooltip relative"
                            >
                              <span className="bg-view block cursor-pointer"></span>
                              <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[39px] after:left-[19px] after:z-[9999]">
                                View
                              </span>
                            </div>
                            <NavLink
                              to="/edit/session"
                              state={{ id: element.objectID }}
                              className="border p-2 mr-2 tooltip relative"
                            >
                              <span className="bg-edit block cursor-pointer"></span>
                              <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[35px] after:left-[14px] after:z-[9999]">
                                Edit
                              </span>
                            </NavLink>
                          </div>
                        ),
                      },
                    ]}
                    algoliaIndex={
                      process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + '_sessions'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewSession setPopup={setPopup} popup={popup} />
    </div>
  );
};
