import React, { useState, useEffect } from 'react';
import {
  addUserData,
  EmailExist,
  getConfigurationData,
  getData,
  updateFieldInDocument,
} from '../common/Firebase';
import { useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useCore } from '../core/CoreContextProvider';
import { useAuth } from '../core/AuthContextProvider';
import LoadingSpinner from '../common/Loading';
import {
  capitalizeFirstWords,
  updatedPayload,
  zipCodeApi,
} from '../common/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { guardianValidationSchema } from '../common/Validation';
import { toast } from 'react-toastify';
import ReactPhoneInput from 'react-phone-input-2';
import ChildrenDetails from './ChildrenDetails';
import BackButton from '../common/BackButton';
import { Select, Option } from '@material-tailwind/react';

export function CreateEditGuardian() {
  const { firestore } = useCore();
  const { userData } = useAuth();
  const location = useLocation();
  const { id } = location.state || {};
  const [configData, setConfigData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showChildrenDetails, setShowChildrenDetails] = useState(false);
  const [guardianDocId, setGuardianDocId] = useState('');
  const [guardianId, setGuardianId] = useState('');
  const [toasting, setToasting] = useState(false);
  const [guardianData, setGuardianData] = useState([]);

  const formOptions = { resolver: yupResolver(guardianValidationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
    watch,
    control,
  } = useForm(formOptions);

  const selectedCountry = watch('country', '');

  useEffect(() => {
    const config = async () => {
      try {
        const config = await getConfigurationData(firestore);
        if (config) {
          setConfigData(config);
        }
      } catch (err) {
        console.error(`Error while fetching config data: `, err.message);
      }
      setLoading(false);
    };
    config();
  }, []);

  useEffect(() => {
    (async () => {
      if (Object.keys(configData).length && id) {
        const result = await getData(firestore, id, 'guardian');
        reset(result);
        setShowChildrenDetails(true);
        setGuardianDocId(
          result?.objectID && result.objectID !== ''
            ? result.objectID
            : result?.id
        );
        setGuardianId(result?.guardian_id);
        setGuardianData(result);
      } else {
        reset({
          first_name: '',
          middle_name: '',
          last_name: '',
          relationship: '',
          email_address: '',
          country: '',
          zip_code: '',
          address_line_one: '',
          address_line_two: '',
          address_line_three: '',
          state: '',
          city: '',
          time_zone: '',
          guardian_status: '',
          remark: '',
        });
        setGuardianId('');
        setGuardianData([]);

        setShowChildrenDetails(false);
      }
    })();
  }, [configData, location]);

  const updateChildren = async (payload) => {
    const payLoadData = {
      guardian_name: `${payload.first_name} ${payload.middle_name} ${payload.last_name}`,
      communication_phone: payload.phone_number,
    };
    const guardianDataChild = await getData(
      firestore,
      guardianDocId,
      'guardian'
    );
    if (!guardianDataChild?.children?.length) return;
    if (
      payload.first_name !== guardianData.first_name ||
      payload.middle_name !== guardianData.middle_name ||
      payload.last_name !== guardianData.last_name ||
      payload.phone_number !== guardianData.phone_number
    ) {
      updatedPayload(payLoadData, 'children', 'update', userData.uid).then(
        async (payload) => {
          await Promise.all(
            guardianDataChild.children.map(async (id) => {
              await updateFieldInDocument(firestore, 'children', id, payload);
            })
          );
        }
      );
    }
  };

  const onSubmit = async (payload) => {
    setLoading(true);
    Object.keys(payload).map((key) => {
      payload[key] = payload[key] ?? '';
    });
    if (showChildrenDetails) {
      try {
        updatedPayload(payload, 'guardian', 'update', userData.uid).then(
          async (payLoadData) => {
            await updateFieldInDocument(
              firestore,
              'guardian',
              guardianDocId,
              payLoadData
            );

            await updateChildren(payLoadData);
            setGuardianData(payLoadData);
            setLoading(false);
            toast.success('Guardian Updated Successfully', {
              toastId: 'update_guardian',
              onClose: () => {
                setToasting(false);
              },
              onOpen: () => {
                setToasting(true);
              },
            });
          }
        );
      } catch (err) {
        console.error(
          `Error while updating guardian document : ${guardianDocId}`,
          err.message
        );
        setLoading(false);
      } finally {
        reset(payload);
      }
    } else {
      try {
        updatedPayload(payload, 'guardian', 'create', userData.uid).then(
          async (payLoadData) => {
            setGuardianId(payLoadData?.guardian_id);
            setGuardianData(payLoadData);
            await addUserData('guardian', firestore, payLoadData)
              .then((result) => {
                if (result.status) {
                  setGuardianDocId(result.id);
                  setShowChildrenDetails(true);
                  setLoading(false);
                  toast.success('Guardian Added Successfully', {
                    toastId: 'create_guardian',
                    onClose: () => {
                      setToasting(false);
                    },
                    onOpen: () => {
                      setToasting(true);
                    },
                  });
                } else {
                  setLoading(false);
                  toast.error(result.message);
                }
              })
              .catch((error) => {
                setLoading(false);
                console.log(error);
                toast.error(error);
              });
          }
        );
      } catch (err) {
        console.error(`Error while creating guardian ,`, err.message);
        setLoading(false);
      } finally {
        reset(payload);
      }
    }
  };

  const handleOnBlurZipCode = (e) => {
    setLoading(true);
    zipCodeApi(e, getValues('country'))
      .then((res) => {
        if (typeof res === 'string') {
          toast.error(res);
        }
        setValue('state', res.state);
        setValue('city', res.city);
        setValue('time_zone', res.timezone.timezone_identifier);
        setValue('time_zone_abbr', res.timezone.timezone_abbr);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setValue('state', '');
        setValue('city', '');
        setValue('time_zone_abbr', '');
        setLoading(false);
      });
  };

  const EmailValid = (emailId) => {
    emailId &&
      EmailExist(firestore, emailId)
        .then((res) => {
          if (!res.status) {
            toast.error('user email is already exist');
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton isDirty={isDirty} />
            <h2 className="text-2xl font-medium mr-auto">
              Guardian Enrollment Form
            </h2>
          </div>
          {loading && <LoadingSpinner />}
          {Object.keys(configData).length !== 0 && (
            <div className="grid grid-cols-12 gap-6 mt-5">
              <div className="col-span-12 lg:col-span-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="bg-white shadow-md rounded-md">
                    <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                      <h2 className="font-bold text-base mr-auto">
                        Guardian Details
                      </h2>
                    </div>
                    <div className="p-5">
                      <div className="preview">
                        <div className="flex flex-col sm:flex-row items-center">
                          <div className="flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.first_name?.message}
                            </span>
                            <input
                              id="first-name"
                              type="text"
                              placeholder=" "
                              className="pt-6 pb-1 block shadow-md w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              {...register('first_name')}
                            />
                            <label
                              htmlFor="first-name"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              First Name<span className="text-red-600">*</span>
                            </label>
                          </div>
                          <div className="flex-auto w-1/3 mx-4 relative">
                            <input
                              id="middle-name"
                              type="text"
                              placeholder=" "
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              {...register('middle_name')}
                            />
                            <label
                              htmlFor="middle-name"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Middle Name
                            </label>
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.last_name?.message}
                            </span>
                            <input
                              id="last-name"
                              type="text"
                              placeholder=" "
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              {...register('last_name')}
                            />
                            <label
                              htmlFor="last-name"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Last Name<span className="text-red-600">*</span>
                            </label>
                          </div>
                        </div>
                        <div className="mt-5 flex flex-col sm:flex-row items-center">
                          <div className="mr-4 flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.relationship?.message}
                            </span>
                            {configData?.relationship && (
                              <Controller
                                name={'relationship'}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      id="relationship"
                                      value={value}
                                      label="Select Relationship"
                                      variant="outlined"
                                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                        value ? ' focus-label' : ''
                                      }`}
                                    >
                                      {configData?.relationship?.map((item) => {
                                        return (
                                          <Option value={item} key={item}>
                                            {capitalizeFirstWords(item)}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  );
                                }}
                                rules={{ required: false }}
                              />
                            )}
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.email_address?.message}
                            </span>
                            <input
                              id="email-address"
                              {...register('email_address', {
                                onBlur: (e) =>
                                  EmailValid(e.target.value.trim()),
                              })}
                              type="text"
                              placeholder=" "
                              className={`pt-6 shadow-md pb-1 block w-full px-4 mt-0  border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                guardianId !== ''
                                  ? 'bg-gray-300'
                                  : 'bg-transparent'
                              }`}
                              disabled={guardianId !== ''}
                            />
                            <label
                              htmlFor="email-address"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Email Address
                              <span className="text-red-600">*</span>
                            </label>
                          </div>
                          <div className="flex-auto mx-4 w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.phone_number?.message}
                            </span>
                            <Controller
                              name={'phone_number'}
                              control={control}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <ReactPhoneInput
                                    id="phone_number"
                                    onChange={(e) => {
                                      onChange(`+${e}`);
                                    }}
                                    country={'us'}
                                    value={value}
                                    disableDropdown={true}
                                    countryCodeEditable={false}
                                  />
                                );
                              }}
                              rules={{ required: false }}
                            />
                          </div>

                          <div className="flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.country?.message}
                            </span>
                            {configData?.country && (
                              <Controller
                                name={'country'}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      id="country"
                                      value={value}
                                      label="Select Country"
                                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                        value ? ' focus-label' : ''
                                      }`}
                                    >
                                      {configData?.country?.map((item) => {
                                        return (
                                          <Option value={item} key={item}>
                                            {capitalizeFirstWords(item)}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  );
                                }}
                                rules={{ required: false }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="mt-5 flex flex-col sm:flex-row items-center">
                          <div className="mr-4 flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {!selectedCountry || selectedCountry === ''
                                ? ''
                                : errors?.zip_code?.message}
                            </span>
                            <input
                              id="guardian_zipcode"
                              type="text"
                              {...register('zip_code', {
                                required: false,
                                onBlur: (e) => {
                                  handleOnBlurZipCode(e);
                                },
                              })}
                              onKeyDown={(e) => {
                                e.keyCode === 13 && e.target.blur();
                              }}
                              placeholder=" "
                              className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 ${
                                !selectedCountry || selectedCountry === ''
                                  ? 'bg-gray-300'
                                  : 'bg-transparent'
                              } border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                              disabled={
                                !selectedCountry || selectedCountry === ''
                              }
                            />
                            <label
                              htmlFor="guardian_zipcode"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Zipcode<span className="text-red-600"></span>
                            </label>
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.address_line_one?.message}
                            </span>
                            <input
                              id="address-line-one"
                              type="text"
                              placeholder=" "
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              {...register('address_line_one')}
                            />
                            <label
                              htmlFor="address-line-one"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Building/Street
                              <span className="text-red-600"></span>
                            </label>
                          </div>
                          <div className="mx-4 flex-auto w-1/3 relative">
                            <input
                              id="address-line-two"
                              type="text"
                              placeholder=" "
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              {...register('address_line_two')}
                            />
                            <label
                              htmlFor="address-line-two"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Address Line 2
                            </label>
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <input
                              id="address-line-three"
                              type="text"
                              placeholder=" "
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              {...register('address_line_three')}
                            />
                            <label
                              htmlFor="address-line-three"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Address Line 3
                            </label>
                          </div>
                        </div>
                        <div className="mt-5 flex flex-col sm:flex-row items-center">
                          <div className="mr-4 flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.state?.message}
                            </span>
                            <input
                              id="state"
                              type="text"
                              placeholder=" "
                              className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 mt-0 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              {...register('state')}
                              disabled
                            />
                            <label
                              htmlFor="state"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              State<span className="text-red-600"></span>
                            </label>
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.city?.message}
                            </span>
                            <input
                              id="city"
                              type="text"
                              placeholder=" "
                              className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 mt-0 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              {...register('city')}
                              disabled
                            />
                            <label
                              htmlFor="city"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              City<span className="text-red-600"></span>
                            </label>
                          </div>
                          <div className="flex-auto mx-4 w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.time_zone?.message}
                            </span>
                            <input
                              id="time_zone"
                              type="text"
                              placeholder=" "
                              className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 mt-0 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              {...register('time_zone')}
                              disabled
                            />
                            <label
                              htmlFor="time_zone"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Time Zone<span className="text-red-600"></span>
                            </label>
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <div className="bg-caret_down"></div>
                            </div>
                            <span className="text-red-600 absolute -top-6">
                              {errors?.guardian_status?.message}
                            </span>
                            <select
                              {...register(
                                'guardian_status',
                                setValue('guardian_status', 'active')
                              )}
                              defaultValue="active"
                              disabled={true}
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-gray-300 border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                            >
                              <option value="active">Active</option>
                            </select>
                            <label
                              htmlFor="status"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Status<span className="text-red-600">*</span>
                            </label>
                          </div>
                        </div>
                        <div className="mt-5 flex flex-col sm:flex-row items-center">
                          <div className="flex-auto w-1/3 relative">
                            <textarea
                              id="remarks"
                              type="text"
                              placeholder=" "
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md h-40 w-full"
                              {...register('remark')}
                            />
                            <label
                              htmlFor="remarks"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Remarks
                            </label>
                          </div>
                        </div>
                        <div className="p-5 text-center">
                          <button
                            disabled={toasting}
                            className="w-50 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                          >
                            {showChildrenDetails ? 'Update' : 'Create'} Guardian
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {showChildrenDetails ? (
                  <ChildrenDetails
                    configData={configData}
                    editData={guardianData}
                    handleSetLoading={(flag) => setLoading(flag)}
                    guardianDocId={guardianDocId}
                    guardianEmail={getValues('email_address')}
                    guardianPhone={getValues('phone_number')}
                    guardianId={guardianId}
                    guardianName={`${getValues('first_name')} ${getValues(
                      'middle_name'
                    )} ${getValues('last_name')}`}
                    guardianStatus={getValues('guardian_status')}
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
