import * as yup from 'yup';

export const scriptValidationSchema = yup.object().shape({
  age_group: yup.string().required('Please select age group'),
  language: yup.string().required('Please select language'),
  script_id: yup.string().required('Please enter script ID'),
  script_name: yup.string().required('Please enter script name'),
  script_status: yup.string().required('Please select status'),
  topic: yup.string().required('Please select topic'),
});

export const sessionValidationSchema = yup.object().shape({
  script_id: yup.string().required('Please select script ID'),
  session_name: yup.string().required('Please enter session name'),
  session_status: yup.string().required('Please select status'),
  description: yup.string().required('Please provide description'),
});
export const blurbValidationSchema = yup.object().shape({
  blurb_name: yup.string().required('Please enter blurb name'),
  description: yup.string().required('Please provide description'),
});
export const contentValidationSchema = yup.object().shape({
  content_name: yup.string().required('Please enter content name'),
  description: yup.string().required('Please provide description'),
  form_name: yup.string().required('Please provide form name'),
});

export const coachValidationSchema = yup.object().shape({
  first_name: yup.string().required('Please enter first name'),
  last_name: yup.string().required('Please enter last name'),
  email_address: yup
    .string()
    .required('Please enter email address')
    .email('Please enter a valid email'),
  phone_number: yup.string().required('Please enter phone number'),
  zip_code: yup.string().required('Please enter zipcode'),
  address_line_one: yup.string().required('Please enter address'),
  time_zone_abbr: yup.string().required('Please enter valid zip code'),
  country: yup.string().required('Please select country'),
  coach_status: yup.string().required('Please select status'),
  gender: yup.string().required('Please select gender'),
  language: yup
    .array()
    .of(yup.string())
    .min(1, 'Please select at least one language'),
  age_group: yup
    .array()
    .of(yup.string())
    .min(1, 'Please select at least one age group'),
  short_bio: yup.string().required('Please enter short bio'),
  detailed_bio: yup.string().required('Please enter detailed bio'),
  state: yup.string().required('Please enter state'),
  city: yup.string().required('Please enter city'),
});

export const moduleValidationSchema = yup.object().shape({
  module_name: yup.string().required('Please enter module name'),
  module_status: yup.string().required('Please select status'),
  module_description: yup.string().required('Please enter description'),
});

export const guardianValidationSchema = yup.object().shape({
  first_name: yup.string().required('Please enter first name'),
  last_name: yup.string().required('Please enter last name'),
  email_address: yup
    .string()
    .required('Please enter email address')
    .email('Please enter a valid email'),
});

export const childrenDetailsValidationSchema = yup.object().shape({
  first_name: yup.string().required('Please enter first name'),
  last_name: yup.string().required('Please enter last name'),
  date_of_birth: yup.string().required('Please enter Date of Birth '),
  email_address: yup.string().email('Please enter a valid email'),
  child_status: yup.string().required('Please select status '),
});

export const formDetailsValidationSchema = yup.object().shape({
  form_name: yup.string().required('Please enter form heading'),
  form_description: yup.string().required('Please enter form description'),
  no_of_steps: yup.string().required('Please enter form No of Steps'),
  form_status: yup.string().required('Please enter form status '),
});
export const formStepsValidationSchema = yup.object().shape({
  type: yup.string().required('Please enter form type'),
  url_query: yup.string().required('Please enter url query'),
});

export const levelValidationSchema = yup.object().shape({
  level_name: yup.string().required('Please enter Level name'),
  level_status: yup.string().required('Please select status'),
  description: yup.string().required('Please provide description'),
});

export const CourseValidationSchema = yup.object().shape({
  course_name: yup.string().required('Please enter Course name'),
  course_status: yup.string().required('Please select status'),
  description: yup.string().required('Please provide description'),
});

export const PodValidationSchema = yup.object().shape({
  course_id: yup.string().required('Please select course name'),
  age_group: yup.string().required('Please select age group'),
  language: yup.string().required('Please select language'),
  country: yup.string().required('Please select country'),
  coach_name: yup.string().required('Please select coach'),
  zip_code: yup.string().required('Please enter zip code'),
  pod_status: yup.string().required('Please select status'),
});

export const podSchuduleSchema = yup.object().shape({
  pod_duration: yup.string().required('Please select pod duration'),
});
