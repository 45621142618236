import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAllDocuments } from '../common/Firebase';
import { useCore } from '../core/CoreContextProvider';
import LoadingSpinner from '../common/Loading';
import { NavLink } from 'react-router-dom';
function ViewModulePopup({ open, data, handleClose }) {
  const { firestore } = useCore();
  let navigate = useNavigate();

  const [allSessions, setAllSessions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleEdit = () => {
    navigate('/edit/module', { state: { id: data.id } });
  };

  useEffect(() => {
    if (open && data && data.sessions !== '') {
      setLoading(true);
      const sessions = async () => {
        try {
          const allSessions = await getAllDocuments(firestore, 'sessions');
          if (allSessions.length) {
            const sessionsData = [];
            data?.sessions?.forEach((selectedSessionKey) => {
              allSessions.forEach((session) => {
                if (selectedSessionKey === session.id) {
                  sessionsData.push(session);
                }
              });
            });
            setAllSessions(sessionsData);
          }
        } catch (err) {
          console.error(`Error while fetching sessions: `, err.message);
        }
        setLoading(false);
      };
      sessions();
    }
  }, [open, data]);

  return (
    <>
      {open ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          {loading && <LoadingSpinner />}
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-5/6 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div
                  className="modal-close cursor-pointer"
                  onClick={() => handleClose()}
                >
                  <span className="bg-close block cursor-pointer"></span>
                </div>
              </div>

              <div className="p-5 float-left w-full border">
                <div className="preview max-w-5xl mx-auto">
                  <div className="flex w-1/2 float-left mt-5">
                    <label className="text-black w-40 px-4 font-bold">
                      Module ID
                    </label>
                    <div className="mt-0 bg-transparent">{data?.module_id}</div>
                  </div>
                  <div className="flex w-1/2 float-left mt-5">
                    <label className="text-black w-40 px-4 font-bold">
                      Module Name
                    </label>
                    <div className="mt-0 bg-transparent">
                      {data?.module_name}
                    </div>
                  </div>
                  <div className="flex w-1/2 float-left mt-5">
                    <label className="text-black w-40 px-4 font-bold">
                      Status
                    </label>
                    <div className="mt-0 bg-transparent">
                      {data?.module_status}
                    </div>
                  </div>

                  <div className="flex w-1/2 float-left mt-5">
                    <label className="text-black w-40 px-4 font-bold">
                      Description
                    </label>
                    <div className="mt-0 bg-transparent">
                      {data?.module_description}
                    </div>
                  </div>
                </div>
                <div className="mt-5 float-left w-full">
                  <div className="text-center mt-5 text-xl font-medium">
                    Added Sessions
                  </div>
                  <div className="p-5 pt-0 max-w-5xl mx-auto">
                    <div className="w-full mx-auto my-0 text-left flex mt-5">
                      <div className="table-section w-full max-h-[250px] overflow-y-auto">
                        <table className="min-w-full border" id="table">
                          <thead className="">
                            <tr>
                              <th
                                data-type="number"
                                scope="col"
                                className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                              >
                                Session ID
                              </th>
                              <th
                                scope="col"
                                className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                              >
                                Session Name
                              </th>
                              <th
                                scope="col"
                                className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allSessions?.length
                              ? allSessions.map((sess) => {
                                  return (
                                    <tr
                                      key={sess.id}
                                      className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                    >
                                      <td className="mt-0 bg-transparent text-blue-900 font-light whitespace-nowrap cursor-pointer underline px-6">
                                        <NavLink
                                          to="/edit/session"
                                          state={{ id: sess.id }}
                                        >
                                          {sess?.session_id}
                                        </NavLink>
                                      </td>
                                      <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {sess?.session_name}
                                      </td>
                                      <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {sess?.session_status}
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-5 text-center">
              <button
                className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                onClick={handleEdit}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

ViewModulePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

export default React.memo(ViewModulePopup);
