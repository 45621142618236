import React, { useEffect, useState } from 'react';
import { CourseValidationSchema } from '../common/Validation';
import { AddedLevels } from './AddedLevels';
import HierarchyPopup from './HierarchyPopup';
import LoadingSpinner from '../common/Loading';
import { ManageCourseLevels } from './ManageCourseLevels';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCore } from '../core/CoreContextProvider';
import { useAuth } from '../core/AuthContextProvider';
import BackButton from '../common/BackButton';
import PropTypes from 'prop-types';
import {
  getConfigurationData,
  updateFieldInDocument,
  addDocument,
  getData,
} from '../common/Firebase';
import { useLocation } from 'react-router-dom';
import {
  updatedPayload,
  findCourse,
  capitalizeFirstWords,
} from '../common/utils';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Select, Option } from '@material-tailwind/react';

export const CreateCourse = () => {
  const [configs, setConfigs] = useState({});
  const [tab, setTab] = useState('default');
  const location = useLocation();
  const id = location?.state?.id ? location?.state?.id : '';
  const [loading, setLoading] = useState(true);
  const [toasting, setToasting] = useState(false);
  const [courseData, setCourseData] = useState({});
  const { firestore } = useCore();
  const { userData } = useAuth();
  const [showAddLevels, setShowAddLevels] = useState(false);
  const initialAddedLevelState = {
    displayContent: [],
    actualContent: [],
  };
  const [addedLevels, setAddedLevels] = useState(initialAddedLevelState);
  const [courseId, setCourseId] = useState('');
  const [popup, setPopup] = useState({ show: false, data: null });
  const formOptions = { resolver: yupResolver(CourseValidationSchema) };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isDirty },
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    if (courseId != '') data.id = courseId;
    data.levels = addedLevels?.actualContent ? addedLevels?.actualContent : [];
    setLoading(true);
    if (showAddLevels && courseId != '') {
      if (data.course_status == 'active') {
        const { courseData, status } = await findCourse(firestore, courseId);
        setPopup({
          show: true,
          displayData: courseData,
          actualData: data,
          status: status,
        });
        setLoading(false);
      } else {
        updatedPayload(data, 'course', 'update', userData.uid)
          .then(async (payLoadData) => {
            await updateFieldInDocument(
              firestore,
              'courses',
              courseId,
              payLoadData
            );
            toast.success('Course Updated Successfully', {
              toastId: 'update_course',
              onClose: () => {
                setToasting(false);
              },
              onOpen: () => {
                setToasting(true);
              },
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
        reset(data);
      }
    } else {
      updatedPayload(data, 'course', 'create', userData.uid).then(
        async (payLoadData) => {
          const levelDocumentId = await addDocument(
            'courses',
            firestore,
            payLoadData
          );

          setCourseId(levelDocumentId);
          setLoading(false);
          toast.success('Course Added Successfully', {
            toastId: 'create_guardian',
            onClose: () => {
              setToasting(false);
            },
            onOpen: () => {
              setToasting(true);
            },
          });
          setShowAddLevels(true);
          reset(payLoadData);
        }
      );
    }
  };

  useEffect(() => {
    popup.show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [popup]);
  useEffect(() => {
    const getConfigData = async () => {
      const data = await getConfigurationData(firestore);
      if (data) setConfigs(data);
      setLoading(false);
    };
    getConfigData();
    setShowAddLevels(false);
  }, []);

  const getCourseData = async (id) => {
    const courseData = await getData(firestore, id, 'courses');
    if (courseData) {
      reset(courseData);
      setCourseId(courseData.id);
      setCourseData(courseData);
      let levelContent = [];
      let levelIds = [];
      courseData.levels &&
        (await Promise.all(
          courseData.levels.map(async (level) => {
            if (level.id) {
              levelIds.push(level.id);
              levelContent.push(level);
            } else {
              const levelData = await getData(firestore, level, 'levels');
              levelContent.push(levelData);
              levelIds.push(level);
            }
          })
        ));
      setAddedLevels({
        ...addedLevels,
        displayContent: levelContent,
        actualContent: levelIds,
      });
      setShowAddLevels(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCourseData(id);
    } else {
      reset({ course_name: '', course_status: '', description: '' });
      setCourseData({});
      setCourseId('');
      setShowAddLevels(false);
      setCourseId('');
      setAddedLevels(initialAddedLevelState);
    }
  }, [configs, id, location]);

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton isDirty={isDirty} />
            <h2 className="text-2xl font-medium mr-auto">
              {courseId != '' ? 'Edit' : 'Create'} Course
            </h2>
          </div>
          {loading && <LoadingSpinner />}
          {Object.keys(configs).length !== 0 && (
            <div className="grid grid-cols-12 gap-6 mt-5">
              <div className="col-span-12 lg:col-span-12">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="bg-white shadow-md rounded-md"
                >
                  <div className="p-5">
                    <div className="preview">
                      <div className="mt-5 mr-2 flex flex-col sm:flex-row items-center">
                        <div className="flex-auto w-1/3 relative ">
                          <span className="text-red-600 absolute -top-6">
                            {errors?.course_name?.message}
                          </span>
                          <input
                            type="text"
                            {...register('course_name')}
                            placeholder=" "
                            id="course_name"
                            className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                          />
                          <label
                            htmlFor="course_name"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Course Name
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                        {courseData?.course_id && (
                          <div className="w-1/3 relative mx-2">
                            <input
                              id="course_id"
                              type="text"
                              className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-gray-200 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              placeholder=" "
                              value={courseData.course_id}
                              disabled={true}
                            />
                            <label
                              htmlFor="course_id"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Course Id
                            </label>
                          </div>
                        )}
                        <div
                          className={`${
                            !courseData?.course_id && 'ml-2'
                          } flex-auto w-1/3 relative`}
                        >
                          <span className="text-red-600 absolute -top-6">
                            {errors?.course_status?.message}
                          </span>
                          {configs?.course_status && (
                            <Controller
                              name={'course_status'}
                              control={control}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <Select
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    id="course_status"
                                    value={value}
                                    label="Select Course Status*"
                                    variant="outlined"
                                    className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                      value ? ' focus-label' : ''
                                    }`}
                                  >
                                    {configs.course_status?.map((status) => {
                                      return (
                                        <Option
                                          key={status}
                                          value={status}
                                          disabled={
                                            addedLevels?.actualContent.length ==
                                              0 &&
                                            (status == 'active' ||
                                              status == 'inactive')
                                          }
                                        >
                                          {capitalizeFirstWords(status)}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                );
                              }}
                              rules={{ required: true }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="mt-8 flex flex-col sm:flex-row items-center">
                        <div className="flex-auto w-full relative">
                          <span className="text-red-600 absolute -top-6">
                            {errors?.description?.message}
                          </span>
                          <textarea
                            {...register('description')}
                            type="text"
                            id="description"
                            placeholder=" "
                            className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md h-40"
                          ></textarea>
                          <label
                            htmlFor="description"
                            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                          >
                            Description
                            <span className="text-red-600">*</span>
                          </label>
                        </div>
                      </div>
                      <div className="p-5 text-center">
                        <button
                          disabled={toasting}
                          className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                        >
                          {courseId != '' ? 'Update' : 'Create'}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="bg-white shadow-md rounded-md mt-5">
                  <div className="p-5">
                    <div className="preview">
                      <div className="tabs-section">
                        <ul
                          id="tabs"
                          className="inline-flex pt-2 w-full border-b"
                        >
                          <li
                            onClick={() => setTab('default')}
                            className={
                              'px-5 text-gray-800 font-semibold py-3 rounded-t cursor-pointer ' +
                              (tab === 'default'
                                ? 'bg-white border-t border-r border-l -mb-px'
                                : '')
                            }
                          >
                            <a id="default-tab">Add Level</a>
                          </li>
                          <li
                            onClick={() => setTab('levels')}
                            className={
                              'px-5 text-gray-800 font-semibold py-3 rounded-t cursor-pointer ' +
                              (tab === 'levels'
                                ? 'bg-white border-t border-r border-l -mb-px'
                                : '')
                            }
                          >
                            <a>Level</a>
                          </li>
                        </ul>
                        <div id="tab-contents" className="border border-t-0">
                          <div
                            id="first"
                            className={
                              'p-5 ' + (tab === 'default' ? '' : 'hidden')
                            }
                          >
                            <ManageCourseLevels
                              showAddLevels={showAddLevels}
                              setAddedLevels={setAddedLevels}
                              addedLevels={addedLevels}
                              setLoading={setLoading}
                              courseId={courseId}
                            />
                          </div>
                          <div
                            id="second"
                            className={
                              'p-5 ' + (tab === 'levels' ? '' : 'hidden')
                            }
                          >
                            <AddedLevels
                              addedLevels={addedLevels}
                              setAddedLevels={setAddedLevels}
                              courseId={courseId}
                              setLoading={setLoading}
                              setValue={setValue}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <HierarchyPopup
        setToasting={setToasting}
        popup={popup}
        setPopup={setPopup}
      />
    </div>
  );
};
CreateCourse.propTypes = {
  control: PropTypes.any,
};
