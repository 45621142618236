import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { useCore } from './CoreContextProvider.js';

import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from 'firebase/auth';
import log from './log';
import { useNavigate } from 'react-router';
import { doc, setDoc } from '@firebase/firestore';
export const AuthContext = React.createContext({});
import { getData } from '../common/Firebase.js';
import { lookup } from 'country-data-list';

const AuthContextProvider = ({ children }) => {
  const { auth, firestore } = useCore();
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const navigation = useNavigate();
  const [countryCode, setCountryCode] = useState('en-US');

  const signIn = async (email, password) => {
    const result = await signInWithEmailAndPassword(auth, email, password);
    if (result) {
      const token = await result.user.getIdTokenResult();
      if (!token.claims || token.claims.role != 'admin') {
        setCurrentUser(null);
        setUserData(null);
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const logOut = () => {
    if (auth) {
      signOut(auth);
      navigation('/');
    } else {
      log(40001, 'SORRY NO AUTH OBJECT' + currentUser);
    }
  };

  const forgotpassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const resetPassword = (oobCode, newPassword) => {
    return confirmPasswordReset(auth, oobCode, newPassword);
  };

  const getLocaleCode = async (uid) => {
    try {
      const profile = await getData(firestore, uid, 'profile');
      if (profile?.locale) {
        setCountryCode(profile?.locale);
      }
    } catch (err) {
      console.log('Error in getting locale' + err.message);
    }
  };

  const addLocale = async (country) => {
    try {
      if (country) {
        const countryData = lookup.countries({
          name: country.toLowerCase(),
        })[0];
        console.log(countryData);
        const lang = countryData.languages.includes('eng')
          ? 'en'
          : countryData.languages[0].slice(0, 2);
        const code = lang + '-' + countryData.alpha2;
        await setDoc(doc(firestore, 'profile', userData?.uid), {
          country: country,
          locale: code,
        });

        setCountryCode(code);
      }
    } catch (err) {
      console.log('Error in adding locale' + err.message);
    }
  };

  useEffect(() => {
    let tmpUserData = null;
    const unSubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((tokenResult) => {
          tmpUserData = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            role: tokenResult.claims.role,
          };
          if (tokenResult.claims && tokenResult.claims.role === 'admin') {
            setUserData(tmpUserData);
            getLocaleCode(tmpUserData.uid);
            setCurrentUser(user);
          }
        });
      }
      setAuthLoading(false);
    });
    return unSubscribe;
  }, []);

  const value = {
    currentUser,
    userData,
    signIn,
    logOut,
    resetPassword,
    authLoading,
    setAuthLoading,
    forgotpassword,
    setCurrentUser,
    addLocale,
    getLocaleCode,
    countryCode,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContextProvider;
