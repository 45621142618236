import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ViewLevel } from './ViewLevel';
import BackButton from '../common/BackButton';
import MaterialTable from '../common/MaterialTable';

export const ManageLevel = () => {
  const [popup, setPopup] = useState({ show: false, data: null });

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton />
            <h2 className="text-2xl font-medium mr-auto">Manage Level</h2>
          </div>
          <div className="grid grid-cols-12 gap-6 mt-5">
            <div className="col-span-12 lg:col-span-12">
              <div className="bg-white shadow-md rounded-md">
                <div className="p-5">
                  <div className="w-full mx-auto text-left flex mt-5">
                    <div className="table-section w-full">
                      <MaterialTable
                        columns={[
                          {
                            title: 'LEVEL ID',
                            field: 'level_id',
                          },
                          {
                            title: 'LEVEL NAME',
                            sorting: false,
                            render: (levels) => {
                              return levels.level_name;
                            },
                          },
                          {
                            title: 'STATUS',
                            field: 'level_status',
                            sorting: false,
                            render: (levels) => {
                              const colorClass =
                                levels.level_status === 'active'
                                  ? 'text-green-500'
                                  : levels.level_status == 'inactive'
                                  ? 'text-gray-500'
                                  : levels.level_status == 'registered' ||
                                    levels.level_status == 'created'
                                  ? 'text-black'
                                  : 'text-blue-900';
                              return (
                                <p className={colorClass}>
                                  {levels.level_status}
                                </p>
                              );
                            },
                          },
                          {
                            title: 'LINKED MODULES',
                            sorting: false,
                            render: (levels) => {
                              return levels.modules ? levels.modules.length : 0;
                            },
                          },
                          {
                            title: 'ACTION',
                            sorting: false,
                            render: (element) => (
                              <div className="flex items-center">
                                <div
                                  onClick={() =>
                                    setPopup({
                                      show: true,
                                      data: element,
                                    })
                                  }
                                  className="border p-3 mr-2 tooltip relative"
                                >
                                  <span className="bg-view block cursor-pointer"></span>
                                  <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[39px] after:left-[19px] after:z-[9999]">
                                    View
                                  </span>
                                </div>
                                <NavLink
                                  to="/edit/level"
                                  state={{ id: element.objectID }}
                                  className="border p-3 mr-2 tooltip relative"
                                >
                                  <span className="bg-edit block cursor-pointer"></span>
                                  <span className="tool absolute -top-12 bg-yellow-400 font-bold rounded-full text-white p-2 -left-1 hidden after:content-[''] after:h-0 after:w-0 after:border-t-yellow-400 after:border-b-transparent after:border-x-transparent  after:border-r-8 after:border-yellow-400 after:border-8 after:absolute after:top-[35px] after:left-[14px] after:z-[9999]">
                                    Edit
                                  </span>
                                </NavLink>
                              </div>
                            ),
                          },
                        ]}
                        algoliaIndex={
                          process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + '_levels'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewLevel popup={popup} setPopup={setPopup} />
    </div>
  );
};
