import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllDocuments } from '../common/Firebase';
import LoadingSpinner from '../common/Loading';
import { addSubsMinutes } from '../common/utils';
import { useCore } from '../core/CoreContextProvider';

export const ScheduleSelectCoach = ({
  coaches,
  setCoaches,
  podData,
  setSelectedCoach,
}) => {
  const { firestore } = useCore();
  const searchCoachRef = useRef('');
  const [searchedCoaches, setSearchedCoaches] = useState([]);
  const [coachAvailabilityData, setCoachAvailabilityData] = useState(null);
  const [loading, setLoading] = useState(false);

  const addCoach = (name, id, uid, availability) => {
    if (!availability) return;
    setSelectedCoach(name, id, uid, coaches.index, coaches.type);
    setCoaches({ ...coaches, show: false });
  };

  const searchCoach = () => {
    if (searchCoachRef.current.value != '') {
      let searchedCoach = searchCoachRef.current.value;
      const coachesData = coaches.data.filter((res) => {
        return res.first_name
          .toLowerCase()
          .includes(searchedCoach.toLowerCase().trim());
      });
      coachesData.length
        ? setSearchedCoaches(coachesData)
        : toast.error('no coaches found');
    }
  };

  useEffect(() => {
    if (coaches?.data?.length) {
      setSearchedCoaches(coaches.data);
      findAvailableCoaches(
        coaches.day,
        coaches.start_time,
        coaches.data,
        coaches.scheduled_date
      );
    }
  }, [coaches]);

  useEffect(() => {
    if (coachAvailabilityData) {
      const tempSearchedCoaches = [...searchedCoaches];
      tempSearchedCoaches.forEach((coach, index) => {
        coachAvailabilityData.forEach((availableCoach) => {
          if (availableCoach.coach_id === coach.uid) {
            tempSearchedCoaches[index].availability =
              availableCoach.availability;
          }
        });
      });

      setSearchedCoaches(tempSearchedCoaches);
    }
  }, [coachAvailabilityData]);

  const findAvailableCoaches = async (
    day,
    startTime,
    coachesAvailable,
    date
  ) => {
    setLoading(true);
    const endTime = addSubsMinutes(startTime, podData?.pod_duration, 'add');

    const semiAvailableCoaches =
      coachesAvailable.length &&
      coachesAvailable
        .map((coach) => coach.uid)
        .filter((coachId) => coachId !== undefined);

    const timeSlotStartTime = addSubsMinutes(startTime, '15', 'substract');
    const timeSlotEndTime = addSubsMinutes(endTime, '15', 'add');
    try {
      const data = await getAllDocuments(firestore, 'coach_schedule');
      const filteredCoaches = data.filter((coachData) =>
        semiAvailableCoaches.includes(coachData.coach_id)
      );

      const result = [
        {
          coach_id: '',
          overall: '',
          specific: [
            {
              date: '',
              day: '',
              status: '',
            },
          ],
        },
      ];

      filteredCoaches.forEach((coaches, index) => {
        result[index] = {
          coach_id: coaches.coach_id,
          assigned_pod: coaches.assigned_pod,
        };
        let overallFlag = false;
        for (let key in coaches.overall_availability) {
          if (key === day) {
            result[index]['day'] = day;
            coaches.overall_availability[key].length
              ? coaches.overall_availability[key].forEach((time) => {
                  if (
                    time.start_time <= timeSlotStartTime &&
                    time.end_time >= timeSlotEndTime
                  ) {
                    overallFlag = true;
                    result[index]['overall'] = 'A';
                  }
                })
              : (result[index]['overall'] = 'NA');
          }
        }
        if (!overallFlag) {
          result[index]['overall'] = 'NA';
          return;
        }

        if (coaches.assigned_pod) {
          coaches.assigned_pod.forEach((pod) => {
            if (pod.day[0] === day) {
              if (
                (pod.start_time >= timeSlotStartTime &&
                  pod.end_time <= timeSlotEndTime) ||
                (timeSlotEndTime > pod.start_time &&
                  timeSlotStartTime < pod.end_time)
              ) {
                result[index]['overall'] = 'NA';
              }
            }
          });
        }

        coaches.specific_availability?.length
          ? coaches.specific_availability.forEach((data, index1) => {
              if (data.date === date) {
                let timingsFlag = false;
                data.timings.forEach((time) => {
                  if (
                    time.start_time <= timeSlotStartTime &&
                    time.end_time >= timeSlotEndTime
                  ) {
                    timingsFlag = true;
                    if (!result[index]['specific']) {
                      result[index]['specific'] = [
                        {
                          day,
                          date: data.date,
                          status: 'A',
                        },
                      ];
                    } else {
                      result[index]['specific'][index1] = {
                        day,
                        date: data.date,
                        status: 'A',
                      };
                    }
                  }
                });
                if (!timingsFlag) {
                  if (!result[index]['specific']) {
                    result[index]['specific'] = [
                      {
                        day,
                        date: data.date,
                        status: 'NA',
                      },
                    ];
                  } else {
                    result[index]['specific'][index1] = {
                      day,
                      date: data.date,
                      status: 'NA',
                    };
                  }
                }
              }
            })
          : '';
      });

      const showData = [
        {
          coach_id: '',
          availability: false,
        },
      ];

      result.forEach((el, index) => {
        if (el.overall === 'NA') {
          showData[index] = {
            coach_id: el.coach_id,
            day: el.day,
            availability: false,
          };
        } else if (el.overall === 'A' && !el.specific) {
          showData[index] = {
            coach_id: el.coach_id,
            day: el.day,
            availability: true,
          };
        } else if (el.overall === 'A' && el.specific.length) {
          showData[index] = {
            coach_id: el.coach_id,
            day: el.day,
            availability: el.specific[0].status === 'NA' ? false : true,
          };
        }
      });
      setLoading(false);
      setCoachAvailabilityData(showData);
    } catch (err) {
      setLoading(false);
      console.error(`Error while fetching coaches: `, err.message);
    }
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {coaches.show ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-5/6 h-5/6 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div
                  className="modal-close cursor-pointer"
                  onClick={() => setCoaches({ ...coaches, show: false })}
                >
                  <span className="bg-close block cursor-pointer"></span>
                </div>
              </div>
              <div className="p-5">
                <div className="preview">
                  <div className="mt-5 flex flex-col sm:flex-row items-center">
                    <div className="flex w-1/2 mx-auto relative z-0 border border-b-2 rounded-md">
                      <input
                        type="text"
                        id="get-coach"
                        ref={searchCoachRef}
                        onChange={() => setSearchedCoaches(coaches.data)}
                        className="pt-6 pb-1 block w-full px-4 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200"
                      />
                      <label
                        htmlFor="get-coach"
                        className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                      >
                        Get Coach
                      </label>
                      <div
                        onClick={searchCoach}
                        className="flex items-center justify-center px-4 border-l"
                      >
                        <div className="bg-search"></div>
                      </div>
                    </div>
                  </div>
                  <div className="w-3/4 mx-auto my-0 text-left flex mt-5">
                    <div className="table-section w-full">
                      <table
                        className="min-w-full border border-b-0"
                        id="table"
                      >
                        <thead className="">
                          <tr>
                            <th
                              scope="col"
                              className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                            >
                              Coach ID
                            </th>
                            <th
                              scope="col"
                              className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                            >
                              Coach Name
                            </th>
                            <th
                              scope="col"
                              className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                            >
                              Gender
                            </th>
                            <th
                              scope="col"
                              className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>

                        {searchedCoaches.length != 0 &&
                          searchedCoaches.map((res) => {
                            return (
                              <tbody key={res.id}>
                                <tr className="bg-white transition duration-300 ease-in-out">
                                  <td className="text-blue-900 font-light p-3 whitespace-nowrap cursor-pointer underline">
                                    <NavLink
                                      to="/edit/coach"
                                      state={{ id: res.id }}
                                    >
                                      {res.coach_id}
                                    </NavLink>
                                  </td>
                                  <td className="text-gray-900 font-light p-6 whitespace-nowrap">
                                    {res.first_name}
                                  </td>
                                  <td className="text-gray-900 font-light p-6 whitespace-nowrap">
                                    {res.gender}
                                  </td>
                                  <td className="text-gray-900 font-light p-6 whitespace-nowrap">
                                    <a
                                      className={` ${
                                        !res.availability
                                          ? 'text-red-600 cursor-not-allowed'
                                          : 'text-blue-600 cursor-pointer hover:text-green-500 '
                                      }`}
                                      onClick={() =>
                                        addCoach(
                                          res.first_name +
                                            ' ' +
                                            res.middle_name +
                                            ' ' +
                                            res.last_name,
                                          res.id,
                                          res.uid,
                                          res.availability
                                        )
                                      }
                                    >
                                      {res.availability
                                        ? 'Add'
                                        : 'Not Available'}
                                    </a>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan="7">
                                    <div className="flex items-center border-b-2 justify-center py-3 pb-6">
                                      <div
                                      // key={index}
                                      >
                                        {res.availability ? (
                                          <span className="bg-green-600">
                                            Available
                                          </span>
                                        ) : (
                                          <span className="bg-red-600">
                                            Not Available
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

ScheduleSelectCoach.propTypes = {
  coaches: PropTypes.object.isRequired,
  setCoaches: PropTypes.func,
  podData: PropTypes.object,
  setSelectedCoach: PropTypes.func,
};
