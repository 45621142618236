import React, { useState, useEffect } from 'react';
import {
  addUserData,
  getAllDocuments,
  getData,
  updateDocument,
  updateFieldInDocument,
  EmailExist,
} from '../common/Firebase';
import { useLocation, NavLink } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useCore } from '../core/CoreContextProvider';
import { useAuth } from '../core/AuthContextProvider';
import {
  calculateAge,
  updatedPayload,
  zipCodeApi,
  getLimit,
  capitalizeFirstWords,
} from '../common/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { childrenDetailsValidationSchema } from '../common/Validation';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ConfirmationPopup from '../common/ConfirmationPopup';
import ReactPhoneInput from 'react-phone-input-2';
import { Select, Option } from '@material-tailwind/react';
function ChildrenDetails({
  configData,
  editData,
  guardianDocId,
  handleSetLoading,
  guardianEmail,
  guardianPhone,
  guardianId,
  guardianName,
  guardianStatus,
}) {
  const { firestore } = useCore();
  const { userData } = useAuth();
  const location = useLocation();
  const { data } = location.state || {};
  const [addedChildren, setAddedChildren] = useState([]);
  const [isChildEdited, setIsChildEdited] = useState(false);
  const [isUsernameEmpty, setIsUsernameEmpty] = useState(false);
  const [activeChildren, setActiveChildren] = useState([]);
  const [age, setAge] = useState(null);
  const [selectedChildrenIds, setSelectedChildrenIds] = useState([]);
  const [toasting, setToasting] = useState(false);
  const [tab, setTab] = useState('default');
  const [popup, setPopup] = useState({
    show: false,
    message: 'Do you really want to unlink this children?',
    id: null,
  });

  const formOptions = {
    resolver: yupResolver(childrenDetailsValidationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
    setValue,
    control,
  } = useForm(formOptions);

  const selectedCountry = watch('country', '');
  const userName = watch('user_name');
  const child_status = watch('child_status');

  const onSubmit = async (payload) => {
    if (
      payload.email_address === '' &&
      (payload.user_name === undefined || payload.user_name === '')
    ) {
      // show username field if email address are not entered
      const userName = `${payload.first_name}.${payload.last_name}`;
      EmailExist(firestore, `${window.btoa(userName)}@tapouts.com`).then(
        (res) => {
          if (!res.status) {
            const randomThreeDigit = Math.floor(
              Math.random() * (999 - 100 + 1) + 100
            );
            setValue(
              'user_name',
              `${payload.first_name}.${payload.last_name}${randomThreeDigit}`
            );
          } else {
            setValue('user_name', `${payload.first_name}.${payload.last_name}`);
          }
        }
      );

      setIsUsernameEmpty(true);
      return;
    }
    handleSetLoading(true);
    const tempPayload = { ...payload };
    tempPayload.communication_email = guardianEmail;
    tempPayload.communication_phone = guardianPhone;
    tempPayload.subscription_status = tempPayload.scholarship
      ? 'scholarship'
      : payload.subscription_status
      ? payload.subscription_status
      : '';
    if (
      !tempPayload.scholarship &&
      tempPayload.subscription_status == 'scholarship'
    ) {
      tempPayload.subscription_status = 'active';
    }
    tempPayload.subscription_status = tempPayload.scholarship
      ? 'scholarship'
      : payload.subscription_status
      ? payload.subscription_status
      : '';
    Object.keys(tempPayload).map((key) => {
      tempPayload[key] = tempPayload[key] ?? '';
    });

    if (isChildEdited) {
      try {
        updatedPayload(tempPayload, 'children', 'update', userData.uid).then(
          async (payLoadData) => {
            await updateFieldInDocument(
              firestore,
              'children',
              payload.id,
              payLoadData
            );

            const resetPayload = {};
            for (let key in payload) {
              resetPayload[key] = '';
            }
            reset(resetPayload);
            setAge(null);
            const tempAddedChildren = [...addedChildren];
            const index = tempAddedChildren.findIndex(
              (child) => child.id === payload.id
            );
            tempAddedChildren[index] = payload;
            setAddedChildren(tempAddedChildren);
            updateGuardian(tempAddedChildren);
            setIsChildEdited(false);
            handleSetLoading(false);
            toast.success('Child Updated Successfully', {
              toastId: 'update_child',
              onClose: () => {
                setToasting(false);
              },
              onOpen: () => {
                setToasting(true);
              },
            });
          }
        );
      } catch (err) {
        console.error(
          `Error while updating child document : ${payload.id}`,
          err.message
        );
        handleSetLoading(false);
      }
    } else {
      //if email address is not entered then use encoded username@tapouts.com as email address
      if (payload.email_address === '') {
        tempPayload.email_address = `${window.btoa(
          payload.user_name
        )}@tapouts.com`;
      }
      tempPayload.guardian_id = guardianId;
      tempPayload.guardian_name = guardianName;
      try {
        updatedPayload(tempPayload, 'children', 'create', userData.uid).then(
          async (newPayload) => {
            await addUserData('children', firestore, newPayload)
              .then((result) => {
                if (result.status) {
                  setAddedChildren([
                    ...addedChildren,
                    { ...newPayload, id: result.id },
                  ]);
                  updateGuardian([
                    ...addedChildren,
                    { ...newPayload, id: result.id },
                  ]);
                  reset();
                  setAge(null);
                  toast.success('Child Added Successfully', {
                    toastId: 'add_child',
                    onClose: () => {
                      setToasting(false);
                    },
                    onOpen: () => {
                      setToasting(true);
                    },
                  });
                } else {
                  toast.error(result.message);
                }
                handleSetLoading(false);
              })
              .catch((error) => {
                handleSetLoading(false);
                console.log(error);
              });
          }
        );
      } catch (err) {
        console.error(`Error while creating child document: `, err.message);
        handleSetLoading(false);
      }
    }
    setIsUsernameEmpty(false);
  };

  const handleRemoveChild = async (child) => {
    handleSetLoading(true);
    const filterAddedChildren = addedChildren.filter(
      (item) => item.id !== child.id
    );
    setAddedChildren(filterAddedChildren);
    updateGuardian(filterAddedChildren);
    const tempChild = { ...child };
    delete tempChild.guardian_id;
    delete tempChild.guardian_name;
    delete tempChild.communication_email;
    delete tempChild.communication_phone;
    try {
      updatedPayload(tempChild, 'children', 'update', userData.uid).then(
        async (payLoadData) => {
          await updateDocument(child.id, 'children', firestore, payLoadData);
          setPopup({
            ...popup,
            show: false,
            id: null,
          });
          handleSetLoading(false);
          setActiveChildren((prevChild) => [...prevChild, child]);
          toast.success(
            `Child ${child.children_id}: ${child.first_name} has been unlinked!`
          );
        }
      );
    } catch (err) {
      console.error(
        `Error while unlinking children : ${child.id}`,
        err.message
      );
    }
    handleSetLoading(false);
  };

  useEffect(() => {
    handleSetLoading(false);
    const children = async () => {
      const findActiveChild = [];
      try {
        const data = await getAllDocuments(firestore, 'children');
        if (data) {
          data.forEach((child) => {
            if (!child.guardian_id) {
              findActiveChild.push(child);
            }
          });
          setActiveChildren(findActiveChild);
        }
      } catch (err) {
        console.error(`Error while fetching children: `, err.message);
      }
      handleSetLoading(false);
    };
    children();
  }, []);

  const updateGuardian = async (addedChildren) => {
    try {
      updatedPayload(
        { children: [...addedChildren.map((child) => child.id)] },
        'guardian',
        'update',
        userData.uid
      ).then(async (payLoadData) => {
        await updateFieldInDocument(
          firestore,
          'guardian',
          guardianDocId === '' ? data?.id : guardianDocId,
          payLoadData
        );
      });
    } catch (err) {
      console.error(
        `Error while updating guardian document: ${
          guardianDocId === '' ? data?.id : guardianDocId
        }`,
        err.message
      );
    }
  };

  useEffect(() => {
    if (editData) {
      delete Object.assign(editData, { ['id']: editData['objectID'] })[
        'objectID'
      ];
      const childrenData = [];
      const children = async () => {
        try {
          await Promise.all(
            editData.children?.map(async (childId) => {
              const child = await getData(firestore, childId, 'children');
              child && childrenData.push({ ...child, id: childId });
            })
          );
          setAddedChildren(childrenData);
        } catch (err) {
          console.error(`Error while fetching child document`, err.message);
        }
      };
      children();
    }
  }, [editData]);

  const handleEditChild = (child) => {
    setAge(calculateAge(child.date_of_birth));
    console.log(child);
    reset(child);
    setIsChildEdited(true);
  };

  const handleSelectAllCheckbox = (e) => {
    const { checked } = e.target;
    if (!checked) {
      setSelectedChildrenIds([]);
    } else {
      setSelectedChildrenIds(activeChildren.map((child) => child.id));
    }
  };

  const handleSelectOneCheckbox = (e) => {
    const { value, checked } = e.target;

    let tempArr = [...selectedChildrenIds];
    if (checked) {
      tempArr.push(value);
      setSelectedChildrenIds(tempArr);
    } else {
      let index = tempArr.indexOf(value);
      index != -1 ? tempArr.splice(index, 1) : '';
      setSelectedChildrenIds(tempArr);
    }
  };

  const handleAddSelectedChildren = async () => {
    handleSetLoading(true);
    const selectedChildren = activeChildren.filter((child) =>
      selectedChildrenIds.includes(child.id)
    );
    setAddedChildren((prevAddedChildren) => [
      ...prevAddedChildren,
      ...selectedChildren,
    ]);
    updateGuardian([...addedChildren, ...selectedChildren]);
    try {
      await Promise.all(
        selectedChildren?.map(async (child) => {
          const tempPayload = { ...child };
          tempPayload.guardian_id = guardianId;
          tempPayload.guardian_name = guardianName;
          tempPayload.communication_email = guardianEmail;
          tempPayload.communication_phone = guardianPhone;
          updatedPayload(tempPayload, 'children', 'update', userData.uid).then(
            async (payLoadData) => {
              await updateFieldInDocument(
                firestore,
                'children',
                child.id,
                payLoadData
              );
            }
          );
        })
      );
      const updatedActiveChildren = activeChildren.filter(
        (child) => !selectedChildrenIds.includes(child.id)
      );
      setActiveChildren(updatedActiveChildren);
      toast.success('Selected children linked successfully!');
    } catch (err) {
      console.error(`Error while linking child document`, err.message);
    }
    setSelectedChildrenIds([]);
    handleSetLoading(false);
  };

  const handleOnBlurZipCode = (e) => {
    handleSetLoading(true);
    zipCodeApi(e, getValues('country'))
      .then((res) => {
        if (typeof res === 'string') {
          toast.error(res);
        }
        setValue('time_zone', res.timezone.timezone_identifier);
        setValue('time_zone_abbr', res.timezone.timezone_abbr);

        handleSetLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setValue('time_zone_abbr', '');
        handleSetLoading(false);
      });
  };

  const EmailValid = (emailId) => {
    emailId &&
      EmailExist(firestore, emailId)
        .then((res) => {
          if (!res.status) {
            toast.error('user email is already exist');
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useEffect(() => {
    getValues('child_status') !== 'enrolled' && reset({ child_status: '' });
  }, [guardianStatus]);

  return (
    <>
      <div className="bg-white shadow-md rounded-md mt-5">
        <div className="p-5">
          <div className="preview">
            <div className="tabs-section">
              <ul id="tabs" className="inline-flex pt-2 w-full border-b">
                <li
                  onClick={() => setTab('default')}
                  className={
                    'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                    (tab === 'default'
                      ? 'bg-white border-t border-r border-l -mb-px'
                      : '')
                  }
                >
                  <a id="default-tab">Children Details</a>
                </li>
                <li
                  onClick={() => setTab('child')}
                  className={
                    'px-5 text-gray-800 font-semibold py-3 cursor-pointer rounded-t ' +
                    (tab === 'child'
                      ? 'bg-white border-t border-r border-l -mb-px'
                      : '')
                  }
                >
                  <a>Available Children</a>
                </li>
              </ul>

              <div id="tab-contents" className="border border-t-0">
                <div
                  id="first"
                  className={'p-5 ' + (tab != 'default' ? 'hidden' : '')}
                >
                  <div className="p-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="preview">
                        <div className="flex flex-col sm:flex-row items-center">
                          <div className="mr-4 flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.first_name?.message}
                            </span>
                            <input
                              id="c-first-name"
                              type="text"
                              placeholder=" "
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              {...register('first_name')}
                            />
                            <label
                              htmlFor="c-first-name"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              First Name
                              <span className="text-red-600">*</span>
                            </label>
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <input
                              id="c-middle-name"
                              type="text"
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              placeholder=" "
                              {...register('middle_name')}
                            />
                            <label
                              htmlFor="c-middle-name"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Middle Name
                            </label>
                          </div>
                          <div className="flex-auto mx-4 w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.last_name?.message}
                            </span>
                            <input
                              id="c-last-name"
                              type="text"
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              placeholder=" "
                              {...register('last_name')}
                            />
                            <label
                              htmlFor="c-last-name"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Last Name
                              <span className="text-red-600">*</span>
                            </label>
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.child_status?.message}
                            </span>
                            {configData?.child_status &&
                            child_status === 'enrolled' ? (
                              <div>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                  <div className="bg-caret_down"></div>
                                </div>
                                <select
                                  disabled
                                  className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0  ${
                                    child_status === 'enrolled'
                                      ? 'bg-gray-300'
                                      : 'bg-transparent'
                                  } border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                                >
                                  <option value={child_status}>
                                    {child_status}
                                  </option>
                                </select>
                                <label
                                  htmlFor="status"
                                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                                >
                                  Status
                                </label>
                              </div>
                            ) : (
                              <Controller
                                name={'child_status'}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      id="child_status"
                                      value={value}
                                      label="Select Status*"
                                      variant="outlined"
                                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0  ${
                                        value ? ' focus-label' : ''
                                      } border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                                    >
                                      {child_status === 'enrolled' ? (
                                        <Option value={child_status}>
                                          {capitalizeFirstWords(child_status)}
                                        </Option>
                                      ) : (
                                        configData?.child_status?.map(
                                          (item) => {
                                            return (
                                              <Option
                                                value={item}
                                                key={item}
                                                disabled={
                                                  item === 'enrolled' ||
                                                  item === 'incourse'
                                                }
                                              >
                                                {capitalizeFirstWords(item)}
                                              </Option>
                                            );
                                          }
                                        )
                                      )}
                                    </Select>
                                  );
                                }}
                                rules={{ required: true }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="mt-5 flex flex-col sm:flex-row items-center">
                          <div className="mr-4 flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.date_of_birth?.message}
                            </span>
                            <input
                              {...register('date_of_birth')}
                              type="date"
                              onChange={(e) =>
                                setAge(calculateAge(e.target.value))
                              }
                              max={getLimit('max')}
                              min={getLimit('min')}
                              className="pt-6 pb-1 shadow-md block w-full px-4 shadow-md mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              placeholder=" "
                            />
                            <label
                              htmlFor="date_of_birth"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Date of Birth
                              <span className="text-red-600">*</span>
                            </label>
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <input
                              id="age"
                              type="text"
                              defaultValue={age ?? ''}
                              className={`pt-6 shadow-md pb-1 bg-gray-300 block w-full px-4 mt-0 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                              placeholder=" "
                              disabled={true}
                            />
                            <label
                              htmlFor="age"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Age
                            </label>
                          </div>
                          <div className="flex-auto mx-4 w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.personality?.message}
                            </span>
                            {configData?.personality && (
                              <Controller
                                name={'personality'}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      name="select"
                                      id="c_personality"
                                      value={value}
                                      defaultValue=""
                                      label="Select Personality"
                                      variant="outlined"
                                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                        value ? ' focus-label' : ''
                                      }`}
                                    >
                                      {configData?.personality?.map((item) => {
                                        return (
                                          <Option value={item} key={item}>
                                            {capitalizeFirstWords(item)}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  );
                                }}
                                rules={{ required: false }}
                              />
                            )}
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.language?.message}
                            </span>
                            {configData?.language && (
                              <Controller
                                name={'language'}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      id="c_language"
                                      defaultValue=""
                                      value={value}
                                      label="Select Language"
                                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                        value ? ' focus-label' : ''
                                      }`}
                                    >
                                      {configData?.language?.map((item) => {
                                        return (
                                          <Option value={item} key={item}>
                                            {capitalizeFirstWords(item)}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  );
                                }}
                                rules={{ required: false }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="mt-5 flex flex-col sm:flex-row items-center">
                          <div className="flex-auto mr-4 w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.country?.message}
                            </span>
                            {configData?.country && (
                              <Controller
                                name={'country'}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      id="c_country"
                                      defaultValue=""
                                      value={value}
                                      label="Select Country"
                                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                        value ? ' focus-label' : ''
                                      }`}
                                    >
                                      {configData?.country?.map((item) => {
                                        return (
                                          <Option value={item} key={item}>
                                            {capitalizeFirstWords(item)}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  );
                                }}
                                rules={{ required: false }}
                              />
                            )}
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {selectedCountry === ''
                                ? ''
                                : errors?.zip_code?.message}
                            </span>
                            <input
                              id="child_zipcode"
                              type="text"
                              {...register('zip_code', {
                                required: false,
                              })}
                              onBlur={handleOnBlurZipCode}
                              onKeyDown={(e) => {
                                e.keyCode === 13 && e.target.blur();
                              }}
                              className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 ${
                                selectedCountry === ''
                                  ? 'bg-gray-300'
                                  : 'bg-transparent'
                              } border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                              placeholder=" "
                              disabled={selectedCountry === ''}
                            />
                            <label
                              htmlFor="child_zipcode"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Zip Code<span className="text-red-600"></span>
                            </label>
                          </div>
                          <div className="flex-auto ml-4 w-1/3 relative">
                            <input
                              id="timezone"
                              type="text"
                              {...register('time_zone_abbr')}
                              className="pt-6 pb-1 bg-gray-300 shadow-md block w-full px-4 mt-0 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                              placeholder=" "
                              disabled
                            />
                            <label
                              htmlFor="timezone"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Time Zone<span className="text-red-600"></span>
                            </label>
                          </div>
                          {/* <div className="flex-auto w-1/3 relative">
                            <Controller
                              name={'scholarship'}
                              control={control}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <Select
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    id="scholarship"
                                    value={value}
                                    label="Select scholarship"
                                    variant="outlined"
                                    className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                      value ? ' focus-label' : ''
                                    }`}
                                  >
                                    <Option value={'yes'} key={'yes'}>
                                      Yes
                                    </Option>
                                    <Option value={'no'} key={'no'}>
                                      No
                                    </Option>
                                  </Select>
                                );
                              }}
                              rules={{ required: false }}
                            />
                          </div> */}
                        </div>
                        <div className="mt-5 flex flex-col sm:flex-row items-center">
                          {!getValues('user_name') ||
                          getValues('user_name') === '' ? (
                            <div className="mr-4 flex-auto w-1/3 relative z-0">
                              <span className="text-red-600 absolute -top-6">
                                {errors?.email_address?.message}
                              </span>
                              <input
                                id="c-email-address"
                                type="text"
                                className={`pt-6 shadow-md pb-1 ${
                                  isChildEdited
                                    ? 'bg-gray-300'
                                    : 'bg-transparent '
                                } block w-full px-4 mt-0 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                                placeholder=" "
                                {...register('email_address', {
                                  onBlur: (e) => {
                                    EmailValid(e.target.value);
                                  },
                                })}
                                disabled={isChildEdited}
                              />
                              <label
                                htmlFor="c-email-address"
                                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                              >
                                Email Address
                              </label>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="flex-auto mr-4 w-1/3 relative">
                            <Controller
                              name={'phone_number'}
                              control={control}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <ReactPhoneInput
                                    id="phone_number"
                                    onChange={(e) => {
                                      onChange(`+${e}`);
                                    }}
                                    country={'us'}
                                    value={value}
                                    disableDropdown={true}
                                    countryCodeEditable={false}
                                  />
                                );
                              }}
                              rules={{ required: false }}
                            />
                          </div>

                          {isUsernameEmpty || getValues('user_name') ? (
                            <div className="flex-auto mr-4 w-1/3 relative z-0">
                              {getValues('email_address') === '' &&
                                !userName && (
                                  <span className="text-red-600 absolute -top-6">
                                    Please enter username
                                  </span>
                                )}

                              <input
                                id="user_name"
                                type="text"
                                placeholder=" "
                                {...register('user_name')}
                                className={`pt-6 pb-1 ${
                                  getValues('email_address')
                                    ? 'bg-gray-300'
                                    : 'bg-transparent'
                                } block w-full px-4 mt-0 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                                {...register('user_name')}
                                disabled={getValues('email_address')}
                              />
                              <label
                                htmlFor="user_name"
                                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                              >
                                Username
                              </label>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="flex-auto ml-2 w-1/3 relative">
                            <input
                              type="checkbox"
                              {...register('child_age_override')}
                            />
                            <label
                              htmlFor="age-override"
                              className="top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Age Override
                            </label>
                          </div>
                          <div className="flex-auto w-1/3 relative">
                            <input
                              type="checkbox"
                              {...register('scholarship')}
                            />
                            <label
                              htmlFor="scholarship"
                              className="top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Scholarship
                            </label>
                          </div>
                        </div>
                        <div className="p-5 text-center">
                          <button
                            disabled={toasting}
                            className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                          >
                            {isChildEdited ? 'Update' : 'Add'} Child
                          </button>
                        </div>
                        {addedChildren.length ? (
                          <div className="p-5">
                            <div className="w-full mx-auto text-left flex mt-5">
                              <div className="table-section w-full">
                                <table className="min-w-full border" id="table">
                                  <thead className="uppercase bg-indigo-50 border-b">
                                    <tr>
                                      <th
                                        data-type="number"
                                        scope="col"
                                        className="font-medium text-gray-900 px-6 py-4 text-left"
                                      >
                                        Children ID
                                      </th>
                                      <th
                                        scope="col"
                                        className="font-medium text-gray-900 px-6 py-4 text-left"
                                      >
                                        Children Name
                                      </th>
                                      <th
                                        scope="col"
                                        className="font-medium text-gray-900 px-6 py-4 text-left"
                                      >
                                        Language
                                      </th>
                                      <th
                                        scope="col"
                                        className="font-medium text-gray-900 px-6 py-4 text-left"
                                      >
                                        Age
                                      </th>
                                      <th
                                        scope="col"
                                        className="font-medium text-gray-900 px-6 py-4 text-left"
                                      >
                                        Personality
                                      </th>
                                      <th
                                        scope="col"
                                        className="font-medium text-gray-900 px-6 py-4 text-left"
                                      >
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {addedChildren?.map((child) => {
                                      return (
                                        <tr
                                          key={child?.id}
                                          className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                        >
                                          <td className="text-blue-900 font-light px-6 whitespace-nowrap cursor-pointer underline">
                                            <NavLink
                                              to="/edit/children"
                                              state={{ id: child.id }}
                                            >
                                              {child.children_id}
                                            </NavLink>
                                          </td>
                                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {child.first_name}{' '}
                                            {child.middle_name}{' '}
                                            {child.last_name}
                                          </td>
                                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {child.language}
                                          </td>
                                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {calculateAge(child.date_of_birth)}
                                          </td>
                                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {child.personality}
                                          </td>
                                          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                              <a
                                                className="border p-3 mr-2 cursor-pointer"
                                                onClick={() =>
                                                  handleEditChild(child)
                                                }
                                              >
                                                <span className="bg-edit block"></span>
                                              </a>
                                              <a
                                                className="border p-3 mr-2 cursor-pointer"
                                                onClick={() =>
                                                  setPopup({
                                                    ...popup,
                                                    show: true,
                                                    id: child,
                                                  })
                                                }
                                              >
                                                <span className="bg-delete block"></span>
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {popup.show && (
                        <ConfirmationPopup
                          setPopup={setPopup}
                          popup={popup}
                          deleteData={(child) => handleRemoveChild(child)}
                        />
                      )}
                    </form>
                  </div>
                </div>
                <div
                  id="second"
                  className={'p-5 ' + (tab != 'child' ? 'hidden' : '')}
                >
                  <div className="w-full mx-auto my-0 text-left flex mt-5">
                    <div className="table-section w-full">
                      {activeChildren.length ? (
                        <>
                          <div className="flex w-full mx-auto flex-col sm:flex-row items-center justify-end">
                            {/* <div className="flex w-full relative border border-b-2 rounded-md">
                              <input
                                id="get-session"
                                type="text"
                                value={sessionSearchText}
                                placeholder=" "
                                className="pt-6 shadow-md pb-1 block w-full px-4 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200"
                                onChange={(e) => setSessionSearchText(e.target.value)}
                              />
                              <label
                                htmlFor="get-session"
                                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                              >
                                Search Child by Name
                              </label>
                              <button
                                className="flex items-center justify-center px-4 border-l"
                                onClick={handleSessionSearch}
                                disabled={sessionSearchText === ''}
                              >
                                <div className="bg-search"></div>
                              </button>
                            </div> */}
                            <div className="text-center">
                              <button
                                type="submit"
                                onClick={handleAddSelectedChildren}
                                disabled={selectedChildrenIds.length === 0}
                                className={
                                  'w-40 px-6 py-3 ml-4 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none focus:outline-none ' +
                                  (selectedChildrenIds.length !== 0
                                    ? 'bg-blue-900 hover:bg-yellow-500 hover:shadow-lg'
                                    : 'bg-gray-400')
                                }
                              >
                                Add Selected
                              </button>
                            </div>
                          </div>
                          <div className="w-full mx-auto my-0 text-left flex mt-5">
                            <div className="table-section w-full">
                              <table className="min-w-full border">
                                <thead className="uppercase bg-indigo-50 border-b">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="font-medium text-gray-900 px-6 py-4 text-left"
                                    >
                                      <div className="col-span-12 flex justify-center">
                                        <input
                                          type="checkbox"
                                          checked={
                                            selectedChildrenIds.length ===
                                            activeChildren.length
                                          }
                                          onChange={handleSelectAllCheckbox}
                                        />
                                      </div>
                                    </th>
                                    <th
                                      scope="col"
                                      className="font-medium text-gray-900 px-6 py-4 text-left"
                                    >
                                      Child ID
                                    </th>
                                    <th
                                      scope="col"
                                      className="font-medium text-gray-900 px-6 py-4 text-left"
                                    >
                                      Child Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="font-medium text-gray-900 px-6 py-4 text-left"
                                    >
                                      Email ID
                                    </th>
                                    <th
                                      scope="col"
                                      className="font-medium text-gray-900 px-6 py-4 text-left"
                                    >
                                      Age
                                    </th>
                                    <th
                                      scope="col"
                                      className="font-medium text-gray-900 px-6 py-4 text-left"
                                    >
                                      Country
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {activeChildren?.map((child) => {
                                    return (
                                      <tr
                                        className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                        key={child.id}
                                      >
                                        <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                          <div className="col-span-12 flex justify-center">
                                            <input
                                              type="checkbox"
                                              value={child.id ?? ''}
                                              checked={selectedChildrenIds.includes(
                                                child.id
                                              )}
                                              onChange={(e) =>
                                                handleSelectOneCheckbox(e)
                                              }
                                            />
                                          </div>
                                        </td>
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          {child.children_id}
                                        </td>
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          {`${child.first_name} ${child.middle_name} ${child.last_name}`}
                                        </td>
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          {child.email_address}
                                        </td>
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          {calculateAge(child.date_of_birth)}
                                        </td>
                                        <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                          {child.country}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="pt-6"> No active child found!</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

ChildrenDetails.propTypes = {
  configData: PropTypes.object.isRequired,
  editData: PropTypes.object,
  guardianDocId: PropTypes.string.isRequired,
  handleSetLoading: PropTypes.func.isRequired,
  guardianEmail: PropTypes.string.isRequired,
  guardianPhone: PropTypes.string,
  guardianId: PropTypes.string.isRequired,
  guardianName: PropTypes.string.isRequired,
  guardianStatus: PropTypes.string.isRequired,
};

export default ChildrenDetails;
