import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationPopup from '../common/ConfirmationPopup';
import { useCore } from '../core/CoreContextProvider';
import { useAuth } from '../core/AuthContextProvider';
import { updateFieldInDocument } from '../common/Firebase';
import { updatedPayload } from '../common/utils';
import { NavLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

export const AddedModules = ({
  setAddedModules,
  addedModules,
  levelId,
  setLoading,
}) => {
  const [popup, setPopup] = useState({
    show: false,
    message: 'Are you sure want to delete this module',
    id: null,
  });
  const { firestore } = useCore();
  const { userData } = useAuth();

  const deleteModule = async (id) => {
    setLoading(true);
    let tempObj = { ...addedModules };
    let index = tempObj.actualContent.indexOf(id);
    index != -1 ? tempObj.actualContent.splice(index, 1) : '';
    const displayContent = tempObj.displayContent.filter((module) => {
      return id != module.id;
    });
    updatedPayload(
      { modules: tempObj.actualContent },
      'level',
      'update',
      userData.uid
    ).then(async (payLoadData) => {
      await updateFieldInDocument(firestore, 'levels', levelId, payLoadData);
      setAddedModules({ ...tempObj, displayContent: displayContent });
      setPopup({
        ...popup,
        show: false,
        id: null,
      });
      setLoading(false);
      toast.success(`Module has been removed!`);
    });
  };

  const handleDragEnd = async (res) => {
    if (!res?.destination) return;
    setLoading(true);
    console.log(addedModules, res);
    let tempModules = [...addedModules.displayContent];
    let [selectedRow] = tempModules.splice(res?.source?.index, 1);
    tempModules.splice(res?.destination.index, 0, selectedRow);
    console.log(tempModules);
    const modulesIds = tempModules.map((temp) => temp.id);
    updatedPayload(
      {
        modules: modulesIds,
      },
      'level',
      'update',
      userData.uid
    ).then(async (payLoadData) => {
      await updateFieldInDocument(firestore, 'levels', levelId, payLoadData);
      setAddedModules({
        actualContent: modulesIds,
        displayContent: tempModules,
      });
      setLoading(false);
    });
  };

  return (
    <div className="w-3/4 mx-auto my-0 text-left flex mt-5">
      <div className="table-section w-full">
        {addedModules?.displayContent?.length != 0 ? (
          <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
            <table className="min-w-full border" id="table">
              <thead className="">
                <tr>
                  <th
                    data-type="number"
                    scope="col"
                    className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  ></th>
                  <th
                    scope="col"
                    className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Module ID
                  </th>
                  <th
                    scope="col"
                    className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {addedModules?.displayContent?.map((res, index) => (
                      <Draggable
                        key={res.id}
                        draggableId={res.id}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            key={res.id}
                            className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                          >
                            <td
                              {...provided.dragHandleProps}
                              className="text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-move relative text-2xl after:absolute after:top-1 after:right-8 after:content-[':::'] transform rotate-90"
                            ></td>
                            <td className="text-blue-900 font-light p-3 whitespace-nowrap cursor-pointer underline">
                              <NavLink to="/edit/module" state={{ id: res.id }}>
                                {res.module_id}
                              </NavLink>
                            </td>
                            <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {res.module_name}
                            </td>
                            <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {res.module_status}
                            </td>
                            <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              <a
                                className="text-blue-600 hover:text-red-500 cursor-pointer"
                                onClick={() =>
                                  setPopup({ ...popup, show: true, id: res.id })
                                }
                              >
                                Remove
                              </a>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>
        ) : (
          <div>No modules are added</div>
        )}
        {popup.show && (
          <ConfirmationPopup
            popup={popup}
            setPopup={setPopup}
            deleteData={deleteModule}
          />
        )}
      </div>
    </div>
  );
};

AddedModules.propTypes = {
  setAddedModules: PropTypes.func,
  addedModules: PropTypes.object,
  levelId: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
};
