import PropTypes from 'prop-types';

const GetAnswer = ({ step, response, storeAnswer, disable }) => {
  return (
    <>
      {step.type == 'free_text' && (
        <input
          type="text"
          onChange={(e) => {
            storeAnswer(e.target.value, e.target.name);
          }}
          name={'answer'}
          defaultValue={response}
          disabled={disable}
          className="w-10/12 px-6 py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2    hover:shadow-lg focus:outline-none "
        />
      )}
      {step.type == 'number' && (
        <>
          <input
            type="number"
            defaultValue={response}
            onChange={(e) => {
              storeAnswer(e.target.value, e.target.name);
            }}
            disabled={disable}
            name={'answer'}
            className="w-10/12  px-6 py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2   hover:shadow-lg focus:outline-none "
          />
        </>
      )}
      {(step.type == 'multi_choice_question' ||
        step.type == 'scale' ||
        step.type == 'blurb') && (
        <select
          onChange={(e) => {
            storeAnswer(e.target.value, e.target.name);
          }}
          value={response}
          disabled={disable}
          name={'answer'}
          className="w-full px-6 py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2   hover:shadow-lg focus:outline-none "
        >
          <option value="">Select your answer</option>
          {Object.keys(step?.options).map((e) => {
            return (
              <>
                <option key={e} value={e}>
                  {step.type === 'scale'
                    ? step.options[e].value
                    : step.type === 'blurb'
                    ? step.options[e].heading
                    : step.options[e]}
                </option>
              </>
            );
          })}
        </select>
      )}
    </>
  );
};

GetAnswer.propTypes = {
  step: PropTypes.object,
  response: PropTypes.string,
  storeAnswer: PropTypes.func,
  disable: PropTypes.any,
};

export default GetAnswer;
