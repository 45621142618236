import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getData } from '../common/Firebase';
import { useCore } from '../core/CoreContextProvider';

export const ViewLevel = ({ popup, setPopup }) => {
  const data = popup.data;
  const [levelData, setLevelData] = useState({});
  const { firestore } = useCore();

  const getModuleData = async (data) => {
    let modules = [];
    data &&
      data.modules &&
      (await Promise.all(
        data.modules.map(async (id) => {
          const moduleData = await getData(firestore, id, 'modules');
          moduleData && modules.push(moduleData);
        })
      ));
    data.modules = modules;
    setLevelData(data);
  };
  useEffect(() => {
    if (data) {
      getModuleData(data);
    }
  }, [data]);
  return (
    <>
      {popup.show && Object.keys(levelData).length != 0 ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-5/6 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div className="modal-close cursor-pointer">
                  <span
                    className="bg-close  block cursor-pointer"
                    onClick={() => setPopup({ show: false, data: null })}
                  ></span>
                </div>
              </div>
              <div className="p-5">
                <div className="preview">
                  <div className="flex w-1/2 float-left mt-5">
                    <label
                      htmlFor="session-id"
                      className="text-black w-40 px-4 font-bold"
                    >
                      Level ID:
                    </label>
                    <div
                      id="level-id"
                      type="text"
                      className="mt-0 bg-transparent"
                    >
                      {levelData.level_id}
                    </div>
                  </div>
                  <div className="flex w-1/2 float-left mt-5">
                    <label
                      htmlFor="level-name"
                      className="text-black w-40 px-4 font-bold"
                    >
                      Level Name:
                    </label>
                    <div
                      id="level-name"
                      type="text"
                      className="mt-0 bg-transparent"
                    >
                      {levelData.level_name}
                    </div>
                  </div>
                  <div className="flex w-1/2 float-left mt-5">
                    <label
                      htmlFor="session-status"
                      className="text-black w-40 px-4 font-bold"
                    >
                      Status:
                    </label>
                    <div
                      name="select"
                      value=""
                      id="level-status"
                      className="mt-0 bg-transparent"
                    >
                      {levelData.level_status}
                    </div>
                  </div>

                  <div className="flex w-1/2 float-left mt-5">
                    <label
                      htmlFor="level-description"
                      className="text-black w-40 px-4 font-bold"
                    >
                      Description:
                    </label>
                    <div
                      id="level-description"
                      type="text"
                      className="mt-0 bg-transparent"
                    >
                      {levelData.description}
                    </div>
                  </div>
                </div>
                <div className="mt-10 mb-5 float-left w-full text-center font-bold">
                  Added Modules
                </div>
                <div className="w-full mx-auto my-0 text-left flex mt-5">
                  <div className="table-section w-full max-h-[250px] overflow-y-auto">
                    {levelData?.modules && levelData.modules.length != 0 ? (
                      <table className="min-w-full border" id="table">
                        <thead className="">
                          <tr>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                            >
                              Module ID
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                            >
                              Module Name
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {levelData?.modules &&
                            levelData.modules.map((data, index) => {
                              return (
                                <tr
                                  key={btoa(index)}
                                  className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                >
                                  <td className="mt-0 bg-transparent text-blue-900 font-light whitespace-nowrap cursor-pointer underline px-6">
                                    <NavLink
                                      to="/edit/module"
                                      state={{ id: data.id }}
                                    >
                                      {data.module_id}
                                    </NavLink>
                                  </td>
                                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {data.module_name}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {data.module_status}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    ) : (
                      <span>No modules are added</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 mb-10 w-full text-center text-center">
              <NavLink
                to="/edit/level"
                state={{ id: levelData.objectID }}
                className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
              >
                Edit
              </NavLink>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

ViewLevel.propTypes = {
  popup: PropTypes.object.isRequired,
  setPopup: PropTypes.func,
};
