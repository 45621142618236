import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
export const AddedScripts = ({ data, setPopup, popup }) => {
  return (
    <div id="second" className=" p-5">
      <div className="w-full mx-auto my-0 text-left flex mt-5">
        <div className="table-section w-full">
          <table className="min-w-full border" id="table">
            {Object.keys(data).length !== 0 && (
              <thead className="">
                <tr>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Script ID
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Topic
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Age Group
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Language
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Version
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Action
                  </th>
                </tr>
              </thead>
            )}
            {Object.keys(data).length !== 0 && (
              <tbody>
                {data.map((e) => {
                  return (
                    <tr
                      key={e.id}
                      className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 cursor-move"
                    >
                      <td className="text-blue-900 font-light px-6 whitespace-nowrap cursor-pointer underline">
                        <NavLink to="/edit/script" state={{ id: e.id }}>
                          {e.script_id}
                        </NavLink>
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {e.topic}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {e.age_group}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {e.language}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {e.script_status}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {e.version}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <div
                          className="text-blue-600 hover:text-red-500 cursor-pointer"
                          onClick={() =>
                            setPopup({ ...popup, show: true, id: e.id })
                          }
                        >
                          Remove
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

AddedScripts.propTypes = {
  data: PropTypes.array.isRequired,
  setPopup: PropTypes.func.isRequired,
  popup: PropTypes.object.isRequired,
  control: PropTypes.any,
};
