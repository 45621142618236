import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getData } from '../common/Firebase';
import { useCore } from '../core/CoreContextProvider';

export const ViewCourse = ({ popup, setPopup }) => {
  const courseData = popup.data;
  const { firestore } = useCore();
  const [viewData, setViewData] = useState({});
  const getLevelData = async (data) => {
    let levels = [];
    data.levels &&
      (await Promise.all(
        data.levels.map(async (id) => {
          const levelData = await getData(firestore, id, 'levels');
          console.log(levelData);
          levels.push(levelData);
        })
      ));
    courseData.levels = levels;
    console.log(courseData);
    setViewData(courseData);
  };
  useEffect(() => {
    if (courseData) {
      getLevelData(courseData);
    }
  }, [courseData]);
  return (
    <>
      {popup.show && Object.keys(viewData).length != 0 ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-5/6 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div className="modal-close cursor-pointer">
                  <span
                    className="bg-close  block cursor-pointer"
                    onClick={() => setPopup({ show: false, data: null })}
                  ></span>
                </div>
              </div>
              <div className="p-5">
                <div className="preview">
                  <div className="flex w-1/2 float-left mt-5">
                    <label
                      htmlFor="session-id"
                      className="text-black w-40 px-4 font-bold"
                    >
                      Course ID:
                    </label>
                    <div
                      id="course-id"
                      type="text"
                      className="mt-0 bg-transparent"
                    >
                      {viewData.course_id}
                    </div>
                  </div>
                  <div className="flex w-1/2 float-left mt-5">
                    <label
                      htmlFor="course-name"
                      className="text-black w-40 px-4 font-bold"
                    >
                      Course Name:
                    </label>
                    <div
                      id="course-name"
                      type="text"
                      className="mt-0 bg-transparent"
                    >
                      {viewData.course_name}
                    </div>
                  </div>
                  <div className="flex w-1/2 float-left mt-5">
                    <label
                      htmlFor="session-status"
                      className="text-black w-40 px-4 font-bold"
                    >
                      Status:
                    </label>
                    <div
                      name="select"
                      value=""
                      id="course-status"
                      className="mt-0 bg-transparent"
                    >
                      {viewData.course_status}
                    </div>
                  </div>

                  <div className="flex w-1/2 float-left mt-5">
                    <label
                      htmlFor="course-description"
                      className="text-black w-40 px-4 font-bold"
                    >
                      Description:
                    </label>
                    <div
                      id="course-description"
                      type="text"
                      className="mt-0 bg-transparent"
                    >
                      {viewData.description}
                    </div>
                  </div>
                </div>
                <div className="mt-10 mb-5 float-left w-full text-center font-bold">
                  Added Levels
                </div>
                <div className="w-full mx-auto my-0 text-left flex mt-5">
                  <div className="table-section w-full max-h-[300px] overflow-y-auto">
                    <table className="min-w-full border" id="table">
                      <thead className="">
                        <tr>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Level ID
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Level Name
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewData?.levels?.length != 0 &&
                          viewData.levels.map((data, index) => {
                            return (
                              <tr
                                key={btoa(index)}
                                className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                              >
                                <td className="mt-0 bg-transparent text-blue-900 font-light whitespace-nowrap cursor-pointer underline px-6">
                                  <NavLink
                                    to="/edit/level"
                                    state={{ id: data.id }}
                                  >
                                    {data.level_id}
                                  </NavLink>
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {data.level_name}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {data.level_status}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 mb-10 w-full text-center text-center">
              <NavLink
                to="/edit/course"
                state={{ id: viewData.id }}
                className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
              >
                Edit
              </NavLink>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

ViewCourse.propTypes = {
  popup: PropTypes.object.isRequired,
  setPopup: PropTypes.func,
};
