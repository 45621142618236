import PropTypes from 'prop-types';
import { Option, Select } from '@material-tailwind/react';
import { Controller, useForm } from 'react-hook-form';
import { updateFieldInDocument } from '../../common/Firebase';
import { useCore } from '../../core/CoreContextProvider';
import log from '../../core/log';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
const CreateValidation = ({ step, form_name, resetStep, setLoading }) => {
  const { firestore } = useCore();
  const { register, handleSubmit, control, reset } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    let finalData = { ...data };
    finalData.required = finalData.required ? true : false;

    const path = Object.prototype.hasOwnProperty.call(step, 'topic_id')
      ? `tapouts_form/${form_name}/steps/${step.topic_id}/sub_steps/`
      : `tapouts_form/${form_name}/steps/`;

    try {
      await updateFieldInDocument(firestore, path, step.id, {
        validations: finalData,
      });
      resetStep();
      toast.success(
        `Form Validation for Step-No-${step.step_no} has been created`
      );
      setLoading(false);
    } catch (err) {
      log('Error while adding validation' + err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (step.variable) {
      reset({ required: 'yes' });
    }
  }, [step]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-20">
          <span>Validations : </span>
          <div className="flex-auto w-1/3 relative mt-3">
            <Controller
              name={'required'}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    onChange={(e) => {
                      onChange(e);
                    }}
                    id="required"
                    value={value}
                    disabled={step.variable}
                    label="Required"
                    className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0  border border-b-2 appearance-none ${
                      step.variable ? 'bg-gray-400' : 'bg-transparent'
                    } z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                      value ? ' focus-label' : ''
                    }`}
                  >
                    <Option value={'yes'}>Yes</Option>
                    <Option value={'no'}>No</Option>
                  </Select>
                );
              }}
              rules={{ required: false }}
            />
          </div>
          {step.variable && (
            <span>Assigned to a variable should be required</span>
          )}
          {step.type == 'number' && (
            <div className="flex w-2/3">
              <div className="flex-auto  mt-3 w-1/3 relative">
                <input
                  type="text"
                  placeholder=" "
                  defaultValue=""
                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                  {...register('min')}
                />
                <label
                  htmlFor="required"
                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                >
                  Min
                </label>
              </div>

              <div className="flex-auto ml-4 mt-3 w-1/3 relative">
                <input
                  type="text"
                  placeholder=" "
                  defaultValue=""
                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                  {...register('max')}
                />
                <label
                  htmlFor="heading"
                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                >
                  Max
                </label>
              </div>
            </div>
          )}
          {step.type == 'free_text' && (
            <div className="flex w-2/3">
              <div className="flex-auto  mt-3 w-1/3 relative">
                <input
                  type="number"
                  placeholder=" "
                  defaultValue=""
                  id="min_character"
                  min={1}
                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                  {...register('min_character')}
                />
                <label
                  htmlFor="min_character"
                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                >
                  Minimum Character
                </label>
              </div>

              <div className="flex-auto ml-4 mt-3 w-1/3 relative">
                <input
                  type="number"
                  placeholder=" "
                  defaultValue=""
                  id="max_character"
                  min={1}
                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                  {...register('max_character')}
                />
                <label
                  htmlFor="max_character"
                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                >
                  Maximum Character
                </label>
              </div>
            </div>
          )}

          {step.type == 'multi_select_question' && (
            <div className="flex-auto  mt-3 w-1/3 relative">
              <input
                type="number"
                placeholder=" "
                defaultValue=""
                id="min_selection"
                max={Object.keys(step.options).length}
                className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                {...register('min_selection')}
              />
              <label
                htmlFor="min_selection"
                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
              >
                Minimum Selection
                <span className="text-red-600">*</span>
              </label>
            </div>
          )}
        </div>
        <div className="flex gap-4">
          <div className="p-5 text-center">
            <button
              type="submit"
              className="w-60 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
            >
              Add Validation
            </button>
          </div>
          {!step.variable && (
            <div className="p-5 text-center">
              <button
                onClick={() => {
                  resetStep();
                }}
                className="w-60 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
              >
                Skip Validation
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

CreateValidation.propTypes = {
  step: PropTypes.object,
  form_name: PropTypes.string,
  resetStep: PropTypes.func,
  setLoading: PropTypes.func,
};
export default CreateValidation;
