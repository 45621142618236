import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ConfirmationPopup from '../common/ConfirmationPopup';
import { useCore } from '../core/CoreContextProvider';
import { useAuth } from '../core/AuthContextProvider';
import { updateFieldInDocument } from '../common/Firebase';
import { updatedPayload } from '../common/utils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

export const AddedLevels = ({
  setAddedLevels,
  addedLevels,
  courseId,
  setLoading,
  setValue,
}) => {
  const [popup, setPopup] = useState({
    show: false,
    message: 'Are you sure want to delete this level',
    id: null,
  });
  const { firestore } = useCore();
  const { userData } = useAuth();

  const deleteLevel = async (id) => {
    setLoading(true);
    let tempObj = { ...addedLevels };
    let index = tempObj.actualContent.indexOf(id);
    index != -1 ? tempObj.actualContent.splice(index, 1) : '';
    const displayContent = tempObj.displayContent.filter((level) => {
      return id != level.id;
    });
    updatedPayload(
      { levels: tempObj.actualContent },
      'course',
      'update',
      userData.uid
    ).then(async (payLoadData) => {
      if (!payLoadData.levels || payLoadData.levels.length === 0) {
        payLoadData.course_status = 'created';
        setValue('course_status', 'created');
      }
      await updateFieldInDocument(firestore, 'courses', courseId, payLoadData);
    });
    setAddedLevels({ ...tempObj, displayContent: displayContent });
    setPopup({
      ...popup,
      show: false,
      id: null,
    });
    setLoading(false);
    toast.success(`Level has been removed!`);
  };

  const handleDragEnd = async (res) => {
    if (!res?.destination) return;
    setLoading(true);
    let tempLevels = [...addedLevels.displayContent];
    let [selectedRow] = tempLevels.splice(res?.source?.index, 1);
    tempLevels.splice(res?.destination.index, 0, selectedRow);
    console.log(tempLevels);
    const levelsIds = tempLevels.map((temp) => temp.id);
    console.log(levelsIds);
    updatedPayload(
      {
        levels: levelsIds,
      },
      'course',
      'update',
      userData.uid
    ).then(async (payLoadData) => {
      await updateFieldInDocument(firestore, 'courses', courseId, payLoadData);
    });
    setAddedLevels({ actualContent: levelsIds, displayContent: tempLevels });
    setLoading(false);
  };

  return (
    <div className="w-3/4 mx-auto my-0 text-left flex mt-5">
      <div className="table-section w-full">
        {addedLevels?.displayContent &&
        addedLevels?.displayContent?.length != 0 ? (
          <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
            <table className="min-w-full border" id="table">
              <thead className="">
                <tr>
                  <th
                    data-type="number"
                    scope="col"
                    className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  ></th>
                  <th
                    scope="col"
                    className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Level ID
                  </th>
                  <th
                    scope="col"
                    className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {addedLevels?.displayContent?.map((res, index) => (
                      <Draggable
                        key={res.id}
                        draggableId={res.id}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            key={res.id}
                            className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                          >
                            <td
                              {...provided.dragHandleProps}
                              className="text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-move relative text-2xl after:absolute after:top-1 after:right-8 after:content-[':::'] transform rotate-90"
                            ></td>
                            <td className="text-blue-900 font-light p-3 whitespace-nowrap cursor-pointer underline">
                              <NavLink to="/edit/level" state={{ id: res.id }}>
                                {res.level_id}
                              </NavLink>
                            </td>
                            <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {res.level_name}
                            </td>
                            <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {res.level_status}
                            </td>
                            <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              <a
                                className="text-blue-600 hover:text-red-500 cursor-pointer"
                                onClick={() =>
                                  setPopup({ ...popup, show: true, id: res.id })
                                }
                              >
                                Remove
                              </a>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>
        ) : (
          <div>No levels are added</div>
        )}
        {popup.show && (
          <ConfirmationPopup
            popup={popup}
            setPopup={setPopup}
            deleteData={deleteLevel}
          />
        )}
      </div>
    </div>
  );
};

AddedLevels.propTypes = {
  setAddedLevels: PropTypes.func,
  addedLevels: PropTypes.object,
  courseId: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  setValue: PropTypes.func,
};
