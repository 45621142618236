import { useEffect, useState } from 'react';
import MultiType from './ConditionType/Multiple/MultipleType';
import SingleType from './ConditionType/Single/SingleType';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const Evaluation = ({
  previousStep,
  nextStep,
  storeAnswer,
  step,
  storedResponse,
  index,
  getStepNumber,
}) => {
  const deleteOperand = [
    'number',
    'scale',
    'free_text',
    'multi_choice_question',
    'blurb',
  ];
  const [conditionType, setConditionType] = useState();
  const [conditionResponse, setConditionResponse] = useState([]);
  const [result, setResult] = useState('');

  const sendResponse = () => {
    const responseValid = checkResponseValid(conditionResponse);
    if (!responseValid) return;

    const obj = {
      ...storedResponse,
      condition: conditionResponse.length
        ? conditionResponse
        : storedResponse.condition,
      type: conditionType,
      answer: step.currentAnswer,
      target: result,
      operand: step.operand,
    };
    if (conditionType !== 'multiple') delete obj.target;
    if (!deleteOperand.includes(step.type)) delete obj.operand;

    storeAnswer(obj, index);
  };

  const checkResponseValid = (data) => {
    let tempArr = [];
    data.forEach((e) => tempArr.push(...Object.values(e)));

    let final = true;
    if (
      tempArr.includes('') ||
      !step.currentAnswer ||
      !conditionType ||
      (step.type != 'number' ? false : step.operand ? false : true) ||
      (conditionType === 'multiple' && !result)
    ) {
      final = false;
      toast.info('Please fill all required fields');
    }

    return final;
  };
  useEffect(() => {
    setConditionType(storedResponse.type);
    setResult(storedResponse.target);
  }, [storedResponse]);

  return (
    <>
      <select
        onChange={(e) => {
          setConditionType(e.target.value);
        }}
        value={conditionType}
        className="w-full px-6 py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2 mr-5   hover:shadow-lg focus:outline-none "
      >
        <option value="">
          Select type of condition <span className="text-red-600">*</span>
        </option>
        <option value={'single'}>Single Condition</option>
        <option value={'multiple'}>Multiple Condition</option>
      </select>

      {conditionType == 'single' && (
        <SingleType
          nextStep={nextStep}
          step={step}
          storeAnswer={setConditionResponse}
          storedResponse={storedResponse.condition}
          actualCondition={storedResponse}
          getStepNumber={getStepNumber}
        />
      )}
      {conditionType == 'multiple' && (
        <>
          <MultiType
            previousStep={previousStep}
            step={step}
            storeAnswer={setConditionResponse}
            storedResponse={storedResponse.condition}
            getStepNumber={getStepNumber}
          />

          <div className="w-full flex justify-between mt-4">
            <div className="w-1/3 flex-auto">
              <div className="w-full text-center px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 focus:outline-none">
                Result
              </div>
            </div>
            <div className="w-2/3 ml-4">
              <select
                value={result}
                onChange={(e) => {
                  setResult(e.target.value);
                }}
                name={'target'}
                className="w-full px-6 py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2 hover:shadow-lg focus:outline-none "
              >
                <option value="">Select your answer</option>
                {nextStep.map((step) => {
                  return (
                    <option key={step.id} value={step.id}>
                      {getStepNumber(step)}) {step.heading}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </>
      )}

      <div className="py-2 text-center">
        <button
          onClick={sendResponse}
          disabled={
            !conditionResponse.length && result == storedResponse?.target
          }
          className={`mt-4 w-24 text-center ${
            !conditionResponse.length && result == storedResponse?.target
              ? 'bg-gray-300'
              : 'bg-green-900'
          }   px-3 py-1  text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none  hover:bg-yellow-500 hover:shadow-lg focus:outline-none`}
        >
          Save
        </button>
      </div>
    </>
  );
};

Evaluation.propTypes = {
  nextStep: PropTypes.array,
  step: PropTypes.object,
  previousStep: PropTypes.array,
  storeAnswer: PropTypes.func,
  storedResponse: PropTypes.array,
  index: PropTypes.number,
  getStepNumber: PropTypes.func,
};

export default Evaluation;
