import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { calculateAge } from '../common/utils';

export function ViewChildPopup({ open, data, handleClose }) {
  let navigate = useNavigate();

  const handleEdit = () => {
    navigate('/edit/children', {
      state: { id: data.id },
    });
  };
  return (
    <>
      {open ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-5/6 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div
                  className="modal-close cursor-pointer"
                  onClick={() => handleClose()}
                >
                  <span className="bg-close block cursor-pointer"></span>
                </div>
              </div>

              <div className="col-span-12 lg:col-span-12">
                <div className="bg-white">
                  <div className="flex flex-col sm:flex-row items-center p-5">
                    <h2 className="font-bold text-base mr-auto">
                      Child Details
                    </h2>
                  </div>
                  <div
                    id="input"
                    className="p-5 border float-left pb-10 w-full"
                  >
                    <div className="preview ">
                      <div className="flex flex-col sm:flex-row items-center">
                        <div className="flex w-1/2 mt-5 float-left">
                          <label
                            htmlFor="script-name"
                            className="text-black w-40 px-4 font-bold"
                          >
                            Children ID:
                          </label>
                          <div
                            id="script-name"
                            type="text"
                            className="mt-0 bg-transparent"
                            placeholder=" "
                          >
                            {data?.children_id}
                          </div>
                        </div>
                        <div className="flex w-1/2 mt-5 float-left items-center">
                          <label
                            htmlFor="script-id"
                            className="text-black px-4 font-bold w-40"
                          >
                            Children Name:
                          </label>
                          <div className="mt-0 bg-transparent">
                            {`${data.first_name} ${data.middle_name} ${data.last_name}`}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row items-center">
                        <div className="flex w-1/2 mt-5 float-left items-center">
                          <label
                            htmlFor="script-name"
                            className="text-black w-40 px-4 font-bold"
                          >
                            Guardian ID:
                          </label>
                          <div
                            id="script-name"
                            type="text"
                            className="mt-0 bg-transparent"
                            placeholder=" "
                          >
                            {data.guardian_id ? (
                              data.guardian_id
                            ) : (
                              <span className="text-gray-900 font-light px-6 whitespace-nowrap  text-4xl">
                                -
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex w-1/2 mt-5 float-left items-center">
                          <label
                            htmlFor="script-id"
                            className="text-black px-4 font-bold w-40"
                          >
                            Guardian Name:
                          </label>
                          <div className="mt-0 bg-transparent">
                            {data.guardian_name ? (
                              data.guardian_name
                            ) : (
                              <span className="text-gray-900 font-light px-6 whitespace-nowrap  text-4xl">
                                -
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left items-center">
                        <label
                          htmlFor="age-group"
                          className="text-black px-4 font-bold w-40"
                        >
                          Email ID:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data?.email_address}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left items-center">
                        <label
                          htmlFor="topics"
                          className="text-black px-4 font-bold w-40"
                        >
                          Phone Number:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data.phone_number ? (
                            data.phone_number
                          ) : (
                            <span className="text-gray-900 font-light px-6 whitespace-nowrap  text-4xl">
                              -
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left items-center">
                        <label
                          htmlFor="tags"
                          className="text-black px-4 font-bold w-40"
                        >
                          Age:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {calculateAge(data?.date_of_birth)}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left items-center">
                        <label
                          htmlFor="topics"
                          className="text-black px-4 font-bold w-40"
                        >
                          User Name:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {data.user_name ? (
                            data.user_name
                          ) : (
                            <span className="text-gray-900 font-light px-6 whitespace-nowrap  text-4xl">
                              -
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-5 text-center w-full float-left items-center">
                  <button
                    className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                    onClick={handleEdit}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

ViewChildPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

export default ViewChildPopup;
