import React from 'react';

export default function LoadingSpinner() {
  return (
    <div
      className="spinner-container loading-overlay w-full z-50 flex bg-black opacity-70 fixed inset-0 items-center justify-center"
      style={{ zIndex: '999' }}
    >
      <div className="loading-spinner animate-spin w-48 h-48 border-50 border-t-black border-8"></div>
    </div>
  );
}
