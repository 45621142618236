import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function ViewScript({ setPopup, popup }) {
  const script = popup.data;

  return (
    <>
      {popup.show ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-2/3 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div className="modal-close cursor-pointer ">
                  <span
                    className="bg-close block cursor-pointer"
                    onClick={() => setPopup({ show: false, data: null })}
                  ></span>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-12">
                <div className="bg-white rounded-md">
                  <div id="input" className="p-5 border float-left pb-10">
                    <div className="flex w-1/2 float-left mt-5">
                      <label
                        htmlFor="script-name"
                        className="text-black w-40 px-4 font-bold"
                      >
                        Script Name:
                      </label>
                      <div
                        id="script-name"
                        type="text"
                        className="mt-0 bg-transparent"
                      >
                        {script.script_name}
                      </div>
                    </div>
                    <div className="flex w-1/2 float-left mt-5">
                      <label
                        htmlFor="script-id"
                        className="text-black w-40 px-4 font-bold"
                      >
                        Script ID:
                      </label>
                      <div
                        id="script-id"
                        type="text"
                        className="mt-0 bg-transparent"
                      >
                        {script.script_id}
                      </div>
                    </div>
                    <div className="flex w-1/2 float-left mt-5">
                      <label
                        htmlFor="age-group"
                        className="text-black w-40 px-4 font-bold"
                      >
                        Age Group:
                      </label>
                      <div
                        name="select"
                        id="age-group"
                        className="mt-0 bg-transparent"
                      >
                        {script.age_group}
                      </div>
                    </div>
                    <div className="flex w-1/2 float-left mt-5">
                      <label
                        htmlFor="topics"
                        className="text-black w-40 px-4 font-bold"
                      >
                        Topics:
                      </label>
                      <div
                        name="select"
                        id="topics"
                        className="mt-0 bg-transparent"
                      >
                        {script.topic}
                      </div>
                    </div>
                    {script.tag && (
                      <div className="flex w-1/2 float-left mt-5">
                        <label
                          htmlFor="tags"
                          className="text-black w-40 px-4 font-bold"
                        >
                          Tags:
                        </label>
                        <div
                          id="tags"
                          type="text"
                          className="mt-0 bg-transparent"
                        >
                          {script.tag}
                        </div>
                      </div>
                    )}
                    <div className="flex w-1/2 float-left mt-5">
                      <label
                        htmlFor="status"
                        className="text-black w-40 px-4 font-bold"
                      >
                        Status:
                      </label>
                      <div
                        id="status"
                        type="text"
                        className="mt-0 bg-transparent"
                      >
                        {script.script_status}
                      </div>
                    </div>
                    <div className="flex w-1/2 float-left mt-5">
                      <label
                        htmlFor="version"
                        className="text-black w-40 px-4 font-bold"
                      >
                        Version:
                      </label>
                      <div
                        name="select"
                        value=""
                        id="version"
                        className="mt-0 bg-transparent"
                      >
                        {script.version}
                      </div>
                    </div>
                    <div className="flex w-1/2 float-left mt-5">
                      <label
                        htmlFor="language"
                        className="text-black w-40 px-4 font-bold"
                      >
                        Language:
                      </label>
                      <div
                        name="select"
                        value=""
                        id="language"
                        placeholder=" "
                        className="mt-0 bg-transparent"
                      >
                        {script.language}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-5 text-center w-full float-left my-10">
                  <NavLink
                    to="/edit/script"
                    state={{ id: script.objectID }}
                    className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                  >
                    Edit
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

ViewScript.propTypes = {
  setPopup: PropTypes.func.isRequired,
  popup: PropTypes.object.isRequired,
};

export default ViewScript;
