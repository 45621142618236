import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { convertLocale, formatAMPM } from '../common/utils';
import { useAuth } from '../core/AuthContextProvider';
import { useCore } from '../core/CoreContextProvider';
import { getData } from '../common/Firebase';
export const ViewPod = ({ popup, setPopup }) => {
  const { data } = popup;
  const { countryCode } = useAuth();
  const { firestore } = useCore();
  const [response, setResponse] = useState({});

  useEffect(() => {
    const getPodData = async () => {
      if (data) {
        const res = await getData(
          firestore,
          data.id && data.id !== '' ? data.id : data.objectID,
          'pods'
        );
        setResponse(res);
      }
    };
    getPodData();
  }, []);

  return (
    <>
      {popup.show ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-5/6 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div
                  className="modal-close cursor-pointer"
                  onClick={() => setPopup({ ...popup, show: false })}
                >
                  <span className="bg-close block cursor-pointer"></span>
                </div>
              </div>

              <div className="col-span-12 lg:col-span-12">
                <div className="bg-white">
                  <div className="flex flex-col sm:flex-row items-center p-5">
                    <h2 className="font-bold text-base mr-auto">Pod Details</h2>
                  </div>
                  <div
                    id="input"
                    className="p-5 border float-left pb-10 w-full"
                  >
                    <div className="preview ">
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="pod_id"
                          className="text-black w-40 px-4 font-bold"
                        >
                          Pod ID:
                        </label>
                        <div
                          id="pod_id"
                          type="text"
                          className="mt-0 bg-transparent"
                          placeholder=" "
                        >
                          {response?.pod_id}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="course_name"
                          className="text-black px-4 font-bold w-40"
                        >
                          Course Name:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {response?.course_name}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="age_group"
                          className="text-black px-4 font-bold w-40"
                        >
                          Age Group:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {response?.age_group}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="language"
                          className="text-black px-4 font-bold w-40"
                        >
                          language:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {response?.language}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="tags"
                          className="text-black px-4 font-bold w-40"
                        >
                          Day:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {response?.day}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="tags"
                          className="text-black px-4 font-bold w-40"
                        >
                          Time Slot:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {formatAMPM(response?.scheduled_timestamp?.toDate())}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="tags"
                          className="text-black px-4 font-bold w-40"
                        >
                          Start Date:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {convertLocale(
                            response?.scheduled_timestamp?.toDate(),
                            countryCode
                          )}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="tags"
                          className="text-black px-4 font-bold w-40"
                        >
                          Country:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {response?.country}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="tags"
                          className="text-black px-4 font-bold w-40"
                        >
                          Coach Name:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {response?.coach_name}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="tags"
                          className="text-black px-4 font-bold w-40"
                        >
                          Time Zone:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {response?.time_zone}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="tags"
                          className="text-black px-4 font-bold w-40"
                        >
                          Status:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {response?.pod_status}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="tags"
                          className="text-black px-4 font-bold w-40"
                        >
                          Start Url:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {response?.start_url ? (
                            <a
                              href={response.start_url}
                              target="_blank"
                              rel="noreferrer"
                              className="text-blue-900 underline"
                            >
                              <span>{response?.start_url}</span>
                            </a>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                      <div className="flex w-1/2 mt-5 float-left">
                        <label
                          htmlFor="tags"
                          className="text-black px-4 font-bold w-40"
                        >
                          Join Url:
                        </label>
                        <div className="mt-0 bg-transparent">
                          {response?.join_url ? (
                            <a
                              href={response.join_url}
                              target="_blank"
                              rel="noreferrer"
                              className="text-blue-900 underline"
                            >
                              <span>{response?.join_url}</span>
                            </a>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-5 text-center w-full cursor-pointer float-left">
                  <NavLink
                    to="/edit/pod"
                    state={{
                      id:
                        response?.id && response?.id !== ''
                          ? response?.id
                          : response?.objectID,
                    }}
                    className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                  >
                    Edit
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

ViewPod.propTypes = {
  popup: PropTypes.object.isRequired,
  setPopup: PropTypes.func,
};
