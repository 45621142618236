import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SubCondition from './SubConditions/SubCondition';
import LoadingSpinner from '../../common/Loading';
import { getData } from '../../common/Firebase';
import { useCore } from '../../core/CoreContextProvider';
const AddCondition = ({ steps, formData }) => {
  const { firestore } = useCore();
  const [showCondition, setShowCondition] = useState('');
  const [addedSteps, setAddedSteps] = useState([]);
  const [parentStep, setParentStep] = useState();
  const [addedId, setAddedId] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const ids = [];
    addedSteps.forEach((step) => {
      if (
        Object.prototype.hasOwnProperty.call(step, 'conditions') &&
        step.conditions.length
      ) {
        ids.push(step.id);
      }
    });
    setAddedId(ids);
    setLoading(false);
  }, [addedSteps]);

  useEffect(() => {
    const allignSteps = async () => {
      if (steps.length) {
        let final = [];

        for (let e of steps) {
          if (e.type == 'blurb') {
            const blurbData = await getData(
              firestore,
              e.blurb_id,
              'tapouts_blurbs'
            );
            final.push({ ...e, options: blurbData.buttons });
          } else if (
            e.type === 'topic' &&
            Object.prototype.hasOwnProperty.call(e, 'sub_steps')
          ) {
            final.push(...e.sub_steps);
          } else {
            final.push(e);
          }
        }

        setAddedSteps(final);
      }
    };
    allignSteps();
  }, [steps]);

  const getStepNumber = (step) => {
    let str = steps.findIndex((e) => e.id == step.id) + 1;
    if (step?.topic_id) {
      const findTopic = steps.findIndex((e) => e.id == step?.topic_id);
      const findSubStep = steps[findTopic].sub_steps.findIndex(
        (e) => e.id == step.id
      );
      str = `${findTopic + 1}.${findSubStep + 1}`;
    }

    return str;
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {addedSteps.map((step) => {
        if (addedId.includes(step.id)) {
          return (
            <>
              <div
                className="border p-3 text-center bg-blue-900 text-white cursor-pointer mt-2"
                onClick={() => {
                  setShowCondition(showCondition == step.id ? '' : step.id);
                  setParentStep();
                }}
              >
                {getStepNumber(step)}) {step.heading}
              </div>
              {showCondition == step.id && (
                <SubCondition
                  parentStep={step}
                  setParentStep={setParentStep}
                  remainingSteps={addedSteps}
                  formData={formData}
                  setAddedSteps={setAddedSteps}
                  setShowCondition={setShowCondition}
                  getStepNumber={getStepNumber}
                />
              )}
            </>
          );
        }
      })}

      {showCondition == '1234' && (
        <div>
          <select
            onChange={(e) => {
              setParentStep(addedSteps.find((e1) => e1.id == e.target.value));
            }}
            className="w-full px-6 py-3 mt-3 text-lg text-black transition-all duration-150 ease-linear rounded-md shadow outline-none border-2   hover:shadow-lg focus:outline-none"
          >
            <option>Select the step</option>
            {addedSteps.map((e) => {
              return (
                <option
                  disabled={
                    addedId.includes(e.id) || e.type == 'multi_select_question'
                  }
                  key={e.id}
                  value={e.id}
                >
                  {getStepNumber(e)}) {e.heading}
                </option>
              );
            })}
          </select>
          {parentStep && (
            <SubCondition
              parentStep={parentStep}
              remainingSteps={addedSteps}
              formData={formData}
              setAddedSteps={setAddedSteps}
              setShowCondition={setShowCondition}
              setParentStep={setParentStep}
              getStepNumber={getStepNumber}
            />
          )}
        </div>
      )}
      {showCondition !== '1234' && (
        <button
          onClick={() => {
            setShowCondition('1234');
            setParentStep();
          }}
          className="w-20 px-3 py-1 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-green-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
        >
          +
        </button>
      )}
    </>
  );
};

AddCondition.propTypes = {
  steps: PropTypes.array,
  formData: PropTypes.object,
};

export default AddCondition;
