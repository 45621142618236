import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Option, Select } from '@material-tailwind/react';
import { useEffect, useRef, useState } from 'react';
import {
  addDocument,
  getAllDocuments,
  updateFieldInDocument,
} from '../common/Firebase';
import { useCore } from '../core/CoreContextProvider';
import log from '../core/log';
import SubSteps from './Substeps';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { formStepsValidationSchema } from '../common/Validation';
import Autocomplete from './AutoComplete';
import CreateValidation from './Validation/CreateValidation';
import { doc, updateDoc } from 'firebase/firestore';
import EditValidation from './Validation/EditValidation';

const GenerateSteps = ({
  setAddedSteps,
  formData,
  addedSteps,
  setLoading,
  setVariable,
  variable,
  loading,
  addedQuery,
  setAddedQuery,
  eachStepData,
  setEachStepData,
  selectedSubStep,
  addedVariable,
  setAddedVariable,
  setSelectedSubStep,
}) => {
  const { firestore } = useCore();
  const [options, setOptions] = useState({});
  const [formType, setFormType] = useState('free_text');
  const [stepAdded, setStepAdded] = useState();
  const heading = useRef();
  const keyRef = useRef();
  const valueRef = useRef();
  const [blurbs, setBlurbs] = useState([]);
  const [popupStepData, setPopupStepData] = useState({
    show: false,
    topicData: null,
  });
  const [popupEditValidation, setPopupEditValidation] = useState({
    show: false,
    data: null,
  });
  const formOptions = {
    resolver: yupResolver(formStepsValidationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm(formOptions);
  const optionsType = [
    'multi_choice_question',
    'multi_select_question',
    'scale',
  ];

  function createEmptyOptions(str) {
    let option = {};
    for (var i = 0; i < 3; i++) {
      if (!str) {
        option[i + 1] = '';
      } else {
        option[i + 1] = { value: '', heading: '', background: '' };
      }
    }

    return option;
  }
  const addExtraVariable = async (key, value, step_id) => {
    try {
      await updateFieldInDocument(
        firestore,
        `tapouts_form/${formData.form_name}/steps/`,
        step_id,
        { extra_variable: { [key]: value } }
      );
      toast.success('Variable added');
    } catch (err) {
      log('Error while adding key and value');
    }
  };

  const resetStep = () => {
    reset({
      heading: '',
      type: '',
      sub_heading: '',
      url_query: '',
      status: '',
    });
    heading.current.value = '';
    setOptions({});
    setStepAdded();
  };

  const setFormOption = (e) => {
    if (optionsType.includes(e)) {
      if (!eachStepData?.heading) {
        const data =
          e == 'scale' ? createEmptyOptions('scale') : createEmptyOptions();
        setOptions(data);
      }
    } else if (e == 'blurb') {
      getAllBlurbs();
    }
    if (formType == 'topic' && getValues('image_url') !== '') {
      setValue('image_url', '');
    }
    setFormType(e);
  };

  useEffect(() => {
    if (eachStepData?.heading) {
      setLoading(true);
      resetStep();
      reset(eachStepData);
      heading.current.value = eachStepData.heading;
      setFormType(eachStepData.type);
      if (optionsType.includes(eachStepData.type)) {
        setOptions(eachStepData.options);
      }
      if (selectedSubStep?.heading) {
        setPopupStepData({
          show: true,
          topicData: eachStepData,
        });
      }
      setLoading(false);
    }
  }, []);

  const setFields = (value, key, heading) => {
    let tempObj = { ...options };
    if (formType !== 'scale') {
      tempObj[key] = value;
    } else if (formType === 'scale') {
      tempObj[key][heading] = value;
    }

    setOptions(tempObj);
  };

  const onSubmit = async (data) => {
    if (eachStepData?.heading) {
      setLoading(true);
      data = { ...data, heading: heading.current.value };
      if (optionsType.includes(data.type)) {
        data = { ...data, options: { ...options } };
      }
      let id = eachStepData.id;
      addedSteps.forEach((e, i) => {
        if (e.id == id) {
          let tempArr = addedSteps;
          data.id = id;
          tempArr[i] = data;
          setAddedSteps(tempArr);
          setEachStepData(data);
        }
      });

      if (data.type == 'topic') {
        setPopupStepData({
          show: true,
          topicData: { ...data, id: id },
        });
      }

      try {
        await updateDoc(
          doc(firestore, `tapouts_form/${formData.form_name}/steps/`, id),
          data
        );
        toast.info('steps updated');
        if (data.type != 'topic') {
          setPopupEditValidation({
            show: true,
            data: data,
          });
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    } else {
      setLoading(true);
      let finalData = {
        ...data,
        heading: heading.current.value,
        validations: '',
      };
      if (addedSteps.length + 1 > formData.no_of_steps) {
        toast.info('You need to update the steps');
        setLoading(false);
      } else if (checkQueryExist(data.url_query)) {
        if (optionsType.includes(data.type)) {
          finalData = { ...finalData, options: { ...options } };
        }
        if (finalData.variable && finalData.type !== 'topic') {
          setAddedVariable((oldArr) => [...oldArr, finalData.variable]);
        } else if (!finalData.variable || finalData.type === 'topic') {
          finalData.variable = '';
        }

        finalData.step_no = addedSteps.length
          ? addedSteps[addedSteps.length - 1].step_no + 1
          : 1;

        try {
          const id = await addDocument(
            `tapouts_form/${formData.form_name}/steps/`,
            firestore,
            finalData
          );
          if (finalData.type == 'topic') {
            setPopupStepData({
              show: true,
              topicData: { ...finalData, id: id },
            });
          } else if (finalData.type != 'blurb') {
            setStepAdded({ ...finalData, id: id });
          } else {
            resetStep();
            setFormType('free_text');
          }

          setAddedSteps((oldSteps) => [...oldSteps, { ...finalData, id: id }]);

          toast.success(`Step-${finalData.step_no} has been created`);
          setLoading(false);
        } catch (err) {
          log('Error while adding document' + err);
          setLoading(false);
        }
      } else {
        toast.info('Url query already exist');
        setLoading(false);
      }
    }
  };

  const resetTopic = () => {
    reset({ heading: '', type: '', sub_heading: '', url_query: '' });
    heading.current.value = '';
    setPopupStepData({
      show: false,
      topicData: null,
    });
    setFormType('free_text');
  };

  const getAllBlurbs = async () => {
    setLoading(true);
    try {
      const data = await getAllDocuments(firestore, 'tapouts_blurbs');
      if (data) setBlurbs(data);
      setLoading(false);
    } catch (err) {
      log('Error while getting all blurbs');
      setLoading(false);
    }
  };

  const addOption = () => {
    const optionsArr = [...Object.keys(options)];
    let tempObj = {};
    if (formType == 'scale') {
      const index = +optionsArr[optionsArr.length - 1] + 1;
      tempObj[index] = { value: '', heading: '', background: '' };
    } else {
      tempObj[+optionsArr[optionsArr.length - 1] + 1] = '';
    }
    if (Object.keys(options).length < 20)
      setOptions({ ...options, ...tempObj });
  };

  const removeOption = (key) => {
    let tempObj = { ...options };
    delete tempObj[key];
    if (Object.keys(options).length > 2) setOptions(tempObj);
  };

  const checkQueryExist = (value) => {
    let exist = true;
    if (addedQuery.includes(value)) {
      exist = false;
    } else {
      setAddedQuery((oldQuery) => [...oldQuery, value]);
    }

    return exist;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-3/3">
          <div className="flex-auto w-1/3 relative mt-3">
            <Autocomplete
              suggestions={variable}
              inputValue={heading}
              addedVariables={addedVariable}
            />
            <label
              htmlFor="heading"
              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
            >
              Heading
              <span className="text-red-600">*</span>
            </label>
          </div>

          {eachStepData != undefined && eachStepData?.variable != '' ? (
            <>
              <div className="flex-auto ml-4 mt-3 w-1/3 relative">
                <input
                  type="text"
                  placeholder=" "
                  defaultValue={variable[eachStepData.variable]}
                  disabled={true}
                  className={`pt-6 bg-gray-300 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
                />
                <label
                  htmlFor="heading"
                  className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                >
                  variable
                  <span className="text-red-600"></span>
                </label>
              </div>

              <div
                className="flex-auto ml-4 mt-3 w-1/3 relative item-center"
                onClick={() => {
                  let key = eachStepData.variable;
                  eachStepData.variable = '';
                  setEachStepData(eachStepData);
                  let arr = [...addedVariable];
                  const index = arr.indexOf(key);
                  if (index > -1) {
                    arr.splice(index, 1);
                  }
                  setAddedVariable(arr);
                }}
              >
                <a className=" p-1 mr-1 cursor-pointer text-red-600">
                  <span className="block">Change variable</span>
                </a>
              </div>
            </>
          ) : (
            <div className="flex-auto ml-4 mt-3 w-1/3 relative">
              {Object.keys(variable).length !== 0 &&
                Object.keys(variable).length !== addedVariable.length &&
                formType !== 'topic' && (
                  <Controller
                    name={'variable'}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          onChange={(e) => {
                            onChange(e);
                          }}
                          id="variable"
                          value={value}
                          label="Select variable"
                          className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                            value ? ' focus-label' : ''
                          }`}
                        >
                          {Object.keys(variable).map((e, i) => {
                            return (
                              <>
                                {!addedVariable.includes(e) && (
                                  <Option key={btoa(i)} value={e}>
                                    {variable[e]}
                                  </Option>
                                )}
                              </>
                            );
                          })}
                        </Select>
                      );
                    }}
                    rules={{ required: false }}
                  />
                )}
            </div>
          )}
        </div>
        <div className="mr-4 flex-auto w-1/3 relative mt-6">
          <span className="text-red-600 absolute -top-6">
            {errors?.sub_heading?.message}
          </span>
          <input
            type="text"
            placeholder=" "
            defaultValue=""
            disabled={
              !selectedSubStep
                ? false
                : Object.keys(selectedSubStep).length == 0
                ? false
                : true
            }
            className={`pt-6 ${
              !selectedSubStep
                ? 'bg-transparent'
                : Object.keys(selectedSubStep).length != 0
                ? 'bg-gray-300'
                : 'bg-transparent'
            } pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
            {...register('sub_heading')}
          />
          <label
            htmlFor="heading"
            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
          >
            Sub-Heading
            <span className="text-red-600">*</span>
          </label>
        </div>
        <div className="mr-4 flex-auto w-1/3 relative mt-3">
          <span className="text-red-600 absolute -top-6">
            {errors?.url_query?.message}
          </span>
          <input
            type="text"
            placeholder=" "
            defaultValue=""
            disabled={
              !selectedSubStep
                ? false
                : Object.keys(selectedSubStep).length == 0
                ? false
                : true
            }
            className={`pt-6 ${
              !selectedSubStep
                ? 'bg-transparent'
                : Object.keys(selectedSubStep).length != 0
                ? 'bg-gray-300'
                : 'bg-transparent'
            } pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
            {...register('url_query')}
          />
          <label
            htmlFor="heading"
            className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
          >
            Url-Query
            <span className="text-red-600">*</span>
          </label>
        </div>

        <div className="flex-auto mr-4 w-1/3 relative mt-6">
          <span className="text-red-600 absolute -top-6">
            {errors?.type?.message}
          </span>

          <Controller
            name={'type'}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  onChange={(e) => {
                    onChange(e);
                    setFormOption(e);
                  }}
                  disabled={popupStepData.show}
                  id="type"
                  value={value}
                  label="Select type"
                  className={`pt-6 ${
                    popupStepData.show ? 'bg-gray-300' : 'bg-transparent'
                  } pb-1 shadow-md block w-full px-4 mt-0  border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0  focus:border-gray-400 border-gray-200 rounded-md ${
                    value ? ' focus-label' : ''
                  }`}
                >
                  <Option value={'topic'}>Topic</Option>
                  <Option value={'multi_choice_question'}>
                    Multi Choice Question
                  </Option>
                  <Option value={'free_text'}>Free Text</Option>
                  <Option value={'multi_select_question'}>
                    Multi Select Question
                  </Option>
                  <Option value={'number'}>Number</Option>
                  <Option value={'blurb'}>Blurb</Option>
                  <Option value={'scale'}>Scale</Option>
                  <Option value={'email'}>Email</Option>
                  <Option value={'pod'}>Pod</Option>
                  <Option value={'payment'}>Payment</Option>
                  <Option value={'static'}>Static</Option>
                </Select>
              );
            }}
            rules={{ required: false }}
          />
        </div>
        {formType === 'topic' && (
          <div className="flex-auto mr-4 w-1/3 relative mt-6">
            <span className="text-red-600 absolute -top-6">
              {errors?.image_url?.message}
            </span>
            <div className="col-span-3 lg:col-span-3">
              <div className="flex-auto w-full relative">
                <label htmlFor="image_url">Add Background Image</label>
                <input
                  type="text"
                  placeholder=" "
                  id="image_url"
                  {...register('image_url')}
                  className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                />
              </div>
            </div>
          </div>
        )}
        {(formType === 'multi_choice_question' ||
          formType === 'multi_select_question' ||
          formType === 'scale') && (
          <ul>
            {Object.keys(options).map((key, index) => (
              <li key={key}>
                <div className="mr-4 flex-auto w-1/3 relative mt-3 ">
                  <span className="text-red-600 absolute -top-6"></span>
                  <input
                    type="text"
                    placeholder=" "
                    defaultValue={
                      formType !== 'scale' ? options[key] : options[key].value
                    }
                    id="option"
                    className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                    onChange={(e) => {
                      setFields(e.target.value, key, 'value');
                    }}
                  />

                  <label
                    htmlFor="option"
                    className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                  >
                    option - {index + 1} <span className="text-red-600">*</span>
                  </label>
                </div>
                {formType == 'scale' && (
                  <div>
                    <div className="mr-4 flex-auto w-1/3 relative mt-3 ">
                      <input
                        type="text"
                        placeholder=" "
                        id="option_heading"
                        defaultValue={options[key].heading}
                        className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                        onChange={(e) => {
                          setFields(e.target.value, key, 'heading');
                        }}
                      />
                      <label
                        htmlFor="option_heading"
                        className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                      >
                        heading - {index + 1}
                      </label>
                    </div>
                    <div className="mr-4 flex-auto w-1/3 relative mt-3 ">
                      <input
                        type="text"
                        placeholder=" "
                        id="option_background"
                        defaultValue={options[key].background}
                        className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                        onChange={(e) => {
                          setFields(e.target.value, key, 'background');
                        }}
                      />
                      <label
                        htmlFor="option_heading"
                        className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                      >
                        background - {index + 1}
                      </label>
                    </div>
                  </div>
                )}
                {index + 1 === Object.keys(options).length ? (
                  <button type="button" onClick={() => addOption()}>
                    +
                  </button>
                ) : (
                  <button type="button" onClick={() => removeOption(key)}>
                    -
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
        {formType === 'blurb' && !loading && (
          <div>
            {blurbs.length ? (
              <div className="flex-auto  mt-6 w-1/3 relative">
                <Controller
                  name={'blurb_id'}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        onChange={(e) => {
                          onChange(e);
                        }}
                        id="blurb_id"
                        value={value}
                        label="Select Blurb"
                        className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                          value ? ' focus-label' : ''
                        }`}
                      >
                        {blurbs.map((e, i) => {
                          return (
                            <Option key={btoa(i)} value={e.id}>
                              {e.blurb_name}
                            </Option>
                          );
                        })}
                      </Select>
                    );
                  }}
                  rules={{ required: false }}
                />
              </div>
            ) : (
              <div>No Blurbs Found</div>
            )}
          </div>
        )}

        {!popupStepData.show && !stepAdded && (
          <div className="p-5 text-center">
            <button className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none">
              {eachStepData != undefined ? 'Update step' : 'Create step'}
            </button>
          </div>
        )}
      </form>

      {stepAdded && (
        <>
          <div className="mt-5">Extra Variables</div>
          <div className="mr-4 flex-auto w-1/3 relative mt-3">
            <input
              type="text"
              placeholder=" "
              ref={keyRef}
              id="key"
              defaultValue=""
              className={`pt-6 bg-transparent pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
            />
            <label
              htmlFor="key"
              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
            >
              Key
              <span className="text-red-600">*</span>
            </label>
          </div>
          <div className="mr-4 flex-auto w-1/3 relative mt-3">
            <input
              type="text"
              placeholder=" "
              id="value"
              ref={valueRef}
              defaultValue=""
              className={`pt-6 bg-transparent pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md`}
            />
            <label
              htmlFor="key"
              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
            >
              Value
              <span className="text-red-600">*</span>
            </label>
          </div>
          <div
            onClick={() => {
              keyRef.current.value == '' || valueRef.current.value == ''
                ? toast.info('Please enter all required field')
                : addExtraVariable(
                    keyRef.current.value,
                    valueRef.current.value,
                    stepAdded.id
                  );
            }}
          >
            Add
          </div>
          <CreateValidation
            step={stepAdded}
            form_name={formData.form_name}
            resetStep={resetStep}
            setLoading={setLoading}
          />
        </>
      )}
      {popupEditValidation.show && (
        <EditValidation
          step={stepAdded}
          form_name={formData.form_name}
          resetStep={resetStep}
          setLoading={setLoading}
          popupEditValidation={popupEditValidation}
        />
      )}

      {popupStepData.show && (
        <SubSteps
          createEmptyOptions={createEmptyOptions}
          popup={popupStepData}
          resetTopic={resetTopic}
          setAddedSteps={setAddedSteps}
          addedSteps={addedSteps}
          setLoading={setLoading}
          formData={formData}
          setVariable={setVariable}
          variable={variable}
          checkQueryExist={checkQueryExist}
          eachStepData={eachStepData}
          setEachStepData={setEachStepData}
          selectedSubStep={selectedSubStep}
          setAddedVariable={setAddedVariable}
          addedVariable={addedVariable}
          setSelectedSubStep={setSelectedSubStep}
          resetStep={resetStep}
        />
      )}
    </>
  );
};
GenerateSteps.propTypes = {
  setAddedSteps: PropTypes.func,
  formData: PropTypes.object,
  addedSteps: PropTypes.array,
  setLoading: PropTypes.func,
  setVariable: PropTypes.func,
  variable: PropTypes.object,
  loading: PropTypes.bool,
  addedQuery: PropTypes.array,
  setAddedQuery: PropTypes.func,
  eachStepData: PropTypes.object,
  setEachStepData: PropTypes.func,
  selectedSubStep: PropTypes.object,
  addedVariable: PropTypes.array,
  setAddedVariable: PropTypes.func,
  setSelectedSubStep: PropTypes.func,
};

export default GenerateSteps;
