import React, { useEffect, useState } from 'react';
import { getDataByQuery, updateFieldInDocument } from '../common/Firebase';
// import { useAuth } from '../core/AuthContextProvider';
import { collection, query, Timestamp, where } from 'firebase/firestore';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import {
  addSubsMinutes,
  convertLocale,
  convertTimestampToDateTime,
  findDayFromIndex,
  updatedPayload,
} from '../common/utils';
import { useAuth } from '../core/AuthContextProvider';
import { useCore } from '../core/CoreContextProvider';
import { ScheduleSelectCoach } from './ScheduleSelectCoach';

function Schedule({
  podId,
  podData,
  handleSetLoading,
  setDirty,
  reEvaluateCoachAndOnlooker,
}) {
  const { firestore } = useCore();
  const { userData, countryCode } = useAuth();
  const [scheduleData, setScheduleData] = useState([]);
  const [, setLoading] = useState(false);
  const [coaches, setCoaches] = useState({ show: false, data: null });
  const [dataChangeFlag, setDataChangeFlag] = useState({});

  useEffect(() => {
    if (podData) {
      const getScheduleData = async () => {
        setLoading(true);
        try {
          const scheduleRef = collection(firestore, 'live_session_schedule');
          let q = query(
            scheduleRef,
            where('pod_doc_id', '==', podId),
            where('status', '==', 'scheduled')
          );
          const filteredScheuled = await getDataByQuery(q);
          if (!filteredScheuled.length) return;
          filteredScheuled.sort(function (a, b) {
            return (
              new Date(a.scheduled_timestamp.toDate()) -
              new Date(b.scheduled_timestamp.toDate())
            );
          });
          const tempFlag = {};
          const newTempData = filteredScheuled.map((schedule) => {
            const dateTime = convertTimestampToDateTime(
              schedule.scheduled_timestamp.toDate()
            );
            let timestamp;
            const timeZone = podData?.time_zone;
            timestamp = new Date(
              `${dateTime.date} ${dateTime.time}`
            ).toLocaleString('en-US', {
              timeZone,
            });
            tempFlag[schedule.id] = false;
            return {
              ...schedule,
              start_time: convertTimestampToDateTime(timestamp).time,
              scheduled_date: convertTimestampToDateTime(timestamp).date,
              scheduled_timestamp: new Date(timestamp),
            };
          });

          setScheduleData(newTempData);
          setDataChangeFlag(tempFlag);
          setLoading(false);
        } catch (err) {
          console.error(
            'Firebase error : While fetching  live_session_schedule',
            err
          );
          setLoading(false);
        }
      };
      getScheduleData();
    }
  }, [podData]);

  const getCoaches = async (data, index, type) => {
    setLoading(true);
    const day = findDayFromIndex(data.scheduled_date);

    try {
      const coachRef = collection(firestore, 'coaches');
      let q;
      let coachData;
      if (type !== 'onlooker') {
        q = query(
          coachRef,
          where('country', '==', podData?.country),
          where('language', 'array-contains', podData?.language),
          where('coach_status', '==', 'active')
        );
        const tempData = await getDataByQuery(q);

        coachData = tempData.filter(
          (coach) => !coach.coach_type || coach.coach_type !== 'onlooker'
        );
      } else {
        q = query(
          coachRef,
          where('country', '==', podData?.country),
          where('language', 'array-contains', podData?.language),
          where('coach_status', '==', 'active'),
          where('coach_type', '==', 'onlooker')
        );
        coachData = await getDataByQuery(q);
      }

      if (coachData.length) {
        coachData = coachData.filter((data) => {
          return data?.age_group?.includes(podData?.age_group);
        });

        coachData.length
          ? setCoaches({
              ...coaches,
              show: true,
              data: coachData,
              index,
              day,
              start_time: data.start_time,
              scheduled_date: data.scheduled_date,
              type,
            })
          : toast.error(`No ${type} found based on your selection`);
        setLoading(false);
      } else {
        toast.error(`No ${type} found based on your selection`);
        setLoading(false);
      }
    } catch (err) {
      console.error('Firebase error : While getting coaches', err);
      setLoading(false);
    }
  };

  const handleScheduleData = async (date, index) => {
    const tempScheduleData = [...scheduleData];
    const dateObj = new Date(date);
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const tempPayload = { ...tempScheduleData[index] };
    const actualTimestamp = new Date(
      dateObj.toDateString() +
        ' ' +
        dateObj.toLocaleTimeString() +
        ` ${podData.time_zone_abbr}`
    );
    tempPayload.scheduled_timestamp = actualTimestamp;
    tempPayload.start_time = convertTimestampToDateTime(dateObj).time;
    tempPayload.start_date = convertLocale(dateObj, countryCode);
    const selectedDay = days[dateObj.getDay()];
    tempPayload.day = [selectedDay];

    const result = await reEvaluateCoachAndOnlooker(tempPayload);
    const tempFlag = { ...dataChangeFlag };
    tempFlag[tempScheduleData[index].id] = true;

    if (!result?.coach) {
      tempScheduleData[index]['coach_name'] = '';
      tempScheduleData[index]['coach_uid'] = '';
      tempFlag[tempScheduleData[index].id] = false;
    }
    if (!result?.onlooker) {
      tempScheduleData[index]['onlooker_name'] = '';
      tempScheduleData[index]['onlooker_uid'] = '';
    }

    tempScheduleData[index]['scheduled_timestamp'] = date;

    tempScheduleData[index]['scheduled_date'] =
      convertTimestampToDateTime(date).date;
    tempScheduleData[index]['start_time'] =
      convertTimestampToDateTime(date).time;
    setDataChangeFlag(tempFlag);
    setScheduleData(tempScheduleData);
  };

  const updateCoach = async (data) => {
    handleSetLoading(true);
    const changeFlag = { ...dataChangeFlag };
    const tempData = { ...data };
    tempData.scheduled_timestamp = Timestamp.fromDate(
      new Date(
        `${data.scheduled_date} ${data.start_time} ${podData.time_zone_abbr}`
      )
    );
    try {
      updatedPayload(
        tempData,
        'live_session_schedule',
        'update',
        userData.uid
      ).then(async (payLoadData) => {
        await updateFieldInDocument(
          firestore,
          'live_session_schedule',
          data.id,
          payLoadData
        );
      });

      if (changeFlag[tempData.id]) {
        dateChangeFunc(data, 'coach');
        dateChangeFunc(data, 'onlooker');
      }

      handleSetLoading(false);
      toast.success('Coach updated sucessfully!');
      setDirty(true);
      changeFlag[tempData.id] = false;
      setDataChangeFlag(changeFlag);
    } catch (err) {
      handleSetLoading(false);
      console.error(`Error while updating coach under schedule: `, err.message);
    }
  };
  const dateChangeFunc = async (data, type) => {
    const coachRef = collection(firestore, 'coach_schedule');
    let q;
    if (type !== 'onlooker') {
      q = query(coachRef, where('coach_id', '==', data.coach_uid));
    } else {
      q = query(coachRef, where('onlooker_id', '==', data.onlooker_uid));
    }
    const coachScheduleData = await getDataByQuery(q);
    if (coachScheduleData.length) {
      let tempSpecificAvailability = [];
      if (coachScheduleData[0].specific_availability) {
        tempSpecificAvailability = [
          ...coachScheduleData[0].specific_availability,
          {
            date: data.scheduled_date,
            day: podData.day,
            action: 'unavailable',
            timings: [
              {
                start_time: data.start_time,
                end_time: addSubsMinutes(
                  data.start_time,
                  podData.pod_duration,
                  'add'
                ),
              },
            ],
          },
        ];
      } else {
        tempSpecificAvailability = [
          {
            date: data.scheduled_date,
            day: podData.day,
            action: 'unavailable',
            timings: [
              {
                start_time: data.start_time,
                end_time: addSubsMinutes(
                  data.start_time,
                  podData.pod_duration,
                  'add'
                ),
              },
            ],
          },
        ];
      }

      updatedPayload(
        {
          specific_availability: tempSpecificAvailability,
        },
        'coach_schedule',
        'update',
        userData.uid
      ).then(async (payLoadData) => {
        await updateFieldInDocument(
          firestore,
          'coach_schedule',
          data.coach_uid,
          payLoadData
        );
      });
    }
  };

  const setSelectedCoach = (name, id, uid, index, type) => {
    const tempScheduleData = [...scheduleData];
    if (type !== 'onlooker') {
      tempScheduleData[index]['coach_name'] = name;
      tempScheduleData[index]['coach_uid'] = uid;
    } else {
      tempScheduleData[index]['onlooker_name'] = name;
      tempScheduleData[index]['onlooker_uid'] = uid;
    }
    setScheduleData(tempScheduleData);
    const tempFlag = { ...dataChangeFlag };
    tempFlag[tempScheduleData[index].id] = true;
    setDataChangeFlag(tempFlag);
  };

  return (
    <div className="w-full mx-auto my-0 text-left flex mt-5">
      <div className="table-section w-full schedule-table">
        {scheduleData.length ? (
          <table className="min-w-full border" id="table">
            <thead className="">
              <tr>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                >
                  Session Name
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                >
                  Date/Time
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                >
                  Coach
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                >
                  Onlooker
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {scheduleData.map((data, index) => {
                return (
                  <tr
                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                    key={data.id}
                  >
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {data.session_name}
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      <div className="flex-auto relative">
                        <ReactDatePicker
                          selected={data.scheduled_timestamp}
                          onChange={(date) => {
                            handleScheduleData(date, index);
                          }}
                          showTimeSelect
                          timeIntervals={15}
                          minDate={new Date()}
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                      </div>
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      <div className="flex w-full relative border border-b-2 rounded-md">
                        <input
                          value={data.coach_name}
                          type="text"
                          readOnly
                          className="pt-6 shadow-md pb-1 block w-full px-4 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200"
                        />
                        <label
                          htmlFor="coach_name"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Coach
                        </label>
                        <div
                          onClick={() => getCoaches(data, index, 'coach')}
                          className="cursor-pointer flex items-center justify-center px-4 modal-open border-l"
                        >
                          <div className="bg-search"></div>
                        </div>
                      </div>
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      <div className="flex w-full relative border border-b-2 rounded-md">
                        <input
                          value={data.onlooker_name}
                          type="text"
                          readOnly
                          className="pt-6 shadow-md pb-1 block w-full px-4 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200"
                        />
                        <label
                          htmlFor="onlooker_name"
                          className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                        >
                          Onlooker
                        </label>
                        <div
                          onClick={() => getCoaches(data, index, 'onlooker')}
                          className="cursor-pointer flex items-center justify-center px-4 modal-open border-l"
                        >
                          <div className="bg-search"></div>
                        </div>
                      </div>
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      <input
                        type="submit"
                        value={'Update'}
                        onClick={() => updateCoach(data)}
                        className={`w-40 px-6 py-3 text-lg text-white transition-all 
                        duration-150 ease-linear rounded-md shadow outline-none ${
                          dataChangeFlag[data.id]
                            ? 'bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none cursor-pointer '
                            : 'bg-gray-300 cursor-not-allowed'
                        } `}
                        disabled={!dataChangeFlag[data.id]}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          'No Schedule found!'
        )}
        <ScheduleSelectCoach
          coaches={coaches}
          podData={podData}
          setCoaches={setCoaches}
          setSelectedCoach={(name, id, uid, index, type) =>
            setSelectedCoach(name, id, uid, index, type)
          }
        />
      </div>
    </div>
  );
}

Schedule.propTypes = {
  podId: PropTypes.string,
  podData: PropTypes.object,
  handleSetLoading: PropTypes.func.isRequired,
  setDirty: PropTypes.func,
  handlePopUpClose: PropTypes.func,
  reEvaluateCoachAndOnlooker: PropTypes.func,
};

export default React.memo(Schedule);
