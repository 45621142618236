import React, { useEffect, useState } from 'react';
import {
  addDocument,
  getConfigurationData,
  getData,
  updateFieldInDocument,
} from '../common/Firebase';
import { useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useCore } from '../core/CoreContextProvider';
import { useAuth } from '../core/AuthContextProvider';
import LoadingSpinner from '../common/Loading';
import { yupResolver } from '@hookform/resolvers/yup';
import { moduleValidationSchema } from '../common/Validation';
import ManageModuleSessions from './ManageModuleSessions';
import { capitalizeFirstWords, updatedPayload } from '../common/utils';
import { toast } from 'react-toastify';
import BackButton from '../common/BackButton';
import { Select, Option } from '@material-tailwind/react';
import { PropTypes } from 'prop-types';
import log from '../core/log';

export function CreateEditModule() {
  const { firestore } = useCore();
  const { userData } = useAuth();
  const location = useLocation();
  const { id } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [showAddSessions, setShowAddSessions] = useState(false);
  const [moduleDocId, setModuleDocId] = useState('');
  const [moduleData, setModuleData] = useState({});
  const [toasting, setToasting] = useState(false);
  const [configs, setConfigs] = useState({});
  const formOptions = { resolver: yupResolver(moduleValidationSchema) };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm(formOptions);

  const onSubmit = async (payload) => {
    if (showAddSessions) {
      try {
        setLoading(true);
        delete payload.sessions;
        updatedPayload(payload, 'module', 'update', userData.uid).then(
          async (payLoadData) => {
            await updateFieldInDocument(
              firestore,
              'modules',
              moduleDocId !== '' ? moduleDocId : id,
              payLoadData
            );
            toast.success('Module Updated Successfully', {
              toastId: 'wrong_cred',
              onClose: () => {
                setToasting(false);
              },
              onOpen: () => {
                setToasting(true);
              },
            });
            setLoading(false);
            setShowAddSessions(true);
          }
        );
      } catch (err) {
        console.error(
          `Error while updating module document : ${
            moduleDocId !== '' ? moduleDocId : id
          }`,
          err.message
        );
        setLoading(false);
      } finally {
        reset(payload);
      }
    } else {
      try {
        setLoading(true);
        updatedPayload(payload, 'module', 'create', userData.uid).then(
          async (payLoadData) => {
            const moduleDocumentId = await addDocument(
              'modules',
              firestore,
              payLoadData
            );
            setModuleDocId(moduleDocumentId);
            setModuleData(payLoadData);
            toast.success('Module Added Successfully', {
              toastId: 'wrong_cred',
              onClose: () => {
                setToasting(false);
              },
              onOpen: () => {
                setToasting(true);
              },
            });
            setLoading(false);
            setShowAddSessions(true);
          }
        );
      } catch (err) {
        console.error(`Error while creating module document: `, err.message);
        setLoading(false);
      } finally {
        reset(payload);
      }
    }
  };
  const getConfigData = async () => {
    setLoading(true);
    try {
      const data = await getConfigurationData(firestore);
      if (data) setConfigs(data);
      setLoading(false);
    } catch (error) {
      log('Firebase error: while getting configuration data', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getConfigData();
    const getModuleData = async () => {
      if (id) {
        const moduleData = await getData(firestore, id, 'modules');
        const tempData = { ...moduleData };
        delete tempData.sessions;
        tempData.sessions = [];
        moduleData.sessions &&
          moduleData.sessions.map((val, index) => {
            if (val.id) {
              tempData.sessions[index] = val.id;
            } else {
              tempData.sessions.push(val);
            }
          });
        reset(tempData);
        setModuleData(tempData);
        setShowAddSessions(true);
      } else {
        reset({ module_name: '', module_status: '', module_description: '' });
        setModuleData({});
        setShowAddSessions(false);
        setModuleDocId('');
      }
    };
    getModuleData();
  }, [location]);

  return (
    <div className="grid grid-rows-1 min-h-screen">
      <div className="flex overflow-hidden">
        <div className="content relative flex-1 px-6 pt-2 pb-10 bg-indigo-50">
          <div className="intro-y flex items-center mt-6">
            <BackButton isDirty={isDirty} />
            <h2 className="text-2xl font-medium mr-auto">
              {id ? 'Edit ' : 'Create'} Module
            </h2>
          </div>
          {loading && <LoadingSpinner />}
          {Object.keys(configs).length !== 0 && (
            <div className="grid grid-cols-12 gap-6 mt-5">
              <div className="col-span-12 lg:col-span-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="bg-white shadow-md rounded-md">
                    <div className="p-5">
                      <div className="preview">
                        <div className="mt-5 flex flex-col sm:flex-row items-center">
                          <div className="flex-auto w-1/3 relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.module_name?.message}
                            </span>
                            <input
                              id="module-name"
                              type="text"
                              placeholder=" "
                              {...register('module_name', {
                                required: true,
                              })}
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                            />
                            <label
                              htmlFor="module-name"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Module Name
                              <span className="text-red-600">*</span>
                            </label>
                          </div>
                          {moduleData?.module_id && (
                            <div className="w-1/3 relative mx-2">
                              <input
                                id="module_id"
                                type="text"
                                className="pt-6 pb-1 block w-full shadow-md px-4 mt-0 bg-gray-200 border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                placeholder=" "
                                value={moduleData?.module_id}
                                disabled={true}
                              />
                              <label
                                htmlFor="module_id"
                                className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                              >
                                Module Id
                              </label>
                            </div>
                          )}

                          <div
                            className={`  ${
                              !moduleData?.module_id && 'ml-2'
                            } flex-auto w-1/2 relative`}
                          >
                            <span className="text-red-600 absolute -top-6">
                              {errors?.module_status?.message}
                            </span>
                            {configs?.module_status && (
                              <Controller
                                name={'module_status'}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      id="module-status"
                                      value={value}
                                      label="Select Module Status*"
                                      variant="outlined"
                                      className={`pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md ${
                                        value ? ' focus-label' : ''
                                      }`}
                                    >
                                      {configs?.module_status?.map((status) => {
                                        return (
                                          <Option key={status} value={status}>
                                            {capitalizeFirstWords(status)}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  );
                                }}
                                rules={{ required: true }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="mt-5 flex flex-col sm:flex-row items-center">
                          <div className="flex-auto w-full relative">
                            <span className="text-red-600 absolute -top-6">
                              {errors?.module_description?.message}
                            </span>
                            <textarea
                              id="module-description"
                              type="text"
                              className="pt-6 pb-1 shadow-md block w-full px-4 mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md h-40"
                              placeholder=" "
                              {...register('module_description', {
                                required: true,
                              })}
                            ></textarea>
                            <label
                              htmlFor="module-description"
                              className="absolute duration-300 top-4 -z-1 origin-0 text-gray-500 px-4"
                            >
                              Description
                              <span className="text-red-600">*</span>
                            </label>
                          </div>
                        </div>
                        <div className="p-5 text-center">
                          <button
                            disabled={toasting}
                            className="w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                          >
                            {`${showAddSessions || id ? 'Update' : 'Create'}`}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {showAddSessions && (
                  <ManageModuleSessions
                    showAddSessions={showAddSessions}
                    moduleDocId={moduleDocId}
                    handleSetLoading={(flag) => setLoading(flag)}
                    editData={moduleData}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
CreateEditModule.propTypes = {
  control: PropTypes.any,
};
